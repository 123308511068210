import React, { FC, useEffect } from 'react';
import { AccountsStep3FormData, RespPlanData, RespPlanTypeCd } from '../types';
import { Col, Row } from 'react-flexbox-grid';
import { RefData } from 'types/interfaces';
import { ProductFormRadioBoolean, ProductFormSelect } from 'components/productFormFields';
import { AccountCard } from './_layout/AccountCard';
import { useContainerCtx } from '../../../providers/containerCtx/ContainerCtx';

// ***********************************************************************
// Ts Type definitions
// ***********************************************************************
export type RespPlanSelectorProps = {
  index: number;
  respPlan: RespPlanData;
  refData: RefData[];
};

// ***********************************************************************
// Component
// ***********************************************************************
export const RespPlanSelector: FC<RespPlanSelectorProps> = ({ index, respPlan, refData }) => {
  const { accountType, currency, respPlanTypeCd } = respPlan;

  const { locale, getTextGlobal, bncReactForm, getText, formatErrorMessage } = useContainerCtx<AccountsStep3FormData>();

  useEffect(() => {
    if (respPlanTypeCd !== RespPlanTypeCd.FAMILY) return;
    bncReactForm.change(`respPlanTypes[${index}].isFamilySibling`, undefined);
  }, [respPlanTypeCd, index, bncReactForm]);

  if (!accountType) return null;
  return (
    <AccountCard isSelected={!!respPlanTypeCd} account={{ currency, accountType }}>
      <Row>
        <Col xs={12} md={8} lg={6}>
          <div data-test={`respPlan_field_${accountType.code}_${currency[locale]}`}>
            <ProductFormSelect
              className={'mc-mb-0'}
              name={`respPlanTypes[${index}].respPlanTypeCd`}
              formatErrorMessage={formatErrorMessage()}
              refData={refData}
              label={getText('respPlanTypeCdLabel')}
              placeholder={getText('respPlanTypeCdPlaceholder')}
              ariaLabel={getText('respPlanTypeCdLabel')}
            />
          </div>
        </Col>
      </Row>

      {respPlanTypeCd === RespPlanTypeCd.FAMILY && (
        <Row className={'mc-mt-medium'}>
          <Col xs={12}>
            <div data-test={`isFamilySibling_field_${accountType.code}_${currency[locale]}`}>
              <ProductFormRadioBoolean
                className="inline"
                label={<div className={'mc-fw-medium'}>{getText('isFamilySiblingLabel')}</div>}
                name={`respPlanTypes[${index}].isFamilySibling`}
                yesLabel={getTextGlobal('button.yes')}
                noLabel={getText('isFamilySiblingNoLabel')}
                formatErrorMessage={formatErrorMessage()}
                inline
              />
            </div>
          </Col>
        </Row>
      )}
    </AccountCard>
  );
};
