import { callRequest } from 'services/callRequestManager';
import configs from 'configs';
import { saveItem, loadItem } from 'utils';
import getLogger from 'utils/getLogger';

const logger = getLogger('baoLoginManager');

export type CurrentEnv = {
  ENV: string;
};

export const getCurrentEnv = (): Promise<string> => {
  const DEV_ENV = loadItem('DEV_ENV') || null;
  if (DEV_ENV) {
    return Promise.resolve(DEV_ENV);
  }

  return callRequest<CurrentEnv>({
    url: configs.params.ENV_URL, // This configuration is always taken from Common.json, since we don't yet know the environment we're on.
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    resType: 'json',
  })
    .catch((err) => {
      // On the local environments ONLY, when 'DEV_ENV' is not set, this call will not work because it point to localhost.
      // Default to development configurations in that case.
      // If this is not a development build, default to common configurations instead.
      // TODO : Redirect to an error page instead, once we have such a page.
      if (process.env.NODE_ENV === 'development') {
        const env: CurrentEnv = {
          ENV: 'dev',
        };
        return env;
      }

      logger.error(`Error getting current environment`, err);
      throw err;
    })
    .then((json: CurrentEnv) => {
      const currentEnv = json.ENV;
      saveItem('CURRENT_ENV', currentEnv);
      return currentEnv;
    });
};
