/* eslint-disable import/prefer-default-export */
import qs from 'query-string';
import { checkStatus, parseJson } from 'utils/fetchHandlers';
import { OccupationTitleConfig } from 'types/interfaces';
import { getLocale } from 'utils';

export const getOccupationTitle = <S>(config: OccupationTitleConfig, search = ''): Promise<S | null> => {
  const { endpoint, method, headers, maxRecords = 100, language = getLocale(), typeCode = 'NOC2016' } = config;

  const query: string = qs.stringify({
    query: search,
    language: language.toUpperCase(),
    limit: maxRecords,
    typeCode: typeCode,
  });

  if (search === '') {
    return Promise.resolve(null);
  }

  return fetch(`${endpoint}?${query}`, {
    method,
    headers,
  })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => json);
};
