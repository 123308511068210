import React, { FunctionComponent } from 'react';
import { Button } from '@nbc-design/button';
import { LANG } from 'utils/constants';
import { getLocale, saveLocale } from 'utils';
import { getAllMessagesByLocale } from 'messages/messagesUtils';
import { ConfigData, ProductFormFieldChangeHandler } from 'types/interfaces';

type changeLocaleParams = {
  locale: string;
  messages: {
    [id: string]: {};
  };
};

export type LocaleButtonProps = {
  changeLocale: (data: changeLocaleParams) => void;
  change: ProductFormFieldChangeHandler;
  config: ConfigData;
};

const LocaleButton: FunctionComponent<LocaleButtonProps> = (props: LocaleButtonProps) => {
  const { change, changeLocale, config } = props;

  if (!config.BUTTON_LANG) {
    return null;
  }

  const nextLocale = getLocale() === LANG.EN ? LANG.FR : LANG.EN;

  const handleButtonClick = (): void => {
    changeLocale({ locale: nextLocale, messages: getAllMessagesByLocale(nextLocale) });
    change('lang', nextLocale);
    saveLocale(nextLocale);

    window.location.reload();
  };

  return (
    <Button className="nav-lang-btn" type="button" onClick={handleButtonClick} appearance="secondary" lang={nextLocale}>
      {nextLocale.toUpperCase()}
    </Button>
  );
};

export default LocaleButton;
