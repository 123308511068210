enum BaoServiceActionTypeKeys {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  SET_SUBMIT_MESSAGE_INDEX = 'SET_SUBMIT_MESSAGE_INDEX',
  SET_USER_DATA = 'SET_USER_DATA',
  SET_USER_CREDENTIALS = 'SET_USER_CREDENTIALS',
  BLOCK_PREVIOUS_STEPS = 'BLOCK_PREVIOUS_STEPS',
  UPDATE_PERSONAL_INFOS_FORM = 'UPDATE_PERSONAL_INFOS_FORM',
}

export default BaoServiceActionTypeKeys;
