import { requestCodeRegex } from '../../utils/regex';
import { ERRORTYPE } from '../../utils/validation';

type isFormValidParamsType = { isFieldsValid: boolean };

export const isFormValid = ({ isFieldsValid }: isFormValidParamsType): boolean => isFieldsValid;

type getFieldsValidationParamsType = { fieldName: string; value: string; password?: string; requestCode?: string };
type getFieldsValidationReturnType = { isFieldsValid: boolean; errors: { requestCode: string; password: string } };

export const getFieldsValidation = ({
  fieldName,
  value,
  password = '',
  requestCode = '',
}: getFieldsValidationParamsType): getFieldsValidationReturnType => {
  const customFormErrors = { requestCode: '', password: '' };

  if (value.length === 0) {
    customFormErrors[fieldName] = ERRORTYPE.REQUIRED.type;
  }

  if (requestCode !== '' && !requestCodeRegex.test(requestCode)) {
    customFormErrors.requestCode = ERRORTYPE.PATTERN.type;
  }

  return {
    isFieldsValid: !customFormErrors.requestCode && !customFormErrors.password,
    errors: customFormErrors,
  };
};
