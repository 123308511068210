import ConfigCOMMON from './common.json';
import ConfigLOCAL from './local.json';
import ConfigDEV from './dev.json';
import ConfigTI from './ti.json';
import ConfigTEST from './test.json';
import ConfigPP from './pp.json';
import ConfigPROD from './prod.json';
import { loadItem, isProduction } from '../utils';
import { Nullable } from 'types/interfaces';

interface IDocumentGeneratorConfigs {
  BASE_URL: string;
  ENDPOINTS: {
    DOCUMENT: string;
    DOCUMENT_MERGED: string;
  };
}

interface ICaptchaConfigs {
  SITE_KEY_FRONTEND: string;
  SITE_KEY_SAFEID: string;
  SITE_KEY_TYPE_FRONTEND: string;
  SITE_KEY_TYPE_SAFEID: string;
  GOOGLE_ENDPOINT: string;
  EXCHANGE_TOKEN_ENDPOINT: string;
}

interface FeatureToggleConfig {
  SSO: boolean;
  IAMX_ENCRYPTION: boolean;
}

export type ConfigType = {
  [key: string]: any;
  DOCUMENTS_GENERATOR_SERVER: IDocumentGeneratorConfigs;
  CAPTCHA: ICaptchaConfigs;
  FEATURE_TOGGLE: FeatureToggleConfig;
};

export const getConfig = (env: Nullable<string> = null): ConfigType => {
  const currentEnv = loadItem('DEV_ENV') || env;

  switch (currentEnv) {
    case 'local':
      return ConfigLOCAL;
    case 'dev':
      return ConfigDEV;
    case 'ti':
      return ConfigTI;
    case 'test':
      return ConfigTEST;
    case 'pp':
      return ConfigPP;
    case 'prod':
      // Determine whether we need the real production configurations (common, using production urls),
      //  or the production configurations used on local environments and tests (prod, using secured zone urls)
      return isProduction() ? ConfigCOMMON : ConfigPROD;
    default:
      return ConfigCOMMON;
  }
};

class Config {
  params: ConfigType;

  constructor() {
    this.params = getConfig();
  }

  init(env: string): ConfigType {
    this.params = getConfig(env);
    return this.params;
  }
}

export default new Config();
