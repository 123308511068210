import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { ProductFormAmountInput } from 'components/productFormFields';
import { getTextFactoryReturnType } from 'utils/TextUtils';
import { ErrorMessage, ProductFormFieldChangeHandler } from 'types/interfaces';
import { Label } from '@nbc-design/label';
import { Text } from '@nbc-design/text';
import { sumAssetsTotal } from '../utils';
import { CALCULATOR_ASSETS_FIELDS } from '../constants';
import { CURRENCIES } from 'utils/constants';

export type AssetsCalculatorProps = {
  formatErrorMessage: (error: ErrorMessage) => string;
  getText: getTextFactoryReturnType;
  change: ProductFormFieldChangeHandler;
  className?: string;
  form: string;
};

const AssetsCalculator: FunctionComponent<AssetsCalculatorProps> = (props: AssetsCalculatorProps) => {
  const { formatErrorMessage, getText, change, className, form } = props;

  change('assetsTotalPopup', sumAssetsTotal(form));

  return (
    <div className={`assets-calculator ${className}`}>
      <Row>
        <Col xs={6} sm={6} md={8} lg={8} data-test="field_assetsLiquidity_text">
          <div className="label-text">
            <Label htmlFor="AmountInput-assetsLiquidity">{getText('assetsLiquidity')}</Label>
          </div>
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} data-test="field_assetsLiquidity">
          <ProductFormAmountInput
            name={CALCULATOR_ASSETS_FIELDS.LIQUIDITY}
            formatErrorMessage={formatErrorMessage}
            suffix={CURRENCIES.CAD}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={6} md={8} lg={8} data-test="field_assetsFixedRevenue_text">
          <div className="label-text">
            <Label htmlFor="AmountInput-assetsFixedRevenue">{getText('assetsFixedRevenue')}</Label>
            <Text size="small" appearance="muted">
              {getText('assetsFixedRevenueDetails')}
            </Text>
          </div>
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} data-test="field_assetsFixedRevenue">
          <ProductFormAmountInput
            name={CALCULATOR_ASSETS_FIELDS.FIXED_REVENUE}
            formatErrorMessage={formatErrorMessage}
            suffix={CURRENCIES.CAD}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={6} md={8} lg={8} data-test="field_assetsEquitySecurities_text">
          <div className="label-text">
            <Label htmlFor="AmountInput-assetsEquitySecurities">{getText('assetsEquitySecurities')}</Label>
            <Text size="small" appearance="muted">
              {getText('assetsEquitySecuritiesDetails')}
            </Text>
          </div>
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} data-test="field_assetsEquitySecurities">
          <ProductFormAmountInput
            name={CALCULATOR_ASSETS_FIELDS.EQUITY_SECURITIES}
            formatErrorMessage={formatErrorMessage}
            suffix={CURRENCIES.CAD}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={6} md={8} lg={8} data-test="field_assetsOther_text">
          <div className="label-text">
            <Label htmlFor="AmountInput-assetsOther">{getText('assetsOtherInvestments')}</Label>
            <Text size="small" appearance="muted">
              {getText('assetsOtherInvestmentsDetails')}
            </Text>
          </div>
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} data-test="field_assetsOther">
          <ProductFormAmountInput
            name={CALCULATOR_ASSETS_FIELDS.OTHER}
            formatErrorMessage={formatErrorMessage}
            suffix={CURRENCIES.CAD}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={6} md={8} lg={8} data-test="field_assetsProperty_text">
          <div className="label-text">
            <Label htmlFor="AmountInput-assetsProperty">{getText('assetsFixedAssets')}</Label>
            <Text size="small" appearance="muted">
              {getText('assetsFixedAssetsDetails')}
            </Text>
          </div>
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} data-test="field_assetsProperty">
          <ProductFormAmountInput
            name={CALCULATOR_ASSETS_FIELDS.PROPERTY}
            formatErrorMessage={formatErrorMessage}
            suffix={CURRENCIES.CAD}
          />
        </Col>
      </Row>
      <Row className="total-assets">
        <Col xs={6} sm={6} md={8} lg={8} data-test="field_assetsTotal_text">
          <div className="label-text">
            <Label htmlFor="AmountInput-assetsTotalPopup">{getText('assetsTotalPopup')}</Label>
          </div>
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} data-test="field_totalInvestments">
          <ProductFormAmountInput name="assetsTotalPopup" isReadOnly placeholder={getText('assetsPlaceholder')} />
        </Col>
      </Row>
    </div>
  );
};

export default AssetsCalculator;
