import React, { FunctionComponent } from 'react';
import { FormGroup } from '@nbc-design/form-group';
import { Text } from '@nbc-design/text';
import { InjectedIntl } from 'react-intl';
import { FormComponentProps } from '../types';
import { getLabelText, getDisplayValue, StringOrLocalizedString } from '../utils';
import { getTextFactory } from 'utils/TextUtils';
import AnalyticsHelper from 'services/analytics/AnalyticsHelper';

export type ProductFormValueProps = FormComponentProps & {
  intl: InjectedIntl;
  displayedValue?: StringOrLocalizedString | StringOrLocalizedString[];
  tooltip?: React.ReactNode;
};

const ProductFormValue: FunctionComponent<ProductFormValueProps> = (props: ProductFormValueProps) => {
  const { tooltip, label, intl, helpText, className, input, displayedValue = null } = props;
  const showLabel = label || getLabelText(props);
  const value = getDisplayValue(displayedValue === null ? input.value : displayedValue, intl);
  const getAccessibilityText = getTextFactory(intl, 'accessibility');
  const onShowTooltip = AnalyticsHelper.handleShowTooltip(input.name);

  return (
    <FormGroup
      className={className}
      {...(showLabel ? { label: { text: showLabel, htmlFor: `text-${input.name}` } } : {})}
      tooltip={{ content: tooltip, ariaLabel: getAccessibilityText('information'), onShowTooltip }}
      description={{ text: helpText, id: `help-${input.name}` }}
    >
      {Array.isArray(value) ? (
        value.map((val, i) => (
          <Text type={'p'} key={i}>
            {val}
          </Text>
        ))
      ) : (
        <Text id={`text-${input.name}`} type={'span'}>
          {String(value)}
        </Text>
      )}
    </FormGroup>
  );
};

export default ProductFormValue;
