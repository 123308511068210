import React, { FC } from 'react';
import createCtx from 'utils/createCtx';
import { ContainerCtxProps } from './ContainerCtx.type';
import { BncProductFormProps } from '../../types/bao-react-forms.type';
import { RouteComponentProps } from 'react-router';
import { BaoDrilledProps, GenericProps, OtherUnknownSourceDrilledProps } from '../../types/interfaces';
import { getTextFactory } from '../../utils/TextUtils';
import { createErrorFormatter } from '../../utils/productFormUtils';

const [_useContainerCtx, ContainerCtxProvider] = createCtx<ContainerCtxProps>('useContainerCtx');

const useContainerCtx = <T extends object = Record<string, any>>() => _useContainerCtx() as ContainerCtxProps<T>;

const withContainerCtxProvider = <TFormValues extends object = {}>(textPrefix: string) => (
  Component: React.FC<any>,
) => {
  const ProviderComponent: FC<BaoDrilledProps> = (props) => {
    // Drilled props separated by type
    const bncReactForm = props as BncProductFormProps;
    const routeProps = props as RouteComponentProps;
    const genericProps = props as GenericProps;
    const otherProps = props as OtherUnknownSourceDrilledProps;
    const formValues = props as TFormValues;

    // Utils
    const { intl } = genericProps;
    const getText = getTextFactory(intl, textPrefix ?? '');
    const getTextGlobal = getTextFactory(intl, 'global');
    const getAccessibilityText = getTextFactory(intl, 'accessibility');
    const formatErrorMessage = (prefix?: string, fieldName?: string) => createErrorFormatter(intl, prefix, fieldName);

    /***************************************************************************
     * Render
     ***************************************************************************/
    return (
      <ContainerCtxProvider
        value={{
          intl,
          locale: intl?.locale,

          formValues,

          // Separated drilled props
          bncReactForm,
          routeProps,
          genericProps,
          otherProps,

          // Utils
          getText,
          getTextGlobal,
          getAccessibilityText,
          formatErrorMessage,
        }}
      >
        <Component {...props} />
      </ContainerCtxProvider>
    );
  };

  ProviderComponent.displayName = `withContainerCtxProvider(${Component.displayName || 'Component'})`;
  return ProviderComponent;
};

export { useContainerCtx, withContainerCtxProvider };
