import React, { ReactNode } from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import cx from 'classnames';
import { NbcColor } from '@nbc-design/icons/lib/web/NbcColor';
import Modal from '@nbc-design/modal';

import { getTextFactory, getTextFactoryReturnType } from 'utils/TextUtils';
import { sendLightboxAnalyticsEvent } from 'services/analytics';

import './styles.scss';

export type ModalProps = {
  intl: InjectedIntl;
  Content: ReactNode;
  headerClass?: string;
  name: string;
  text: string;
  onClose?: () => void;
  title?: string;
  close?: string;
  className?: string;
  isCloseHidden?: boolean;
  hasIcon?: boolean;
  hasFooter?: boolean;
  footerClass?: string;
  customButton?: {
    text: string;
    type: string;
    appearance: string;
    className: string;
    onClick: (e) => void;
    disabled: boolean;
  };
};

export type ModalState = {
  display: boolean;
};

export class ModalComponent extends React.PureComponent<ModalProps, ModalState> {
  getAccessibilityText: getTextFactoryReturnType;

  constructor(props) {
    super(props);
    this.state = {
      display: true,
    };
    this.getAccessibilityText = getTextFactory(props.intl, 'accessibility.icon');
  }

  componentDidMount() {
    this.setState({
      display: true,
    });
    sendLightboxAnalyticsEvent(this.props.name, this.props.text);
  }

  render() {
    const {
      title = '',
      onClose = null,
      close = 'OK',
      Content,
      headerClass = '',
      className = '',
      isCloseHidden = true,
      hasIcon = true,
      customButton = null,
      hasFooter = true,
      footerClass = 'mc-justify-content-center',
    } = this.props;

    if (!this.state.display) return null;

    const closeModal = (): void => {
      this.setState({ display: false });
      onClose && onClose();
    };

    return (
      <Modal
        onModalClose={closeModal}
        titleId="modalTitleId"
        className={cx('modal-component', className)}
        appearance="light"
      >
        <Modal.Header
          isCloseHidden={isCloseHidden}
          closeBtnAriaLabel={this.getAccessibilityText('close')}
          className={headerClass}
        >
          {hasIcon ? <NbcColor size="large" title="bnc-color" /> : null}
          {title}
        </Modal.Header>
        <Modal.Body>{Content}</Modal.Body>
        {hasFooter && (
          <Modal.Footer className={footerClass}>
            {customButton ? (
              <Modal.CloseBtn
                text={customButton.text}
                type={customButton.type}
                appearance={customButton.appearance}
                className={customButton.className}
                onClick={(e) => customButton.onClick(e)}
                disabled={customButton.disabled}
              >
                {customButton.text}
              </Modal.CloseBtn>
            ) : (
              <Modal.CloseBtn appearance="primary" type="submit" className="dsc-d-none dsc-d-small-block">
                {close}
              </Modal.CloseBtn>
            )}
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

export default injectIntl(ModalComponent);
