export type Address = {
  _raw: object;
  full: string;
  streetName: string;
  streetNumber: string;
  complement: string;
  city: string;
  province: string;
  postalCode: string;
  country: string;
  isManualMode: boolean;
};

export type MelissaAddress = {
  addresses?: Address[];
  isFetching: boolean;
  fetchingError?: object[];
  failure: boolean;
};

export const searchedAddresses = 'searchedAddresses';

export type MelissaState = MelissaAddress & {
  searchedAddresses?: MelissaAddress;
};
