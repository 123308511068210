import React, { FunctionComponent, ChangeEvent } from 'react';
import cn from 'classnames';
import { DEFAULT_ENV } from 'utils/constants';
import { BFF_STEPS } from 'utils/stepsConstants';
import { SSO_ENVS, SSOEnv } from 'services/ssoRedirectService/constants';
import { saveItem, loadItem } from 'utils';
import { AppStep, ConfigData } from 'types/interfaces';
import { InspectSsoTokenButton } from './InspectSsoTokenButton';

import './devNav.scss';

export const changeEnv = (env: string): void => {
  saveItem('DEV_ENV', env);
  window.location.reload();
};

export const changeSsoEnv = (env: string): void => {
  saveItem('SSO_ENV', env);
  window.location.reload();
};

export type DevNavProps = {
  DEV_ENV?: string;
  SSO_ENV?: string;
  NODE_ENV?: string;
  goToStep: ((id: string) => void) | null;
  config: ConfigData;
};

const handleChangeSSOEnv = (event: React.ChangeEvent<HTMLSelectElement>): void => changeSsoEnv(event.target.value);

const DevNav: FunctionComponent<DevNavProps> = (props: DevNavProps) => {
  const {
    NODE_ENV = process.env.NODE_ENV,
    DEV_ENV = loadItem('DEV_ENV') || DEFAULT_ENV,
    SSO_ENV = loadItem('SSO_ENV') || DEFAULT_ENV,
    goToStep = null,
    config,
  } = props;

  if (NODE_ENV !== 'development') {
    return null;
  }

  return (
    <div className="devnav" role="navigation">
      Connect to
      <button
        onClick={() => changeEnv('local')}
        className={cn('devnav__button--local', { active: DEV_ENV === 'local' })}
      >
        LOCAL
      </button>
      <button onClick={() => changeEnv('dev')} className={cn('devnav__button--dev', { active: DEV_ENV === 'dev' })}>
        DEV
      </button>
      <button onClick={() => changeEnv('ti')} className={cn('devnav__button--ti', { active: DEV_ENV === 'ti' })}>
        TI
      </button>
      <button onClick={() => changeEnv('test')} className={cn('devnav__button--test', { active: DEV_ENV === 'test' })}>
        TEST
      </button>
      <button onClick={() => changeEnv('pp')} className={cn('devnav__button--pp', { active: DEV_ENV === 'pp' })}>
        PP
      </button>
      <label htmlFor="sso-env">SSO ENV:</label>
      <select id="sso-env" className="devnav__select--sso" defaultValue={SSO_ENV} onChange={handleChangeSSOEnv}>
        {SSO_ENVS.map((env: SSOEnv, index: number) => (
          <option key={`sso-env-${index}`} value={env.code}>
            {env.name}
          </option>
        ))}
      </select>
      {DEV_ENV !== 'local' && !config.PATH && (
        <select
          className="devnav__select--backend"
          aria-label="Backend Step"
          onChange={(event: ChangeEvent<HTMLSelectElement>): void | null => goToStep && goToStep(event.target.value)}
        >
          <option value="null">Go To Step</option>

          {BFF_STEPS.map((step: AppStep, index: number) => (
            <option key={index} value={step.ID}>
              {step.ID}
            </option>
          ))}
        </select>
      )}
      <InspectSsoTokenButton>Inspect SSO token</InspectSsoTokenButton>
    </div>
  );
};

export default DevNav;
