import React from 'react';
import { InjectedIntl } from 'react-intl';
import { History, LocationState } from 'history';
import { ROUTE } from 'utils/constants';
import getLogger from 'utils/getLogger';
import { sendStepLoadedEvent } from 'services/analytics';
import { EVENT_IDS } from 'services/analytics/constants';
import { AppErrorData, ConfigData } from 'types/interfaces';
import {
  isAuthenticatedWith,
  OKTA_AUTHENT_TYPE,
  signinSSO,
  signupSSO,
  validateApplication,
} from 'services/loginManager';
import { checkAccessTokenValidity, persistUnifiedLogin } from 'services/ssoRedirectService/oktaService';
import { getBncId, loadItem, saveItem } from 'utils';
import CONFIG from './config';
import { getErrorProps } from 'components/errorManager/utils';
import Modal from 'components/Modal/Modal';
import ExistedApplication from '../CreateUserProfile/components/ExistedApplication';

import '../CreateUserProfile/styles.scss';

export type WelcomeProps = {
  intl: InjectedIntl;
  history: History<LocationState>;
  config: ConfigData;
};

export type WelcomeState = {
  accessToken: null | OktaAccessToken;
  applicationInProgress: boolean;
  isTechnicalError: boolean;
  errorData: null | AppErrorData;
};

const logger = getLogger('Welcome');

export const redirectToLogin = (history: History<LocationState>) => {
  history.push({
    pathname: ROUTE.CLIENT_REDIRECT,
    hash: '',
  });
};

export default class Welcome extends React.Component<WelcomeProps, WelcomeState> {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: null,
      applicationInProgress: false,
      isTechnicalError: false,
      errorData: null,
    };
  }

  componentDidMount() {
    const { history, intl } = this.props;

    sendStepLoadedEvent(CONFIG.ID, EVENT_IDS.FS, '', {
      user: {
        bncId: getBncId(),
      },
    });

    if (history.location.hash.includes('access_token')) {
      return persistUnifiedLogin(intl.locale)
        .then(() => {
          this.handleRedirect();
        })
        .catch(() => {
          redirectToLogin(history);
          return;
        });
    } else {
      this.handleRedirect();
    }
  }

  goToAuthenticationOperation(accessToken: OktaAccessToken, isSignIn = false): Promise<void> {
    const authenticationOperation = isSignIn ? signinSSO : signupSSO;

    return authenticationOperation(accessToken.value, accessToken.tokenType)
      .then(() => {
        saveItem('newRequest', 'true');
        logger.log(`[AUTH] redirecting to STEPPER_MANAGER page`);
        window.location.assign(`${process.env.PUBLIC_URL}${ROUTE.STEPPER_MANAGER}`);
      })
      .catch((err) => {
        logger.error(`[AUTH] ERROR redirecting, redirecting to CLIENT_REDIRECT page`, err);
        redirectToLogin(this.props.history);
      });
  }

  handleRedirect(): void {
    const { history, intl } = this.props;

    if (isAuthenticatedWith(OKTA_AUTHENT_TYPE)) {
      checkAccessTokenValidity(intl.locale)
        .then((accessToken) => {
          if (accessToken === null) {
            redirectToLogin(history);
            return;
          }

          if (loadItem('newRequest') === 'true') {
            return validateApplication()
              .then((response) => {
                console.log('RESPONSE:', response);
                this.setState({ applicationInProgress: true, accessToken });
              })
              .catch((err) => {
                console.log('ERROR: ', err);
                if (err.status === 400) {
                  this.goToAuthenticationOperation(accessToken);
                } else {
                  this.setState({ isTechnicalError: true, errorData: getErrorProps(intl, err.status) });
                }
              });
          } else if (loadItem('newRequest') === 'false') {
            this.goToAuthenticationOperation(accessToken);
          } else {
            this.goToAuthenticationOperation(accessToken, true);
          }
        })
        .catch((err) => {
          logger.error(`[AUTH] ERROR getting the access token before signin, redirecting to CLIENT_REDIRECT page`, err);
          redirectToLogin(history);
        });
    } else {
      // BAO login, handled later
      history.push({
        pathname: ROUTE.CREATE_USER_PROFILE,
      });
    }
  }

  render() {
    const { accessToken, applicationInProgress, isTechnicalError, errorData } = this.state;

    return (
      <div className="create-user-profile-wrapper">
        {accessToken && applicationInProgress && (
          <ExistedApplication intl={this.props.intl} history={this.props.history} accessToken={accessToken} />
        )}
        {isTechnicalError && errorData && <Modal {...errorData} name={errorData.title} text={errorData.text} />}
      </div>
    );
  }
}
