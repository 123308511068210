import React, { FunctionComponent } from 'react';
import { InjectedIntl } from 'react-intl';
import { Row, Col } from 'react-flexbox-grid';
import { Button, IconButton } from '@nbc-design/button';
import { ArrowLeft } from '@nbc-design/icons/lib/web/ArrowLeft';

import { ConfigData } from 'types/interfaces';
import { getTextFactory } from 'utils/TextUtils';
import './style.scss';

export type FormFooterProps = {
  intl: InjectedIntl;
  goToPreviousStep?: (() => void) | null;
  config?: ConfigData;
  disableSubmit?: boolean;
};

const FormFooter: FunctionComponent<FormFooterProps> = (props: FormFooterProps) => {
  const { intl, goToPreviousStep = null, disableSubmit = false, config = {} } = props;

  const getText = getTextFactory(intl, 'global.button');
  const getAccessibilityText = getTextFactory(intl, 'accessibility.button');

  let buttonKey = 'continue';
  buttonKey = config.BUTTON_SUBMIT ? 'submit' : buttonKey;
  buttonKey = config.BUTTON_SIGN ? 'sign' : buttonKey;
  const buttonText = getText(buttonKey);

  return (
    <Row className="layout-footer">
      <Col xs={12} md={12} lg={12} className="footer-wrapper" data-test="buttons__form_footer">
        {goToPreviousStep ? (
          <IconButton
            icon={<ArrowLeft title={getAccessibilityText('back')} />}
            onClick={() => {
              goToPreviousStep();
            }}
            type="button"
            appearance="secondary"
            className="back-button"
          />
        ) : (
          <div />
        )}
        {config.BUTTON_CONTINUE === false ? null : (
          <Button appearance="primary" type="submit" disabled={disableSubmit} dataTest={buttonKey}>
            {buttonText}
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default FormFooter;
