import React, { Fragment, FunctionComponent, MouseEvent, ReactNode } from 'react';
import { InjectedIntl } from 'react-intl';

import { getTextFactory } from 'utils/TextUtils';

import Modal from 'components/Modal/Modal';
import { SafeIdFinancialDataType } from '../../types';

export type DeleteConfirmationModalProps = {
  handleSubmit: Function;
  closeModal: () => void;
  intl: InjectedIntl;
  change: (key: string, value: SafeIdFinancialDataType) => void;
};

const DeleteConfirmationModal: FunctionComponent<DeleteConfirmationModalProps> = (
  props: DeleteConfirmationModalProps,
) => {
  const { handleSubmit, closeModal, intl } = props;

  const getText = getTextFactory(intl, 'specificsBankingInfo');
  const buttonText = getTextFactory(intl, 'global.button');
  const modalTitle = getText('safeIdDeleteBankAccountTitle');

  const handleModalButtonClick = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    handleSubmit();
  };

  const ModalContent: ReactNode = (
    <Fragment>
      <p className="safeid-delete-bank-account-description" data-test="safeid-delete-bank-account-description">
        {getText('safeIdDeleteBankAccount')}
      </p>
    </Fragment>
  );

  const customButtonConfig = {
    text: buttonText('delete'),
    type: 'button',
    appearance: 'primary',
    className: 'dsc-d-none dsc-d-small-block',
    onClick: (e) => {
      handleModalButtonClick(e);
    },
    disabled: false,
  };

  const modalProps = {
    title: modalTitle,
    onClose: closeModal,
    className: 'safe-id-delete-bank-account-modal',
    Content: ModalContent,
    isCloseHidden: false,
    hasIcon: false,
    customButton: customButtonConfig,
  };

  return <Modal {...modalProps} name={modalTitle} text={getText('safeIdDeleteBankAccount')} />;
};

export default DeleteConfirmationModal;
