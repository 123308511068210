import React, { FunctionComponent } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { ProductFormSelect } from 'components/productFormFields';
import { FinancesComponentsProps } from '../types';

const AnnualRevenue: FunctionComponent<FinancesComponentsProps> = (props: FinancesComponentsProps) => {
  const { formatErrorMessage, getText, getTextGlobal } = props;

  return (
    <Row>
      <Col xs={12} md={12} lg={12} data-test="field_annualRevenue">
        <ProductFormSelect
          name="annualRevenue"
          label={getText('annualRevenue')}
          formatErrorMessage={formatErrorMessage}
          className="annualRevenue"
          placeholder={getTextGlobal('select')}
        />
      </Col>
    </Row>
  );
};

export default AnnualRevenue;
