import get from 'lodash/get';
import merge from 'lodash/merge';
import set from 'lodash/set';

import { AnalyticData, AnalyticsContext } from './types';
import { getLocale } from 'utils';
import { getState } from 'globalRedux/store';
import { EVENT_NAME, EVENT_IDS, EVENT_TYPE } from './constants';

/** Helper type to be able to work with the Window object safely */
type ExtendedWindow = Window & {
  dataLayer: AnalyticData[] | Partial<AnalyticData[]>;
  _satellite: {
    track: Function;
  };
};

/**
 * Buffers events when trying to send them before the analytics API is ready,
 * in case it is not yet ready.
 * @private
 */
const analyticDataBuffer: AnalyticData[] | Partial<AnalyticData> = [];

const getTimeStamp = (date: Date): string => `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

const getWindowObject = (): ExtendedWindow => (window as unknown) as ExtendedWindow;

/**
 * Generate an object to be sent to the analytics.
 * @param eventType - the value of to the `event` field
 * @param eventName - the value of `eventInfo.eventName` field
 * @param eventId - the value of `eventInfo.eventId` field
 * @param currentStep - the value of the `step.stepName` and field and used in the generation of `page.pageName`
 * @param requestCode - the value of the `user.referenceId` field
 * @param additionalInfo - an object that will be recurlsively merged with the generated analytics data
 */
const getAnalyticData = (
  eventType: EVENT_TYPE,
  eventName: EVENT_NAME,
  eventId: EVENT_IDS,
  currentStep: string,
  requestCode: string,
  additionalInfo: RecursivePartial<AnalyticData>,
  isTrackEvent: boolean = false,
): AnalyticData | Partial<AnalyticData> => {
  const fullData = merge(
    {
      event: eventType,
      eventInfo: {
        ucsId: `UCS-bao-${eventId}`,
        ucsState: null,
        eventName: eventName,
        eventSource: 'nbdb',
        eventSourceDetail: 'bao',
        eventTime: getTimeStamp(new Date()),
        eventId: `${eventId}-BAO`,
        formError: null,
        roadBlock: null,
        interaction: null,
        helpText: null,
        technicalError: null,
      },
      page: {
        pageName: `nbdb:personal:bao:${currentStep}`,
        language: getLocale().toUpperCase(),
        loadTime: null,
        breakPoints: null,
        scrollPortion: null,
        loB: 'personal',
        Category: null,
        phoneDisplayed: null,
      },
      product: {
        productCategory: 'National Bank Direct Brokerage',
        productName: null,
        productPricing: null,
        promoDetail: null,
      },
      step: {
        stepName: currentStep,
        stepId: null,
        formStatus: null,
        formId: 'bao',
        roadBlock: null,
        fieldFilled: null,
        fieldAnswer: null,
        helpText: null,
        flowId: null,
        timeOnStep: null,
        timeToComplete: null,
        timeSpinner: null,
      },
      user: {
        bncId: null,
        loggedStatus: null,
        age: null,
        gender: null,
        postalCode: null,
        province: null,
        referenceId: requestCode,
        residencyStatus: null,
        jobStatus: null,
        accountUsage: null,
        fundSource: null,
        SINinput: null,
        taxes: null,
        applicantNumber: 1,
        applicantId: null,
      },
    },
    additionalInfo,
  );

  return !isTrackEvent ? fullData : { event: fullData.event, eventInfo: fullData.eventInfo };
};

const sendEvent = (data: AnalyticData | Partial<AnalyticData>) => {
  const win = getWindowObject();

  if (Array.isArray(win.dataLayer)) {
    // take any buffered events first, if any, respecting their order
    let item: AnalyticData | undefined;
    while ((item = analyticDataBuffer.shift())) {
      win.dataLayer.push(item);
    }
    win.dataLayer.push(data as AnalyticData);
  } else {
    // buffer the event
    analyticDataBuffer.push(data as AnalyticData);
  }

  // TODO only when data.eventInfo.eventName === EVENT_NAME.PAGE_LOAD ???
  if (win._satellite && win._satellite.track) {
    const eventType = 'form submit';
    win._satellite.track(eventType);
  }
};

const getAnalyticsContext = (): null | AnalyticsContext => {
  const state = getState();
  if (!state) return null;

  const currentStep: string = get(state, 'productFormReducer.baoForms.productForm.meta.stepId', '');
  const requestCode: string = get(state, 'productFormReducer.baoForms.productForm.meta.requestCode', '');
  const submitFailed: boolean = get(state, 'reduxFormReducer.baoForms.submitFailed', false);
  const formError = get(state, 'reduxFormReducer.baoForms.error', null);
  const syncErrors = get(state, `reduxFormReducer.baoForms.syncErrors`, {});
  return { state, currentStep, requestCode, submitFailed, formError, syncErrors };
};

/**
 * Send analytics data signaling that a step has been reached.
 * @param currentStep - the ID of the step being reached
 * @param eventId - the value of `eventInfo.eventId` field
 * @param requestCode - the requestCode of the current request. Optional.
 * @param additionalInfo - an object that will be recurlsively merged with the generated analytics data
 */
export const sendStepLoadedEvent = (
  currentStep: string,
  eventId: EVENT_IDS,
  requestCode: string = '',
  additionalInfo: RecursivePartial<AnalyticData> = {},
): void => {
  const additionalInfoFull = set(additionalInfo, 'eventInfo.ucsState', 'pv');
  const newData = getAnalyticData(
    EVENT_TYPE.TRACK_PAGE_VIEW,
    EVENT_NAME.PAGE_LOAD,
    eventId,
    currentStep,
    requestCode,
    additionalInfoFull,
  );
  sendEvent(newData);
};

/**
 * Send analytics data signaling that the user submitted a form containing errors.
 * @param fieldNames - the list of fields that failed validation.
 */
export const sendErrorAnalyticsEvent = (fieldNames: string[]): void => {
  const context = getAnalyticsContext();
  if (!context || !context.submitFailed) {
    return;
  }

  if (context.formError) {
    const roadBlock = get(context.formError, 'type', '').slice(0, 100);
    const roadBlockEvent = getAnalyticData(
      EVENT_TYPE.TRACK_EVENT,
      EVENT_NAME.ROAD_BLOCK,
      EVENT_IDS.RB,
      context.currentStep,
      context.requestCode,
      {
        eventInfo: {
          ucsState: 'non-pv',
          roadBlock,
        },
      },
      true,
    );
    sendEvent(roadBlockEvent);
    return;
  }

  const formErrors = fieldNames.reduce((formError, fieldName) => {
    const error = get(context.syncErrors, fieldName, null);
    const separator = formError.length > 0 ? ',' : '';
    // only use the first part of the value path (i.e. `phoneNumbers` for `phoneNumbers[1].phoneType`)
    const firstLevelFieldNameRegExp = fieldName.match(/^([a-z0-9]+)/i);
    if (firstLevelFieldNameRegExp === null) {
      return formError;
    }
    const errorLabel = firstLevelFieldNameRegExp[0];
    return error === null ? formError : `${formError}${separator}${errorLabel}:${error.type}`;
  }, '');

  const formErrorEvent = getAnalyticData(
    EVENT_TYPE.TRACK_EVENT,
    EVENT_NAME.FORM_ERROR,
    EVENT_IDS.FE,
    context.currentStep,
    context.requestCode,
    {
      eventInfo: {
        ucsState: 'non-pv',
        formError: formErrors,
      },
    },
    true,
  );
  sendEvent(formErrorEvent);
};

/**
 * Send analytics data signaling that the user submitted a form containing errors
 *  in a form that is out of the form-flow (e.g. Pending Request)
 * @param fieldNames - the list of fields that failed validation.
 */
export const sendOutOfFormFlowAnalyticsEvent = (errorMessage: string, stepId: string): void => {
  const roadBlock = errorMessage.slice(0, 100);
  const roadBlockEvent = getAnalyticData(
    EVENT_TYPE.TRACK_EVENT,
    EVENT_NAME.ROAD_BLOCK,
    EVENT_IDS.RB,
    stepId,
    '',
    {
      eventInfo: {
        ucsState: 'non-pv',
        roadBlock,
      },
    },
    true,
  );
  sendEvent(roadBlockEvent);
};

export const translateAnalyticsMsg = (frErrorMsg: string) => {
  let enErrorMsg: string = '';

  switch (frErrorMsg) {
    case 'Obligatoire':
      enErrorMsg = 'required';
      break;
    case 'Format invalide.':
      enErrorMsg = 'invalid format';
      break;
    default:
      enErrorMsg = frErrorMsg;
      break;
  }

  return enErrorMsg;
};

/**
 * Send analytics data signaling validation error on a form
 *  in a form that is out of the form-flow (e.g. Pending Request)
 * @param fieldNames - the list of fields that failed validation.
 */
export const sendOutOfFormFlowErrorAnalyticsEvent = (
  formErrors: { [key: string]: string },
  currentStep: string,
): void => {
  const formattedFormErrors: string[] = [];
  for (const [key, value] of Object.entries(formErrors).filter(([_, value]) => value !== '')) {
    formattedFormErrors.push(`${key}:${translateAnalyticsMsg(value)}`);
  }

  const formErrorsEvent = getAnalyticData(
    EVENT_TYPE.TRACK_EVENT,
    EVENT_NAME.FORM_ERROR,
    EVENT_IDS.FE,
    currentStep,
    '',
    {
      eventInfo: {
        ucsState: 'non-pv',
        formError: formattedFormErrors.join(','),
      },
    },
    true,
  );
  sendEvent(formErrorsEvent);
};

/**
 * Send analytics data signaling that the user displayed some help text (tooltip, etc...).
 * @param helpText - name of the field the help is about
 */
export const sendHelpTextAnalyticsEvent = (helpText: string): void => {
  const context = getAnalyticsContext();
  if (!context) {
    return;
  }

  const additionalInfo: RecursivePartial<AnalyticData> = {
    eventInfo: {
      ucsState: 'non-pv',
      helpText: helpText.slice(0, 100),
      interaction: 'HelpText displayed',
    },
  };

  const newData = getAnalyticData(
    EVENT_TYPE.TRACK_EVENT,
    EVENT_NAME.HELP_DISPLAYED,
    EVENT_IDS.HD,
    context.currentStep || helpText,
    context.requestCode,
    additionalInfo,
    true,
  );
  sendEvent(newData);
};

/**
 * Send analytics data signaling that a technical error happened.
 * @param msg - message to send to analytics
 */
export const sendTechnicalErrorAnalyticsEvent = (msg: string): void => {
  const context = getAnalyticsContext();
  if (!context) {
    return;
  }

  const additionalInfo: RecursivePartial<AnalyticData> = {
    eventInfo: {
      ucsState: 'non-pv',
      technicalError: msg.slice(0, 100),
    },
  };

  const newData = getAnalyticData(
    EVENT_TYPE.TRACK_EVENT,
    EVENT_NAME.PAGE_ERROR,
    EVENT_IDS.TE,
    context.currentStep,
    context.requestCode,
    additionalInfo,
    true,
  );
  sendEvent(newData);
};

/**
 * Send analytics data signaling that a lightbox has been displyed to the user.
 * @param name - the "interaction" of the analytics event.
 */
export const sendLightboxAnalyticsEvent = (name: string, helpText: string): void => {
  const context = getAnalyticsContext();
  if (!context) {
    return;
  }

  const additionalInfo: RecursivePartial<AnalyticData> = {
    eventInfo: {
      ucsState: 'non-pv',
      interaction: name.slice(0, 50),
      helpText: helpText.slice(0, 100),
    },
  };

  const newData = getAnalyticData(
    EVENT_TYPE.TRACK_EVENT,
    EVENT_NAME.LIGHTBOX,
    EVENT_IDS.LB,
    context.currentStep || name,
    context.requestCode,
    additionalInfo,
    true,
  );
  sendEvent(newData);
};

/**
 * Send analytics data signaling that a link or a button or a radio-button has been clicked by the user.
 * @param name - the "interaction" of the analytics event.
 */
export const sendClickAnalyticsEvent = (currentStep: string, name: string): void => {
  const context = getAnalyticsContext();
  if (!context) {
    return;
  }

  const additionalInfo: RecursivePartial<AnalyticData> = {
    eventInfo: {
      ucsState: 'non-pv',
      interaction: name.slice(0, 50),
    },
    page: {},
    product: {},
    step: {
      stepName: currentStep,
    },
    user: {},
  };

  const newData = getAnalyticData(
    EVENT_TYPE.TRACK_EVENT,
    EVENT_NAME.CLICK,
    EVENT_IDS.CCTA,
    currentStep,
    '',
    additionalInfo,
    true,
  );
  sendEvent(newData);
};
