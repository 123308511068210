import React, { ChangeEvent, FunctionComponent } from 'react';
import { InjectedIntl } from 'react-intl';
import Title from 'components/Title';
import { Row, Col } from 'react-flexbox-grid';
import { ProductFormRadioBoolean, ProductFormInput, ProductFormSelect } from 'components/productFormFields';
import { createErrorFormatter } from 'utils/productFormUtils';
import { getTextFactory } from 'utils/TextUtils';
import { nonDigitRegex } from 'utils/regex';
import './styles.scss';

export type RequestAssistantProps = {
  intl: InjectedIntl;
  isBNCDAdvisor?: boolean;
  change: (key: string, value: string) => void;
};

const RequestAssistant: FunctionComponent<RequestAssistantProps> = (props: RequestAssistantProps) => {
  const { intl, isBNCDAdvisor = undefined, change }: RequestAssistantProps = props;

  const formatErrorMessage = (prefix?: string, fieldName?: string) => createErrorFormatter(intl, prefix, fieldName);

  const getText = getTextFactory(intl, 'requestAssistant');
  const getTextGlobal = getTextFactory(intl, 'global');

  const handleOnlyDigitOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    const {
      target: { name, value },
    } = event;

    const newVal = value.replace(nonDigitRegex, '');
    change(name, newVal);
  };

  return (
    <div className="request_assistant_form">
      <Title label={getText('pageTitle')} />
      <Row className="request_assistant_advisor">
        <Col xs={12} md={12} lg={12} data-test="field_advisor">
          <ProductFormRadioBoolean
            inline
            name="isBNCDAdvisor"
            yesLabel={getText('BNCD')}
            noLabel={getText('BNC')}
            formatErrorMessage={formatErrorMessage()}
          />
        </Col>
      </Row>
      <div className="request_assistant_content">
        <Row>
          <Col xs={12} md={6} lg={6} data-test="field_firstName">
            <ProductFormInput
              name="firstName"
              formatErrorMessage={formatErrorMessage()}
              label={getTextGlobal('firstName')}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} lg={6} data-test="field_lastName">
            <ProductFormInput
              name="lastName"
              formatErrorMessage={formatErrorMessage()}
              label={getTextGlobal('lastName')}
            />
          </Col>
        </Row>
        {isBNCDAdvisor && (
          <Row>
            <Col xs={12} md={6} lg={6} data-test="field_requestSource">
              <ProductFormSelect
                name="requestSource"
                placeholder={getTextGlobal('select')}
                label={getText('originalRequest')}
                formatErrorMessage={formatErrorMessage()}
              />
            </Col>
          </Row>
        )}
      </div>
      {!isBNCDAdvisor && typeof isBNCDAdvisor !== 'undefined' && (
        <div className="request_assistant_content bnc">
          <Row>
            <Col xs={12} md={6} lg={6} data-test="field_employeeNo">
              <ProductFormInput
                name="employeeNo"
                formatErrorMessage={formatErrorMessage()}
                label={getText('employeeNumber')}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} lg={6} data-test="field_transit">
              <ProductFormInput
                name="transit"
                formatErrorMessage={formatErrorMessage()}
                label={getText('transit')}
                onChange={handleOnlyDigitOnChange}
              />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default RequestAssistant;
