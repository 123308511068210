import React, { ChangeEvent, ReactNode } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Combo, Props as ComboProps } from '@nbc-design/combo';
import { Fieldset } from '@nbc-design/fieldset';
import { FormGroup } from '@nbc-design/form-group';
import { Radio } from '@nbc-design/radio';
import { Text } from '@nbc-design/text';

import { getTextFactory } from 'utils/TextUtils';
import {
  getErrorMessage,
  getProductFormField,
  getSchemaOptions,
  isInvalidOnChange,
} from 'components/productFormFields/utils';
import { FormComponentProps, OptionsType } from 'components/productFormFields/types';

export type IdentityValidationOptionsProps = InjectedIntlProps &
  FormComponentProps & {
    onChange?: OnChangeEventHandler;
  };

export type OnChangeEventHandler = (event: ChangeEvent<HTMLInputElement>) => void;

export const IdentityValidationOptions: React.FunctionComponent<IdentityValidationOptionsProps> = (props) => {
  const { intl, input } = props;

  const invalid = isInvalidOnChange(props);

  const getText = getTextFactory(intl, 'identityVerification');
  const label = getText('verificationSelect');
  const getAccessibilityText = getTextFactory(intl, 'accessibility.icon');

  const getValidationMethodDescription = (type: string): ReactNode => {
    const rawText = getText(`${type}.description`);
    return (
      <div className="long-description first">
        {rawText.split('\n').map((line, index) => (
          <p key={`${type}_description_${index}`}>{line}</p>
        ))}
      </div>
    );
  };

  const getValidationMethodCollapseText = (type: string): ReactNode => {
    const rawText = getText(`${type}.collapseText`);
    return (
      <ul className="long-description">
        {rawText.split('\n').map((line, index) => (
          <li key={`${type}_collapseText_${index}`}>{line}</li>
        ))}
      </ul>
    );
  };

  const renderRadio = (option: OptionsType): React.ReactElement => {
    const { label, value, readOnly = false } = option;
    const text = getValidationMethodDescription(option.value);
    return (
      <Radio
        key={value}
        id={`${input.name}-radio-${value}`}
        dataTest={`${input.name}-radio-${value}`}
        name={`radio-${value}`}
        text={text}
        label={label}
        value={value}
        onChange={input.onChange as (event: ChangeEvent<HTMLInputElement>) => void}
        checked={input.value === value}
        disabled={readOnly}
      />
    );
  };

  const comboItems: ComboProps['items'] = getSchemaOptions(props).map((option: OptionsType) => {
    const description = getValidationMethodCollapseText(option.value);
    return {
      children: renderRadio(option),
      collapse: {
        ariaLabelClose: getAccessibilityText('open'),
        ariaLabelOpen: getAccessibilityText('close'),
        description,
        // TEMP: avoid passing the open state not to confuse the collapsible
        isOpen: (undefined as unknown) as boolean,
      },
      disabled: false,
    };
  });

  return (
    <FormGroup validate={{ hasError: invalid, errorMsg: getErrorMessage(props) }}>
      <Fieldset>
        <Text type={'p'}>{label}</Text>
        <React.Fragment>
          <Combo id={input.name} type="form" items={comboItems} />
        </React.Fragment>
      </Fieldset>
    </FormGroup>
  );
};

export default getProductFormField(injectIntl(IdentityValidationOptions));
