import { ALL_STEPS, SIDEBAR_MENU_STEPS } from 'utils/stepsConstants';
import { AppStep } from 'types/interfaces';

export const getStepIndex = (currentStepId = ''): number => {
  return SIDEBAR_MENU_STEPS.findIndex((step: AppStep): boolean | undefined => {
    if (step.ID === currentStepId) {
      return true;
    }
    return step.subSteps && !!step.subSteps.find((subStep: AppStep): boolean => subStep.ID === currentStepId);
  });
};

export const getCompletedMainSteps = (currentStepId = ''): (string | undefined)[] => {
  const currentStepIndex = getStepIndex(currentStepId);
  return SIDEBAR_MENU_STEPS.filter((step: AppStep, index: number) => index < currentStepIndex).map(
    (step: AppStep): string | undefined => step.ID,
  );
};

export const getGivenStep = (stepId: string, currentStepId: string): AppStep[] => {
  const currentStepIndex = getStepIndex(currentStepId);
  const completedMainSteps = getCompletedMainSteps(currentStepId);

  return SIDEBAR_MENU_STEPS.filter((step) => step.ID === stepId).map(
    (step: AppStep, index: number): AppStep => {
      return {
        ...step,
        isCompleted: !!step.ID && completedMainSteps.includes(step.ID),
        isCurrent: index === currentStepIndex,
      };
    },
  );
};

export const getExcludedGivenStep = (stepId: string, currentStepId: string): AppStep[] => {
  const currentStepIndex = getStepIndex(currentStepId);
  const completedMainSteps = getCompletedMainSteps(currentStepId);

  return ALL_STEPS.filter((step) => step.ID === stepId).map(
    (step: AppStep, index: number): AppStep => {
      return {
        ...step,
        isCompleted: !!step.ID && completedMainSteps.includes(step.ID),
        isCurrent: index === currentStepIndex,
      };
    },
  );
};

export const getSteps = (currentStepId: string): AppStep[] => {
  const currentStepIndex = getStepIndex(currentStepId);
  const completedMainSteps = getCompletedMainSteps(currentStepId);

  return (
    SIDEBAR_MENU_STEPS.map(
      (step: AppStep, index: number): AppStep => {
        return {
          ...step,
          isCompleted: !!step.ID && completedMainSteps.includes(step.ID),
          isCurrent: index === currentStepIndex,
        };
      },
    ) || []
  );
};

export const getAllIndividualSteps = (currentStepId: string): AppStep[] => {
  const allSteps: AppStep[] = getSteps(currentStepId);
  return allSteps.filter((step) => step.ID !== step.STEP_JOINT_ID);
};
