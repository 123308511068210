import { ERRORTYPE, requiredValidation } from 'utils/validation';
import { ErrorData } from '../../types/interfaces';
import { isAccountSelected, isNotEmpty } from './SpecificsBankingInfo';
import {
  AccountDataType,
  BankingAccountsErrorData,
  ProgramErrorData,
  ProgramParticipationErrorData,
  SpecificsBankingInfoErrorData,
  SpecificsBankingInfoFormData,
  SpecificsBankingInfoFormError,
} from './types';
import { hasSafeIdDeleteRequest, hasSafeIdOpenSessionRequest } from './utils';

export const bankingAccountValidation = (account: AccountDataType | null | {}): SpecificsBankingInfoErrorData => {
  const fields = ['institutionName', 'branchNo', 'institutionNo', 'accountNo', 'currency'];

  return {
    ...requiredValidation(account, fields),
  };
};

export const bankingAccountsValidation = (values: SpecificsBankingInfoFormData): BankingAccountsErrorData => {
  const { bankingAccounts } = values;

  const accounts = !bankingAccounts || !bankingAccounts.length ? [{}] : bankingAccounts;
  const errors = accounts.map((account): SpecificsBankingInfoErrorData => bankingAccountValidation(account));
  const hasError = !!errors.filter((error) => Object.keys(error).length).length;
  return hasError ? { bankingAccounts: errors } : {};
};

export const programValidation = (values: SpecificsBankingInfoFormData): ProgramErrorData | {} => {
  const { program } = values;
  if (!program || !program.length) {
    return { program: ERRORTYPE.REQUIRED };
  }
  return {};
};

export const programParticipationValidation = (
  values: SpecificsBankingInfoFormData,
): ProgramParticipationErrorData | {} => {
  const { programParticipation } = values;
  if (programParticipation === undefined && !hasSafeIdDeleteRequest(values.safeIdFinancialExp)) {
    return { programParticipation: ERRORTYPE.REQUIRED };
  }
  return {};
};

export const confirmedBankingAccountsValidation = (
  values: SpecificsBankingInfoFormData,
): { _error: ErrorData } | null => {
  const { confirmedBankingAccounts = [], bankingAccounts } = values;
  const hasSelectedConfirmedAccounts = confirmedBankingAccounts.some(
    (cab) => isNotEmpty(cab) && isAccountSelected(cab),
  );
  const hasEnteredBankingAccounts = (bankingAccounts || []).some((ba) => !!ba); // at least one banking account, but deal with [null]

  if (!hasSelectedConfirmedAccounts && !hasEnteredBankingAccounts) {
    return { _error: { type: 'oneBankingAccountRequired' } };
  }
  return null;
};

const validation = (values: SpecificsBankingInfoFormData): SpecificsBankingInfoFormError => {
  if (!hasSafeIdOpenSessionRequest(values.safeIdFinancialExp)) {
    return {
      ...bankingAccountsValidation(values),
      ...programValidation(values),
      ...confirmedBankingAccountsValidation(values),
      ...programParticipationValidation(values),
    };
  }
  return {};
};

export default validation;
