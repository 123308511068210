/* eslint-disable import/prefer-default-export */
import qs from 'query-string';
import { checkStatus, parseJson } from 'utils/fetchHandlers';
import { StockSymbolConfig } from 'types/interfaces';

export const getStockSymbol = <S>(config: StockSymbolConfig, search = ''): Promise<S | null> => {
  const { endpoint, id, method, headers, format = 'json', country = 'CA', maxRecords = 10 } = config;

  const query: string = qs.stringify({
    format,
    id,
    symbol: search,
    split: true,
    country,
    maxrecords: maxRecords,
  });

  if (search === '') {
    return Promise.resolve(null);
  }

  return fetch(`${endpoint}?${query}`, {
    method,
    headers,
  })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => json);
};
