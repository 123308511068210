import React from 'react';
import { Alert } from '@nbc-design/alert';
import { Text } from '@nbc-design/text';
import { PROVINCES } from 'utils/constants';
import { useContainerCtx } from 'providers/containerCtx/ContainerCtx';
import { BeneficiaryDataType, BeneficiaryDocuments, BeneficiaryFormValues, BeneficiaryRelationTypeCd } from '../types';
import { calculateAge, getBeneficiariesNotDeleted } from '../utils';
import { isCountryCA } from 'utils';
import { RespPlanTypeCd } from 'containers/AccountsStep3/types';
import { isNil } from 'lodash';

export type BeneficiaryDocumentAlertProps = {
  beneficiary: BeneficiaryDataType;
  addCurrentToTotal?: boolean;
};

const BeneficiaryDocumentAlert: React.FC<BeneficiaryDocumentAlertProps> = ({
  beneficiary,
  addCurrentToTotal = false,
}: BeneficiaryDocumentAlertProps): JSX.Element => {
  const { getText, getAccessibilityText, formValues } = useContainerCtx<BeneficiaryFormValues>();
  const { beneficiaries, account } = formValues;
  const { currentAddress, relationTypeCd, birthDate, isCurrentUser } = beneficiary;
  const { respPlanTypeCd } = account ?? {};
  const age = calculateAge(birthDate);
  const beneficiaryCount = addCurrentToTotal
    ? getBeneficiariesNotDeleted(beneficiaries).length + 1
    : getBeneficiariesNotDeleted(beneficiaries).length;

  return (
    <Alert
      size={'normal'}
      description={
        <>
          <Text className="mc-mb-normal" aria-label={getAccessibilityText('description.beneficiary.documents')}>
            {getText('documents')}
          </Text>
          <ul>
            {isCountryCA(currentAddress?.country) && (
              <li aria-label={BeneficiaryDocuments.SDE0093}>{BeneficiaryDocuments.SDE0093}</li>
            )}

            {beneficiaryCount > 1 && respPlanTypeCd && respPlanTypeCd === RespPlanTypeCd.FAMILY && (
              <li aria-label={BeneficiaryDocuments.SDE0093A}>{BeneficiaryDocuments.SDE0093A}</li>
            )}

            {relationTypeCd &&
              relationTypeCd !== BeneficiaryRelationTypeCd.PARENT &&
              isCountryCA(currentAddress?.country) && (
                <li aria-label={BeneficiaryDocuments.SDE0093B}>{BeneficiaryDocuments.SDE0093B}</li>
              )}

            {!isNil(age) && age < 10 && currentAddress?.province === PROVINCES.BC && (
              <li aria-label={BeneficiaryDocuments.SDE0093D}>{BeneficiaryDocuments.SDE0093D}</li>
            )}

            {isCurrentUser &&
              respPlanTypeCd &&
              respPlanTypeCd === RespPlanTypeCd.INDIVIDUAL &&
              !isNil(age) &&
              age > 18 &&
              age < 21 && <li aria-label={BeneficiaryDocuments.SDE0107}>{BeneficiaryDocuments.SDE0107}</li>}
          </ul>
        </>
      }
      appearance={'information'}
      dataTest={'form-alert-documents'}
      ariaLabelIcon={getAccessibilityText('icon.information')}
      type={'h1'}
      isClosable={false}
    />
  );
};

export default BeneficiaryDocumentAlert;
