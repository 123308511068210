import React, { Fragment, FunctionComponent, ReactNode } from 'react';
import { InjectedIntl } from 'react-intl';
import { GenericProps } from 'types/interfaces';
import { Heading } from '@nbc-design/heading';
import Title from 'components/Title';
import { getTextFactory, getTextListFactory } from 'utils/TextUtils';
import { createErrorFormatter, isJointMenu } from 'utils/productFormUtils';
import { Participant, RegulatoryRadioItem } from './types';
import { Col, Row } from 'react-flexbox-grid';
import {
  ProductFormComboRadio,
  ProductFormInput,
  ProductFormRadioBoolean,
  ProductFormRadioSelect,
} from 'components/productFormFields';
import HelpText from 'components/HelpText';
import { NOTARIZEDBYPROXY, TRANSACTIONBYPROXY } from './constants';

import { Text } from '@nbc-design/text';
import { Link } from '@nbc-design/link';
import { URLS } from 'utils/constants';
import { ErrorFieldFilledColor } from '@nbc-design/icons/lib/web/ErrorFieldFilledColor';

import './styles.scss';

export type RegulatoryProps = GenericProps & {
  intl: InjectedIntl;
  displayEndorserOnAccount?: boolean;
  displayJointSurvivorship?: boolean;
  hasTraderOnAccount?: boolean;
  proxyType?: string;
  firstParticipant?: Participant;
  secondParticipant?: Participant;
};

const Regulatory: FunctionComponent<RegulatoryProps> = (props: RegulatoryProps) => {
  const {
    intl,
    firstParticipant,
    hasTraderOnAccount = false,
    secondParticipant,
    displayEndorserOnAccount = true,
    displayJointSurvivorship = false,
    proxyType,
    meta,
    change,
  } = props;

  React.useEffect(() => {
    if (firstParticipant) {
      change('firstParticipant.hasTrustedContactPerson', firstParticipant.hasTrustedContactPerson ? true : false);
      change('firstParticipant.hasControlOnPlacements', firstParticipant.hasControlOnPlacements ? true : false);
    }

    if (secondParticipant) {
      change('secondParticipant.hasTrustedContactPerson', secondParticipant.hasTrustedContactPerson ? true : false);
      change('secondParticipant.hasControlOnPlacements', secondParticipant.hasControlOnPlacements ? true : false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getText = getTextFactory(intl, 'regulatory');
  const getTextList = getTextListFactory(intl, 'regulatory');
  const getGlobalText = getTextFactory(intl, 'global');
  const formatErrorMessage = (prefix?: string, fieldName?: string) => createErrorFormatter(intl, prefix, fieldName);

  const getRadioBoolean = ({ name, condition, complement = null, helpText }: RegulatoryRadioItem): ReactNode => {
    if (!condition) return null;

    return (
      <Fragment key={name}>
        <Row>
          <Col xs={12} md={12} lg={12} data-test={`field_regulatory_question_${name}`}>
            <ProductFormRadioBoolean
              name={name}
              yesLabel={getGlobalText('button.yes')}
              noLabel={getGlobalText('button.no')}
              inline
              label={getText(name)}
              formatErrorMessage={formatErrorMessage()}
              className={`regulatory__question ${name}`}
              helpText={helpText}
            />
            {complement}
          </Col>
        </Row>
      </Fragment>
    );
  };

  const documentsTooltipText: ReactNode = (
    <div>
      <p className="section__explanation" data-test="text_explanation">
        <p>{getText('documentsTypeTooltip')}</p>
        <ol type="a">
          <li>{getText('documentsTypeTooltipA')}</li>
          <li>{getText('documentsTypeTooltipB')}</li>
          <li>{getText('documentsTypeTooltipC')}</li>
        </ol>
      </p>
    </div>
  );

  const documentsHelpText: ReactNode = (
    <div className="section__explanation" data-test="text_explanation">
      {getTextList('documentsTypeTooltipAgrements').map((text, i) => (
        <HelpText dataTest={`documentsTypeTooltipAgrements.${i + 1}`} key={`documentsTypeTooltipAgrements.${i + 1}`}>
          {text}
        </HelpText>
      ))}
    </div>
  );

  const hasControlOnPlacementsComplement = (
    accountHolder: Participant | undefined,
    inputName: string,
  ): ReactNode | null => {
    return accountHolder && accountHolder.hasControlOnPlacements ? (
      <Row>
        <Col xs={12} md={12} lg={12}>
          <ProductFormInput
            name={`${inputName}.involvComment`}
            formatErrorMessage={formatErrorMessage()}
            label={getText('involvComment')}
          />
        </Col>
      </Row>
    ) : null;
  };

  const getLegalText = (startText: string, linkText: string, endText: string): React.ReactNode => (
    <div className="regulatory-text-legal">
      <ErrorFieldFilledColor size="xsmall" title="error-field-filled-color" />
      <Text data-test="fields_proxy_Information" className="product-form-input__help legal">
        {getText(startText)}
        <Link
          href={URLS.FORMS[intl.locale.toUpperCase()]}
          target="_blank"
          rel="noopener noreferrer"
          underlined
          data-test="link_forms"
          className="forms-link"
        >
          {getText(linkText)}
        </Link>
        {getText(endText)}
      </Text>
    </div>
  );

  const proxyTypeComplementDescription =
    proxyType === TRANSACTIONBYPROXY
      ? getLegalText('proxyStartText', 'proxyFormsLink', 'proxyEndText')
      : proxyType === NOTARIZEDBYPROXY
      ? getLegalText('notaryProxyStartText', 'notaryProxyFormsLink', 'notaryProxyEndText')
      : '';

  const proxyTypeComplement: ReactNode | null = hasTraderOnAccount ? (
    <Row>
      <Col xs={12} md={12} lg={12}>
        <ProductFormRadioSelect
          className="no-bold"
          label={getText('proxyType')}
          name="proxyType"
          inline
          formatErrorMessage={formatErrorMessage()}
          helpText={proxyTypeComplementDescription}
        />
      </Col>
    </Row>
  ) : null;

  const trustedContactPersonComplementDescription = (accountHolder: Participant | undefined): string => {
    return accountHolder && accountHolder.hasTrustedContactPerson
      ? getText('trustedContactPersonComplementDescription')
      : '';
  };

  const isMultiHolderMode = isJointMenu(meta);

  const radioBooleanItems: RegulatoryRadioItem[] = [
    { name: 'hasTraderOnAccount', condition: true, complement: proxyTypeComplement },
    { name: 'hasEndorserOnAccount', condition: displayEndorserOnAccount },
    { name: 'hasAnotherUserOnAccount', condition: true },
    { name: 'hasJointSurvivorship', condition: displayJointSurvivorship },
    {
      name: 'firstParticipant.hasTrustedContactPerson',
      condition: true,
      helpText: trustedContactPersonComplementDescription(firstParticipant),
    },
    {
      name: 'firstParticipant.hasControlOnPlacements',
      condition: true,
      complement: hasControlOnPlacementsComplement(firstParticipant, 'firstParticipant'),
    },
    {
      name: 'secondParticipant.hasTrustedContactPerson',
      condition: true,
      helpText: trustedContactPersonComplementDescription(secondParticipant),
    },
    {
      name: 'secondParticipant.hasControlOnPlacements',
      condition: true,
      complement: hasControlOnPlacementsComplement(secondParticipant, 'secondParticipant'),
    },
  ];

  const hasCommunicationApproval = { name: 'hasCommunicationApproval', condition: true };

  return (
    <div className="regulatory__form">
      <Title label={getText('pageTitle')} />

      {isMultiHolderMode ? (
        <>
          {radioBooleanItems
            .filter(
              (accountHolderItem) =>
                accountHolderItem.name.search('firstParticipant') === -1 &&
                accountHolderItem.name.search('secondParticipant') === -1,
            )
            .map((item: RegulatoryRadioItem): ReactNode => getRadioBoolean(item))}
          <Heading type={'h3'} size={5}>
            {getText('applicantTitle')}
          </Heading>
          <hr />
          {radioBooleanItems
            .filter((accountHolderItem) => accountHolderItem.name.search('firstParticipant') !== -1)
            .map((item: RegulatoryRadioItem): ReactNode => getRadioBoolean(item))}
          <Heading type={'h3'} size={5}>
            {getText('spouseTitle')}
          </Heading>
          <hr />
          {radioBooleanItems
            .filter((accountHolderItem) => accountHolderItem.name.search('secondParticipant') !== -1)
            .map((item: RegulatoryRadioItem): ReactNode => getRadioBoolean(item))}
        </>
      ) : (
        <>
          {radioBooleanItems
            .filter((accountHolderItem) => accountHolderItem.name.search('secondParticipant') === -1)
            .map((item: RegulatoryRadioItem): ReactNode => getRadioBoolean(item))}
        </>
      )}

      <Heading type={'h2'} size={4}>
        {getText('beneficialShareholders')}
      </Heading>
      <hr />

      {getRadioBoolean(hasCommunicationApproval)}

      <Row>
        <Col xs={12} md={12} lg={12} data-test="field_documentsType">
          <ProductFormComboRadio
            name="documentsType"
            label={getText('documentsType')}
            formatErrorMessage={formatErrorMessage()}
            tooltip={documentsTooltipText}
            className="regulatory__documents"
          />
        </Col>
        {documentsHelpText}
      </Row>
    </div>
  );
};

export default Regulatory;
