import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Button } from '@nbc-design/button';
import { LANG } from 'utils/constants';
import { Download } from '@nbc-design/icons/lib/web/Download';
import Icon from 'assets/icons/lang/fr-icon.png';
import { Text } from '@nbc-design/text';
import Title from 'components/Title';
import { createErrorFormatter, ErrorFormatterReturnType } from 'utils/productFormUtils';
import { BREAKPOINT } from 'utils/constants';
import { getTextFactory, getTextFactoryReturnType } from 'utils/TextUtils';
import { GenericProps } from 'types/interfaces';
import ErrorMessageDisplay from 'components/errorManager/ErrorMessageDisplay';
import { ProductFormCheckbox } from 'components/productFormFields';
import { ERRORCODE } from 'components/errorManager/utils';
import { DocumentItem } from './types';
import { getDocumentsUrls } from './utils';
import DocumentViewer from './components/DocumentViewer';
import './styles.scss';
import { getLocale } from '../../utils';

export type DocumentsProps = GenericProps & {
  documentsAcceptance?: boolean;
  isBNCD?: boolean;
  width: number;
};

export type DocumentsState = {
  documentsURLs: string[];
  merged?: string;
  onTop: boolean;
};

const scalesPerBreakpoint = [
  [BREAKPOINT.MD, 0.6],
  [BREAKPOINT.LG, 0.8],
  [BREAKPOINT.XL, 1],
  [BREAKPOINT.XXL, 1.2],
];
export const getScale = (width: number) =>
  scalesPerBreakpoint.reduce((currentScale, [breakpoint, scale]) => (width >= breakpoint ? scale : currentScale), 0.6);

class Documents extends React.Component<DocumentsProps, DocumentsState> {
  private getText: getTextFactoryReturnType;
  private getGlobalText: getTextFactoryReturnType;
  private getAccessibilityText: getTextFactoryReturnType;
  private formatErrorMessage: ErrorFormatterReturnType;
  private errorFormatter: ErrorFormatterReturnType;

  constructor(props: DocumentsProps) {
    super(props);
    this.state = {
      documentsURLs: [],
      merged: undefined,
      onTop: true,
    };
    this.getText = getTextFactory(props.intl, 'documents');
    this.getGlobalText = getTextFactory(props.intl, 'global');
    this.getAccessibilityText = getTextFactory(props.intl, 'accessibility.button');
    this.formatErrorMessage = createErrorFormatter(props.intl);
    this.errorFormatter = createErrorFormatter(props.intl, 'documents');
    this.onScroll = this.onScroll.bind(this);
  }

  async componentDidMount() {
    const {
      productForm,
      meta: { requestCode },
      intl: { locale },
    } = this.props;

    const documents: DocumentItem[] = get(productForm, 'values.documents', []);
    return getDocumentsUrls(documents, requestCode, locale)
      .then((values) => {
        this.setState({
          documentsURLs: values.slice(0, -1),
          merged: values.slice(-1)[0],
        });
      })
      .catch(this.handleDocumentFetchingError);
  }

  handleDocumentFetchingError(err: number) {
    if (err === ERRORCODE.CONFLICT) {
      window.location.reload();
    }
    return '';
  }

  onScroll(event: React.UIEvent<HTMLDivElement>) {
    this.setState({ onTop: event.currentTarget.scrollTop === 0 });
  }

  render() {
    const { submitFailed, error, intl, productForm, width } = this.props;
    const { onTop, merged } = this.state;
    const { getText, getGlobalText, getAccessibilityText, formatErrorMessage } = this;

    const documents: DocumentItem[] = get(productForm, 'values.documents', []);
    if (isEmpty(documents)) {
      return (
        <div className="labelPageLoading" data-test="label_page_loading">
          {getGlobalText('loading')}
        </div>
      );
    }

    const errorMessage = !!error ? this.errorFormatter(error) : null;

    const pdfScale = getScale(width);

    return (
      <div className="documents__form">
        <Title label={getText('pageTitle')} />

        <Row className="explanation-row">
          <Col xs={12} md={12} lg={12} data-test="label_explanation">
            <Text>{getText('explanation')}</Text>
          </Col>
        </Row>

        <Row className="pdf-row">
          <Col xs={12} md={12} lg={12} className="pdf-section">
            {!onTop && <div className="rectangle top" />}
            <div className="pdf-reader" onScroll={this.onScroll}>
              <DocumentViewer
                documents={documents}
                documentsURLs={this.state.documentsURLs}
                pdfScale={pdfScale}
                intl={intl}
                getText={this.getText}
                getGlobalText={this.getGlobalText}
              />
            </div>
            <div className="rectangle bottom" />
          </Col>

          <Col xs={12} md={12} lg={12} className="download-section">
            <Button
              icon={<Download title={getAccessibilityText('downloadAll')} />}
              appearance="secondary"
              type="link"
              href={merged}
              download
            >
              {getText('download')}
            </Button>
          </Col>
        </Row>

        <Row className="documents-acceptance-row">
          <Col xs={12} md={12} lg={12} data-test="field_documentsAcceptance">
            <ProductFormCheckbox
              name="documentsAcceptance"
              label={getText('acceptanceLabel')}
              formatErrorMessage={formatErrorMessage}
            />
          </Col>
        </Row>
        {getLocale() === LANG.EN && (
          <div className="Bill14">
            <Row className="bill-14-header">
              <Col xs={12} md={12} lg={12}>
                <strong>{getText('bill14Header')} </strong>
              </Col>
            </Row>

            <Row className="bill-14-row-en">
              <Col xs={12} md={12} lg={12}>
                <Text>
                  {getText('bill14En1')}
                  <img className="lang-img" src={Icon} alt={''} /> {getText('bill14En2')}{' '}
                </Text>
              </Col>
            </Row>

            <Row className="bill-14-row-fr">
              <Col xs={12} md={12} lg={12}>
                <Text>
                  {getText('bill14Fr1')}
                  <img className="lang-img" src={Icon} alt={''} /> {getText('bill14Fr2')}{' '}
                </Text>
              </Col>
            </Row>
          </div>
        )}

        <ErrorMessageDisplay visible={submitFailed && !!error} message={errorMessage} />
      </div>
    );
  }
}

export default Documents;
