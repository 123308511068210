import React, { FunctionComponent, SetStateAction } from 'react';
import { InjectedIntl } from 'react-intl';
import { Loading } from '@nbc-design/loading';
import { getTextFactory } from 'utils/TextUtils';
import { sendStepLoadedEvent } from 'services/analytics';
import { EVENT_IDS } from 'services/analytics/constants';
import { getBncId } from 'utils';

export type LoadingMessengerProps = {
  intl: InjectedIntl;
};

const LoadingMessenger: FunctionComponent<LoadingMessengerProps> = (props: LoadingMessengerProps) => {
  const { intl } = props;

  const getText = getTextFactory(intl, 'aboutYou.loading');

  const [title, setTitle] = React.useState('');
  const [textString, setTextString] = React.useState('');
  const [ariaLabel, setAriaLabel] = React.useState('');
  const [status, setStatus] = React.useState(undefined);
  const [hideTextOnSuccess, setHideTextOnSuccess] = React.useState(undefined);
  const [onSuccessDelay, setOnSuccessDelay] = React.useState(undefined);

  React.useEffect(() => {
    sendStepLoadedEvent('profile creation processing', EVENT_IDS.ST, '', {
      eventInfo: {
        ucsId: 'UCS-bao-005',
      },
      step: {
        stepId: '5',
        flowId: 'profile creation',
      },
      user: {
        bncId: getBncId(),
      },
    });

    displayLoading();
    setHideTextOnSuccess(false as SetStateAction<any>);
    setOnSuccessDelay(3000 as SetStateAction<any>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayLoading = () => {
    setTitle(getText('initialTitle'));
    setTextString(getText('firstMessage'));

    setTimeout(() => {
      setTextString(getText('secondMessage'));

      setTimeout(() => {
        setStatus('success' as SetStateAction<any>);
        setAriaLabel(getText('finalArial'));
        setTitle(getText('finalTitle'));
        setTextString('');
      }, 1500);
    }, 1500);
  };

  const handleIsLoaded = () => {
    setTitle(getText('initialTitle'));
    setTextString(getText('firstMessage'));
    setStatus(undefined);
  };

  return (
    <Loading
      title={title}
      text={textString}
      ariaLabel={ariaLabel}
      status={status}
      onLoaded={handleIsLoaded}
      hideTextOnSuccess={hideTextOnSuccess}
      onSuccessDelay={onSuccessDelay}
    />
  );
};

export default LoadingMessenger;
