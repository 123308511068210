import React, { FunctionComponent } from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { Col, Row } from 'react-flexbox-grid';
import { RefData, RefDataContainer, Schema } from 'types/interfaces';

import '../styles.scss';
import { AccountDataType } from '../types';
import BankingAccountManual from './BankingAccountManual';

export type BankingAccountProps = {
  name: string;
  input: { name: string };
  customSchema: Schema;
  change: (key: string, value: string) => void;
  intl: InjectedIntl;
  title?: string;
  refData?: RefData[];
  data?: AccountDataType;
};

const BankingAccount: FunctionComponent<BankingAccountProps> = (props: BankingAccountProps) => {
  const { name, input, customSchema, change, title = '', refData, data } = props;

  const getSchema = (propertyName: string): RefDataContainer => customSchema.properties[propertyName];

  // Pre-select currency when only one option is selectable
  const currencyRefData = refData || getSchema('currency').refData;

  return (
    <div className="bankingAccount">
      {title && (
        <Row>
          <Col xs={12} md={12} lg={12}>
            {title}
          </Col>
        </Row>
      )}
      <BankingAccountManual
        name={name}
        input={input}
        customSchema={customSchema}
        change={change}
        refData={currencyRefData}
        data={data}
      />
    </div>
  );
};

export default injectIntl(BankingAccount);
