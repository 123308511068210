import React, { FunctionComponent } from 'react';
import Title from 'components/Title';
import { Col, Row } from 'react-flexbox-grid';
import { Text } from '@nbc-design/text';
import { BaoDrilledProps } from 'types/interfaces';
import LegislationSelectors from './components/LegislationSelectors';
import UtilisationSelectors from './components/UtilisationSelectors';
import { ProductFormCheckbox, ProductFormInput } from 'components/productFormFields';
import Heading from 'components/Heading';
import { ACCOUNT_TYPE } from '../AccountsStep1/constants';

import './styles.scss';
import { RespPlanSelectors } from './components/RespPlanSelectors';
import { AccountsStep3FormData } from './types';
import { useContainerCtx } from 'providers/containerCtx/ContainerCtx';

type AccountsStep3Props = BaoDrilledProps & AccountsStep3FormData;

const AccountsStep3: FunctionComponent<AccountsStep3Props> = ({
  legislations,
  utilisations,
  displayTradingOption,
  promotionCdAvailable = false,
  promotionCd = null,
}) => {
  const { getText, getTextGlobal, formatErrorMessage } = useContainerCtx();

  const displayPromo = promotionCdAvailable || (!!promotionCd && !promotionCd.length);

  const displayUtilisation = !(
    utilisations &&
    utilisations.length === 1 &&
    utilisations.find((item) => item.accountType.code === ACCOUNT_TYPE.FHSA)
  );

  const displayLegislation =
    legislations &&
    legislations.find((legislation) => legislation.eligibleProvinces && legislation.eligibleProvinces.length > 0);

  return (
    <div className="accountsStep3__form">
      <Title label={getText('pageTitle')} />

      {displayUtilisation && <UtilisationSelectors />}
      <RespPlanSelectors />
      {displayLegislation && <LegislationSelectors />}

      {displayTradingOption && (
        <Row>
          <Col xs={12} md={12} lg={12}>
            <Heading type="h3" size={5}>
              {getText('tradingOptions')}
            </Heading>
            <Text className="tradingOptions">{getText('tradingOptionsInfo')}</Text>
            <ProductFormCheckbox
              name="tradingOptionsInd"
              formatErrorMessage={formatErrorMessage()}
              label={getText('tradingOptionsInd')}
            />
          </Col>
        </Row>
      )}

      <Row className="promotion">
        <Col xs={12} sm={6} md={6} lg={5} className="promotionCdAvailable">
          <ProductFormCheckbox
            name="promotionCdAvailable"
            formatErrorMessage={formatErrorMessage()}
            label={getText('promotionCd')}
          />
        </Col>

        {displayPromo && (
          <Col xs={12} sm={4} md={4} lg={4}>
            <ProductFormInput
              name="promotionCd"
              placeholder={getTextGlobal('promoCodePlaceHolder')}
              formatErrorMessage={formatErrorMessage()}
              ariaLabel={getTextGlobal('promoCodePlaceHolder')}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default AccountsStep3;
