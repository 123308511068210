import React, { FunctionComponent, Fragment, useState, ReactNode } from 'react';
import { InjectedIntl } from 'react-intl';
import { Alert } from '@nbc-design/alert';
import { IconButton } from '@nbc-design/button';
import { Text } from '@nbc-design/text';
import TextList from '@nbc-design/text-list';
import { Close } from '@nbc-design/icons/lib/web/Close';

import { signoutAll, signOutUnifiedLogin } from 'services/ssoRedirectService/oktaService';
import { isAuthenticatedWith, OKTA_AUTHENT_TYPE } from 'services/loginManager';

import { getTextFactory } from 'utils/TextUtils';
import { ConfigData } from 'types/interfaces';

import Modal from 'components/Modal/Modal';

export type QuitProps = {
  intl: InjectedIntl;
  config: ConfigData;
};

const Quit: FunctionComponent<QuitProps> = (props: QuitProps) => {
  const { intl, config } = props;

  const [showModal, setShowModal] = useState(false);

  if (!config.BUTTON_QUIT) {
    return null;
  }

  const getText = getTextFactory(intl, 'quit');
  const getTextGlobal = getTextFactory(intl, 'global');
  const getAccessibilityText = getTextFactory(intl, 'accessibility');

  const redirect = (): void => {
    const url = getTextGlobal('bncdURL');
    typeof window.location.assign === 'function'
      ? window.location.assign(url)
      : // Edge < 12 support using window.location.href
        (window.location.href = url);
  };

  const handleClick = (): void => {
    signoutAll()
      .then((): void => setShowModal(true))
      .catch((): void => setShowModal(true));
  };

  const closeModal = (): void => {
    setShowModal(false);

    isAuthenticatedWith(OKTA_AUTHENT_TYPE) ? signOutUnifiedLogin() : redirect();
  };

  const ModalContent: ReactNode = (
    <div className="quit-modal">
      <Alert
        appearance="success"
        title={getText('alertText')}
        ariaLabelIcon={getAccessibilityText('icon.success')}
        type="h3"
        className="quit-alert"
      />
      <div className="quit-explanations">
        <TextList>
          <Text className="quit-main-explanation">{getText('explanationTitle')}</Text>
          <TextList.Item className="quit-explanation">{getText('explanation1')}</TextList.Item>
          <TextList.Item className="quit-explanation">{getText('explanation2')}</TextList.Item>
        </TextList>
      </div>
    </div>
  );

  const modalProps = {
    title: getText('modalTitle'),
    headerClass: 'modal-big-title',
    isCloseHidden: false,
    hasIcon: false,
    onClose: closeModal,
    hasFooter: true,
    footerClass: 'modal-right-button',
    Content: ModalContent,
    close: getText('closeButtonTitle'),
  };

  return (
    <Fragment>
      <IconButton
        icon={<Close title={getAccessibilityText('button.close')} />}
        type="button"
        onClick={handleClick}
        appearance="secondary"
        className="close-button"
      />

      {showModal && <Modal {...modalProps} name={'Open:Leave and resume'} text={''} />}
    </Fragment>
  );
};

export default Quit;
