import { RootState } from 'types/reducers';
import { getState } from 'globalRedux/store';
import { APPLICANT_STEP_ID } from 'utils/stepsConstants';
import { cloneDeep, get, set } from 'lodash';
import { HolderName } from './types';

export const updateReduxForm = (): void => {
  const state: RootState | null = getState();

  if (state) {
    const productFormReducerData = get(state, 'productFormReducer.baoForms.productForm.values', {});
    const currentStepId = get(state, 'productFormReducer.baoForms.currentStep', '');
    const isFormLoading = get(state, 'productFormReducer.baoForms.isLoading', true);
    const isReduxFormReady = get(state, 'productFormReducer.baoForms.isReduxFormReady', false);

    const isForcedUpdateCondition =
      currentStepId === APPLICANT_STEP_ID.PERSONAL_INFOS && !isFormLoading && isReduxFormReady;

    if (isForcedUpdateCondition) {
      set(state, 'reduxFormReducer.baoForms.values', cloneDeep(productFormReducerData));
      set(state, 'baoReducer.isMultiHolderMode', false);
    }
  }
};

export const formatHolderName = (name: HolderName): string => {
  return `${name.firstName}${name.middleName ? ' ' + name.middleName : ''} ${name.lastName}`;
};
