import React, { FunctionComponent, ReactNode, useReducer, useState } from 'react';
import { InjectedIntl } from 'react-intl';
import { Button } from '@nbc-design/button';
import { Check } from '@nbc-design/icons/lib/web/Check';
import Title from 'components/Title';
import { Col, Row } from 'react-flexbox-grid';
import finalisationImage from 'assets/images/finalisation-image.png';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { createErrorFormatter, isJointMenu } from 'utils/productFormUtils';
import { getTextFactory } from 'utils/TextUtils';
import { DocumentItem, NoteItem } from './types';
import { DOCUMENT_ID, DOCUMENTS_W9 } from './constants';
import ErrorMessageDisplay from 'components/errorManager/ErrorMessageDisplay';
import { GenericProps } from 'types/interfaces';
import StepRow from './components/StepRow';
import { getDocument } from 'services/documentManager';
import { ERRORCODE } from 'components/errorManager/utils';

import './styles.scss';

export type FinalisationProps = GenericProps & {
  intl: InjectedIntl;
  allLinksClicked: boolean;
  isBNCD: boolean;
};

const Finalisation: FunctionComponent<FinalisationProps> = (props: FinalisationProps) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [clickedLinks, setClickedLinks] = useState<string[]>([]);

  const { submitFailed, error = null, intl, change, productForm, meta } = props;

  React.useEffect(() => {
    change('isBNCD', false);
    change('allLinksOpened', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const finLegalEntity: string = get(productForm, ['values', 'finLegalEntity']);
  const isBNCD = finLegalEntity && finLegalEntity === 'BNCD';

  const getText = getTextFactory(intl, 'finalisation');
  const getTextGlobal = getTextFactory(intl, 'global');
  const getAccessibilityText = getTextFactory(intl, 'accessibility.icon');

  const isMultiHolderMode = isJointMenu(meta);

  // common data
  const documents: DocumentItem[] = get(productForm, ['values', 'commonArtifacts', 'documents'], []);
  const notes: NoteItem[] = get(productForm, ['values', 'commonArtifacts', 'notes'], []);

  // mainHolder data
  const mainHolderDocuments: DocumentItem[] = get(productForm, ['values', 'mainHolderArtifacts', 'documents'], []);
  const mainHolderNotes: NoteItem[] = get(productForm, ['values', 'mainHolderArtifacts', 'notes'], []);

  // coHolder data
  const coHolderDocuments: DocumentItem[] = get(productForm, ['values', 'coHolderArtifacts', 'documents'], []);
  const coHolderNotes: NoteItem[] = get(productForm, ['values', 'coHolderArtifacts', 'notes'], []);

  const allLinksQuantity = documents.length + mainHolderDocuments.length + coHolderDocuments.length;
  const allNotesQuantity = notes.length + mainHolderNotes.length + coHolderNotes.length;

  const getData = (data: NoteItem | DocumentItem, key: string): string => data[key][intl.locale];

  const handleLinksClicks = (id: string): void => {
    if (!clickedLinks.find((linkId) => linkId === id)) {
      clickedLinks.push(id);
    }

    setClickedLinks(clickedLinks);

    forceUpdate();

    change('isBNCD', isBNCD);

    if (clickedLinks.length > 0) {
      !isMultiHolderMode
        ? change('allLinksOpened', clickedLinks.length === documents.length)
        : change('allLinksOpened', clickedLinks.length === allLinksQuantity);
    }
  };

  const renderNote = (noteData: NoteItem, index: number): ReactNode => {
    return (
      <li key={index}>
        <span className="note-link" data-test={`label_note_${index}`}>
          {getData(noteData, 'title')}
        </span>
      </li>
    );
  };

  const renderNotes = (noteItems: NoteItem[]): ReactNode | null => {
    const renderedNotes = noteItems.map((note: NoteItem, index: number): ReactNode => renderNote(note, index));

    return <ul>{renderedNotes}</ul>;
  };

  const openDocument = (url: string, docID: string): void => {
    getDocument(url)
      .then((blobDoc) => {
        const docURL = window.URL.createObjectURL(blobDoc);
        window.open(docURL, '_blank');
        handleLinksClicks(docID);
      })
      .catch((err) => {
        if (err === ERRORCODE.CONFLICT) window.location.reload();
      });
  };

  const renderLink = (linkData: DocumentItem, index: number): ReactNode => {
    const documentId = getData(linkData, 'documentId');
    if (DOCUMENTS_W9.includes(documentId as DOCUMENT_ID)) {
      const handleW9Click = () => {
        handleLinksClicks(documentId);
      };
      return (
        <li key={documentId}>
          {clickedLinks.find((linkId) => linkId === documentId) && (
            <Check
              className="sidebar-menu__step__icon sidebar-menu__step__icon--completed"
              title={getAccessibilityText('check')}
            />
          )}
          <a target="_blank" rel="noopener noreferrer" href={getData(linkData, 'uri')} onClick={handleW9Click}>
            {getData(linkData, 'title')}
          </a>
        </li>
      );
    }
    return (
      <li key={documentId}>
        {clickedLinks.find((linkId) => linkId === documentId) && (
          <Check
            className="sidebar-menu__step__icon sidebar-menu__step__icon--completed"
            title={getAccessibilityText('check')}
          />
        )}
        <Button
          type="button"
          className="link"
          onClick={() => openDocument(getData(linkData, 'uri'), documentId)}
          data-test={`label_document_link_${index}`}
        >
          {getData(linkData, 'title')}
        </Button>
      </li>
    );
  };

  const renderDocumentsLinks = (documentsList: DocumentItem[]): ReactNode | null => {
    if (isEmpty(documentsList)) {
      return null;
    }

    const documentItems = documentsList.map(
      (document: DocumentItem, index: number): ReactNode => renderLink(document, index),
    );

    return <ul>{documentItems}</ul>;
  };

  if (!allLinksQuantity) {
    return <div data-test="label_page_loading">{getTextGlobal('loading')}</div>;
  }

  const errorMessage = error && createErrorFormatter(intl, 'finalisation')(error);

  return (
    <div className="finalisation__form">
      <Row className="image-row">
        <Col xs={12} md={12} lg={12} data-test="image_page">
          <img src={finalisationImage} alt="finalisation" />
        </Col>
      </Row>

      <Title label={getText('pageTitle')} />
      <Row className="finalisation-container-row">
        <StepRow stepIndex={1} stepTitle={getText('step1Title')}>
          <Col xs={12} md={12} lg={12}>
            <p className="product-form-input__help">{getText('verifyInformation')}</p>
          </Col>
          <Col xs={12} md={12} lg={12}>
            {getText('step1SubTitle')}
          </Col>

          {!isEmpty(documents) && (
            <div className="common-documents-section">
              <Col xs={12} md={12} lg={12} className="documents-links-row" data-test="section_documents">
                {renderDocumentsLinks(documents)}
              </Col>
            </div>
          )}

          {isMultiHolderMode && (
            <div className="multi-holder-documents">
              {!isEmpty(mainHolderDocuments) && (
                <div className="main-holder-documents-section multi-section">
                  <Col xs={12} md={12} lg={12} className="documents-links-title" data-test="section_title">
                    {getText('mainHolderDocumentsTitle')}
                  </Col>
                  <Col xs={12} md={12} lg={12} className="documents-links-row" data-test="section_documents">
                    {renderDocumentsLinks(mainHolderDocuments)}
                  </Col>
                </div>
              )}

              {!isEmpty(coHolderDocuments) && (
                <div className="co-holder-documents-section multi-section">
                  <Col xs={12} md={12} lg={12} className="documents-links-title" data-test="section_title">
                    {getText('coHolderDocumentsTitle')}
                  </Col>
                  <Col xs={12} md={12} lg={12} className="documents-links-row" data-test="section_documents">
                    {renderDocumentsLinks(coHolderDocuments)}
                  </Col>
                </div>
              )}
            </div>
          )}
        </StepRow>

        {allNotesQuantity && (
          <StepRow stepIndex={2} stepTitle={getText('step2Title')}>
            {!isEmpty(notes) && (
              <div className="common-notes-section">
                <Col xs={12} md={12} lg={12} className="notes-links-row" data-test="section_notes">
                  {renderNotes(notes)}
                </Col>
              </div>
            )}

            {isMultiHolderMode && (
              <div className="multi-holder-notes">
                {!isEmpty(mainHolderNotes) && (
                  <div className="main-holder-notes-section multi-section">
                    <Col xs={12} md={12} lg={12} className="notes-links-title" data-test="section_title">
                      {getText('mainHolderDocumentsTitle')}
                    </Col>
                    <Col xs={12} md={12} lg={12} className="notes-links-row" data-test="section_notes">
                      {renderNotes(mainHolderNotes)}
                    </Col>
                  </div>
                )}

                {!isEmpty(coHolderNotes) && (
                  <div className="co-holder-notes-section multi-section">
                    <Col xs={12} md={12} lg={12} className="notes-links-title" data-test="section_title">
                      {getText('coHolderDocumentsTitle')}
                    </Col>
                    <Col xs={12} md={12} lg={12} className="notes-links-row" data-test="section_notes">
                      {renderNotes(coHolderNotes)}
                    </Col>
                  </div>
                )}
              </div>
            )}
          </StepRow>
        )}

        <StepRow stepIndex={3} stepNumber={!allNotesQuantity ? 2 : 3} stepTitle={getText('step3Title')}>
          <Col xs={12} md={12} lg={12} data-test="label_address1Line">
            {getTextGlobal('address1Line')}
          </Col>
          <Col xs={12} md={12} lg={12} data-test="label_address2Line">
            {getTextGlobal('address2Line')}
          </Col>
          <Col xs={12} md={12} lg={12} data-test="label_address3Line">
            {getTextGlobal('address3Line')}
          </Col>
          <Col xs={12} md={12} lg={12} data-test="label_address4Line">
            {getTextGlobal('address4Line')}
          </Col>
          <Col xs={12} md={12} lg={12} data-test="label_address5Line">
            {getTextGlobal('address5Line')}
          </Col>
        </StepRow>
      </Row>

      <ErrorMessageDisplay visible={submitFailed && !!error} message={errorMessage} />
    </div>
  );
};

export default Finalisation;
