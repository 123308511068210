import isEmpty from 'lodash/isEmpty';
import React, { ChangeEvent, MouseEvent, FunctionComponent, ReactNode } from 'react';
import { InjectedIntl } from 'react-intl';
import { Col, Row } from 'react-flexbox-grid';
import { Button } from '@nbc-design/button';
import { AccordionOpen } from '@nbc-design/icons/lib/web/AccordionOpen';
import { Delete } from '@nbc-design/icons/lib/web/Delete';

import {
  AllRefData,
  RefData,
  RefDataContainer,
  Schema,
  ProductForm,
  ProductFormFieldChangeHandler,
} from 'types/interfaces';
import { FormComponentProps, ErrorType, OptionsType } from '../productFormFields/types';
import './style.scss';

export type CollectionProps = {
  data: any[];
  name: string;
  typeComponent: Function;
  intl: InjectedIntl;
  change: ProductFormFieldChangeHandler;
  isHidden?: (data: any) => boolean;
  handleSubmit?: Function;
  label?: string;
  placeholder?: string;
  // TODO: stuff declared but never used! Could we remove it?
  helpText?: string; // prop used on PersonalInformation page, but doesn't managed on this code...
  formatErrorMessage?: (error: ErrorType, props: FormComponentProps) => string; // or FormFieldProps?
  customOnChange?: ((event: ChangeEvent<HTMLInputElement>) => void) | ((event: ChangeEvent<HTMLSelectElement>) => void);
  card?: boolean;
  addLabel?: string;
  deleteLabel?: string;
  deleteButton?: boolean;
  sort?: ((a: OptionsType, b: OptionsType) => number) | boolean;
  filter?: Function;
  maxItems?: number;
  minItems?: number;
  refData?: RefData[];
  allRefData?: AllRefData;
  customRefData?: RefData[][];
  schema?: RefDataContainer;
  transformSchema?: RefDataContainer;
  customSchema?: Schema;
  childProps?: FormComponentProps; // or FormFieldProps?
  defaultValue?: any;
  productForm?: ProductForm;
};

//NOSONAR
const Collection: FunctionComponent<CollectionProps> = (props: CollectionProps) => {
  const {
    data,
    name,
    typeComponent,
    intl,
    label,
    placeholder,
    formatErrorMessage,
    customOnChange,
    change,
    isHidden,
    handleSubmit,
    card,
    addLabel,
    deleteLabel,
    deleteButton = true,
    sort,
    filter,
    minItems = 1,
    maxItems,
    refData,
    allRefData,
    customRefData = [],
    schema,
    transformSchema,
    customSchema,
    childProps,
    productForm,
  } = props;

  const isNumber = (val: any): boolean => typeof val === 'number';
  const minLength = schema && isNumber(schema.minItems) ? schema.minItems : minItems;
  const maxLength = maxItems || (schema && isNumber(schema.maxItems) ? schema.maxItems : 5);

  if (data && minLength !== undefined && data.length < minLength) {
    const defaultValues: null[] = [...data];

    for (let i = data.length; i < minLength; i++) {
      defaultValues.push(null);
    }

    change(`${name}`, defaultValues);
  }

  const itemClassName = `collection__${card ? 'card' : 'item'}`;

  const handleOnClickAddButton = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();

    const newData = [...data, null];
    change(`${name}`, newData);
  };

  const handleOnClickDeleteButton = (event: MouseEvent<HTMLButtonElement>, indexToDelete: number): void => {
    event.preventDefault();

    const newData = data.filter((_, index: number): boolean => index !== indexToDelete);
    change(`${name}`, newData);
  };

  const renderAddButton = (): ReactNode | null => {
    if (maxLength && (data || []).length >= maxLength) {
      return null;
    }

    const disabledAddButton = isEmpty(data) || !!(data || []).filter((val) => isEmpty(val) || !val).length;

    return (
      <Row className={`${itemClassName} collection__button add`}>
        <Col xs={12} md={12} lg={12}>
          <Button
            disabled={disabledAddButton}
            className={disabledAddButton ? 'disabled' : ''}
            icon={<AccordionOpen title={addLabel} />}
            type="button"
            appearance="secondary"
            data-test="add-button"
            size="small"
            onClick={(e) => handleOnClickAddButton(e)}
          >
            {addLabel || intl.formatMessage({ id: 'collection.add' })}
          </Button>
        </Col>
      </Row>
    );
  };

  const renderDeleteButton = (index: number): ReactNode | null => {
    if (minLength && index < minLength) {
      return null;
    }

    return (
      <div className="collection__button delete">
        <Button
          appearance="minimalDanger"
          icon={<Delete title={deleteLabel} />}
          type="button"
          data-test="delete-button"
          size="small"
          onClick={(e) => handleOnClickDeleteButton(e, index)}
        >
          {deleteLabel || intl.formatMessage({ id: 'collection.delete' })}
        </Button>
      </div>
    );
  };

  const createComponent = (index: number, value: any): ReactNode => {
    const InjectedComponent = typeComponent;

    return (
      <Row className={itemClassName} key={index}>
        <Col xs={12} md={12} lg={12}>
          <InjectedComponent
            data={value === '' ? {} : value}
            name={`${name}[${index}]`}
            change={change}
            handleSubmit={handleSubmit}
            index={index}
            label={label || ''}
            placeholder={placeholder}
            ariaLabel={addLabel}
            mapSchema={transformSchema}
            formatErrorMessage={formatErrorMessage}
            {...(allRefData ? { allRefData: allRefData } : {})}
            {...(refData ? { refData: refData } : {})}
            {...(customRefData[index] ? { refData: customRefData[index] } : {})}
            {...(customOnChange ? { onChange: customOnChange } : {})}
            {...childProps}
            {...(sort ? { sort: sort } : {})}
            {...(filter ? { filter: filter } : {})}
            {...(customSchema ? { customSchema: customSchema } : {})}
            {...(productForm ? { productForm: productForm } : {})}
          />
        </Col>
        {deleteButton ? (
          <Col xs={12} md={3} lg={3}>
            {renderDeleteButton(index)}
          </Col>
        ) : (
          ''
        )}
      </Row>
    );
  };

  return (
    <div className="collection">
      {(data || []).map(
        (field: any, index: number): ReactNode => (!isHidden || !isHidden(field)) && createComponent(index, field),
      )}

      {renderAddButton()}
    </div>
  );
};

export default Collection;
