import React, { FC, ChangeEvent } from 'react';
import { InjectedIntl } from 'react-intl';
import { FormGroup } from '@nbc-design/form-group';
import { Select } from '@nbc-design/select';
import { Text } from '@nbc-design/text';

import { getTextFactory } from 'utils/TextUtils';
import AnalyticsHelper from 'services/analytics/AnalyticsHelper';
import { FormComponentProps, OptionsType } from '../types';
import {
  getLabelText,
  getErrorMessage,
  isInvalidOnChange,
  getOptionsReadOnlyLabel,
  getSchemaOptions,
  getIntroductionText,
} from '../utils';
import './styles.scss';

export type ProductFormSelectProps = FormComponentProps & {
  intl: InjectedIntl;
  defaultValue?: string | number | string[];
  disabled?: boolean;
  ariaLabel?: string;
  tooltip?: React.ReactNode;
};

const ProductFormSelect: FC<ProductFormSelectProps> = (props) => {
  const {
    intl,
    placeholder,
    helpText,
    tooltip,
    input,
    isReadOnly,
    sort = false,
    filter,
    schema,
    className,
    defaultValue = '',
    disabled,
    ariaLabel,
    meta,
  } = props;

  const invalid = isInvalidOnChange(props) || (meta.touched && !!meta.error);
  const options: OptionsType[] = getSchemaOptions(props, sort, filter);
  const optional = schema && schema.optional;
  const readOnlyLabel = getOptionsReadOnlyLabel(options, input.value);
  const label = getLabelText(props);
  const introduction = getIntroductionText(props);
  const getAccessibilityText = getTextFactory(intl, 'accessibility');
  const onShowTooltip = AnalyticsHelper.handleShowTooltip(input.name);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    input.onChange && input.onChange(event);
  };

  const selectProps = {
    id: `select-${input.name}`,
    name: input.name,
    value: input.value,
    defaultValue,
    placeholder,
    onChange: handleChange,
    clearable: optional,
    options,
    disabled,
  };

  if (!!ariaLabel) {
    selectProps['aria-label'] = ariaLabel;
  }

  return (
    <FormGroup
      className={className}
      label={label ? { text: label || '', htmlFor: `select-${input.name}` } : undefined}
      introduction={introduction ? { text: introduction || '', id: `select-${input.name}` } : undefined}
      tooltip={{ content: tooltip, ariaLabel: getAccessibilityText('information'), onShowTooltip }}
      validate={{ hasError: invalid, errorMsg: getErrorMessage(props) }}
      description={helpText ? { text: helpText, id: `help-${input.name}` } : undefined}
    >
      {isReadOnly ? <Text type="p">{readOnlyLabel}</Text> : <Select {...selectProps} />}
    </FormGroup>
  );
};

export default ProductFormSelect;
