import React, { FC } from 'react';
import { FormStep, withProductForm } from 'bnc-react-forms';

import { BFF_STEPS } from 'utils/stepsConstants';

import FormFooter from '../FormFooter';
import ErrorModal from '../errorManager/errorModal/errorModal';
import { BaoDrilledProps } from '../../types/interfaces';

export type ProductFormContentProps = BaoDrilledProps & {
  productForm: { error: string; status: number };
  Page?: React.ComponentType<any>;
};

export const ProductFormContent: FC<ProductFormContentProps> = (props) => {
  const { handleSubmit, currentStep, meta, goToStep, isFailure, productForm, intl, config, history, Page } = props;

  const { previousStepId } = meta;
  const goToPreviousStep = previousStepId ? () => goToStep && goToStep(previousStepId) : null;

  if (Page) {
    return <Page {...{ history, config }} />;
  }

  return (
    <form onSubmit={handleSubmit} className="layout-content">
      <ErrorModal isFailure={isFailure} productForm={productForm} intl={intl} />

      {BFF_STEPS.map((step, index) => (
        <FormStep key={index} form={props} isActive={currentStep === step.ID} component={step.container} />
      ))}

      {config.FOOTER && <FormFooter config={config} goToPreviousStep={goToPreviousStep} />}
    </form>
  );
};

// Connect to the product form on the default export
export default withProductForm((productForm, props) => ({
  stepId: props.currentStep,
  productForm,
}))(ProductFormContent);
