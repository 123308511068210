import { ConfigData } from 'types/interfaces';

const config: ConfigData = {
  ID: 'FINANCE_STEP',
  SUB_STEP_IDS: ['TAX_STEP'],
  STEP_JOINT_ID: 'FINANCE_STEP_JOINT',
  SUB_STEP_JOINT_IDS: ['TAX_STEP_JOINT'],
  PROGRESS_BAR_COUNT: true,
  SIDEBAR_MENU: true,
  PROGRESS_BAR: true,
  FOOTER: true,
  BUTTON_HELP: true,
  BUTTON_LANG: true,
  BUTTON_QUIT: true,
};

export default config;
