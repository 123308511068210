import React, { useEffect, FunctionComponent, FocusEvent, ChangeEvent, KeyboardEvent } from 'react';
import trimStart from 'lodash/trimStart';
import { FormGroup } from '@nbc-design/form-group';
import { Input, Props as InputProps } from '@nbc-design/input';
import { Text } from '@nbc-design/text';
import { FormComponentProps } from '../types';
import { getLabelText, getErrorMessage, isInvalidOnChange, parseNumber, getIntroductionText } from '../utils';
import Copy from 'components/Copy';
import AnalyticsHelper from 'services/analytics/AnalyticsHelper';

import './style.scss';
import { getTextFactory } from 'utils/TextUtils';

export type ProductFormInputProps = {
  trim?: boolean;
  inline?: boolean;
  type?: string;
  maxLength?: number;
  isCopy?: boolean;
  disabled?: boolean;
  func?: any;
  handleOnBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  handleOnChange?: (event: ChangeEvent<HTMLInputElement>, isValid?: boolean) => void;
  handleOnFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  handleOnKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  ariaDescription?: string;
  ariaLabel?: string;
  tooltip?: React.ReactNode;
  inputRawValue?: string;
  hasError?: boolean;
  inputProps?: Partial<InputProps>;
};

type DrilledProductFormInputProps = FormComponentProps & ProductFormInputProps;

const ProductFormInput: FunctionComponent<DrilledProductFormInputProps> = (props) => {
  const {
    intl,
    placeholder,
    optionalText,
    input,
    schema,
    isReadOnly,
    maxLength,
    customSchema,
    className,
    isCopy,
    disabled,
    handleOnBlur,
    handleOnChange,
    handleOnFocus,
    handleOnKeyDown,
    ariaDescription,
    ariaLabel,
    inputRawValue,
    helpText = null,
    tooltip = null,
    type = 'text',
    trim = false,
    hasError = false,
    meta,
    inputProps: passedInputProps,
  } = props;

  const inputSchema = schema || customSchema;
  const isTypeNumber = inputSchema && (inputSchema.type === 'number' || inputSchema.type === 'integer');
  const invalid = isInvalidOnChange(props) || (meta.submitFailed && hasError) || (meta.touched && !!meta.error);
  const displayedValue = inputRawValue ? inputRawValue : input.value;
  const value = trim ? (displayedValue as string).trim() : trimStart(displayedValue);
  const max = maxLength || (inputSchema && inputSchema.maxLength);
  const onShowTooltip = AnalyticsHelper.handleShowTooltip(input.name);
  const introduction = getIntroductionText(props);

  useEffect(() => {
    const { name, value, onChange: inputOnChange } = input;
    if (max && inputOnChange && value && value.length > max) {
      const val = value.slice(0, max);
      const inputValue = isTypeNumber ? parseNumber(val) : { target: { name, value: val } };
      inputOnChange(inputValue);
    }
  }, [max, input, isTypeNumber]);

  // TODO we probably should stop using ProductFormInput and the likes altogether.
  // this will throw a warning when linting, but it's the only thing short of rewriting
  //  a lot of code that will work.
  useEffect(() => {
    if (handleOnChange) {
      const event = { target: { value } };
      handleOnChange(event as any, !invalid);
    }
  }, [invalid, value]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    input.onChange && input.onChange(isTypeNumber ? parseNumber(event.target.value) : event);
    handleOnChange && handleOnChange(event, !invalid);
  };

  const onBlur = (event: FocusEvent<HTMLInputElement>) => {
    input.onBlur && input.onBlur(isTypeNumber ? parseNumber(event.target.value) : event);
    handleOnBlur && handleOnBlur(event);
  };

  const onFocus = (event: FocusEvent<HTMLInputElement>) => {
    input.onFocus && input.onFocus(event);
    handleOnFocus && handleOnFocus(event);
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    input.onKeyDown && input.onKeyDown(event);
    handleOnKeyDown && handleOnKeyDown(event);
  };

  const label = getLabelText(props);
  const getAccessibilityText = getTextFactory(intl, 'accessibility');

  const inputProps = {
    ...passedInputProps,
    id: `input-${input.name}`,
    type,
    name: input.name,
    placeholder,
    value,
    maxLength: max,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    disabled,
  };

  if (!!ariaDescription) {
    inputProps['aria-describedby'] = `label.${input.name}`;
  }
  if (!!ariaLabel) {
    inputProps['aria-label'] = ariaLabel;
  }

  return (
    <FormGroup
      className={className}
      label={label ? { text: label, htmlFor: `input-${input.name}` } : undefined}
      optionalText={optionalText}
      tooltip={{ content: tooltip, ariaLabel: getAccessibilityText('information'), onShowTooltip }}
      validate={{ hasError: invalid, errorMsg: getErrorMessage(props) }}
      description={helpText ? { text: helpText, id: `help-${input.name}` } : undefined}
      introduction={introduction ? { text: introduction || '', id: `input-${input.name}` } : undefined}
    >
      {isReadOnly || isCopy ? (
        <div className="read-only">
          <Text type={'p'}>{value}</Text>
          {isCopy && <Copy value={value} />}
        </div>
      ) : (
        <Input {...inputProps} />
      )}
      {!!ariaDescription && (
        <label className="d-none" id={`label.${input.name}`}>
          {ariaDescription}
        </label>
      )}
    </FormGroup>
  );
};

export default ProductFormInput;
