import { ConfigData } from 'types/interfaces';

const config: ConfigData = {
  ID: 'ACCOUNT_STEP_ONE',
  SUB_STEP_IDS: ['ACCOUNT_STEP_ONE', 'ACCOUNT_STEP_TWO', 'ACCOUNT_STEP_THREE', 'SPECIFICS_OPTIONS_STEP'],
  SIDEBAR_MENU: true,
  PROGRESS_BAR: true,
  FOOTER: true,
  PROGRESS_BAR_COUNT: true,
  BUTTON_HELP: true,
  BUTTON_LANG: true,
  BUTTON_QUIT: true,
};

export default config;
