import React from 'react';

export default ({ className }) => (
  <svg viewBox="0 0 16 16" className={`error-icon ${className}`}>
    <path
      id="interface_statusError--colored-a"
      d="M8.77 8.063l2.084 2.084a0.5 0.5 0 0 1 -0.707 0.707l-2.084 -2.084 -2.084 2.084a0.5 0.5 0 0 1 -0.707 -0.707l2.084 -2.084 -2.084 -2.084a0.5 0.5 0 0 1 0.707 -0.707l2.084 2.084 2.084 -2.084a0.5 0.5 0 0 1 0.707 0.707l-2.084 2.084zM8 15.5c4.142 0 7.5 -3.358 7.5 -7.5C15.5 3.858 12.142 0.5 8 0.5 3.858 0.5 0.5 3.858 0.5 8c0 4.142 3.358 7.5 7.5 7.5zm0 0.5C3.582 16 0 12.418 0 8 0 3.582 3.582 0 8 0c4.418 0 8 3.582 8 8 0 4.418 -3.582 8 -8 8z"
    />
  </svg>
);
