import { ERRORTYPE } from 'utils/validation';
import { RegulatoryErrorData, RegulatoryFormData } from './types';

export const validation = (values: RegulatoryFormData): RegulatoryErrorData => {
  const { firstParticipant, secondParticipant, hasTraderOnAccount, proxyType } = values;

  const firstParticipantCommentCondition =
    firstParticipant && firstParticipant.hasControlOnPlacements && !firstParticipant.involvComment;
  const secondParticipantCommentCondition =
    secondParticipant && secondParticipant.hasControlOnPlacements && !secondParticipant.involvComment;

  return {
    ...(firstParticipantCommentCondition || secondParticipantCommentCondition
      ? { involvComment: ERRORTYPE.REQUIRED }
      : {}),
    ...(hasTraderOnAccount && !proxyType ? { proxyType: ERRORTYPE.REQUIRED } : {}),
  };
};

export default validation;
