import React, { FunctionComponent, useState, ReactNode, Fragment } from 'react';
import cx from 'classnames';
import { IconButton } from '@nbc-design/button';
import { Heading } from '@nbc-design/heading';
import { Download } from '@nbc-design/icons/lib/web/Download';
import { ExternalLink } from '@nbc-design/icons/lib/web/ExternalLink';
import { Text } from '@nbc-design/text';
import ReactPdfJs from '@mikecousins/react-pdf';
import PdfPagination from './PdfPagination';
import './styles.scss';
import { getTextFactory } from 'utils/TextUtils';
import { InjectedIntl } from 'react-intl';

export type PdfViewerProps = {
  intl: InjectedIntl;
  file: string;
  title?: string;
  description?: string | ReactNode;
  pagination?: boolean;
  scale?: number;
  className?: string;
  dataTest?: string;
};

const PdfViewer: FunctionComponent<PdfViewerProps> = ({
  intl,
  file,
  title,
  description,
  pagination,
  scale = 1,
  className,
  dataTest = 'pdf-viewer',
}) => {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState<number>(1);

  const onDocumentComplete = (numPages: any) => {
    if (numPages && pages !== numPages) setPages(numPages);
  };

  const openDocument = () => {
    window.open(file, '_blank');
  };

  const renderTitle = () => {
    const descriptionMarkup =
      typeof description === 'string' ? <Text className="pdf-description">{description}</Text> : description;
    const getAccessibilityText = getTextFactory(intl, 'accessibility.icon');

    return (
      <Fragment>
        <Heading type="h2" size={5} className="pdf-title">
          {title}
          <span className="pdf-buttons">
            <a href={file} download>
              <IconButton
                icon={<Download title={getAccessibilityText('download')} />}
                size="small"
                appearance="secondary"
              />
            </a>
            <IconButton
              icon={<ExternalLink title={getAccessibilityText('externalLink')} />}
              size="small"
              appearance="secondary"
              onClick={openDocument}
            />
          </span>
        </Heading>
        {descriptionMarkup}
      </Fragment>
    );
  };

  const renderAllPages = () => {
    return (
      !pagination &&
      [...Array(pages)].map((_, i) => (
        <ReactPdfJs
          data-test=""
          key={i + 1}
          file={file}
          scale={scale}
          onDocumentLoadSuccess={onDocumentComplete}
          page={i + 1}
        />
      ))
    );
  };

  const renderPage = () => {
    return (
      pagination && <ReactPdfJs file={file} onDocumentLoadSuccess={onDocumentComplete} page={page} scale={scale} />
    );
  };

  const renderPagination = () => {
    return pagination && pages > 1 && <PdfPagination page={page} pages={pages} setPage={setPage} />;
  };

  const classes = cx(className, 'pdf-viewer');

  return (
    <div className={classes} data-test={dataTest}>
      {renderTitle()}
      {renderAllPages()}
      {renderPage()}
      {renderPagination()}
    </div>
  );
};

export default PdfViewer;
