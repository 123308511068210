import React, { FunctionComponent, ReactNode } from 'react';
import { InjectedIntl } from 'react-intl';
import Title from 'components/Title';
import { getTextFactory } from 'utils/TextUtils';
import { PaymentDetail, PaymentInformationErrorData } from './types';
import { GenericProps, ProductFormFieldChangeHandler } from 'types/interfaces';

import './style.scss';
import { Text } from '@nbc-design/text';
import AccountPaymentDetails from './components/AccountPaymentDetails';
import { Divider } from '@nbc-design/divider';
import { ACCOUNT_TYPE } from 'containers/AccountsStep1/constants';
import { CURRENCIES } from 'utils/constants';
import { Alert } from '@nbc-design/alert';

export type PaymentInformationProps = GenericProps & {
  intl: InjectedIntl;
  payments: PaymentDetail[];
  bankingAccountCurrency?: string[];
  error?: PaymentInformationErrorData;
  change: ProductFormFieldChangeHandler;
};

const PaymentInformation: FunctionComponent<PaymentInformationProps> = (props: PaymentInformationProps) => {
  const { intl, productForm, payments, bankingAccountCurrency, change } = props;

  const getText = getTextFactory(intl, 'paymentInformation');
  const getAccessibilityText = getTextFactory(intl, 'accessibility');

  const RRIFUSDIndex = payments?.findIndex(
    (payment) => payment.accountType.code === ACCOUNT_TYPE.RRIF && payment.currency.code === CURRENCIES.USD,
  );

  const hasRRIFUSD: boolean = RRIFUSDIndex !== -1;

  const shouldDisableEFT = (paymentDetail: PaymentDetail): boolean =>
    (paymentDetail.accountType.code === ACCOUNT_TYPE.RRIF &&
      bankingAccountCurrency &&
      !bankingAccountCurrency.includes(paymentDetail.currency.code)) ||
    false;

  return (
    <div id="PaymentInformation_form">
      <Title label={getText('pageTitle')} subTitle={getText('pageSubtitle')} />

      <Alert
        size={'normal'}
        className="mc-mb-xlarge"
        description={
          <>
            {hasRRIFUSD ? (
              <>
                <ul className="mc-my-0 alert-list-custom" data-test="alert-list">
                  <li>
                    <Text className="alert-list-description-text">{getText('titleInfo.FERR1')}</Text>
                  </li>
                  <li>
                    <Text className="alert-list-description-text">{getText('titleInfo.FERR2')}</Text>
                  </li>
                </ul>
              </>
            ) : (
              <Text data-test="alert-title" className="alert-list-description-text">
                {getText('titleInfo')}
              </Text>
            )}
          </>
        }
        appearance={'information'}
        dataTest={'form-alert-payments'}
        ariaLabelIcon={getAccessibilityText('icon.information')}
        type={'h1'}
        isClosable={false}
      />

      {payments &&
        payments.map(
          (paymentDetail: PaymentDetail, index: number): ReactNode => (
            <div key={`AccountPaymentDetail_${index}`}>
              <AccountPaymentDetails
                index={index}
                intl={intl}
                schema={productForm.schema}
                change={change}
                payments={payments}
                disableEFT={shouldDisableEFT(paymentDetail)}
                paymentDetail={paymentDetail}
              />
              {payments && index + 1 < payments.length && (
                <Divider appearance="dark" size="small" className="account-divider" />
              )}
            </div>
          ),
        )}
    </div>
  );
};

export default PaymentInformation;
