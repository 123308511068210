import React, { FC, ChangeEvent, FocusEvent } from 'react';
import { injectIntl, InjectedIntl } from 'react-intl';
import { Row, Col } from 'react-flexbox-grid';
import get from 'lodash/get';

import { ProductFormFieldChangeHandler } from 'types/interfaces';
import { isCountryUS } from 'utils';
import { createErrorFormatter } from 'utils/productFormUtils';
import { getTextFactory } from 'utils/TextUtils';
import { ProductFormCheckbox, ProductFormInput } from 'components/productFormFields';

import { Residency } from '../types';
import { clearValueIfObfuscated, formatSsnItin } from '../utils';

export type IdentityProps = {
  change: ProductFormFieldChangeHandler;
  intl: InjectedIntl;
  residency: Residency;
  fieldNamePrefix: string;
};

const Identity: FC<IdentityProps> = (props: IdentityProps) => {
  const { residency, fieldNamePrefix, change, intl } = props;

  const getText = getTextFactory(intl, 'tax');
  const country = get(residency, 'country');
  const identificationPresent: boolean = get(residency, 'identificationPresent', false);
  const formatErrorMessage = (prefix?: string, fieldName?: string) => createErrorFormatter(intl, prefix, fieldName);
  const isUs = country && isCountryUS(country);
  const isTinFields = !isUs && identificationPresent;
  const isSinItinFields = isUs && identificationPresent;
  const isIdentificationPresentField = country !== undefined;

  const onSsnItinChange = (event: ChangeEvent<HTMLInputElement>): void => formatSsnItin(change, event);

  const clearObfuscatedValueOnFocus = (event: FocusEvent<HTMLInputElement>): void =>
    clearValueIfObfuscated(change, event);

  return (
    <>
      {isIdentificationPresentField && (
        <Row>
          <Col xs={12} data-test="field_identificationPresent">
            <ProductFormCheckbox
              label={!isUs ? getText('identifyNumberNif') : getText('identifyNumberSsnItin')}
              helpText={identificationPresent ? getText('identificationNotsure') : undefined}
              name={`${fieldNamePrefix}.identificationPresent`}
              formatErrorMessage={formatErrorMessage()}
            />
          </Col>
        </Row>
      )}

      {isTinFields && (
        <Row>
          <Col xs={12} data-test="field_tin">
            <ProductFormInput
              label={getText('tin')}
              name={`${fieldNamePrefix}.tin`}
              formatErrorMessage={formatErrorMessage('tax', 'tin')}
              onFocus={clearObfuscatedValueOnFocus}
              maxLength={30}
            />
          </Col>
        </Row>
      )}

      {isSinItinFields && (
        <Row>
          <Col xs={12} md={6} data-test="field_ssn">
            <ProductFormInput
              label={getText('ssn')}
              name={`${fieldNamePrefix}.ssn`}
              onChange={onSsnItinChange}
              onFocus={clearObfuscatedValueOnFocus}
              formatErrorMessage={formatErrorMessage('tax', 'ssn')}
            />
          </Col>
          <Col xs={12} md={6} data-test="field_itin">
            <ProductFormInput
              label={getText('itin')}
              name={`${fieldNamePrefix}.itin`}
              onChange={onSsnItinChange}
              onFocus={clearObfuscatedValueOnFocus}
              formatErrorMessage={formatErrorMessage('tax', 'itin')}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default injectIntl(Identity);
