import React, { FC, useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { PUBLIC_PATH } from 'utils/constants';
import { BFF_STEPS } from 'utils/stepsConstants';
import { GenericProps } from 'types/interfaces';
import { saveItem, deleteItem } from 'utils';
import AppsRoutes from './AppsRoutes';
import { BncProductFormProps } from '../../types/bao-react-forms.type';

export type AppProps = GenericProps & BncProductFormProps;

const App: FC<AppProps> = (props) => {
  const {
    currentStep,
    meta: { requestCode },
  } = props;

  useMemo(() => {
    if (BFF_STEPS.findIndex((step) => step.ID === currentStep) > -1) {
      saveItem('requestCode', requestCode);
    } else {
      deleteItem('requestCode');
    }
  }, [currentStep, requestCode]);

  return (
    <BrowserRouter basename={PUBLIC_PATH}>
      <AppsRoutes {...props} />
    </BrowserRouter>
  );
};

export default App;
