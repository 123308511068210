import { arrayBufferToString, stringToArrayBuffer } from 'pvutils';
import { fromBER } from 'asn1js';
import { Certificate, CertificateSet, ContentInfo, EnvelopedData, OriginatorInfo } from 'pkijs';

import configs from 'configs';

export const getCertificate = async (): Promise<string> => {
  return fetch(configs.params.IAMX_CERTIFICATE_FILE_NAME)
    .then((res) => res.text())
    .then((data) => data.replace(/-{5}.*-{5}|\n/g, ''));
};

const createEnvelope = (certificateBuffer: ArrayBuffer): EnvelopedData => {
  const asn1 = fromBER(certificateBuffer);
  const certificate = new Certificate({ schema: asn1.result });

  const cmsEnvelope = new EnvelopedData({
    originatorInfo: new OriginatorInfo({
      certs: new CertificateSet({
        certificates: [certificate],
      }),
    }),
  });

  cmsEnvelope.addRecipientByCertificate(certificate, { oaepHashAlgorithm: 'SHA-256' });
  return cmsEnvelope;
};

export const cmsEncryptValue = async (certificate: string, value: string): Promise<string | undefined> => {
  if (!certificate || !value) return undefined;

  const certificateBuffer = stringToArrayBuffer(atob(certificate));
  const valueBuffer = stringToArrayBuffer(value);

  const cmsEnvelope = createEnvelope(certificateBuffer);
  const encAlgo = { name: 'AES-CBC', length: 128 };

  return cmsEnvelope.encrypt(encAlgo, valueBuffer).then(() => {
    const contentInfo = new ContentInfo();

    contentInfo.contentType = '1.2.840.113549.1.7.3';
    contentInfo.content = cmsEnvelope.toSchema();

    const cmsEnvelopedBuffer = contentInfo.toSchema().toBER(false);
    return btoa(arrayBufferToString(cmsEnvelopedBuffer));
  });
};
