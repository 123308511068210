import { InjectedIntl } from 'react-intl';
import { Nullable } from 'types/interfaces';

export const formatId = (prefix: string, ...suffixes: (string | number)[]): string => `${prefix}.${suffixes.join('.')}`;

export type getTextFactoryReturnType = (suffix: string) => string;
export type getTextListFactoryReturnType = (suffix: string) => string[];

export const getTextFactory = (intl: Nullable<InjectedIntl>, prefix: string): getTextFactoryReturnType => {
  if (!intl) {
    throw new Error('intl cannot be null or undefined');
  }

  return (suffix) =>
    intl.formatMessage({
      id: formatId(prefix, suffix),
    });
};

/**
 * Returns a factory function that returns an array of strings composed from the prefix, suffix and one-based index,
 *  e.g. ['prefix.suffix.1', 'prefix.suffix.2']
 * @param intl - react-intl props. Throws if not thruty.
 * @param prefix - a prefix to add to every message's id
 */
export const getTextListFactory = (intl: Nullable<InjectedIntl>, prefix: string): getTextListFactoryReturnType => {
  if (!intl) {
    throw new Error('intl cannot be null or undefined');
  }

  return (suffix) => {
    let text = '';
    const texts: string[] = [];

    for (let i = 1; true; i++) {
      const id = formatId(prefix, suffix, i);
      text = intl.formatMessage({ id });
      // the value does not exists, so the id is returned
      if (id !== text) {
        texts.push(text);
      } else {
        break;
      }
    }

    return texts;
  };
};
