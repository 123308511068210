import React, { FunctionComponent } from 'react';
import { Col } from 'react-flexbox-grid';
import { Icon, IconName } from '@nbc-design/icons';
import Heading from 'components/Heading';

import '../styles.scss';

export type StepProps = {
  icon: IconName;
  label: string;
  description: string;
  dataTest?: string;
};

const Step: FunctionComponent<StepProps> = ({ icon, label, description, dataTest = '' }) => {
  return (
    <Col xs={12} md={4} lg={4} className="step-wrapper">
      <div className="infostep" data-test={`${dataTest}_step`}>
        <Icon name={icon} size={{ width: 64, height: 64 }} />

        <Heading type="h3" size={5} dataTest="label">
          {label}
        </Heading>

        <p data-test="description">{description}</p>
      </div>
    </Col>
  );
};

export default Step;
