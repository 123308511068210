export default {
  ID: 'THANK_YOU_ESIGN_STEP',
  BG_WHITE: true,
  LINKS: {
    FR: {
      NEWSLETTER: 'https://www.contacts.bnc.ca/infolettrebncd/abonnement',
      WEBSITE: 'https://bncd.ca/',
    },
    EN: {
      NEWSLETTER: 'https://www.contacts.nbc.ca/newsletternbdb/subscription',
      WEBSITE: 'https://nbdb.ca/',
    },
  },
};
