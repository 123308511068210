import { addressValidation, requiredValidation } from 'utils/validation';
import { AccountDataType, IdentityVerificationErrorData, IdentityVerificationFormData } from './types';

export const bankingAccountValidation = ({
  bankingAccount,
  indvIdentMethod = '',
}: IdentityVerificationFormData): IdentityVerificationErrorData | null => {
  if (indvIdentMethod !== 'BANKREF') {
    return null;
  }

  const fields = ['institutionName', 'branchAddress', 'branchNo', 'institutionNo', 'accountNo', 'currency'];
  const { branchAddress }: AccountDataType = (bankingAccount as AccountDataType) || {};

  const addressErrors = addressValidation(branchAddress);
  const err = requiredValidation(bankingAccount, fields);

  const errors = {
    ...err,
    ...(addressErrors ? { branchAddress: addressErrors } : {}),
  };
  return Object.keys(errors).length ? { bankingAccount: errors } : null;
};

const validation = (values: IdentityVerificationFormData): IdentityVerificationErrorData => {
  return {
    ...bankingAccountValidation(values),
  };
};

export default validation;
