import { getDocument } from 'services/documentManager';
import { DocumentItem } from './types';
import configs from 'configs';

export const getDocumentsUrls = (documents: DocumentItem[], requestCode: string, locale: string): Promise<string[]> => {
  return Promise.all(
    documents.map(getDocUrl(requestCode, locale)).concat(getMergedDocUrl(requestCode, locale)).map(getFile),
  );
};

const getFile = (url: string) => getDocument(url).then(window.URL.createObjectURL);

const getLocalizedString = (uri: { fr: string; en: string } | string, locale: string): string =>
  typeof uri === 'string' ? uri : uri[locale];

const getDocUrl = (requestCode: string, locale: string) => (doc: DocumentItem) => {
  const { BASE_URL, ENDPOINTS } = configs.params.DOCUMENTS_GENERATOR_SERVER;
  const documentId = getLocalizedString(doc.documentId, locale);
  const code = getLocalizedString(doc.code, locale);
  return `${BASE_URL}${ENDPOINTS.DOCUMENT}`
    .replace(/\{requestCode\}/g, requestCode)
    .replace(/\{locale\}/g, locale)
    .replace(/\{documentId\}/g, documentId)
    .replace(/\{code\}/g, code);
};

const getMergedDocUrl = (requestCode: string, locale: string) => {
  const { BASE_URL, ENDPOINTS } = configs.params.DOCUMENTS_GENERATOR_SERVER;
  return `${BASE_URL}${ENDPOINTS.DOCUMENT_MERGED}`.replace('{requestCode}', requestCode).replace('{locale}', locale);
};
