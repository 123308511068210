import React, { FC } from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { RefData, ProductFormFieldChangeHandler } from 'types/interfaces';
import { getTextFactory } from 'utils/TextUtils';
import { Col, Row } from 'react-flexbox-grid';
import ProductFormPhoneInputGroup from 'components/productFormFields/ProductFormPhoneInputGroup';
import { ProductFormInput } from 'components/productFormFields';
import { sortPhoneAreaCodesByCountryName, createErrorFormatter } from 'utils';
import get from 'lodash/get';
import { RootState } from 'types/reducers';
import { getState } from 'globalRedux/store';
import './styles.scss';

export type EmployerPhoneProps = {
  change: ProductFormFieldChangeHandler;
  intl: InjectedIntl;
  refData: RefData[];
  name: string;
};

const EmployerPhone: FC<EmployerPhoneProps> = ({ intl, change, refData, name }) => {
  const getTextEmployment = getTextFactory(intl, 'employmentV2');

  const countryName = `${name}.country`;

  const state: RootState | null = getState();
  const countryValue = get(state, `reduxFormReducer.baoForms.values.${countryName}`, '');

  const sortedCountry = sortPhoneAreaCodesByCountryName(refData);

  const formatErrorMessage = (prefix?: string, suffix?: string) => {
    return createErrorFormatter(intl, prefix, suffix);
  };

  return (
    <>
      <Row className="phone-country-input">
        <Col xs={12} md={6} lg={6}>
          <ProductFormPhoneInputGroup
            prefixName={countryName}
            prefixValue={countryValue}
            name={`${name}.number`}
            refData={sortedCountry}
            formatErrorMessage={formatErrorMessage('employmentV2', 'error')}
            optionalText={getTextEmployment('optional')}
            change={change}
            label={getTextEmployment('employerPhone')}
          />
        </Col>
        <Col xs={12} md={3} lg={3}>
          <ProductFormInput
            className="extensionNumberInput"
            name={`${name}.extensionNumber`}
            label={getTextEmployment('employerPhoneExtension')}
            formatErrorMessage={formatErrorMessage('employmentV2', 'error')}
            optionalText={getTextEmployment('ifApplicable')}
          />
        </Col>
      </Row>
    </>
  );
};

export default injectIntl(EmployerPhone);
