import React, { FunctionComponent, MouseEvent } from 'react';
import { Button } from '@nbc-design/button';
import { InputType } from 'components/productFormFields/types';
import { Delete } from '@nbc-design/icons/lib/web/Delete';

export type CancelButtonProps = {
  input: InputType;
  label: string;
};

const CancelButton: FunctionComponent<CancelButtonProps> = (props: CancelButtonProps) => {
  const { input, label } = props;

  const handleCancelClick = (event: MouseEvent<HTMLButtonElement>, input: InputType): void => {
    event.preventDefault();
    input.onChange && input.onChange(input.value.original || {});
  };

  return (
    <Button
      icon={<Delete title="delete" />}
      type="button"
      className="manual-address-form__cancel-link"
      data-test="button_cancelLink"
      size="small"
      onClick={(e) => handleCancelClick(e, input)}
    >
      {label}
    </Button>
  );
};

export default CancelButton;
