import React from 'react';
import { SuccessFilledXsmallColor } from '@nbc-design/icons/lib/web/SuccessFilledXsmallColor';
import { ErrorFieldFilledColor } from '@nbc-design/icons/lib/web/ErrorFieldFilledColor';
import { useContainerCtx } from 'providers/containerCtx/ContainerCtx';
import { BeneficiaryFormValues } from '../types';

type BeneficiaryStatusProps = {
  isCompleted: boolean;
};

const BeneficiaryStatus: React.FC<BeneficiaryStatusProps> = ({ isCompleted }) => {
  const { getAccessibilityText } = useContainerCtx<BeneficiaryFormValues>();
  if (isCompleted) {
    return (
      <div data-test={'status-success'}>
        <SuccessFilledXsmallColor
          aria-label={getAccessibilityText('icon.beneficiary.success')}
          size={'large'}
          title="success-filled-xsmall-color"
        />
      </div>
    );
  }

  return (
    <div data-test={'status-failure'}>
      <ErrorFieldFilledColor
        aria-label={getAccessibilityText('icon.beneficiary.failure')}
        size={'large'}
        title="failure-filled-xsmall-color"
      />
    </div>
  );
};

export default BeneficiaryStatus;
