import React, { FC } from 'react';
import { InjectedIntl } from 'react-intl';
import { Col, Row } from 'react-flexbox-grid';

import { Edit } from '@nbc-design/icons/lib/web/Edit';
import { Button } from '@nbc-design/button';

import { getTextFactory } from 'utils/TextUtils';
import { formatAddress } from 'components/productFormFields/ProductFormAddressSelect/utils';
import { Address } from 'components/productFormFields/ProductFormAddressSelect/types';

export type ReadOnlyAddressesProps = {
  addresses: Address[];
  intl: InjectedIntl;
  isCurrentAddress?: boolean;
  onModifyAddress?: () => void;
};

const ReadOnlyAddresses: FC<ReadOnlyAddressesProps> = ({
  addresses = [],
  intl,
  isCurrentAddress = false,
  onModifyAddress,
}) => {
  const getGlobalText = getTextFactory(intl, 'global');
  const getAddressText = getTextFactory(intl, 'address');

  return (
    <Row className={!isCurrentAddress ? 'mc-mb-small' : 'mc-my-small'}>
      <Col xs={12} md={4} className="label-info">
        {getGlobalText(isCurrentAddress ? 'currentAddress' : 'futureAddress')}
      </Col>

      <Col xs={12} md={8}>
        <Row>
          <Col sm={9}>
            {addresses.map((address, i) => (
              <div key={i} className="label-value" data-test="readonlyAddress">
                {formatAddress(address, false, getAddressText('appartmentLabel'))}
              </div>
            ))}
          </Col>

          <Col sm={3} className="modify-area">
            {isCurrentAddress && (
              <Button
                appearance="minimal"
                size="small"
                icon={<Edit title={getGlobalText('button.edit')} />}
                onClick={onModifyAddress}
              >
                {getGlobalText('button.edit')}
              </Button>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ReadOnlyAddresses;
