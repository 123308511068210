import React from 'react';
import { Tooltip, TooltipProps } from '@nbc-design/tooltip';

import { sendHelpTextAnalyticsEvent } from 'services/analytics';

export type BaoTooltipProps = TooltipProps & {
  name: string;
  stepId: string;
};

/**
 * Helper component that logs a "HELP_DISPLAYED" event to the analytics when the Tooltip is opened.
 * The function will only send a single event per tooltip, per page.
 */
class BaoTooltip extends React.Component<BaoTooltipProps> {
  static displayName = 'BaoTooltip';

  analyticsCalled = false;
  lastStepId?: string;

  constructor(props) {
    super(props);
    this.getTooltipDisplayTracker = this.getTooltipDisplayTracker.bind(this);
    this.lastStepId = props.stepId;
  }

  componentDidMount() {
    this.lastStepId = this.props.stepId;
    this.analyticsCalled = false;
  }

  componentDidUpdate() {
    if (this.props.stepId !== this.lastStepId) {
      this.lastStepId = this.props.stepId;
      this.analyticsCalled = false;
    }
  }

  getTooltipDisplayTracker() {
    return () => {
      if (this.analyticsCalled) {
        return;
      }
      this.analyticsCalled = true;
      sendHelpTextAnalyticsEvent(this.props.name);
    };
  }

  render() {
    const { name, stepId, children, ...tooltipProps } = this.props;

    return (
      <Tooltip
        data-test={`tooltip_${name}`}
        id={name}
        onShowTooltip={this.getTooltipDisplayTracker()}
        {...tooltipProps}
      >
        {children}
      </Tooltip>
    );
  }
}

export default BaoTooltip;
