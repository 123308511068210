import React, { FunctionComponent } from 'react';
import { AccountsStep3FormData, LegislationData, OptionsTypeData } from '../types';
import { Col, Row } from 'react-flexbox-grid';
import { RefData } from 'types/interfaces';
import { ProductFormSelect } from 'components/productFormFields';
import { AccountCard } from './_layout/AccountCard';
import { useContainerCtx } from '../../../providers/containerCtx/ContainerCtx';

// ***********************************************************************
// Ts Type definitions
// ***********************************************************************
export type LegislationSelectorProps = {
  index: number;
  legislation: LegislationData;
  provinceRefData: RefData[];
};

// ***********************************************************************
// Component
// ***********************************************************************
const LegislationSelector: FunctionComponent<LegislationSelectorProps> = ({ index, legislation, provinceRefData }) => {
  const { locale, getText, formatErrorMessage } = useContainerCtx<AccountsStep3FormData>();
  const { accountType, values, province, currency, eligibleProvinces } = legislation;

  const hasLegislationSelected = !!province;
  const filterProvince = ({ value }: OptionsTypeData): boolean => eligibleProvinces?.indexOf(value) !== -1;

  if (!accountType || !values || !eligibleProvinces || eligibleProvinces.length <= 0) return null;
  return (
    <AccountCard isSelected={hasLegislationSelected} account={{ currency, accountType }}>
      <Row>
        <Col xs={12} md={8} lg={6}>
          <div data-test={`fields_${accountType.code}_${currency[locale]}`}>
            <ProductFormSelect
              name={`legislations[${index}].province`}
              formatErrorMessage={formatErrorMessage()}
              refData={provinceRefData}
              label={getText('jurisdictionLabel')}
              placeholder={getText('jurisdictionPlaceholder')}
              ariaLabel={getText('jurisdictionLabel')}
              className="province"
              filter={filterProvince}
              sort={true}
            />
          </div>
        </Col>
      </Row>
    </AccountCard>
  );
};

export default LegislationSelector;
