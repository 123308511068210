import { Alert } from '@nbc-design/alert';
import { LANG } from '@nbc-design/currency-input';
import { Link } from '@nbc-design/link';
import Heading from 'components/Heading';
import ProductFormRadioBoolean from 'components/productFormFields/ProductFormRadioBoolean';
import get from 'lodash/get';
import { useContainerCtx } from 'providers/containerCtx/ContainerCtx';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';

const CanadianChildrenBenefits = (): JSX.Element | null => {
  const { formValues, locale, getText, getTextGlobal, getAccessibilityText, formatErrorMessage } = useContainerCtx();
  const displayCCB = get(formValues, 'displayCCB', false);
  if (!displayCCB) {
    return null;
  } else {
    return (
      <>
        {displayCCB && (
          <>
            <Heading type="h2" data-test="heading_canChildrenBenInd" size={4}>
              {getText('canadianChildrenBenefitTitle')}
            </Heading>

            <Row>
              <Col xs={12} data-test="field_canChildrenBenInd">
                <ProductFormRadioBoolean
                  name="canChildrenBenInd"
                  label={getText('canadianChildrenBenefit')}
                  yesLabel={getTextGlobal('button.yes')}
                  noLabel={getTextGlobal('button.no')}
                  formatErrorMessage={formatErrorMessage()}
                  inline
                />
              </Col>

              <Col xs={12} data-test="field_canadianChildrenBenefitAdmissibility" className="label">
                <Alert
                  description={getText('canadianChildrenBenefitAdmissibility')}
                  type={'p'}
                  appearance="information"
                  ariaLabelIcon={getAccessibilityText('icon.information')}
                  link={
                    <Link
                      href={
                        locale.includes(LANG.EN)
                          ? 'https://www.canada.ca/en/services/benefits/education/education-savings/estimating-amounts.html'
                          : 'https://www.canada.ca/fr/services/prestations/education/epargne-etudes/estimation-montants.html'
                      }
                      target="_blank"
                      underlined={true}
                    >
                      {getText('canadianChildrenBenefitAdmissibilityLink')}
                    </Link>
                  }
                  isClosable={false}
                />
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
};

export default CanadianChildrenBenefits;
