import get from 'lodash/get';

import { BAOStore, RootState } from 'types/reducers';

let store: BAOStore | null = null;

export const getStore = (): BAOStore | null => store;

export const setStore = (value: BAOStore): void => {
  store = value;
};

export const getState = (): RootState | null => {
  store = getStore();
  return store ? store.getState() : null;
};

/**
 * Find a value in the store using lodash `get`function.
 * @param path - any valid value for parameter `path` of lodash `get` function
 * @param defaultValue - any valid value for parameter `defaultValue` of lodash `get` function
 * @returns `defaultValue` if the store is not ready or the value is not found or undefined; the value otherwise.
 */
export const fromState = <T, U>(path: Parameters<typeof get>[1], defaultValue: Parameters<typeof get>[2]): T | U => {
  if (!store) {
    return defaultValue;
  }
  const state = store.getState();

  return get(state, path, defaultValue);
};

export const getFormFieldValue = (formName: string, fieldName: string, defaultValue) => {
  return get(getState(), `reduxFormReducer.${formName}.values.${fieldName}`, defaultValue);
};
