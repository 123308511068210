import React, { ChangeEvent, FunctionComponent } from 'react';
import { FormComponentProps } from '../types';
import { Checkbox } from '@nbc-design/checkbox';
import { FormGroup } from '@nbc-design/form-group';
import { Check } from '@nbc-design/icons/lib/web/Check';
import { Text } from '@nbc-design/text';
import { getErrorMessage, getLabelText, isInvalidOnChange } from '../utils';
import { getTextFactory } from 'utils/TextUtils';
import { InjectedIntl } from 'react-intl';
import AnalyticsHelper from 'services/analytics/AnalyticsHelper';

import './style.scss';

export type ProductFormCheckboxProps = FormComponentProps & {
  intl: InjectedIntl;
  disabled: boolean;
  labelHelp: string;
  tooltip?: React.ReactNode;
};

const ProductFormCheckbox: FunctionComponent<ProductFormCheckboxProps> = (props: ProductFormCheckboxProps) => {
  const { input, tooltip, helpText, isReadOnly, intl, disabled, className, labelHelp } = props;

  const invalid = isInvalidOnChange(props);
  const label = getLabelText(props) || '';
  const getAccessibilityText = getTextFactory(intl, 'accessibility');
  const onShowTooltip = AnalyticsHelper.handleShowTooltip(input.name);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (input.onChange) {
      input.onChange(event);
    }
  };

  return (
    <FormGroup
      className={className}
      tooltip={{ content: tooltip, ariaLabel: getAccessibilityText('information'), onShowTooltip }}
      validate={{ hasError: invalid, errorMsg: getErrorMessage(props) || '' }}
      description={{ text: helpText, id: `help-${input.name}` }}
    >
      {isReadOnly ? (
        <Text type={'p'}>
          <Check title="check" />
          {label}
        </Text>
      ) : (
        <Checkbox
          id={`input-${input.name}`}
          label={label}
          name={input.name}
          checked={input.value}
          disabled={disabled}
          onChange={handleChange}
          text={labelHelp}
        />
      )}
    </FormGroup>
  );
};

export default ProductFormCheckbox;
