import {
  StockSymbolServiceListRequestedAction,
  StockSymbolServiceListRequestFailedAction,
  StockSymbolServiceListRequestSucceededAction,
  StockSymbolServiceResetAction,
} from 'types/actions';
import StockSymbolKeys from 'services/StockSymbolService/actionTypes';
import { StockSymbol, InvolvementOptions, SymbolError } from 'types/reducers';

export const stockSymbolServiceListRequested = (
  search: string = '',
  options: InvolvementOptions = {} as InvolvementOptions,
): StockSymbolServiceListRequestedAction => {
  return {
    type: StockSymbolKeys.STOCK_SYMBOL_SERVICE_LIST_REQUESTED,
    search,
    options,
  };
};

export const stockSymbolServiceListRequestSucceeded = (
  stockSymbolList: StockSymbol[],
  options: InvolvementOptions = {} as InvolvementOptions,
): StockSymbolServiceListRequestSucceededAction => {
  return {
    type: StockSymbolKeys.STOCK_SYMBOL_LIST_REQUEST_SUCCEEDED,
    stockSymbolList,
    options,
  };
};

export const stockSymbolServiceListRequestFailed = (
  err: SymbolError,
  options: InvolvementOptions = {} as InvolvementOptions,
): StockSymbolServiceListRequestFailedAction => {
  return {
    type: StockSymbolKeys.STOCK_SYMBOL_SERVICE_LIST_REQUEST_FAILED,
    err,
    options,
  };
};

export const stockSymbolServiceReset = (
  options: InvolvementOptions = {} as InvolvementOptions,
): StockSymbolServiceResetAction => {
  return {
    type: StockSymbolKeys.STOCK_SYMBOL_SERVICE_LIST_RESET,
    options,
  };
};
