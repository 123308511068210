import React, { useMemo } from 'react';
import Heading from 'components/Heading';
import { Col, Row } from 'react-flexbox-grid';
import { Message } from '@nbc-design/message';
import { useContainerCtx } from '../../../../providers/containerCtx/ContainerCtx';
import { TransferAccountsError, TransferFormData } from '../../types';
import { ACCOUNT_SOURCE } from '../../constants';
import TransferAccountsFiltered from './TransferAccountsFiltered';

type AccountSubSectionContainerProps = {
  headerText: string;
  filteredSource: ACCOUNT_SOURCE;
  errorKey: keyof TransferAccountsError;
  MapAccountItem?: React.ComponentProps<typeof TransferAccountsFiltered>['ChildrenMapAccountItem'];
};

const AccountSubContentContainer: React.FC<AccountSubSectionContainerProps> = ({
  errorKey,
  filteredSource,
  headerText,
  MapAccountItem,
}) => {
  const {
    bncReactForm: { submitFailed },
    genericProps: { error },
    formatErrorMessage,
  } = useContainerCtx<TransferFormData>();

  const errorPrefix = useMemo(() => {
    if (errorKey === 'transferFinancialError') return 'transfer';
    if (errorKey === 'transferEmployerError') return 'transferEmployer';
    if (errorKey === 'transferInternalError') return 'transferInternal';
  }, [errorKey]);

  return (
    <div>
      <Heading type="h5" size={6} divider={false} className={'mc-mb-large'}>
        {headerText}
      </Heading>

      <TransferAccountsFiltered sourceCd={filteredSource} ChildrenMapAccountItem={MapAccountItem} />

      {submitFailed && !!error?.[errorKey] && (
        <div data-test={'error-container'}>
          <Row className="error">
            <Col xs={6} md={12} lg={12}>
              <Message appearance="error">{formatErrorMessage(errorPrefix)(error)}</Message>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default AccountSubContentContainer;
