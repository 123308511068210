import React, { PureComponent } from 'react';
import { getBankingInformationSlice } from '../selectors';
import { BankingInformationSlice } from '../types';
import { InjectedIntl, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getTextFactory } from 'utils/TextUtils';
import SummarySection from './SummarySection';
import { BFF_STEPS_IDS } from 'utils/stepsConstants';
import { Col, Row } from 'react-flexbox-grid';
import { ProductFormValue } from 'components/productFormFields';

export type BankingInformationSectionPropsFromState = {
  bankingInformationSlice: BankingInformationSlice;
};

export type BankingInformationSectionProps = {
  goToStep: (stepId: string) => string;
  intl: InjectedIntl;
  isJointStep: boolean;
};

export class BankingInformationSection extends PureComponent<
  BankingInformationSectionProps & BankingInformationSectionPropsFromState
> {
  getGlobalText = getTextFactory(this.props.intl, 'global');
  getBankingInformationText = getTextFactory(this.props.intl, 'specificsBankingInfo');
  getSummaryText = getTextFactory(this.props.intl, 'summary.bankingInformation');

  renderParticipationAnswerRow = (): JSX.Element => {
    return (
      <Row className={`section__content participation-answer-row`}>
        <Col xs={12} md={12} lg={12} className="field" data-test={``}>
          <ProductFormValue
            name="bankingInformation.programParticipation"
            label={this.getBankingInformationText('programParticipation')}
            className="summary-form-value-section"
          />
        </Col>
      </Row>
    );
  };

  renderBankInfo = (index: number) => {
    return (
      <Row className={`section__content bank-account-info-row`} key={index}>
        <Col xs={12} md={12} lg={12}>
          <p>{this.getBankingInformationText('pageTitle')}</p>
        </Col>
        <Col xs={12} md={6} lg={4} className="field" data-test={`field_${index}_branchNo`}>
          <ProductFormValue
            name={`bankingInformation.bankingAccounts[${index}].branchNo`}
            label={this.getBankingInformationText('branchNo')}
          />
        </Col>
        <Col xs={12} md={6} lg={4} className="field" data-test={`field_${index}_institutionNo`}>
          <ProductFormValue
            name={`bankingInformation.bankingAccounts[${index}].institutionNo`}
            label={this.getBankingInformationText('institutionNo')}
          />
        </Col>
        <Col xs={12} md={6} lg={4} className="field" data-test={`field_${index}_accountNo`}>
          <ProductFormValue
            name={`bankingInformation.bankingAccounts[${index}].accountNo`}
            label={this.getBankingInformationText('accountNo')}
          />
        </Col>
        <Col xs={12} md={6} lg={4} className="field" data-test={`field_${index}_currency`}>
          <ProductFormValue
            name={`bankingInformation.bankingAccounts[${index}].currency`}
            label={this.getBankingInformationText('currency')}
          />
        </Col>
        <Col xs={12} md={6} lg={4} className="field" data-test={`field_${index}_institutionName`}>
          <ProductFormValue
            name={`bankingInformation.bankingAccounts[${index}].institutionName`}
            label={this.getBankingInformationText('institutionName')}
          />
        </Col>
      </Row>
    );
  };

  renderContent = (): JSX.Element | null => {
    const { bankingInformationSlice } = this.props;

    const { bankingAccounts } = bankingInformationSlice;

    return (
      <React.Fragment>
        {bankingAccounts &&
          Array.isArray(bankingAccounts) &&
          bankingAccounts.map((account, index) => this.renderBankInfo(index))}
        {this.renderParticipationAnswerRow()}
      </React.Fragment>
    );
  };

  render() {
    const { goToStep, intl, bankingInformationSlice, isJointStep } = this.props;

    if (!bankingInformationSlice) {
      return null;
    }

    return (
      <SummarySection
        intl={intl}
        name={'bankingInformation'}
        title={this.getSummaryText('pageTitle')}
        stepId={!isJointStep ? BFF_STEPS_IDS.SPECIFICSBANKINGINFO : BFF_STEPS_IDS.SPECIFICSBANKINGINFOJOINT}
        goToStep={goToStep}
        renderSectionContent={this.renderContent}
        editable={bankingInformationSlice.editable}
      />
    );
  }
}

const mapStateToProps = (state: BankingInformationSlice) => {
  return {
    bankingInformationSlice: getBankingInformationSlice(state),
  };
};

export default connect<BankingInformationSectionPropsFromState, {}, BankingInformationSection>(mapStateToProps)(
  injectIntl(BankingInformationSection),
);
