import React, { FunctionComponent, ReactNode } from 'react';
import { AccountsStep3FormData, UtilisationData } from '../types';
import { Row, Col } from 'react-flexbox-grid';
import { RefData } from 'types/interfaces';
import { UTILISATION_OTHER_CODE } from '../constants';
import { ProductFormSelect, ProductFormInput } from 'components/productFormFields';
import { ACCOUNT_TYPE } from '../../AccountsStep1/constants';
import { AccountCard } from './_layout/AccountCard';
import { useContainerCtx } from '../../../providers/containerCtx/ContainerCtx';

// ***********************************************************************
// Ts Type definitions
// ***********************************************************************
export type UtilisationSelectorProps = {
  index: number;
  label: ReactNode;
  utilisation: UtilisationData;
  utilisationsRefData: RefData[];
  utilisationCommentMaxLength: string;
};

// ***********************************************************************
// Component
// ***********************************************************************
const UtilisationSelector: FunctionComponent<UtilisationSelectorProps> = ({
  index,
  utilisation,
  utilisationsRefData,
  utilisationCommentMaxLength,
}) => {
  const { locale, getText, formatErrorMessage } = useContainerCtx<AccountsStep3FormData>();

  const { accountType, currency, usageCode, usageOtherComment } = utilisation;
  const isSelected = !!usageCode && (usageCode === UTILISATION_OTHER_CODE ? !!usageOtherComment : true);

  if (!accountType || accountType.code === ACCOUNT_TYPE.FHSA) return null;
  return (
    <AccountCard isSelected={isSelected} account={{ currency, accountType }}>
      <Row>
        <Col xs={12} md={8} lg={6}>
          <div data-test={`fields_${accountType.code}_${currency[locale]}`}>
            <ProductFormSelect
              name={`utilisations[${index}].usageCode`}
              formatErrorMessage={formatErrorMessage()}
              refData={utilisationsRefData}
              label={getText('utilisationLabel')}
              placeholder={getText('utilisationPlaceholder')}
              ariaLabel={getText('utilisationLabel')}
            />
          </div>
        </Col>
      </Row>

      {usageCode === UTILISATION_OTHER_CODE && (
        <Row>
          <Col xs={12} mdOffset={4} md={8} lgOffset={6} lg={6}>
            <div data-test={`fields_${accountType.code}_${currency[locale]}`}>
              <ProductFormInput
                name={`utilisations[${index}].usageOtherComment`}
                formatErrorMessage={formatErrorMessage()}
                placeholder={getText('specify')}
                {...(utilisationCommentMaxLength ? { maxLength: parseInt(utilisationCommentMaxLength) } : {})}
                ariaLabel={getText('specify')}
              />
            </div>
          </Col>
        </Row>
      )}
    </AccountCard>
  );
};

export default UtilisationSelector;
