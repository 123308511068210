import { Props as FromGroupProps } from '@nbc-design/form-group';

import { fromState } from 'globalRedux/store';
import { sendHelpTextAnalyticsEvent } from '.';

type ShowTooltipHandler = Required<FromGroupProps>['tooltip']['onShowTooltip'];

export default class AnalyticsHelper {
  private static instance: AnalyticsHelper | null = null;
  private lastStep: string | null = null;
  private currentStepSentFields: string[] = [];

  private static getInstance(): AnalyticsHelper {
    if (!this.instance) {
      this.instance = new AnalyticsHelper();
    }
    return this.instance;
  }

  private get currentStep(): string | null {
    return fromState<string, null>('productFormReducer.baoForms.currentStep', null);
  }

  private showHelpTextHandler(name: string): ShowTooltipHandler {
    return () => {
      if (this.lastStep !== this.currentStep) {
        this.lastStep = this.currentStep;
        this.currentStepSentFields = [];
      }

      if (!this.currentStepSentFields.includes(name)) {
        this.currentStepSentFields.push(name);
        sendHelpTextAnalyticsEvent(name);
      }
    };
  }

  /**
   * Get an event handler that sends analytics data signaling that the user displayed some help text (tooltip, etc...),
   * ensuring that it is sent only once per page.
   * @param name - name of the field described with the tooltip
   */
  static handleShowTooltip(name: string): ShowTooltipHandler {
    const instance = AnalyticsHelper.getInstance();
    return instance.showHelpTextHandler.bind(instance)(name);
  }
}
