import { Channel } from 'redux-saga';
import { BFF_STEPS } from 'utils/stepsConstants';
import { AppStep, ErrorData, Validation } from 'types/interfaces';
import { BaoActionTypes } from 'types/actions';

export type FormBaoServiceData = {
  validate: (id?: string, defaultValidation?: (obj: {}) => { _error: ErrorData }) => void;
  onChangeStep: () => Channel<BaoActionTypes>;
  onError: () => Channel<BaoActionTypes>;
};

const validation = (formService: FormBaoServiceData): void => {
  BFF_STEPS.filter((step: AppStep): Validation | undefined => step.validation).forEach((step: AppStep): void => {
    formService.validate(step.ID, step.validation && step.validation.default);
  });
};

export default validation;
