import { ConfigData } from 'types/interfaces';

const config: ConfigData = {
  ID: 'TRANSFER_STEP',
  SUB_STEP_IDS: [
    'IDENTITY_CHECK_STEP',
    'IDENTITY_CHECK_BNC_STEP',
    'BENEFICIARY_STEP',
    'RESP_BENEFICIARY_STEP',
    'SPOUSE_INFOS_STEP',
    'PAYMENT_INFOS_STEP',
  ],
  SIDEBAR_MENU: true,
  PROGRESS_BAR: true,
  PROGRESS_BAR_COUNT: true,
  FOOTER: true,
  BUTTON_HELP: true,
  BUTTON_LANG: true,
  BUTTON_QUIT: true,
};

export default config;
