import React, { FunctionComponent } from 'react';
import { InjectedIntl } from 'react-intl';
import Title from 'components/Title';
import { Row, Col } from 'react-flexbox-grid';
import { createErrorFormatter, getRefData } from 'utils/productFormUtils';
import { getTextFactory } from 'utils/TextUtils';

import './styles.scss';
import { GenericProps } from 'types/interfaces';
import { ProductFormSelect, ProductFormRadioBoolean, ProductFormInput } from 'components/productFormFields';

export type SpecificsOptionsProps = GenericProps & {
  hasExperience?: boolean;
  marginAccountSelected?: boolean;
  intl: InjectedIntl;
};

const SpecificsOptions: FunctionComponent<SpecificsOptionsProps> = (props: SpecificsOptionsProps) => {
  const { intl, hasExperience = null, marginAccountSelected = false } = props;

  const formatErrorMessage = createErrorFormatter(intl);
  const getText = getTextFactory(intl, 'specificsOptions');
  const getTextGlobal = getTextFactory(intl, 'global');
  const strategyRefData = getRefData(
    props,
    marginAccountSelected ? 'marginAccountOptionsStrategies' : 'noMarginAccountOptionsStrategies',
  );

  return (
    <div className="specific-options__form">
      <Title label={getText('pageTitle')} subTitle={getText('pageSubTitle')} />

      <Row>
        <Col xs={12} md={12} lg={12} data-test="field_strategy">
          <ProductFormSelect
            name="strategy"
            placeholder={getTextGlobal('select')}
            label={getText('strategy')}
            helpText={getText('strategyHelp')}
            formatErrorMessage={formatErrorMessage}
            className="strategy"
            refData={strategyRefData}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={12} lg={12} data-test="field_hasExperience">
          <ProductFormRadioBoolean
            name="hasExperience"
            yesLabel={getTextGlobal('button.yes')}
            noLabel={getTextGlobal('button.no')}
            inline
            label={getText('hasExperience')}
            formatErrorMessage={formatErrorMessage}
          />
        </Col>
      </Row>

      {hasExperience && (
        <Row>
          <Col xs={12} md={12} lg={12} data-test="field_experienceAmount">
            <ProductFormInput
              name="experienceAmount"
              label={getText('experienceAmount')}
              formatErrorMessage={formatErrorMessage}
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={12} md={12} lg={12} data-test="field_knowledge">
          <ProductFormSelect
            name="knowledge"
            placeholder={getTextGlobal('select')}
            label={getText('knowledge')}
            formatErrorMessage={formatErrorMessage}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SpecificsOptions;
