import { connect } from 'react-redux';
import * as actionCreators from 'services/baoService/actions';

import { injectIntl } from 'react-intl';
import CreateUserProfile from './CreateUserProfile';
import config from './config';
import { UserCredentials } from 'types/actions';

export const CONFIG = config;

const mapDispatchToProps = (dispatch) => {
  return {
    setUserCredentials: (userCredentials: UserCredentials) =>
      dispatch(actionCreators.setUserCredentials(userCredentials)),
  };
};

export default connect(null, mapDispatchToProps)(injectIntl(CreateUserProfile));
