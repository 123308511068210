export type BAOLogger = {
  info: Console['info'];
  log: Console['log'];
  warn: Console['warn'];
  error: Console['error'];
};

const makeLogger = (action: string, prefix?: string) => (...args): void => {
  const params = prefix ? [`[${prefix}]`, ...args] : args;
  const forceLogs = localStorage.getItem('FORCE_LOGS');
  if (process.env.NODE_ENV === 'development' || !!forceLogs) {
    if (console[action]) {
      console[action](...params);
    } else if (console.log) {
      console.log(...params);
    }
  }
};

/**
 * Only logs in the console when in development builds.
 */
const getLogger = (prefix?: string): BAOLogger => ({
  info: makeLogger('info', prefix),
  log: makeLogger('log', prefix),
  warn: makeLogger('warn', prefix),
  error: makeLogger('error', prefix),
});

export default getLogger;
