import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Label } from '@nbc-design/label';
import Title from 'components/Title';

import { createErrorFormatter, isJointMenu } from 'utils/productFormUtils';
import { getTextFactory } from 'utils/TextUtils';

import AnnualRevenue from './components/AnnualRevenue';
import RevenueSource from './components/RevenueSource';
import FinancialAssets from './components/FinancialAssets';

import { ProductFormInput } from 'components/productFormFields';
import { FinancesProps } from './types';
import CONFIG from './config';
import { Text } from '@nbc-design/text';

import './style.scss';

const Finances: FunctionComponent<FinancesProps> = (props: FinancesProps) => {
  const { intl, meta } = props;

  const getText = getTextFactory(intl, 'finances');
  const getTextGlobal = getTextFactory(intl, 'global');
  const formatErrorMessage = createErrorFormatter(intl);

  const isJointStep = meta && meta.stepId === CONFIG.STEP_JOINT_ID;

  return (
    <div className="finances__form">
      {isJointMenu(meta) && (
        <Text size="large" className={isJointStep ? 'joint-spouse-title' : 'joint-applicant-title'}>
          {isJointStep ? getTextGlobal('spouseMenuTitle') : getTextGlobal('applicantMenuTitle')}
        </Text>
      )}

      <Title label={getText('pageTitle')} />

      <AnnualRevenue
        formatErrorMessage={formatErrorMessage}
        getText={getText}
        getTextGlobal={getTextGlobal}
        {...props}
      />

      <RevenueSource
        formatErrorMessage={formatErrorMessage}
        getText={getText}
        getTextGlobal={getTextGlobal}
        {...props}
      />

      <FinancialAssets
        formatErrorMessage={formatErrorMessage}
        getText={getText}
        getTextGlobal={getTextGlobal}
        {...props}
      />

      <Row>
        <Col xs={12} sm={12} md={12} lg={12} data-test="field_numberOfDependents" className="field_numberOfDependents">
          <Label>{getText('numberOfDependents')}</Label>
          <ProductFormInput
            helpText={getText('numberOfDependentsInfo')}
            name="numberOfDependents"
            formatErrorMessage={formatErrorMessage}
            className="numberOfDependents"
            ariaLabel={getText('numberOfDependents')}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Finances;
