import React, { FC } from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { Button } from '@nbc-design/button';
import { Card } from '@nbc-design/card';
import { Heading } from '@nbc-design/heading';
import { Delete } from '@nbc-design/icons/lib/web/Delete';
import { SuccessOutlineColor } from '@nbc-design/icons/lib/web/SuccessOutlineColor';
import { Text } from '@nbc-design/text';

import { SafeIdFinancialDataType } from '../../types';
import { getTextFactory } from 'utils/TextUtils';
import '../../styles.scss';

export type BankingAccountSafeIdProps = {
  intl: InjectedIntl;
  data: SafeIdFinancialDataType;
  handleOnClickDeleteButton: () => void;
};

const BankingAccountSafeIdSuccess: FC<BankingAccountSafeIdProps> = ({
  intl,
  data: { currency, account },
  handleOnClickDeleteButton,
}: BankingAccountSafeIdProps) => {
  const getText = getTextFactory(intl, 'specificsBankingInfo');

  return (
    <Card border="none" className="bankingAccountSafeIdSuccess">
      <Heading type="h6">
        {getText('safeIdCurrency')} {currency}
      </Heading>
      <Card border="none">
        <div>
          <SuccessOutlineColor size="medium" title={getText('completedStatus')} className="check-icon" />
          <Text type={'span'} className="bold">
            {account?.institutionName}
          </Text>
          <Text type={'span'} appearance={'muted'}>
            {account?.branchNo} {account?.institutionNo} {account?.accountNo}
          </Text>
        </div>
        <Button
          appearance="minimalDanger"
          size="small"
          icon={<Delete title="delete" />}
          iconPosition="left"
          dataTest="bank-safeid-delete"
          children={getText('safeIdCurrency.delete')}
          onClick={() => handleOnClickDeleteButton()}
        />
      </Card>
      <Text appearance="muted">{getText('safeIdCurrency.confirmHolder')}</Text>
    </Card>
  );
};

export default injectIntl(BankingAccountSafeIdSuccess);
