import React, { FC, useState } from 'react';
import { InjectedIntl } from 'react-intl';
import { Link } from '@nbc-design/link';
import { Text } from '@nbc-design/text';

import { GenericProps } from 'types/interfaces';
import { createErrorFormatter, getRefData } from 'utils/productFormUtils';
import { URLS } from 'utils/constants';
import { getTextFactory } from 'utils/TextUtils';
import ErrorMessageDisplay from 'components/errorManager/ErrorMessageDisplay/ErrorMessageDisplay';
import Title from 'components/Title';

import { AccountData } from './types';
import { CLIENT_TYPE, NON_REGISTERED_ACCOUNT_TYPES, REGISTERED_ACCOUNT_TYPES } from './constants';
import AccountTypeSelector from './components/AccountTypeSelector';
import AccountsSection from './components/AccountsSection';
import './styles.scss';

export type AccountsStep1Props = GenericProps & {
  intl: InjectedIntl;
  clientType: CLIENT_TYPE;
  accountsIndiv: AccountData[];
  accountsJoint: AccountData[];
};

const AccountsStep1: FC<AccountsStep1Props> = (props: AccountsStep1Props) => {
  const { accountsIndiv, accountsJoint, clientType, error, intl, submitFailed, change, productForm } = props;

  const getText = getTextFactory(intl, 'yourAccount');
  const getGlobalText = getTextFactory(intl, 'global');
  const errorMessage = error ? createErrorFormatter(intl, 'yourAccount')(error) : undefined;

  const accounts: AccountData[] = clientType === CLIENT_TYPE.INDIV ? accountsIndiv : accountsJoint;
  const [registeredAccounts, setRegisteredAccounts] = useState<AccountData[]>();
  const [nonRegisteredAccounts, setNonRegisteredAccounts] = useState<AccountData[]>();

  React.useEffect(() => {
    if (accounts) {
      setNonRegisteredAccounts(accounts.filter(({ code }) => NON_REGISTERED_ACCOUNT_TYPES.includes(code)));
      setRegisteredAccounts(accounts.filter(({ code }) => REGISTERED_ACCOUNT_TYPES.includes(code)));
    }
  }, [accounts]);

  return (
    <div className="accountsStep1__form">
      <Title data-test="title" label={getText('pageTitle')} />

      <AccountTypeSelector name="clientType" />

      {accounts && accounts.length > 0 && nonRegisteredAccounts && nonRegisteredAccounts.length > 0 && (
        <AccountsSection
          id="nonRegisteredAccounts"
          totalAccounts={accounts}
          accounts={nonRegisteredAccounts}
          intl={intl}
          change={change}
          clientType={clientType}
          accountsRefData={getRefData(props, 'accountsIndiv')}
          currenciesRefData={getRefData(props, 'currencies')}
          productForm={productForm}
        />
      )}

      {accounts && accounts.length > 0 && registeredAccounts && registeredAccounts.length > 0 && (
        <AccountsSection
          id="registeredAccounts"
          totalAccounts={accounts}
          accounts={registeredAccounts}
          intl={intl}
          change={change}
          clientType={clientType}
          accountsRefData={getRefData(props, 'accountsIndiv')}
          currenciesRefData={getRefData(props, 'currencies')}
          productForm={productForm}
        />
      )}

      <ErrorMessageDisplay visible={submitFailed && !!error} message={errorMessage} />

      <Link
        href={URLS.MORE_INFORMATION[intl.locale.toUpperCase()]}
        target="_blank"
        rel="noopener noreferrer"
        underlined
        data-test="link_moreInformation"
        className="more-information-link"
      >
        <Text>{getGlobalText('moreInformation')}</Text>
      </Link>
    </div>
  );
};

export default AccountsStep1;
