import React, { FunctionComponent } from 'react';
import { InjectedIntl } from 'react-intl';
import { Collapsible } from '@nbc-design/collapsible';
import { Heading } from '@nbc-design/heading';
import { Text } from '@nbc-design/text';
import { SafeIdPhotoColor } from '@nbc-design/icons/lib/web/SafeIdPhotoColor';
import { getTextFactory } from 'utils/TextUtils';
import DocumentsPopup from './DocumentsPopup';
import { sendClickAnalyticsEvent } from 'services/analytics';

export type StepsPopupProps = {
  intl: InjectedIntl;
};

const StepsPopup: FunctionComponent<StepsPopupProps> = (props: StepsPopupProps) => {
  const { intl } = props;

  const getText = getTextFactory(intl, 'clientRedirectNew');
  const acceptedDocuments = [
    {
      children: getText('documents.modalTitle'),
      description: <DocumentsPopup intl={intl} />,
      onToggleDone: (e) => {
        e && sendClickAnalyticsEvent('welcome', 'View accepted ID documents');
      },
    },
  ];

  return (
    <div className="steps-popup">
      <SafeIdPhotoColor
        size={{ width: 128, height: 128 }}
        title="safe-id-photo-color"
        className="authentification-icon"
      />
      <Heading type="h2" className="mc-mb-large mc-pt-xsmall">
        {getText('steps.modalTitle')}
      </Heading>
      <div className="authentification-explanation-wrapper">
        <div className="authentification-explanation">
          <div className="authentification-stepNumber">1</div>
          <Text appearance="mutedDarkGrey" size="paragraph" className="mc-mb-normal step-explanation">
            {getText('steps.explanationBold1')}
            {getText('steps.explanation1')}
          </Text>
        </div>
        <div className="authentification-explanation">
          <div className="authentification-stepNumber">2</div>
          <Text appearance="mutedDarkGrey" size="paragraph" className="mc-mb-normal step-explanation">
            {getText('steps.explanation2Start')}
            <b>{getText('steps.explanationBold2')}</b>
            {getText('steps.explanation2')}
          </Text>
        </div>
        <Collapsible items={acceptedDocuments} ariaLabelOpen="More info" ariaLabelClose="Close" appearance="normal" />
      </div>
      <div className="other-validation-wrapper">
        <Heading type="h5" className="mc-mb-small mc-pt-xsmall">
          {getText('steps.otherValidation')}
        </Heading>
        <Text size="paragraph" className="mc-mb-xxsmall">
          {getText('steps.explanation3')}
        </Text>
        <Text appearance="mutedDarkGrey" size="small" className="mc-mb-normal">
          {getText('steps.explanation4')}
        </Text>
      </div>
    </div>
  );
};

export default StepsPopup;
