import { UTILISATION_OTHER_CODE } from './constants';
import { ERRORTYPE } from '../../utils/validation';
import {
  AccountsStep3FormData,
  LegislationData,
  UtilisationData,
  AccountsStep3FormValidation,
  RespPlanData,
  RespPlanTypeCd,
} from './types';

const legislationValidator = (legislations: LegislationData[]): AccountsStep3FormValidation => {
  const legislationsErrors = legislations.map((legislation) => {
    const provincialLegislationCheckbox = legislation.values.filter((value) => value.value);

    return {
      ...(!provincialLegislationCheckbox || provincialLegislationCheckbox.length <= 0
        ? { values: ERRORTYPE.REQUIRED }
        : {}),
      ...(!legislation.province || legislation.province === '' ? { province: ERRORTYPE.REQUIRED } : {}),
    };
  });

  return { legislations: legislationsErrors };
};

const utilisationValidator = (utilisations: UtilisationData[]): AccountsStep3FormValidation => {
  const errors = utilisations.map(({ usageCode, usageOtherComment }) => {
    const usageOtherCommentOnError = usageCode === UTILISATION_OTHER_CODE && !usageOtherComment;

    return {
      ...(!usageCode ? { usageCode: ERRORTYPE.REQUIRED } : {}),
      ...(usageOtherCommentOnError ? { usageOtherComment: ERRORTYPE.REQUIRED } : {}),
    };
  });

  return { utilisations: errors };
};

const respPlanValidator = (respPlanTypes: RespPlanData[]): AccountsStep3FormValidation => {
  const errors = respPlanTypes.map(({ respPlanTypeCd, isFamilySibling }) => {
    return {
      ...(!respPlanTypeCd ? { respPlanTypeCd: ERRORTYPE.REQUIRED } : {}),
      ...(respPlanTypeCd === RespPlanTypeCd.FAMILY && isFamilySibling === undefined
        ? { isFamilySibling: ERRORTYPE.REQUIRED }
        : {}),
    };
  });

  return { respPlanTypes: errors };
};

const validation = ({
  legislations = [],
  utilisations = [],
  respPlanTypes = [],
  promotionCd = '',
}: AccountsStep3FormData): AccountsStep3FormValidation => {
  const errors = {
    ...legislationValidator(legislations),
    ...utilisationValidator(utilisations),
    ...respPlanValidator(respPlanTypes),
    ...(!promotionCd ? { promotionCd: ERRORTYPE.REQUIRED } : {}),
  };

  return errors;
};

export default validation;
