import React, { ChangeEvent, ClipboardEvent, Component, FocusEvent, ReactNode, SyntheticEvent } from 'react';
import { InjectedIntl } from 'react-intl';
import { Col, Row } from 'react-flexbox-grid';
import { reactLocalStorage } from 'reactjs-localstorage';
import { History } from 'history';
import { Alert } from '@nbc-design/alert';
import { Button } from '@nbc-design/button';
import { Card } from '@nbc-design/card';
import { FormGroup } from '@nbc-design/form-group';
import { Heading } from '@nbc-design/heading';
import { Input } from '@nbc-design/input';
import { Password } from '@nbc-design/password';
import { Text } from '@nbc-design/text';
import TextList from '@nbc-design/text-list';
import { validateUserEmail } from 'services/loginManager';
import { getFieldsValidation } from '../validation';
import {
  sendOutOfFormFlowAnalyticsEvent,
  sendOutOfFormFlowErrorAnalyticsEvent,
  sendStepLoadedEvent,
} from 'services/analytics';
import { EVENT_IDS } from 'services/analytics/constants';
import { getTextFactory } from 'utils/TextUtils';
import ErrorMessageDisplay from 'components/errorManager/ErrorMessageDisplay';
import Captcha from 'components/Captcha';
import BaoTooltip from 'components/BaoTooltip/BaoTooltip';
import { SiteKeyType } from 'components/Captcha/Captcha';
import { Security } from '@nbc-design/icons/lib/web/Security';
import PasswordCriteria, { PasswordCriterionProps } from '../../NewAccountRequest/components/PasswordCriteria';
import { cmsEncryptValue, getCertificate } from '../utils';
import configs from 'configs';
import { getBncId } from 'utils';

export type CredentialsCreationProps = {
  intl: InjectedIntl;
  history: History;
  setUserCredentials: Function;
  setCardWrapperState: Function;
  isError: boolean;
};

export type CredentialsCreationState = {
  formValues: {
    email: string;
    password: string;
  };
  touchedFields: {
    email: boolean;
    password: boolean;
  };
  formErrors: {
    email: string;
    password: string;
    connection: string;
  };
  isSubmitted: boolean;
  isLoading: boolean;
  isFormValid: boolean;
  isValidPassword: boolean;
};

class CredentialsCreation extends Component<CredentialsCreationProps, CredentialsCreationState> {
  state: CredentialsCreationState = {
    formValues: {
      email: reactLocalStorage.get('userEmail') || '',
      password: '',
    },
    touchedFields: {
      email: false,
      password: false,
    },
    formErrors: { email: '', password: '', connection: '' },
    isSubmitted: false,
    isLoading: false,
    isFormValid: false,
    isValidPassword: false,
  };

  getText = getTextFactory(this.props.intl, 'createCredentials');
  getTextError = getTextFactory(this.props.intl, 'error');
  getGlobalText = getTextFactory(this.props.intl, 'global');
  getAccessibilityText = getTextFactory(this.props.intl, 'accessibility');

  config = {
    ID: 'CREDENTIALS_CREATION',
  };

  componentDidMount = () => {
    sendStepLoadedEvent('profile creation', EVENT_IDS.ST, '', {
      eventInfo: {
        ucsId: 'UCS-bao-002',
      },
      step: {
        stepId: '2',
        flowId: 'profile creation',
      },
      user: {
        bncId: getBncId(),
      },
    });
  };

  validationConditions = (): PasswordCriterionProps[] => {
    const {
      formValues: { password },
    } = this.state;
    return [
      { id: 'oneLetter', label: this.getText('passwordRequirement2'), isValid: /[A-Z]+/.test(password) },
      { id: 'oneNumber', label: this.getText('passwordRequirement1'), isValid: /\d+/.test(password) },
      { id: 'noSpace', label: this.getText('passwordRequirement3'), isValid: /^\S+$/.test(password) },
      {
        id: 'length',
        label: this.getText('passwordRequirement4'),
        isValid: password.length >= 12 && password.length <= 25,
      },
    ];
  };

  validatePassword = (): void => {
    const isValidPassword = this.validationConditions().reduce(
      (validPassword, { isValid }) => validPassword && isValid,
      true,
    );
    this.setState({ isValidPassword });
  };

  validateForm() {
    this.formErrorsValues() && sendOutOfFormFlowErrorAnalyticsEvent(this.state.formErrors, 'profile creation');

    this.setState({ isFormValid: this.hasRequiredValues() && this.state.isValidPassword && !this.formErrorsValues() });
  }

  setError = (name: string, error: string): void => {
    this.setState(({ formErrors }) => ({
      formErrors: {
        ...formErrors,
        [name]: error ? this.getTextError(error) : '',
      },
    }));
  };

  hasRequiredValues = (): boolean => {
    return Object.values(this.state.formValues).every((value) => value);
  };

  formErrorsValues = (): string | boolean | undefined => {
    return Object.values(this.state.formErrors).find((value) => value !== '');
  };

  validateField = (fieldName: string, value: string): void => {
    const { formErrors } = getFieldsValidation({
      fieldName,
      value,
    });

    this.setError(fieldName, formErrors[fieldName]);
  };

  executeRecaptcha = (event: SyntheticEvent): void => {
    event.preventDefault();
    (window as any).grecaptcha.reset();
    (window as any).grecaptcha.execute();
  };

  handleError = (response): void => {
    console.log('ERROR SERVER RESPONSE: ', response);

    this.setError('connection', 'connection');
    this.setState({ isLoading: false });

    sendOutOfFormFlowAnalyticsEvent('' + this.formErrorsValues(), '' + this.config.ID);
  };

  saveUserCredentials = async (response: { emailInUse: boolean } | null): Promise<void | null> => {
    const {
      formValues: { email, password },
    } = this.state;
    const { setUserCredentials, setCardWrapperState } = this.props;

    if (!response) {
      return Promise.resolve(null);
    }

    try {
      if (!response.emailInUse) {
        const isIamxEncrypted = configs.params.FEATURE_TOGGLE.IAMX_ENCRYPTION;
        const certificate = await getCertificate();
        const encryptedPassword = isIamxEncrypted ? await cmsEncryptValue(certificate, password) : password;

        await setUserCredentials({ email, password, encryptedPassword });

        this.setState({ isLoading: false, isSubmitted: true }, () => {
          setCardWrapperState({ step: 2 });
        });
      } else {
        this.setState({ isLoading: false }, () => {
          setCardWrapperState({ existedEmail: true, rejectedEmail: email });
        });
      }
    } catch (err) {
      this.handleError(err);
    }
  };

  validateAllFields = (email: string, password: string): void => {
    this.validateField('email', email);
    this.validateField('password', password);
    this.validateForm();
  };

  handleFormSubmit = (): void => {
    const {
      formValues: { email, password },
    } = this.state;

    this.validateAllFields(email, password);

    this.setState({ isSubmitted: true, isLoading: this.state.isFormValid });

    // keep it for testing without back-end
    // const response = { emailInUse: false };
    // this.state.isFormValid && this.saveUserCredentials(response).then(() => {});

    this.state.isFormValid && validateUserEmail(email?.trim()).then(this.saveUserCredentials).catch(this.handleError);
  };

  onChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    const {
      target: { name, value },
    } = event;

    const formValues = this.state.formValues;
    formValues[name] = value.trim();

    this.setState({ formValues }, () => {
      this.state.touchedFields[name] && this.validateField(name, value.trim());
      this.validatePassword();
    });
  };

  onBlur = (event: FocusEvent<HTMLInputElement | HTMLSelectElement>): void => {
    const {
      target: { name, value },
    } = event;

    const touchedFields = this.state.touchedFields;
    touchedFields[name] = true;

    this.setState({ touchedFields }, () => {
      this.validateField(name, value.trim());
    });
  };

  onPaste = (event: ClipboardEvent<HTMLInputElement>): void => {
    event.preventDefault();
  };

  render(): ReactNode {
    const { isError } = this.props;

    const {
      formValues: { password },
      formErrors,
      isLoading,
    } = this.state;

    const validations = this.validationConditions();

    let notValidConditions = validations
      .filter((condition) => !condition.isValid)
      .map((condition) => `${condition.id}-condition`)
      .join(' ');

    if (!notValidConditions.length) {
      notValidConditions = 'requirements-met';
    }

    return (
      <>
        {isError && (
          <Alert
            appearance="error"
            title={this.getText('noCreationErrorTitle')}
            description={this.getText('noCreationErrorText')}
            ariaLabelIcon={this.getAccessibilityText('icon.error')}
          />
        )}

        <Card border="shadow" className="spc-creationProfil__card mc-mb-normal mc-mt-normal">
          <form onSubmit={this.executeRecaptcha}>
            <div className="spc-creationProfil__securityHeader">
              <Security title="security" size={{ width: 40, height: 40 }} className="mc-mr-normal mc-text-navy900" />
              <TextList size="small">
                <Text size={'small'} className="mc-mb-xxsmall profil-explanation">
                  {this.getText('explanation1')}
                </Text>
                <TextList.Item className="mc-mb-xxsmall">{this.getText('explanation2')}</TextList.Item>
                <TextList.Item className="mc-mb-0">{this.getText('explanation3')}</TextList.Item>
              </TextList>
            </div>

            <div className="spc-creationProfil__cardBody spc-creationProfil__cardBody--fullContentMobile">
              <div className="spc-creationProfil__stepNumber">1</div>
              <div className="spc-creationProfil__stepTitle">
                <Heading type="h2" size={5}>
                  {this.getText('stepTitle1')}
                </Heading>
                <BaoTooltip
                  name="credentialsCreation"
                  stepId={'CREATE_USER_PROFILE'}
                  content={this.getText('stepTitleTooltip')}
                  position="top"
                />
              </div>
              <div></div>
              <div className="mc-pt-small">
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <FormGroup
                      label={{
                        text: this.getText('identificationEmailLabel'),
                        htmlFor: 'identification-email-input',
                      }}
                      validate={{ hasError: !!formErrors.email, errorMsg: formErrors.email }}
                      data-test="label_identificationEmail"
                      length="large"
                    >
                      <Input
                        id="identification-email-input"
                        name="email"
                        type="email"
                        maxLength={64}
                        onChange={(e) => {
                          this.onChange(e);
                          reactLocalStorage.set('userEmail', e.target.value);
                        }}
                        onBlur={this.onBlur}
                        onPaste={this.onPaste}
                        data-test="field_identificationEmail"
                        placeholder={this.getText('identificationEmailPlaceholder')}
                        defaultValue={reactLocalStorage.get('userEmail')}
                        disabled={isLoading}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <FormGroup
                      label={{ text: this.getText('passwordLabel'), htmlFor: 'password-input' }}
                      validate={{ hasError: !!formErrors.password, errorMsg: formErrors.password }}
                      data-test="label_password"
                      introduction={{
                        text: this.getText('passwordSubLabel'),
                        id: 'mdpDesc',
                      }}
                      length="large"
                    >
                      <Password
                        id="password-input"
                        name="password"
                        maxLength={25}
                        value={password}
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                        onPaste={this.onPaste}
                        data-test="field_password"
                        autoComplete="off"
                        ariaLabelShow={this.getText('showPassword')}
                        ariaLabelHide={this.getText('hidePassword')}
                        aria-describedby={notValidConditions}
                        disabled={isLoading}
                      />
                    </FormGroup>

                    <PasswordCriteria
                      criteria={this.validationConditions()}
                      requirementMetLabel={this.getText('requirementsMet')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} lg={12} className="consent-explanation">
                    <Text appearance="mutedDarkGrey" size="xSmall">
                      {this.getText('consentExplanation1')}
                    </Text>
                    <Text appearance="mutedDarkGrey" size="xSmall">
                      {this.getText('consentExplanation2')}
                    </Text>
                  </Col>
                </Row>
                <ErrorMessageDisplay visible={!!formErrors.connection} message={formErrors.connection} />
              </div>
            </div>
            <div className="spc-creationProfil__cardFooter">
              <Button
                appearance="primary"
                type="submit"
                loading={{ isLoading: isLoading, ariaLabel: this.getGlobalText('loading') }}
              >
                {this.getGlobalText('button.continue')}
              </Button>
            </div>
          </form>
        </Card>
        <Captcha
          siteKeyType={SiteKeyType.frontend}
          success={this.handleFormSubmit}
          timeout={() => {
            // This is intentional
          }}
          failure={() => {
            // This is intentional
          }}
          invisible
        />
      </>
    );
  }
}

export default CredentialsCreation;
