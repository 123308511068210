export const scrollIntoView = (selector: string) => {
  const scrollZone: HTMLDivElement | null = document.querySelector(selector);
  scrollZone && scrollZone.scrollIntoView({ behavior: 'smooth' });
};

export const scrollToTop = (selector: string) => {
  const scrollZone: HTMLDivElement | null = document.querySelector(selector);
  scrollZone && scrollZone.scrollTo(0, 0);
};

export const focusBody = () => {
  const body: HTMLBodyElement | null = document.querySelector('body');
  body && body.focus();
};

export const makeItInvalid = (selector: string): void => {
  const element: HTMLInputElement | null = document.querySelector(selector);
  element && element.classList.add('invalid-field');
};

export const makeItValid = (selector: string): void => {
  const element: HTMLInputElement | null = document.querySelector(selector);
  element && element.classList.remove('invalid-field');
};
