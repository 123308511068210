import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { BtnType } from '@nbc-design/button/dist/interface';
import { Button } from '@nbc-design/button';
import { Card } from '@nbc-design/card';
import { Icon, IconName } from '@nbc-design/icons';

import Heading from 'components/Heading';

type IdentityProps = {
  title: string;
  subTitle: string;
  continueText?: string;
  iconName: IconName;
  hasButton: boolean;
  buttonType?: BtnType;
  buttonAction?: Function;
  children?: ReactNode;
  extraHeaderElements?: ReactNode;
  className?: string;
};

const IdentityWrapper: FC<IdentityProps> = function (props: IdentityProps) {
  const {
    title,
    subTitle,
    continueText,
    iconName,
    hasButton = true,
    buttonType,
    buttonAction,
    extraHeaderElements,
    className,
  } = props;

  const handleButtonClick = (event): void => {
    if (event) {
      event.preventDefault();
    }
    buttonAction && typeof buttonAction === 'function' && buttonAction();
  };

  return (
    <Card className={classNames(className, 'identity-validation-box')} border="none">
      <section className="idv-header">
        <Icon name={iconName} size="medium" />
        <section className="idv-title">
          <div className="title-wrapper">
            <div>
              <Heading type={'h4'} size={6} className="idv-title-label">
                {title}
              </Heading>
            </div>
            {extraHeaderElements}
          </div>
          <p className="idv-title-description">{subTitle}</p>
        </section>
      </section>
      <section className="idv-content">{props.children}</section>
      {hasButton && (
        <section className="idv-footer">
          <Button type={buttonType} appearance="primary" onClick={handleButtonClick}>
            {continueText}
          </Button>
        </section>
      )}
    </Card>
  );
};

export default IdentityWrapper;
