import { redirectToUnifiedLogin } from './index';

export const manageAutomaticRedirect = (locale: string) => {
  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);

  const modeParam = urlParams.get('mode');

  if (modeParam && modeParam === 'redirect_sso') {
    redirectToUnifiedLogin(locale);
  }
};
