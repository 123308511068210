import { injectIntl } from 'react-intl';
import { formValues } from 'bnc-react-forms';
import Finances from './Finances';
import config from './config';

export const CONFIG = config;

export default injectIntl(
  formValues(
    'fundingSource',
    'assetsLiquidity',
    'assetsFixedRevenue',
    'assetsEquitySecurities',
    'assetsOther',
    'assetsProperty',
    'assetsTotal',
    'liabilitiesPersonalLoan',
    'liabilitiesCreditMargins',
    'liabilitiesMortgage',
    'liabilitiesOther',
    'liabilitiesTotal',
    'numberOfDependents',
  )(Finances),
);
