import React, { FunctionComponent, useEffect } from 'react';
import Title from 'components/Title';
import { TransferFormData } from './types';
import { BaoDrilledProps } from 'types/interfaces';

import './style.scss';
import { ACCOUNT_SOURCE } from './constants';
import { useContainerCtx } from '../../providers/containerCtx/ContainerCtx';
import TransferAccountsOtherFin from './components/TransferAccountsOtherFin';
import TransferAccountsEmployer from './components/TransferAccountsEmployer';
import TransferAccountsInternal from './components/TransferAccountsInternal';

export type TransferProps = BaoDrilledProps & TransferFormData;

const Transfer: FunctionComponent<TransferProps> = () => {
  const { formValues, getText } = useContainerCtx<TransferFormData>();
  const {
    accounts = [],
    transferAccountOtherFin = false,
    transferAccountEmployer = false,
    transferAccountInternal = false,
  } = formValues;

  // *****************************************************************************************
  // When selecting FALSE to a main question, we reset the corresponding child accounts values
  useEffect(() => {
    if (!accounts) return;
    if (transferAccountOtherFin && transferAccountEmployer && transferAccountInternal) return;

    accounts.forEach((account) => {
      if (account.transferSourceCd === ACCOUNT_SOURCE.OTHFIN) {
        account.transfer = !transferAccountOtherFin ? false : account.transfer;
      }

      if (account.transferSourceCd === ACCOUNT_SOURCE.EMPLYR) {
        account.transfer = !transferAccountEmployer ? false : account.transfer;
      }

      if (account.transferSourceCd === ACCOUNT_SOURCE.INTERNAL) {
        account.transfer = !transferAccountInternal ? false : account.transfer;
      }
    });
  }, [accounts, transferAccountOtherFin, transferAccountEmployer, transferAccountInternal]);

  return (
    <div id="Transfer_form">
      <Title label={getText('pageTitle')} />

      <TransferAccountsOtherFin />
      <TransferAccountsEmployer />
      <TransferAccountsInternal />
    </div>
  );
};

export default Transfer;
