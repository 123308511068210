import React, { ChangeEvent, FC, FocusEvent } from 'react';
import { InjectedIntl } from 'react-intl';
import { Col, Row } from 'react-flexbox-grid';
import { ProductFormDateFields, ProductFormInput } from 'components/productFormFields';
import { InfoFillColor } from '@nbc-design/icons/lib/web/InfoFillColor';
import { Text } from '@nbc-design/text';
import { getTextFactory } from 'utils/TextUtils';
import { ProductFormFieldChangeHandler } from 'types/interfaces';
import { createErrorFormatter } from 'utils/productFormUtils';
import { socialInsuranceFormatter } from 'utils';

export type SpouseFormProps = {
  intl: InjectedIntl;
  change: ProductFormFieldChangeHandler;
  isSrrsp: boolean;
  isUpdate?: boolean;
};

const SpouseForm: FC<SpouseFormProps> = (props: SpouseFormProps) => {
  const { intl, change, isSrrsp, isUpdate } = props;

  const getText = getTextFactory(intl, 'spouseInformation');
  const getGlobalText = getTextFactory(intl, 'global');
  const formatErrorMessage = (prefix?: string, fieldName?: string) => createErrorFormatter(intl, prefix, fieldName);

  const [maskedSIN, setMaskedSIN] = React.useState('');

  const handleChangeSIN = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    const { name, value } = event.target;
    change(name, socialInsuranceFormatter(value));
  };

  const handleFocusSIN = (event: FocusEvent<HTMLInputElement>): void => {
    event.preventDefault();
    setMaskedSIN('');
  };

  const handleBlurSIN = (event: FocusEvent<HTMLInputElement>): void => {
    event.preventDefault();
    const start = event.target.value.substring(0, 7);
    const end = event.target.value.substring(8);
    const formattedMmaskedSIN = start.replace(new RegExp('[0-9]', 'g'), '*') + ' ' + end;
    setMaskedSIN(formattedMmaskedSIN);
  };

  return (
    <div>
      <Row>
        <Col xs={12} md={6} data-test="field_firstName">
          <ProductFormInput
            name="firstName"
            className="firstName"
            label={getGlobalText('firstName')}
            formatErrorMessage={formatErrorMessage()}
          />
        </Col>
        <Col xs={12} md={6} data-test="field_middleName">
          <ProductFormInput
            name="middleName"
            label={getGlobalText('middleName')}
            optionalText={getGlobalText('optional')}
            formatErrorMessage={formatErrorMessage()}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={8} data-test="field_lastName">
          <ProductFormInput
            name="lastName"
            className="lastName"
            label={getGlobalText('lastName')}
            formatErrorMessage={formatErrorMessage()}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={8} data-test="field_birthDate">
          <ProductFormDateFields
            name="birthDate"
            change={change}
            label={getGlobalText('birthDate')}
            formatErrorMessage={formatErrorMessage()}
            isUpdate={isUpdate}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={8} data-test="field_socialInsuranceNumber">
          <ProductFormInput
            name="socialInsuranceNumber"
            className="socialInsuranceNumber"
            label={getText('socialInsuranceNumber')}
            helpText={getText('reason')}
            formatErrorMessage={formatErrorMessage('tax', 'socialInsuranceNumber')}
            onChange={handleChangeSIN}
            onFocus={handleFocusSIN}
            onBlur={handleBlurSIN}
            inputRawValue={maskedSIN}
          />
        </Col>
      </Row>

      {isSrrsp && (
        <Row className="info-SRRSP">
          <Col lg={1} md={1} xs={1}>
            <InfoFillColor size="small" title="info-fill-color" />
          </Col>
          <Col lg={11} md={11} xs={11} data-test="info-SRRSP-text">
            <Text>{getText('infoSRRSP')}</Text>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default SpouseForm;
