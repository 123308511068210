import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-flexbox-grid';

import '../styles.scss';

export type StepRowProps = {
  stepIndex: number;
  stepNumber?: number;
  stepTitle: string;
};

const StepRow: FunctionComponent<StepRowProps> = ({ stepIndex, stepNumber = stepIndex, stepTitle, children }) => {
  return (
    <Col key={`finalisation-step-${stepIndex}`} xs={12} md={12} lg={12} className="finalisation-card-row">
      <Row className="finalisation-card-label">
        <Col xs={12} md={12} lg={12}>
          <span className="finalisation-step-number" data-test={`label_step${stepIndex}_number`}>
            {stepNumber}
          </span>
          <div className="finalisation-step-title" data-test={`label_step${stepIndex}_title`}>
            {stepTitle}
          </div>
        </Col>
      </Row>
      <Row className="finalisation-card-content" data-test={`label_step${stepIndex}_subTitle`}>
        {children}
      </Row>
    </Col>
  );
};

export default StepRow;
