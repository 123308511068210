import { getProductFormField } from '../utils';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import { injectIntl } from 'react-intl';
import {
  occupationTitleServiceListRequested,
  occupationTitleServiceReset,
} from 'services/OccupationTitleService/actions';
import ProductFormOccupationSelect from './ProductFormOccupationSelect';

export default compose<any>(
  connect(
    (state, ownProps) => {
      return {
        isLoading: get(state, ['occupationTitleServiceReducer', ownProps.name, 'isFetching'], false),
        occupationList: get(state, ['occupationTitleServiceReducer', ownProps.name, 'occupationList']),
        error: get(state, ['occupationTitleServiceReducer', ownProps.name, 'fetchingError']),
      };
    },
    {
      searchOccupation: occupationTitleServiceListRequested,
      resetOccupation: occupationTitleServiceReset,
    },
  ),
  injectIntl,
)(getProductFormField(ProductFormOccupationSelect));
