import { createSelector } from 'reselect';
import { SummarySlice } from './types';
import { get } from 'lodash';

export const getSummaryReduxFormValues = (state): SummarySlice => get(state, 'reduxFormReducer.baoForms.values');

export const getAccountsSlice = createSelector(getSummaryReduxFormValues, (reduxFormValues) => {
  return reduxFormValues ? reduxFormValues.accounts : null;
});

export const getBeneficiarySlice = createSelector(getSummaryReduxFormValues, (reduxFormValues) => {
  return reduxFormValues ? reduxFormValues.beneficiary : null;
});

export const getTaxSlice = createSelector(getSummaryReduxFormValues, (reduxFormValues) => {
  return reduxFormValues ? reduxFormValues.tax : null;
});

export const getTransferSlice = createSelector(getSummaryReduxFormValues, (reduxFormValues) => {
  return reduxFormValues ? reduxFormValues.transfer : null;
});

export const getBankingInformationSlice = createSelector(getSummaryReduxFormValues, (reduxFormValues) => {
  return reduxFormValues ? reduxFormValues.bankingInformation : null;
});

export const getSpouseInformationSlice = createSelector(getSummaryReduxFormValues, (reduxFormValues) => {
  return reduxFormValues ? reduxFormValues.spouseInfos : null;
});

export const getBeneficiaryInformationSlice = createSelector(getSummaryReduxFormValues, (reduxFormValues) => {
  return reduxFormValues ? { ...reduxFormValues.respBeneficiary, ...reduxFormValues.personalInformation } : null;
});

export const getPaymentInformationSlice = createSelector(getSummaryReduxFormValues, (reduxFormValues) => {
  return reduxFormValues ? reduxFormValues.payment : null;
});
