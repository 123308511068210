import upperCase from 'lodash/upperCase';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Redirect, RouteComponentProps } from 'react-router';
import { Loading } from '@nbc-design/loading';
import { ROUTE } from 'utils/constants';
import getLogger from 'utils/getLogger';
import { redirectToUnifiedLogin } from 'services/ssoRedirectService';
import { saveItem } from 'utils';

export type RedirectPageProps = InjectedIntlProps & RouteComponentProps<{ variant?: string }>;

const logger = getLogger('🅰 / 🅱 variant');

export const RedirectPage: React.FunctionComponent<RedirectPageProps> = (props) => {
  const {
    intl: { locale },
    match: { params },
  } = props;
  const variant = upperCase(params.variant);

  logger.info(variant, locale);

  switch (variant) {
    case 'A':
      saveItem('newRequest', 'true');
      redirectToUnifiedLogin(locale);
      return <Loading />;
    case 'B':
      saveItem('newRequest', 'false');
      redirectToUnifiedLogin(locale);
      return <Loading />;
    default:
      return <Redirect from={ROUTE.REDIRECT_PAGE} to={ROUTE.CLIENT_REDIRECT} />;
  }
};

RedirectPage.displayName = 'RedirectPage';

export default injectIntl(RedirectPage);
