import { injectIntl } from 'react-intl';
import { formValues } from 'bnc-react-forms';
import BeneficiaryInformation, { BeneficiaryInformationProps } from './BeneficiaryInformation';
import config from './config';
import { withContainerCtxProvider } from 'providers/containerCtx/ContainerCtx';
import { flow } from 'lodash';
import * as validation from './validation';
import { withBeneficiaryInformationCtxProvider } from './providers/BeneficiaryInformationCtx';

export const CONFIG = config;
export const VALIDATION = validation;

export default flow(
  withBeneficiaryInformationCtxProvider(),
  withContainerCtxProvider<BeneficiaryInformationProps>('beneficiaryInformation'),
  formValues(
    'beneficiaries',
    'eligibleBeneficiaries',
    'account',
    'eligibleRelationTypes',
    'useHolderAddress',
    'formData',
    'holder',
    'validation',
  ),
  injectIntl,
)(BeneficiaryInformation);
