import React, { FunctionComponent, PropsWithChildren } from 'react';

import { loadToken } from 'services/loginManager';

import './inspectSsoTokenButton.scss';

export type InspectSsoTokenButtonProps = {};

const handleInspectJWT = (): void => {
  const token = loadToken();
  const jwtIoUrl = `https://jwt.io/?token=${token}`;
  window.open(jwtIoUrl, '_blank');
};

export const InspectSsoTokenButton: FunctionComponent<PropsWithChildren<InspectSsoTokenButtonProps>> = ({
  children,
}) => {
  const token = loadToken();
  const status = !!token ? 'online' : 'offline';

  return (
    <button className={`inspect-sso ${status}`} onClick={handleInspectJWT}>
      <span className="status"></span>
      {children}
    </button>
  );
};
