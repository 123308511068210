import React, { FunctionComponent } from 'react';
import { InjectedIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import CONFIG from './config';
import { Confirmation } from '@nbc-design/confirmation';
import { Button } from '@nbc-design/button';
import { Card } from '@nbc-design/card';
import { Check } from '@nbc-design/icons/lib/web/Check';
import { getTextFactory } from 'utils/TextUtils';

import './styles.scss';

export type ThankYouEsignProps = {
  intl: InjectedIntl;
};

const ThankYouEsign: FunctionComponent<ThankYouEsignProps> = (props: ThankYouEsignProps) => {
  const { intl } = props;
  const { locale } = intl;

  const getText = getTextFactory(intl, 'thankYou');
  const pageTitle = getText('pageTitle');

  return (
    <div className="thank_you_esign__form">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Confirmation title={pageTitle} appearance="success" />

      <Card className="checklist">
        <ul>
          <li>
            <Check title="check" size={{ width: 16, height: 16 }} className="check-icon" data-test="image_checkIcon" />
            {getText('emailSentSoon')}
          </li>
        </ul>
      </Card>

      <Button
        type="link"
        appearance="secondary"
        href={CONFIG.LINKS[locale.toUpperCase()].WEBSITE}
        data-test="link_close"
        className="button_link button_close"
      >
        {getText('close')}
      </Button>
    </div>
  );
};

export default ThankYouEsign;
