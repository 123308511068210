import { DateTime } from 'luxon';
import { addressValidation, ERRORTYPE, isPOBOX } from 'utils/validation';
import {
  AddressValidationData,
  CitizenshipData,
  CitizenshipValidationData,
  PersonalInformationErrorsDictionary,
  PersonalInformationFormData,
  PhoneNumber,
  PhoneNumberValidationData,
  PhoneNumberValidationError,
} from './types';
import { ErrorData, ErrorsDictionary, Meta } from 'types/interfaces';
import { isJointMenu } from 'utils/productFormUtils';
import { SPOUSE_INFORMATION_ERRORTYPE } from 'containers/SpouseInformation/validation';
import { phoneNumberCanadaUSARegex } from 'utils/regex';

export const PERSONAL_INFORMATION_ERRORTYPE: PersonalInformationErrorsDictionary = {
  ...ERRORTYPE,
  MUST_BE_MAJOR: { type: 'personalInformation.mustBeMajor' },
};

export const phoneNumberValidation = (values: PersonalInformationFormData): PhoneNumberValidationData => {
  const { phoneNumbers = [] } = values;

  const phoneNumberErrors: PhoneNumberValidationError[] = phoneNumbers.map(
    (phone: PhoneNumber, phoneIndex: number, self: PhoneNumber[]) => {
      const isPhoneNumberADuplicate = self.find(
        (pn, index) => !!phone && !!pn && pn.phoneNumber === phone.phoneNumber && index !== phoneIndex,
      );

      const cleanPhoneNumber = phone && phone.phoneNumber && phone.phoneNumber.replace(/-/g, '');

      return {
        ...(!phone || !phone.telephoneType ? { telephoneType: PERSONAL_INFORMATION_ERRORTYPE.REQUIRED } : {}),
        ...(isPhoneNumberADuplicate ? { phoneNumber: PERSONAL_INFORMATION_ERRORTYPE.DUPLICATE } : {}),
        ...(!phone || !phone.phoneNumber ? { phoneNumber: PERSONAL_INFORMATION_ERRORTYPE.REQUIRED } : {}),
        ...(cleanPhoneNumber && !phoneNumberCanadaUSARegex.test(cleanPhoneNumber)
          ? { phoneNumber: PERSONAL_INFORMATION_ERRORTYPE.PATTERN }
          : {}),
        ...(phone && phone.phoneNumber && phone.phoneNumber.length < 12
          ? { phoneNumber: PERSONAL_INFORMATION_ERRORTYPE.PATTERN }
          : {}),
      };
    },
  );

  const hasError: boolean = phoneNumberErrors.some(
    (val: PhoneNumberValidationError): boolean => !!Object.keys(val).length,
  );
  return hasError
    ? {
        phoneNumbers: phoneNumberErrors,
      }
    : {};
};

export const citizenshipValidation = (values: PersonalInformationFormData): CitizenshipValidationData => {
  const { citizenship = [] }: CitizenshipData = values as CitizenshipData;

  if (!citizenship.length) {
    return {
      citizenship: [PERSONAL_INFORMATION_ERRORTYPE.REQUIRED],
    };
  }

  const citizenshipErrors = citizenship.reduce((acc: ErrorData[], item: string) => {
    return !!item ? acc : acc.concat(PERSONAL_INFORMATION_ERRORTYPE.REQUIRED);
  }, []);

  return citizenshipErrors.some((val: ErrorData | undefined): boolean => !!val)
    ? {
        citizenship: citizenshipErrors,
      }
    : {};
};

export const addressesValidation = (values: PersonalInformationFormData): AddressValidationData => {
  const { currentAddress, currentAddressStartDate, currentAddressOwnership, mailingAddress } = values;

  const addressError = { ...addressValidation(currentAddress), ...isPOBOX(currentAddress) };
  const mailingAddressError = addressValidation(mailingAddress);
  const currentAddressStartDateError = startDateValidation(currentAddressStartDate);
  const currentAddressOwnershipError = ownershipValidation(currentAddressOwnership);

  return {
    ...(mailingAddressError ? { mailingAddress: mailingAddressError } : {}),
    ...(Object.keys(addressError).length ? { currentAddress: addressError } : {}),
    ...(currentAddressStartDate ? { currentAddressStartDate: currentAddressStartDateError } : {}),
    ...(currentAddressOwnership ? { currentAddressOwnership: currentAddressOwnershipError } : {}),
  };
};

export const birthDateValidation = (values: PersonalInformationFormData, meta?: Meta): ErrorsDictionary => {
  const isSpouse = isJointMenu(meta!);
  const { birthDate } = values;

  if (!birthDate) {
    return { birthDate: PERSONAL_INFORMATION_ERRORTYPE.PATTERN };
  }

  const date = DateTime.fromISO(birthDate);
  if (!date.isValid) {
    return { birthDate: PERSONAL_INFORMATION_ERRORTYPE.PATTERN };
  }

  if ((date.diffNow('days').toObject().days || 0) >= 0) {
    return { birthDate: PERSONAL_INFORMATION_ERRORTYPE.PATTERN };
  }

  const todayDate = DateTime.utc();
  const age = todayDate.diff(date, 'years').years;
  if (age < 18) {
    return {
      birthDate: isSpouse ? SPOUSE_INFORMATION_ERRORTYPE.MUST_BE_MAJOR : PERSONAL_INFORMATION_ERRORTYPE.MUST_BE_MAJOR,
    };
  }

  if (date.year < 1900) {
    return {
      birthDate: PERSONAL_INFORMATION_ERRORTYPE.PATTERN,
    };
  }

  return {};
};

export const startDateValidation = (date: string | undefined): ErrorsDictionary | undefined => {
  if (!date) {
    return { currentAddressStartDate: PERSONAL_INFORMATION_ERRORTYPE.REQUIRED };
  }

  const dateTime = DateTime.fromISO(date);
  const { day, month, year } = dateTime;

  if (!day || !month || !year) {
    return { currentAddressStartDate: PERSONAL_INFORMATION_ERRORTYPE.REQUIRED };
  }

  if (!dateTime.isValid) {
    return { currentAddressStartDate: PERSONAL_INFORMATION_ERRORTYPE.PATTERN };
  }

  return undefined;
};

export const ownershipValidation = (ownership: string | undefined): ErrorsDictionary | undefined => {
  if (!ownership) {
    return { currentAddressOwnership: PERSONAL_INFORMATION_ERRORTYPE.REQUIRED };
  }

  return undefined;
};

const validation = (
  values: PersonalInformationFormData,
  { meta }: any,
): PhoneNumberValidationData | CitizenshipValidationData | AddressValidationData | ErrorsDictionary => {
  return {
    ...phoneNumberValidation(values),
    ...citizenshipValidation(values),
    ...addressesValidation(values),
    ...birthDateValidation(values, meta),
  };
};

export default validation;
