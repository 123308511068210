import { BeneficiaryFormValues } from '../types';
import { useContainerCtx } from '../../../providers/containerCtx/ContainerCtx';
import { useMemo } from 'react';
import { RespPlanTypeCd } from '../../AccountsStep3/types';
import {
  isIndividualOnlyBeneficiary,
  getBeneficiariesNotDeleted,
  calculatedTotalContribution,
  areAllContributionFieldsEmpty,
  isBeneficiaryProfileComplete,
} from '../utils';

export const useBeneficiaryHelpers = () => {
  const { formValues } = useContainerCtx<BeneficiaryFormValues>();
  const { beneficiaries, account, formData } = formValues;
  const { respPlanTypeCd } = account ?? {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const remainingBeneficiaries = getBeneficiariesNotDeleted(beneficiaries);

  const _isIndividualOnlyBeneficiary = useMemo(
    () => isIndividualOnlyBeneficiary(remainingBeneficiaries, respPlanTypeCd),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [remainingBeneficiaries],
  );

  const areAllProfilesCompleted = useMemo(
    (): boolean => {
      return remainingBeneficiaries?.every((beneficiary, index, array) =>
        isBeneficiaryProfileComplete(beneficiary, array, respPlanTypeCd),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [remainingBeneficiaries, beneficiaries, formData],
  );

  const _calculatedTotalContribution = useMemo(
    () => calculatedTotalContribution(beneficiaries),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [remainingBeneficiaries, beneficiaries, formData],
  );

  const _areAllContributionFieldsEmpty = useMemo(() => {
    return areAllContributionFieldsEmpty(remainingBeneficiaries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingBeneficiaries, beneficiaries, formData]);

  const isFamilyRespPlanType = useMemo((): boolean => {
    return respPlanTypeCd === RespPlanTypeCd.FAMILY;
  }, [respPlanTypeCd]);

  return {
    isIndividualOnlyBeneficiary: _isIndividualOnlyBeneficiary,
    isFamilyRespPlanType,
    areAllProfilesCompleted,
    calculatedTotalContribution: _calculatedTotalContribution,
    areAllContributionFieldsEmpty: _areAllContributionFieldsEmpty,
  };
};
