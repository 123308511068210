import React, { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from 'react';
import '../styles.scss';
import { useContainerCtx } from 'providers/containerCtx/ContainerCtx';
import { BeneficiaryInformationProps } from '../BeneficiaryInformation';
import Modal from '@nbc-design/modal';
import { Button, IconButton } from '@nbc-design/button';
import { Col, Row } from 'react-flexbox-grid';
import { BeneficiaryDataType, OperationMode } from '../types';
import BeneficiaryForm from './BeneficiaryForm';
import { ProductFormSelect } from 'components/productFormFields';
import { RefData } from 'types/interfaces';
import { useBeneficiaryInformationCtx } from '../providers/BeneficiaryInformationCtx';
import { NEW_BENEFICIARY_KEYWORD } from '../constant';
import { RespPlanTypeCd } from 'containers/AccountsStep3/types';
import DialogCloseConfirmation from './DialogCloseConfirmation';
import { getAllFieldNames, getBeneficiariesNotDeleted } from '../utils';
import { ArrowLeft } from '@nbc-design/icons/lib/web/ArrowLeft';

export type BeneficiaryModalProps = {
  handleCloseModal: Function;
  handleSubmitForm: Function;
  resetFormValidation: Function;
  resetForm: Function;
};

const BeneficiaryModal: FunctionComponent<BeneficiaryModalProps> = (
  props: BeneficiaryModalProps,
): JSX.Element | null => {
  const {
    currentBeneficiary,
    beneficiaryToEdit,
    setCurrentBeneficiary,
    setBeneficiaryFormIndex,
  } = useBeneficiaryInformationCtx();

  const {
    getText,
    getTextGlobal,
    getAccessibilityText,
    formValues,
    bncReactForm,
  } = useContainerCtx<BeneficiaryInformationProps>();

  const { touch, change, untouch, submitFailed } = bncReactForm;

  const { beneficiaries, eligibleBeneficiaries, account } = formValues;
  const { respPlanTypeCd } = account;
  const { handleCloseModal, handleSubmitForm, resetFormValidation, resetForm } = props;
  const [didSkipFirstStep, setDidSkipFirstStep] = useState<boolean>(false);
  const [showQuitDialog, setShowQuitDialog] = useState(false);
  const [isTriggerModalClose, setIsTriggerModalClose] = useState(false);

  const beneficiarySelectOptions = useMemo((): RefData[] => {
    const remainingEligibleBeneficiaries = eligibleBeneficiaries?.filter(
      (eligibleBeneficiary) =>
        !getBeneficiariesNotDeleted(beneficiaries)?.find(
          (beneficiary) => beneficiary.bncId === eligibleBeneficiary.bncId,
        ),
    );

    const myself =
      remainingEligibleBeneficiaries
        ?.filter((beneficiary) => beneficiary.isCurrentUser && account.respPlanTypeCd === RespPlanTypeCd.INDIVIDUAL)
        ?.map((beneficiary) => {
          return {
            en: {
              label: getText('myself'),
            },
            fr: {
              label: getText('myself'),
            },
            valueDomainCode: beneficiary.bncId ?? NEW_BENEFICIARY_KEYWORD,
          };
        }) ?? [];

    const others =
      remainingEligibleBeneficiaries
        ?.filter((beneficiary) => !beneficiary.isCurrentUser)
        ?.map((beneficiary) => {
          return {
            en: {
              label: `${beneficiary.firstName} ${beneficiary.lastName}`,
            },
            fr: {
              label: `${beneficiary.firstName} ${beneficiary.lastName}`,
            },
            valueDomainCode: beneficiary.bncId ?? NEW_BENEFICIARY_KEYWORD,
          };
        }) ?? [];

    return [
      {
        en: {
          label: getText('newBeneficiary'),
        },
        fr: {
          label: getText('newBeneficiary'),
        },
        valueDomainCode: NEW_BENEFICIARY_KEYWORD,
      },
      ...myself,
      ...others,
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eligibleBeneficiaries, getText, beneficiaries, account.respPlanTypeCd]);

  const handleNewBeneficiarySelection = useCallback(
    (event?: ChangeEvent<HTMLSelectElement>) => {
      if (submitFailed) {
        resetForm();
      }

      const deletedBeneficiary = beneficiaries.find(
        (beneficiary) =>
          event?.target.value === beneficiary.bncId && beneficiary.operationMode === OperationMode.DELETE,
      );

      if (deletedBeneficiary) {
        setCurrentBeneficiary(deletedBeneficiary);
        setBeneficiaryFormIndex(beneficiaries.findIndex((beneficiary) => beneficiary === deletedBeneficiary));
        change('formData', deletedBeneficiary);
        untouch(...getAllFieldNames());
      } else {
        const isNewBeneficiary = !event ? true : event?.target.value === NEW_BENEFICIARY_KEYWORD;

        let newBeneficiaryData = isNewBeneficiary
          ? ({
              bncId: NEW_BENEFICIARY_KEYWORD,
              operationMode: OperationMode.CREATE,
            } as BeneficiaryDataType)
          : eligibleBeneficiaries?.find((eligibleBeneficiary) => eligibleBeneficiary.bncId === event?.target.value);

        if (newBeneficiaryData && newBeneficiaryData?.operationMode !== OperationMode.CREATE) {
          newBeneficiaryData.operationMode = OperationMode.MODIFY;
        }

        setCurrentBeneficiary(newBeneficiaryData);
        setBeneficiaryFormIndex(beneficiaries.length);
        change('formData', newBeneficiaryData!);
        untouch(...getAllFieldNames());
      }
    },
    [
      beneficiaries,
      setCurrentBeneficiary,
      setBeneficiaryFormIndex,
      change,
      untouch,
      eligibleBeneficiaries,
      submitFailed,
      resetForm,
    ],
  );

  const handlePreviousBackButton = () => {
    if (beneficiaryToEdit || currentBeneficiary) {
      setShowQuitDialog(true);
    } else {
      resetFormValidation();
    }
  };

  const triggerModalAndDialogClose = () => {
    if (!beneficiaryToEdit && currentBeneficiary && !didSkipFirstStep) {
      setShowQuitDialog(false);
      setCurrentBeneficiary(undefined);
      resetFormValidation();
    } else {
      setIsTriggerModalClose(true);
      handleCloseModal();
    }
  };

  React.useEffect(() => {
    if (!currentBeneficiary) {
      if (beneficiaryToEdit) {
        change('formData', beneficiaryToEdit);
        setCurrentBeneficiary(beneficiaryToEdit);
        setBeneficiaryFormIndex(beneficiaries.findIndex((beneficiary) => beneficiaryToEdit === beneficiary));
        touch(...getAllFieldNames());
      } else if (
        respPlanTypeCd === RespPlanTypeCd.FAMILY &&
        beneficiarySelectOptions.length === 1 &&
        beneficiarySelectOptions.every((option) => option.valueDomainCode === NEW_BENEFICIARY_KEYWORD)
      ) {
        setDidSkipFirstStep(true);
        handleNewBeneficiarySelection();
      }
    } else if (currentBeneficiary.isCurrentUser) {
      if (currentBeneficiary.operationMode === OperationMode.DELETE) {
        currentBeneficiary.operationMode = OperationMode.MODIFY;
        change('beneficiaries', [
          ...beneficiaries.filter((beneficiary) => beneficiary.bncId !== currentBeneficiary.bncId),
          currentBeneficiary,
        ]);
        handleCloseModal();
      } else {
        change('beneficiaries', [...beneficiaries, currentBeneficiary]);
        handleCloseModal();
      }
    }
  }, [
    beneficiaries,
    beneficiarySelectOptions,
    beneficiaryToEdit,
    change,
    currentBeneficiary,
    eligibleBeneficiaries,
    handleCloseModal,
    handleNewBeneficiarySelection,
    respPlanTypeCd,
    setBeneficiaryFormIndex,
    setCurrentBeneficiary,
    touch,
    untouch,
  ]);

  return (
    <>
      <Modal
        key="beneficiary_modal"
        preventCloseClick={() => {
          if (currentBeneficiary) {
            setShowQuitDialog(true);
          } else {
            handleCloseModal();
          }
        }}
        triggerClosing={isTriggerModalClose}
        titleId="beneficiary_modal_title_id"
      >
        <Modal.Header closeBtnAriaLabel={getAccessibilityText('button.closeModal')}>
          {beneficiaryToEdit ? getText('modifyBeneficiary') : getText('addBeneficiary')}
        </Modal.Header>
        <Modal.Body
          className={`${!currentBeneficiary ? 'beneficiary-modal-select mc-p-large' : 'mc-pt-medium mc-pb-large'}`}
        >
          {!currentBeneficiary ? (
            <Row>
              <Col xs={12} sm={8}>
                <ProductFormSelect
                  label={getText('selectOrAdd')}
                  data-test={'select_beneficiaryModal'}
                  name="beneficiary_modal_select"
                  onChange={handleNewBeneficiarySelection}
                  refData={beneficiarySelectOptions}
                  placeholder={`${getText('SelectDefaultOption')}`}
                />
              </Col>
            </Row>
          ) : (
            <BeneficiaryForm cancel={() => handleCloseModal()} />
          )}
        </Modal.Body>

        {currentBeneficiary && (
          <Modal.Footer>
            <IconButton
              data-test="button_close_mobile"
              appearance="secondary"
              onClick={handlePreviousBackButton}
              aria-label={getAccessibilityText('button.closeModal')}
              className="sm:mc-d-none back-button"
              icon={<ArrowLeft title={getAccessibilityText('back')} />}
              type="button"
            ></IconButton>
            <Button
              data-test="button_close"
              appearance="secondary"
              size={'medium'}
              onClick={handlePreviousBackButton}
              aria-label={getAccessibilityText('button.closeModal')}
              className="mc-d-none sm:mc-d-block"
            >
              {getTextGlobal('button.cancel')}
            </Button>

            <Button data-test="button_continue" size={'medium'} appearance="primary" onClick={() => handleSubmitForm()}>
              {getText('button.continue')}
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      {showQuitDialog && (
        <DialogCloseConfirmation
          handleCloseClick={triggerModalAndDialogClose}
          handleCloseDialog={() => {
            setShowQuitDialog(false);
          }}
        />
      )}
    </>
  );
};

export default BeneficiaryModal;
