import { connect } from 'react-redux';
import * as actionCreators from 'services/baoService/actions';

import Layout from './Layout';
import { UserData } from '../../types/actions';

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (userData: UserData) => dispatch(actionCreators.setUserData(userData)),
  };
};

export default connect(null, mapDispatchToProps)(Layout);
