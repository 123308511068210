import React, { FunctionComponent, MouseEvent } from 'react';
import { ProductFormFieldChangeHandler } from 'types/interfaces';
import Modal from 'components/Modal/Modal';
import { FinancesComponentsProps } from '../types';
import LiabilitiesCalculator from './LiabilitiesCalculator';
import AssetsCalculator from './AssetsCalculator';
import { hasAssetsErrors, hasLiabilitiesErrors } from '../utils';
import { CALCULATOR_MODE_ASSETS, CALCULATOR_MODE_LIABILITIES } from '../constants';

export type CalculatorModalProps = FinancesComponentsProps & {
  calculatorMode: string;
  closeModal: (validated?: boolean) => void;
  change: ProductFormFieldChangeHandler;
};

const CalculatorModal: FunctionComponent<CalculatorModalProps> = (props: CalculatorModalProps) => {
  const {
    calculatorMode,

    closeModal,
    change,

    formatErrorMessage,
    getText,
    getTextGlobal,
    handleSubmit,
    form,
  } = props;

  const handleModalButtonClick = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();

    const assetsHasErrors = hasAssetsErrors(form);
    const liabilitiesHasErrors = hasLiabilitiesErrors(form);

    if (
      (calculatorMode === CALCULATOR_MODE_ASSETS && !assetsHasErrors) ||
      (calculatorMode === CALCULATOR_MODE_LIABILITIES && !liabilitiesHasErrors)
    ) {
      closeModal(true);
    } else {
      handleSubmit();
    }
  };

  const customButtonConfig = {
    text: getTextGlobal('button.confirm'),
    type: 'button',
    appearance: 'primary',
    className: 'dsc-d-none dsc-d-small-block calculator__btn-calculate',
    onClick: (e) => {
      handleModalButtonClick(e);
    },
    disabled: false,
  };

  const modalProps = {
    title: calculatorMode === CALCULATOR_MODE_ASSETS ? getText('assetCalculation') : getText('liabilityCalculation'),
    className: 'calculator-modal',
    onClose: closeModal,
    Content: (
      <div className="calculator" data-test="calculator">
        {calculatorMode === CALCULATOR_MODE_ASSETS ? (
          <AssetsCalculator formatErrorMessage={formatErrorMessage} getText={getText} change={change} form={form} />
        ) : (
          <LiabilitiesCalculator
            formatErrorMessage={formatErrorMessage}
            getText={getText}
            change={change}
            form={form}
          />
        )}
      </div>
    ),
    isCloseHidden: true,
    hasIcon: false,
    hasFooter: true,
    customButton: customButtonConfig,
  };

  return <Modal {...modalProps} name={modalProps.title} text={modalProps.title} />;
};

export default CalculatorModal;
