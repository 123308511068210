import { DECISION_ABANDON, DECISION_SUCCESS } from 'containers/SpecificsBankingInfo/constants';
import React, { FunctionComponent, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { InjectedIntl, injectIntl } from 'react-intl';

import '../../styles.scss';
import { SafeIdFinancialDataType } from '../../types';
import BankingAccountSafeIdSuccess from './BankingAccountSafeIdSuccess';
import BankingOpenSafeIdBox from './BankingOpenSafeIdBox';
import DeleteConfirmationModal from './DeleteConfirmationModal';

export type BankingAccountSafeIdProps = {
  name: string;
  change: (key: string, value: SafeIdFinancialDataType) => void;
  intl: InjectedIntl;
  title?: string;
  data: SafeIdFinancialDataType;
};

export const isDecisionPresent = (decision?: string): boolean => !!decision;
export const isDecisionSuccess = (decision?: string): boolean => decision === DECISION_SUCCESS;
export const isDecisionAbandon = (decision?: string): boolean => decision === DECISION_ABANDON;
export const isDecisionFail = (decision?: string): boolean =>
  isDecisionPresent(decision) && !isDecisionSuccess(decision) && !isDecisionAbandon(decision);
export const isSafeIdFinancialExpError = (safeIdExp: SafeIdFinancialDataType | undefined): boolean =>
  !!safeIdExp && !!safeIdExp.error;
export const isAllowSafeIdFinancialExp = (safeIdExp: SafeIdFinancialDataType | undefined): boolean =>
  (!isDecisionPresent(safeIdExp?.decision) || isDecisionSuccess(safeIdExp?.decision)) &&
  !isSafeIdFinancialExpError(safeIdExp);

const BankingAccountSafeId: FunctionComponent<BankingAccountSafeIdProps> = (props: BankingAccountSafeIdProps) => {
  const { title = '', change, name, data, intl } = props;

  const { currency, decision } = data;

  const handleOnClickAddButton = (): void => {
    data.openSession = true;
    change(name, data);
    (window as any).grecaptcha.execute();
  };

  const handleOnClickDeleteButton = (): void => {
    setShowModal(true);
  };

  const [showModal, setShowModal] = useState(false);

  const handleModalSubmit = () => {
    data.delete = true;
    change(name, data);

    setShowModal(false);
  };

  const closeModal = (): void => {
    setShowModal(false);
  };

  return (
    <div className="bankingAccountSafeId">
      {title && (
        <Row>
          <Col xs={12} md={12} lg={12}>
            {title}
          </Col>
        </Row>
      )}
      {isDecisionSuccess(decision) && (
        <BankingAccountSafeIdSuccess handleOnClickDeleteButton={handleOnClickDeleteButton} data={data} />
      )}
      {!isDecisionPresent(decision) && !isSafeIdFinancialExpError(data) && (
        <BankingOpenSafeIdBox handleOnClickAddButton={handleOnClickAddButton} currency={currency} />
      )}

      {showModal && (
        <DeleteConfirmationModal handleSubmit={handleModalSubmit} closeModal={closeModal} intl={intl} change={change} />
      )}
    </div>
  );
};

export default injectIntl(BankingAccountSafeId);
