// import { callRequest } from 'services/callRequestManager';
// import btoa from 'btoa';
// import { HttpRequest } from 'types/interfaces';
import { Token } from './utils';
// import { loadItem } from 'utils';

export interface RecaptchaSdkType {
  siteKey: string;
  exchangeTokenEndpoint: string;
  parentDiv: string;
  reCapatchaId: string | null;
  success: Function | null;
  failure: Function | null;
  timeoutRecaptcha: Function | null;
  readyCheck: number | undefined;
  initCallBacks: (success: Function, failure: Function, timeoutRecaptcha: Function) => void;
  show: () => void;
  isReadyCheck: () => boolean | HTMLElement | null;
  updateReadyState: () => void;
  displayRecaptcha: () => void;
  reset: () => void;
  exchangeToken: <S>(token: Token) => Promise<S>;
}

class RecaptchaSdk implements RecaptchaSdkType {
  siteKey: string;
  exchangeTokenEndpoint: string;
  parentDiv: string;
  reCapatchaId: string | null;
  success: Function | null;
  failure: Function | null;
  timeoutRecaptcha: Function | null;
  readyCheck: number | undefined;
  invisible: boolean;

  constructor(
    siteKey: string,
    exchangeTokenEndpoint: string,
    googleRecaptchaApiEndpoint: string,
    parentDiv: string,
    invisible = false,
  ) {
    this.siteKey = siteKey;
    this.exchangeTokenEndpoint = exchangeTokenEndpoint;
    this.parentDiv = parentDiv;
    this.reCapatchaId = null;
    this.success = null;
    this.failure = null;
    this.timeoutRecaptcha = null;
    this.readyCheck = undefined;
    this.invisible = invisible;

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = googleRecaptchaApiEndpoint;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  initCallBacks = (success: Function, failure: Function, timeoutRecaptcha: Function): void => {
    this.success = success;
    this.failure = failure;
    this.timeoutRecaptcha = timeoutRecaptcha;
  };

  show = (): void => {
    if (this.isReadyCheck()) {
      this.displayRecaptcha();
    } else {
      this.readyCheck = window.setInterval(this.updateReadyState, 1000);
    }
  };

  isReadyCheck = (): boolean | HTMLElement | null =>
    typeof window !== 'undefined' &&
    typeof (window as any).grecaptcha !== 'undefined' &&
    typeof (window as any).grecaptcha.render === 'function' &&
    document.getElementById(this.parentDiv);

  updateReadyState = (): void => {
    if (this.isReadyCheck()) {
      window.clearInterval(this.readyCheck);
      this.displayRecaptcha();
    }
  };

  displayRecaptcha = (): void => {
    const parentElement: HTMLElement | null = document.getElementById(this.parentDiv);
    //make sure it's empty (required by Google)
    if (parentElement) {
      parentElement.innerHTML = '';
    }

    const parameters = {
      sitekey: this.siteKey,
      callback: this.exchangeToken,
      'error-callback': this.failure,
      'expired-callback': this.timeoutRecaptcha,
    };

    if (this.invisible) {
      (parameters as any).size = 'invisible';
    }
    this.reCapatchaId = (window as any).grecaptcha.render(parentElement, parameters);
  };

  reset = (): void => {
    if (this.reCapatchaId !== null) {
      (window as any).grecaptcha.reset(this.reCapatchaId);
    }
  };

  exchangeToken = <S>(response): Promise<S> => {
    // const DEV_ENV = loadItem('DEV_ENV') || null;
    // const CURRENT_ENV = loadItem('CURRENT_ENV') || null;

    // if (DEV_ENV || CURRENT_ENV === 'dev') {
    return this.success && this.success(response);
    // }

    // const config: HttpRequest = {
    //   url: this.exchangeTokenEndpoint,
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json',
    //     Authorization: `Basic ${btoa(':')}`,
    //   },
    //   body: JSON.stringify({
    //     recaptcha: myToken,
    //   }),
    //   resType: 'json',
    // };

    // return callRequest<Token>(config)
    //   .then((json: Token) => {
    //     if (json.expiresIn) {
    //       const expirationTime = new Date();
    //       // Reduce the expiration time by 5 seconds to make sure that the token is still after the validation check
    //       expirationTime.setSeconds(expirationTime.getSeconds() + parseInt(json.expiresIn, 10) - 5);

    //       json.expirationTime = expirationTime;

    //       return this.success && this.success(json);
    //     } else {
    //       return this.failure && this.failure('Incorrect token');
    //     }
    //   })
    //   .catch(err => {
    //     return this.success && this.success({});
    //     // return this.failure && this.failure(err)
    //   });
  };
}

export default RecaptchaSdk;
