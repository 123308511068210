import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { Col, Row } from 'react-flexbox-grid';
import { History } from 'history';
import { Alert } from '@nbc-design/alert';
import { Heading } from '@nbc-design/heading';
import { SecureFill } from '@nbc-design/icons/lib/web/SecureFill';
import { Text } from '@nbc-design/text';
import { ROUTE } from 'utils/constants';
import { getTextFactory } from 'utils/TextUtils';
import { sendStepLoadedEvent } from 'services/analytics';
import { EVENT_IDS } from 'services/analytics/constants';
import CONFIG from './config';
import './styles.scss';
import { redirectToUnifiedLogin } from 'services/ssoRedirectService';
import { manageAutomaticRedirect } from 'services/ssoRedirectService/helper';
import { BAO_AUTHENT_TYPE, setAuthenticatedWith } from 'services/loginManager';
import { getBncId } from 'utils';

export type ClientRedirectProps = InjectedIntlProps & {
  history: History;
};

class ClientRedirect extends React.PureComponent<ClientRedirectProps> {
  constructor(props) {
    super(props);
    this.manageRedirect = this.manageRedirect.bind(this);
  }

  componentDidMount() {
    sendStepLoadedEvent(CONFIG.ID, EVENT_IDS.ST, '', {
      user: {
        bncId: getBncId(),
      },
    });
    manageAutomaticRedirect(this.props.intl.locale);
  }

  manageRedirect(event: React.MouseEvent<HTMLButtonElement>): void {
    const { history, intl } = this.props;
    const { name } = event.target as HTMLButtonElement;

    if (name === 'no-button') {
      setAuthenticatedWith(BAO_AUTHENT_TYPE);
      history.push({ pathname: ROUTE.WELCOME });
    } else {
      redirectToUnifiedLogin(intl.locale);
    }
  }

  render() {
    const { intl } = this.props;
    const getText = getTextFactory(intl, 'clientRedirect');

    return (
      <div className="client-redirect__container">
        <div className="page-title" data-test="page-title">
          <Heading type="h1" size={6}>
            {getText('pageTitle')}
          </Heading>
        </div>

        <Row>
          <Col xs={12} md={12} lg={12} data-test="question-text">
            <Heading type="h2" size={4} className="question-title">
              {getText('question')}
            </Heading>
          </Col>

          <Col xs={12} md={12} lg={12} data-test="explanation-text">
            <Text size="small" className="explanation-question">
              {getText('explanation')}
            </Text>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12} lg={12} data-test="yes-button">
            <button className="dsc-btn is-primary yes-button" name="yes-button" onClick={this.manageRedirect}>
              {getText('yesButtonText')}
            </button>
          </Col>

          <Col xs={12} md={12} lg={12} data-test="yes-button-explanation">
            <Text size="small" appearance="muted">
              {getText('yesButtonExplanation')}
            </Text>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12} lg={12} data-test="no-button">
            <button className="dsc-btn is-secondary no-button" name="no-button" onClick={this.manageRedirect}>
              {getText('noButtonText')}
            </button>
          </Col>
        </Row>

        <Row className="security-explanation-row">
          <Col xs={12} md={12} lg={12} className="security-explanation-col" data-test="security-explanation-text">
            <Alert
              title={getText('securityInformation')}
              size="small"
              icon={<SecureFill size="small" title={getText('securityInformation')} />}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default ClientRedirect;
