import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import { InjectedIntl } from 'react-intl';
import { OccupationTitle, OccupationTitleOption } from 'types/reducers';
import { FormComponentProps } from '../types';
import { Autocomplete } from '@nbc-design/autocomplete';
import { FormGroup } from '@nbc-design/form-group';
import { Text } from '@nbc-design/text';
import { getErrorMessage, getLabelText, isInvalidOnChange } from '../utils';
import { getTextFactory } from 'utils/TextUtils';
import { getLocale } from 'utils';
import AnalyticsHelper from 'services/analytics/AnalyticsHelper';

export type ProductFormOccupationSelectProps = FormComponentProps & {
  intl: InjectedIntl;
  searchOccupation: Function;
  occupationList: OccupationTitle[];
  isLoading: boolean;
  tooltip?: React.ReactNode;
};

const ProductFormOccupationSelect: FunctionComponent<ProductFormOccupationSelectProps> = (
  props: ProductFormOccupationSelectProps,
) => {
  const {
    intl,
    input,
    isLoading,
    helpText,
    tooltip,
    placeholder,
    isReadOnly,
    searchOccupation,
    occupationList = [],
    className,
  } = props;

  const formatOccupation = (occupation: OccupationTitle): string | null => {
    if (!occupation || !occupation.employmentCode) {
      return null;
    }

    return getLocale() === 'en' ? occupation?.label?.en : occupation?.label?.fr;
  };

  const handleInputChange = (event: string): void => {
    const { name } = input;
    handleChange(null);
    searchOccupation(event, { name });
  };

  const label = getLabelText(props);

  const handleChange = (suggestion: { value?: unknown } | null): void => {
    if (suggestion) {
      input.onChange && input.onChange(suggestion.value);
    } else {
      input.onChange && input.onChange(null);
    }
  };

  const getSelectOptions = (): OccupationTitleOption[] => {
    let occupationOptions: OccupationTitle[] = [];

    // Always append the current value so we can render it again when cancelling the dropdown
    if (occupationList && occupationList.length > 0) {
      occupationOptions = [...occupationList, input.value];
    } else if (input.value) {
      occupationOptions = [input.value];
    }

    return (
      (occupationOptions.length > 0 &&
        occupationOptions.map(
          (occupation: OccupationTitle): OccupationTitleOption => ({
            label: formatOccupation(occupation) || '',
            value: occupation?.employmentCode,
            data: occupation,
          }),
        )) ||
      []
    );
  };

  const invalid = isInvalidOnChange(props);
  const formattedOccupation = formatOccupation(input.value);
  const options = getSelectOptions();
  const selected = formatOccupation(input.value) || '';
  const getAccessibilityText = getTextFactory(intl, 'accessibility');
  const onShowTooltip = AnalyticsHelper.handleShowTooltip(input.name);

  return (
    <FormGroup
      className={className}
      label={{
        text: label,
        htmlFor: `occupation-autocomplete-${input.name}-form`,
      }}
      tooltip={{ content: tooltip, ariaLabel: getAccessibilityText('information'), onShowTooltip }}
      validate={{ hasError: invalid, errorMsg: getErrorMessage(props) }}
      description={{ text: helpText, id: `help-${input.name}` }}
    >
      {isReadOnly ? (
        <Text type={'span'}>{formattedOccupation}</Text>
      ) : (
        <Autocomplete
          id={`occupation-autocomplete-${input.name}`}
          isExactSearch={false}
          fuzzySearchOptions={{
            isCaseSensitive: false,
            includeScore: false,
            shouldSort: true,
            includeMatches: false,
            findAllMatches: false,
            minMatchCharLength: 1,
            location: 0,
            threshold: 0.6,
            distance: 100,
            useExtendedSearch: false,
            ignoreLocation: false,
            ignoreFieldNorm: false,
            fieldNormWeight: 1,
          }}
          name={input.name}
          value={selected}
          suggestions={options}
          isLoading={isLoading}
          placeholder={placeholder}
          onSelect={handleChange}
          onChange={handleInputChange}
          className={cx({ error: invalid })}
        />
      )}
    </FormGroup>
  );
};

export default ProductFormOccupationSelect;
