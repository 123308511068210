import React, { Fragment, FunctionComponent } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { fundingSourceOtherValue } from '../constants';
import { ProductFormSelect, ProductFormInput } from 'components/productFormFields';
import { FinancesComponentsProps } from '../types';

const RevenueSource: FunctionComponent<FinancesComponentsProps> = (props: FinancesComponentsProps) => {
  const { formatErrorMessage, getText, getTextGlobal, fundingSource } = props;

  const isOtherSourceInputVisible = fundingSource === fundingSourceOtherValue;

  return (
    <Fragment>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} data-test="field_fundingSource">
          <ProductFormSelect
            name="fundingSource"
            placeholder={getTextGlobal('select')}
            label={getText('fundingSource')}
            formatErrorMessage={formatErrorMessage}
            className="fundingSource"
          />
        </Col>
      </Row>
      {isOtherSourceInputVisible && (
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} data-test="field_fundingSourceOther">
            <ProductFormInput
              name="fundingSourceOther"
              label={getText('fundingSourceOther')}
              formatErrorMessage={formatErrorMessage}
            />
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default RevenueSource;
