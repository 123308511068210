import React from 'react';
import { render } from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';

import configureStore from 'globalRedux/configureStore';
import getLogger from 'utils/getLogger';
import Main from './components/Main';

// polyfill scrollTo, scrollIntoView, etc...
// see https://caniuse.com/#feat=element-scroll-methods
smoothscroll.polyfill();

const logger = getLogger();
logger.log(`
-------------------------------------
  ######        ###         #####
  ##   ##      ## ##      ##     ##  
  ######      #######     ##     ##
  ##   ##    ##     ##    ##     ##
  ######    ##       ##     #####
-------------------------------------
`);

const store = configureStore();

render(<Main store={store} />, document.getElementById('root'));
