import { isNullOrUndefined, isCountryCAorUS } from './';
import { AddressDetailedData, AddressErrorData, ErrorsDictionary, ErrorData } from '../types/interfaces';
import { POBOX_VALIDATION_FIELDS } from 'containers/PersonalInformation/types';

export interface GenericErrorTypes extends ErrorsDictionary {
  REQUIRED: ErrorData;
  PATTERN: ErrorData;
  DUPLICATE: ErrorData;
  ONLY_ONE: ErrorData;
  INVALIDSIN: ErrorData;
}

export const ERRORTYPE: GenericErrorTypes & ErrorsDictionary = {
  REQUIRED: { type: 'required' },
  PATTERN: { type: 'pattern' },
  DUPLICATE: { type: 'duplicate' },
  ONLY_ONE: { type: 'onlyOne' },
  INVALIDSIN: { type: 'socialInsuranceNumberError' },
};

export const requiredValidation = (values, fieldsNames: string[], isEmpty = false): ErrorsDictionary => {
  const valuesEmpty = isEmpty || isNullOrUndefined(values);
  const fieldsErrors: ErrorsDictionary = {};
  fieldsNames.forEach((name) => {
    const isFieldEmpty: boolean =
      valuesEmpty || (typeof values[name] === 'boolean' ? isNullOrUndefined(values[name]) : !values[name]);
    if (isFieldEmpty) fieldsErrors[name] = ERRORTYPE.REQUIRED;
  });
  return fieldsErrors;
};

export const isEmptyAddress = (address?: AddressDetailedData): boolean => {
  const { city, country, postalCode, province, streetName, streetNumber } = address || ({} as AddressDetailedData);
  return !address || (!streetName && !country && !province && !streetNumber && !city && !postalCode);
};

export const isPOBOX = (address?: AddressDetailedData): ErrorsDictionary => {
  const blacklist = ['po box', 'pobox'];
  const errors: ErrorsDictionary = {};
  Object.keys(address || ({} as AddressDetailedData)).forEach((k) => {
    const key = k as keyof typeof POBOX_VALIDATION_FIELDS;
    if (blacklist.some((word) => String(address?.[key]).toLowerCase()?.includes(word.toLowerCase()))) {
      errors[key] = ERRORTYPE.PATTERN;
    }
  });
  return errors;
};

export const addressValidation = (address?: AddressDetailedData): AddressErrorData | null => {
  const addressFieldsNames: string[] = ['city', 'country', 'postalCode', 'streetName', 'streetNumber'];
  const { country = '', province }: AddressDetailedData = address || {};
  const isEmpty: boolean = isEmptyAddress(address);

  const addressError: AddressErrorData = {
    ...requiredValidation(address, addressFieldsNames, isEmpty),
    ...(isCountryCAorUS(country) && !province ? { province: ERRORTYPE.REQUIRED } : {}),
  };

  return Object.keys(addressError).length ? addressError : null;
};
