import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { getTextFactory } from 'utils/TextUtils';
import { InjectedIntl } from 'react-intl';
import { createErrorFormatter } from 'utils/productFormUtils';
import { ProductForm, ProductFormFieldChangeHandler, Properties } from 'types/interfaces';
import { ProductFormCustomContentRadioSelect } from 'components/productFormFields';
import { Card } from '@nbc-design/card';
import { AccountDataType } from '../../SpecificsBankingInfo/types';
import BankRef from 'containers/IdentityVerification/components/BankRef';
import get from 'lodash/get';

type ValidAlternateProps = {
  intl: InjectedIntl;
  change: ProductFormFieldChangeHandler;
  isHidden?: boolean;
  bankingAccountSchema: { properties: Properties };
  productForm: ProductForm;
};

const ValidAlternate: FunctionComponent<ValidAlternateProps> = (props: ValidAlternateProps) => {
  const { intl, change, isHidden, bankingAccountSchema, productForm } = props;
  const getText = getTextFactory(intl, 'identityValidation');
  const getBankText = getTextFactory(intl, 'identityVerification');
  const formatMessageError = () => createErrorFormatter(intl);

  const firstParticipantData: AccountDataType = get(
    productForm,
    'values.identityCheckForm.firstParticipantBankAccount',
    undefined,
  );
  const secondParticipantData: AccountDataType = get(
    productForm,
    'values.identityCheckForm.secondParticipantBankAccount',
    undefined,
  );

  const customContentDictionary: ReactNode = useMemo(() => {
    return {
      CLRDCHECK: {
        description: [<div className="long-description first">{getText('OptionClearedCheckDescription')}</div>],
      },
      BANKREF: {
        description: [],
        customContent: (
          <>
            <BankRef
              description={
                secondParticipantData ? getText('OptionBankRefTitleFirstParticipant') : getText('OptionBankRefTitle')
              }
              bankingAccountSchema={bankingAccountSchema}
              intl={intl}
              change={change}
              name="identityCheckForm.firstParticipantBankAccount"
              data={firstParticipantData}
            />
            {secondParticipantData && (
              <BankRef
                description={getText('OptionBankRefTitleSecondParticipant')}
                bankingAccountSchema={bankingAccountSchema}
                intl={intl}
                change={change}
                name="identityCheckForm.secondParticipantBankAccount"
                data={secondParticipantData}
              />
            )}
          </>
        ),
      },
    };
  }, [getText, change, intl, bankingAccountSchema, firstParticipantData, secondParticipantData]);

  const informativeTexts = [
    ...getBankText('BANKREF.description').split('\n'),
    ...getBankText('BANKREF.collapseText').split('\n'),
  ].map((line, index) => <li key={index}>{line}</li>);

  return !isHidden ? (
    <Row className="us-sources" data-test="us-sources-content">
      <Col xs={12} md={12} lg={12}>
        <ProductFormCustomContentRadioSelect
          isCombo={true}
          label=""
          name="identityCheckForm.indvIdentMethod"
          customContentDictionary={customContentDictionary}
          formatErrorMessage={formatMessageError()}
        />
        <Card>
          <ul>{informativeTexts}</ul>
        </Card>
      </Col>
    </Row>
  ) : (
    <div />
  );
};

export default ValidAlternate;
