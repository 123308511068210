import { RespPlanTypeCd } from 'containers/AccountsStep3/types';
import { ERRORTYPE } from '../../utils/validation';
import {
  BeneficiaryFormValues,
  BeneficiaryInformationErrorsDictionary,
  BeneficiaryInformationValidationReturnType,
  FormValidationType,
} from './types';
import {
  areAllContributionFieldsEmpty,
  calculatedTotalContribution,
  validateBeneficiary,
  getBeneficiariesNotDeleted,
} from './utils';

export const BENEFICIARY_INFORMATION_ERRORTYPE: BeneficiaryInformationErrorsDictionary = {
  ...ERRORTYPE,
  MUST_BE_UNDER_21: { type: 'mustBeUnder21' },
};

const validation = ({
  account,
  formData,
  beneficiaries,
}: BeneficiaryFormValues): BeneficiaryInformationValidationReturnType => {
  let validationErrors: FormValidationType = {};
  const beneficiariesToValidate = getBeneficiariesNotDeleted(beneficiaries);
  if (!!formData) {
    return validateBeneficiary(formData, beneficiaries, account?.respPlanTypeCd);
  }

  if (!formData && account?.respPlanTypeCd === RespPlanTypeCd.FAMILY) {
    validationErrors = {
      ...(!areAllContributionFieldsEmpty(beneficiariesToValidate) &&
      calculatedTotalContribution(beneficiariesToValidate) !== 100
        ? { contribution: ERRORTYPE.PATTERN }
        : {}),
      ...(beneficiariesToValidate?.some((beneficiary) => beneficiary.hasIncompleteInfo)
        ? { hasIncompleteInfo: ERRORTYPE.PATTERN }
        : {}),
      ...(!beneficiariesToValidate?.length && { beneficiaries: ERRORTYPE.REQUIRED }),
    };
  }

  if (!formData && account?.respPlanTypeCd === RespPlanTypeCd.INDIVIDUAL) {
    validationErrors = {
      ...(beneficiariesToValidate.some((beneficiary) => beneficiary.hasIncompleteInfo) && {
        hasIncompleteInfo: ERRORTYPE.PATTERN,
      }),
      ...(beneficiariesToValidate?.[0]?.requestGovGrt === undefined && {
        beneficiaries: [{ requestGovGrt: ERRORTYPE.REQUIRED }],
      }),
      ...(!beneficiariesToValidate.length && { beneficiaries: ERRORTYPE.REQUIRED }),
      ...(beneficiariesToValidate.length > 1 && { beneficiaries: ERRORTYPE.PATTERN }),
    };
  }

  return {
    ...(Object.keys(validationErrors).length ? { ...validationErrors, _error: { ...validationErrors } } : {}),
  };
};

export default validation;
