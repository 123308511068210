import { injectIntl } from 'react-intl';
import { formValues } from 'bnc-react-forms';
import AccountsStep1 from './AccountsStep1';
import config from './config';
import * as validation from './validation';

export const VALIDATION = validation;
export const CONFIG = config;

export default injectIntl(
  formValues(
    'clientType',
    'accountsIndiv',
    'accountsJoint',
    'fhsaEligible',
    'MGN-display-section',
    'MGNS-display-section',
    'CASH-display-section',
  )(AccountsStep1),
);
