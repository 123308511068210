import { emailRegex, nameFormatRegex, passwordRegex } from 'utils/regex';
import { ERRORTYPE } from 'utils/validation';
import { convertDateStringValue, validateDateFields } from 'components/productFormFields/utils';
import { Props as DateFieldsProps } from '@nbc-design/date-fields';

type getFieldsValidationParamsType = {
  fieldName: string;
  value: string;
};

type getFieldsValidationReturnType = {
  formErrors: {
    firstName?: string;
    lastName?: string;
    email?: string;
    password?: string;
    birthDate?: string;
    language?: string;
  };
};

type InvalidDateFields = {
  hasErrorYear: boolean;
  hasErrorMonth: boolean;
  hasErrorDay: boolean;
};

export const getDateFieldsErrors = (
  value: string,
  validate: boolean,
  dateFieldsOptions?: Pick<DateFieldsProps, 'display'>,
): InvalidDateFields => {
  return validateDateFields(convertDateStringValue(value), validate, dateFieldsOptions);
};

export const getFieldsValidation = ({
  fieldName,
  value,
}: getFieldsValidationParamsType): getFieldsValidationReturnType => {
  let customFormErrors = {};

  if (fieldName !== 'middleName' && value.length === 0) {
    customFormErrors[fieldName] = ERRORTYPE.REQUIRED.type;
  } else if (value.length === 1) {
    customFormErrors[fieldName] = ERRORTYPE.PATTERN.type;
  } else {
    switch (fieldName) {
      case 'title':
        customFormErrors[fieldName] = '';
        break;
      case 'firstName':
      case 'lastName':
        customFormErrors[fieldName] = !nameFormatRegex.test(value) ? ERRORTYPE.PATTERN.type : '';
        break;
      case 'middleName':
        value.length > 1 && (customFormErrors[fieldName] = !nameFormatRegex.test(value) ? ERRORTYPE.PATTERN.type : '');
        break;
      case 'email':
        customFormErrors[fieldName] = !emailRegex.test(value) ? ERRORTYPE.PATTERN.type : '';
        break;
      case 'password':
        customFormErrors[fieldName] = !passwordRegex.test(value) ? ERRORTYPE.PATTERN.type : '';
        break;
      case 'birthDate':
        const dateErrors = getDateFieldsErrors(value, true);
        const hasErrors = Object.values(dateErrors).find((value) => value);
        customFormErrors[fieldName] = hasErrors ? ERRORTYPE.PATTERN.type : '';
        break;
      default:
        break;
    }
  }

  return {
    formErrors: customFormErrors,
  };
};
