import sessionManager from 'bnc-react-services/managers/SessionManager';
import omit from 'lodash/omit';
import get from 'lodash/get';
import { UAParser } from 'ua-parser-js';
import Configs from '../configs';
import * as RootConfigs from 'configs';
import { getUserDeviceType, loadSession, loadToken } from 'services/loginManager';
import { BAO_FORMS, RECAPTCHA_KEY, RECAPTCHA_SITE_KEY_TYPE } from 'utils/constants';
import { InjectedIntl } from 'react-intl';
import {
  AllRefData,
  Definitions,
  ErrorMessage,
  Meta,
  Payload,
  ProductForm,
  Properties,
  RefData,
  RefDataContainer,
  Schema,
} from 'types/interfaces';
import { getCaptchaResponse, getLocale, removeCaptchaResponse } from 'utils';

export const FORM_ID = BAO_FORMS;

export const BEARER = 'Bearer ';

/**
 * Headers to be excluded from request to productForms.
 * Mainly because they are not allowed and/or handled by DataPower.
 */
const EXCLUDED_HEADERS = ['X-Partner-Id', 'Authorization'];

export type HeadersType = { [key: string]: string | null };

export const getHeaders = (): HeadersInit => omit(sessionManager.getRequestHeaders(), EXCLUDED_HEADERS);

export const getBaoHeaders = (): HeadersInit => {
  const token = loadToken();
  const session = loadSession();
  const parser = getUserAgentParser();

  const headers: Record<string, string> = {
    BAO_Language: getLocale(),
    BAO_Device_Info: getUserDeviceType(parser),
  };

  if (token) {
    headers['Authorization'] = BEARER + token;
  }

  if (session) {
    headers['BAO_Session_Token'] = session;
  }

  const captchaResponse = getCaptchaResponse();
  if (captchaResponse) {
    headers[RECAPTCHA_KEY] = captchaResponse;
    headers[RECAPTCHA_SITE_KEY_TYPE] = RootConfigs.getConfig().CAPTCHA.SITE_KEY_TYPE_SAFEID;
    removeCaptchaResponse();
  }

  return headers;
};

export const getBFFHeaders = (): HeadersInit => {
  return {
    ...getHeaders(),
    ...getBaoHeaders(),
  };
};

export const getUserAgentParser = (): UAParser => {
  return new UAParser();
};

export const setupRequest: (
  form: string,
) => {
  endpoint: string;
  request?: RequestInit;
} = (form: string) => {
  const endpoint: string = Configs.params.API.ENDPOINT.PRODUCT_FORMS.ENDPOINT; // This configuration is always taken from Common.json, since we don't yet know the environment we're on.

  return {
    endpoint,
    request: {
      headers: getBFFHeaders(),
    },
  };
};

export type ErrorFormatterReturnType = (error: ErrorMessage) => string;
export const createErrorFormatter = (
  intl: InjectedIntl,
  prefix?: string,
  fieldName?: string,
): ErrorFormatterReturnType => (error: ErrorMessage): string => {
  const { message, type, schema: values } = error;

  let messageId = '';

  if (fieldName) {
    messageId = `error.${prefix}.${fieldName}.${type}`;
  }

  if (!messageId || !(messageId in intl.messages)) {
    messageId = `error.${prefix}.${type}`;
  }

  if (!prefix || !(messageId in intl.messages)) {
    messageId = `error.${type}`;
  }

  return intl.formatMessage(
    {
      id: messageId,
      defaultMessage: message,
    },
    values,
  );
};

export const createAddressErrorFormatter = (intl: InjectedIntl): ErrorFormatterReturnType => (
  addressError: ErrorMessage,
): string => {
  if (addressError.type) {
    return intl.formatMessage({
      id: `error.${addressError.type}`,
    });
  }

  let error = {} as ErrorMessage;

  Object.keys(addressError).forEach((key: string): string => (error = addressError[key]));

  return intl.formatMessage({
    id: `error.${error.type}`,
  });
};

export const createStockSymbolFormatter = (intl: InjectedIntl): ErrorFormatterReturnType => (
  symbolError: ErrorMessage,
): string => {
  let error = {} as ErrorMessage;

  Object.keys(symbolError).forEach((key: string): string => (error = symbolError[key]));

  return intl.formatMessage({
    id: `error.${error.type}`,
  });
};

export const createMainOccupationAddressErrorFormatter = (
  intl: InjectedIntl,
): ErrorFormatterReturnType => (): string => {
  return intl.formatMessage({
    id: `error.addressMainOccupation.required`,
  });
};

type getAllRefDataType = { productForm: ProductForm; schema?: Schema; refData?: AllRefData };

export const getAllRefData = ({ productForm: { schema } }: getAllRefDataType): AllRefData => {
  const properties = schema.properties || {};
  const definitions = schema.definitions || {};

  const all: AllRefData = {};

  const extractRefData = (structure: Properties | Schema): void => {
    Object.keys(structure).forEach((key: string): void => {
      const refData: RefData[] | undefined = structure[key].refData;
      if (refData) {
        all[key] = refData;
      }
    });
  };

  extractRefData(schema);
  extractRefData(properties);

  Object.keys(definitions).forEach((key: string): void => {
    const dataProperties: Properties = definitions[key].properties || {};

    extractRefData(dataProperties);
  });

  return all;
};

export const findNested = (
  obj: Payload | Schema | Definitions | Properties,
  key: string,
  accumulator?: RefDataContainer[],
): RefDataContainer[] => {
  if (!Array.isArray(accumulator)) {
    accumulator = [];
  }

  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (property === key && obj[property].refData) {
        accumulator.push(obj[property]);
        break;
      } else if (Array.isArray(obj[property]) || typeof obj[property] === 'object') {
        findNested(obj[property], key, accumulator);
      }
    }
  }

  return accumulator;
};

export const getRefData = ({ productForm, schema, refData }: getAllRefDataType, ref: string): RefData[] => {
  if (refData && refData[ref]) return refData[ref];

  const schemaObject = get(productForm, 'schema', schema);
  const item = schemaObject && findNested(schemaObject, ref);

  if (item && item.length > 0 && item[0].refData) {
    return item[0].refData;
  }

  return [];
};

export const getLabel = (refData: RefData[], lang: string, code: string): string => {
  const data = (refData || []).find(({ valueDomainCode }: RefData): boolean => valueDomainCode === code);
  return data ? data[lang].label : '';
};

/**
 * Checks wether a field is required according to the given `schema`, by it's name.
 * @param schema The schema of the form
 * @param fieldName The name of the field to check
 */
export const isFieldRequired = (schema: Schema | undefined, fieldName: string): boolean => {
  if (!schema || !schema.required) {
    return false;
  }
  return schema.required.includes(fieldName);
};

/**
 * Gets the minimal length of the field according to the given `schema`, by it's name.
 * @param schema The schema of the form
 * @param fieldName The name of the field
 * @returns the minimal number of characters, or "undefined" if none.
 */
export const getFieldMinLength = (schema: Schema | undefined, fieldName: string): number | undefined => {
  return get(schema, ['properties', fieldName, 'minLength']);
};

/**
 * Gets the maximal length of the field according to the given `schema`, by it's name.
 * @param schema The schema of the form
 * @param fieldName The name of the field
 * @returns the maximal number of characters, or "undefined" if none.
 */
export const getFieldMaxLength = (schema: Schema | undefined, fieldName: string): number | undefined => {
  return get(schema, ['properties', fieldName, 'maxLength']);
};

/**
 * Gets a collection of RefData according to the given `schema`, by it's name.
 * @param schema The schema of the form
 * @param fieldName The name of the field
 * @returns the RefData array, or undefined if
 */
export const getRefDataForField = (schema: Schema | undefined, fieldName: string): RefData[] | undefined => {
  return get(schema, ['properties', fieldName, 'refData'], undefined);
};

export const isTrueValue = (fieldValue?: string): boolean => {
  if (!fieldValue) {
    return false;
  }
  return /true/i.test(fieldValue);
};

export const isFieldDisabled = (schema: Schema | undefined, fieldName: string): boolean => {
  return isTrueValue(get(schema, ['properties', fieldName, 'disabled']));
};

export const isJointMenu = (metaData: Meta): boolean => {
  return metaData && metaData.displayMenuJoint !== undefined && metaData.displayMenuJoint;
};
