import BaoServiceKeys from 'services/baoService/actionTypes';
import {
  BlockPreviousStepsTypeAction,
  LoginTypeAction,
  LogoutTypeAction,
  SetSubmitMessageIndexTypeAction,
  SetUserCredentialsAction,
  SetUserTypeAction,
  UpdatePersonalInfosFormTypeAction,
  UserCredentials,
  UserData,
} from 'types/actions';

export const login = (token: string): LoginTypeAction => {
  return { type: BaoServiceKeys.LOGIN, token };
};

export const logout = (): LogoutTypeAction => {
  return { type: BaoServiceKeys.LOGOUT };
};

export const setSubmitMessageIndex = (submitMessageIndex: number): SetSubmitMessageIndexTypeAction => {
  return { type: BaoServiceKeys.SET_SUBMIT_MESSAGE_INDEX, submitMessageIndex };
};

export const setUserData = (userData: UserData): SetUserTypeAction => {
  return { type: BaoServiceKeys.SET_USER_DATA, userData };
};

export const setUserCredentials = (userCredentials: UserCredentials): SetUserCredentialsAction => {
  return { type: BaoServiceKeys.SET_USER_CREDENTIALS, userCredentials };
};

export const blockPreviousSteps = (): BlockPreviousStepsTypeAction => {
  return { type: BaoServiceKeys.BLOCK_PREVIOUS_STEPS };
};

export const updatePersonalInfosForm = (): UpdatePersonalInfosFormTypeAction => {
  return { type: BaoServiceKeys.UPDATE_PERSONAL_INFOS_FORM };
};
