import React, { FunctionComponent } from 'react';
import { InjectedIntl } from 'react-intl';
import { getTextFactory } from 'utils/TextUtils';
import Phone from 'assets/icons/phone';
import { ConfigData } from 'types/interfaces';

export type HelpProps = {
  intl: InjectedIntl;
  config: ConfigData;
};

const Help: FunctionComponent<HelpProps> = (props: HelpProps) => {
  const { intl, config } = props;

  if (!config.BUTTON_HELP) {
    return null;
  }

  const getGlobalText = getTextFactory(intl, 'global');

  return (
    <div className="help" data-test="help">
      <Phone className={''} />

      <div>
        <p className="label">{getGlobalText('helpLabel')}</p>
        <p className="phone">{getGlobalText('helpPhone')}</p>
      </div>
    </div>
  );
};

export default Help;
