import React, { ChangeEvent, FunctionComponent, ReactNode } from 'react';
import { Card, Interactive } from '@nbc-design/card';
import { Fieldset } from '@nbc-design/fieldset';
import { Radio } from '@nbc-design/radio';
import { Text } from '@nbc-design/text';

import { InjectedIntl } from 'react-intl';
import { RefData } from 'types/interfaces';
import { FormComponentProps, OptionsType } from '../types';
import { getLabelText, getErrorMessage, isInvalidOnChange, getSchemaOptions, getOptionsReadOnlyLabel } from '../utils';
import { getTextFactory } from 'utils/TextUtils';
import AnalyticsHelper from 'services/analytics/AnalyticsHelper';

import './style.scss';

export type ProductFormCardProps = FormComponentProps & {
  intl: InjectedIntl;
  options: OptionsType[];
  data: RefData[];
  subLabel: string;
  tooltip?: React.ReactNode;
};

/**
 * This component is not the same than the original ProductFormCardSelect,
 * we don't need the slider feature.
 */
const ProductFormCard: FunctionComponent<ProductFormCardProps> = (props: ProductFormCardProps) => {
  const { helpText = '', input, schema, tooltip, options, data, subLabel, intl } = props;

  const cardOptions: OptionsType[] = options || getSchemaOptions(props);
  const cardData: RefData[] = data || (schema && schema.refData) || [];

  const invalid = isInvalidOnChange(props);

  const label = getLabelText(props);
  const getAccessibilityText = getTextFactory(intl, 'accessibility');
  const onShowTooltip = AnalyticsHelper.handleShowTooltip(input.name);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (input.onChange) {
      input.onChange(event);
    }
  };

  const cards: ReactNode = cardData.map(
    ({ valueDomainCode }: RefData): ReactNode => {
      // Replace all space in number by non-breaking space.
      const selectReadOnlyLabels = getOptionsReadOnlyLabel(cardOptions, valueDomainCode);
      const cardLabel = !selectReadOnlyLabels
        ? ''
        : selectReadOnlyLabels
            .replace(/ 0/g, '\xa00')
            .replace(/ 9/g, '\xa09')
            .replace(/ \$/g, '\xa0$')
            .replace(/\$ /g, '$\xa0');

      return (
        <Card key={valueDomainCode}>
          <Interactive>
            <Radio
              id={`radio-${input.name}-${valueDomainCode}`}
              label={cardLabel}
              name={`productform-card-${input.name}-${valueDomainCode}`}
              onChange={handleChange}
              checked={input.value.toString() === valueDomainCode.toString()}
              value={valueDomainCode.toString()}
            />
          </Interactive>
        </Card>
      );
    },
  );

  return (
    <Fieldset
      legend={label}
      tooltip={{ content: tooltip, ariaLabel: getAccessibilityText('information'), onShowTooltip }}
      validate={{ hasError: invalid, errorMsg: getErrorMessage(props) || '' }}
      description={{ text: helpText, id: 'dscFieldset__description' }}
    >
      {subLabel && <Text type={'p'}>{subLabel}</Text>}
      <div className="product-form-cards">{cards}</div>
    </Fieldset>
  );
};

export default ProductFormCard;
