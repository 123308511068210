import React, { FunctionComponent, ReactNode } from 'react';
import { InjectedIntl } from 'react-intl';
import { ProductFormFieldChangeHandler, Schema } from 'types/interfaces';
import BankingAccount from '../../SpecificsBankingInfo/components/BankingAccount';
import { Heading } from '@nbc-design/heading';
import { AccountDataType } from '../../SpecificsBankingInfo/types';

export type BankRefProps = {
  description: ReactNode;
  bankingAccountSchema: Schema;
  change: ProductFormFieldChangeHandler;
  intl: InjectedIntl;
  name?: string;
  data?: AccountDataType;
};

const BankRef: FunctionComponent<BankRefProps> = (props: BankRefProps) => {
  const { description, bankingAccountSchema, change, intl, name, data } = props;

  const bankingAccountProps = {
    name: name || 'bankingAccount',
    input: {
      name: name || 'bankingAccount',
    },
    customSchema: bankingAccountSchema,
    change,
    intl,
    data,
  };

  return (
    <div className="bank-reference">
      <Heading type="h3" size={5}>
        {description}
      </Heading>
      <BankingAccount {...bankingAccountProps} />
    </div>
  );
};

export default BankRef;
