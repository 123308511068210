import React, { FC, ReactNode, useMemo, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Alert } from '@nbc-design/alert';
import { AttentionFilledColor } from '@nbc-design/icons/lib/web/AttentionFilledColor';
import { SecureFill } from '@nbc-design/icons/lib/web/SecureFill';
import get from 'lodash/get';
import { getTextFactory } from 'utils/TextUtils';
import { createErrorFormatter, isJointMenu } from 'utils/productFormUtils';
import { sendTechnicalErrorAnalyticsEvent } from 'services/analytics';
import { GenericProps, Properties } from 'types/interfaces';
import Title from 'components/Title';
import IdentityWrapper from './components/IdentityWrapper';
import SafeIdConsent from './components/SafeIdConsent';
import ValidAlternate from './components/ValidAlternate';
import DocuSignBox from './components/DocuSignBox';
import { ProductFormCustomContentRadioSelect } from 'components/productFormFields';
import Captcha from 'components/Captcha';
import { saveCaptchaResponse } from 'utils';
import { ALTERNATE, ONLINE } from 'containers/ESign/constants';
import { DECISION_SUCCESS, DECISSION_FAILURE } from './constants';
import { SiteKeyType } from 'components/Captcha/Captcha';

import './styles.scss';

type IdentityValidationProps = GenericProps & {
  handleSubmit: Function;
  indvIdentPrefMethod: string;
  submit: Function;
};

const IdentityValidation: FC<IdentityValidationProps> = (props: IdentityValidationProps) => {
  const { intl, productForm, change, submit, handleSubmit, indvIdentPrefMethod, meta } = props;

  const getText = getTextFactory(intl, 'identityValidation');
  const getAccessibilityText = getTextFactory(intl, 'accessibility.icon');
  const buttonText = getTextFactory(intl, 'global.button');
  const formatMessageError = () => createErrorFormatter(intl);

  const bankingAccountSchema: { properties: Properties } = get(productForm, 'schema.definitions.BankingAccountUI');
  const indvIdentPrefMethodRefData = get(productForm, 'schema.properties.indvIdentPrefMethod.refData', []);

  const isSafeIdAvailable = indvIdentPrefMethodRefData.some((method) => method.valueDomainCode === 'ONLINE');

  const safeIdUrl: string = get(productForm, 'values.safeIdUrl');
  const decision: string | undefined = get(productForm, 'values.decision');

  // log an error when !safeIdUrl && (DECISSION_FAILURE === decision || safeID is down)
  // note: don't forget the cleanup on the stash :)
  safeIdUrl && window.location.assign(safeIdUrl);

  React.useEffect(() => {
    decision === DECISION_SUCCESS && submit();
  }, [decision, submit]);

  const [currentMethod, setCurrentMethod] = useState(indvIdentPrefMethod);
  const [technicalErrorSent, setTechnicalErrorSent] = useState(false);

  const continueAction = () => {
    if (currentMethod === ONLINE) {
      (window as any).grecaptcha.reset();
      (window as any).grecaptcha.execute();
    } else {
      handleSubmit();
    }
  };

  const handleCaptchaSuccess = (token) => {
    saveCaptchaResponse(token);
    handleSubmit();
  };

  const handlePrefMethodChange = () => {
    if (currentMethod === ONLINE) {
      setCurrentMethod(ALTERNATE);
    } else {
      setCurrentMethod(ONLINE);
    }
  };

  const isSafeIdFailed = decision === DECISSION_FAILURE;

  useMemo<null>(() => {
    let msg = '';
    switch (decision) {
      case DECISION_SUCCESS:
        msg = '';
        break;
      case DECISSION_FAILURE:
        msg = 'safeIdFailure';
        break;
      case undefined:
        msg = indvIdentPrefMethod === ALTERNATE && technicalErrorSent === false ? 'safeIdDown' : '';
        break;
      default:
        break;
    }

    if (msg !== '') {
      sendTechnicalErrorAnalyticsEvent(msg);
      setTechnicalErrorSent(true);
    }

    return null;
  }, [decision, technicalErrorSent, indvIdentPrefMethod]);

  const warningTitleKey = isSafeIdFailed ? 'safeIdFailure' : 'safeIdDown';
  const warningDescriptionKey = isSafeIdFailed ? 'safeIdFailureDescription' : 'safeIdUnavailableDescription';

  const customContentDictionary: ReactNode = useMemo(() => {
    return {
      ONLINE: {
        description: [
          <div key="description-online" className="long-description first">
            {getText('onlineDescription')}
          </div>,
        ],
        customContent: <SafeIdConsent intl={intl} change={change} key="safeIdConsent" />,
      },
      ALTERNATE: {
        description: [
          <div key="description-online" className="long-description first">
            {getText('otherDescription')}
          </div>,
        ],
        customContent: (
          <ValidAlternate
            key="validAlternate"
            intl={intl}
            change={change}
            bankingAccountSchema={bankingAccountSchema}
            productForm={productForm}
          />
        ),
      },
    };
  }, [intl, change, getText, bankingAccountSchema, productForm]);

  const isMultiHolderMode = isJointMenu(meta);

  return (
    <div className="identity-validation-form">
      <Title label={getText('pageTitle')} subTitle={getText('subTitle')} tabTitle={getText('tabTitle')} />
      <Row className="security-explanation-row">
        <Col xs={12} md={12} lg={12} className="security-explanation-col" data-test="security-explanation-text">
          <Alert
            title={getText('securityInformation')}
            size="small"
            icon={<SecureFill size="small" title={getText('securityInformation')} />}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={12}>
          <IdentityWrapper
            title={getText('validateTitle')}
            subTitle={getText('validateDescription')}
            continueText={buttonText('continue')}
            iconName="selfie"
            hasButton={true}
            buttonType="button"
            buttonAction={continueAction}
          >
            {!isSafeIdAvailable && !isMultiHolderMode && (
              <Alert
                key="safeIdConsent-readOnly"
                icon={<AttentionFilledColor size="small" title={getAccessibilityText('alert')} />}
                title={getText(warningTitleKey)}
                description={getText(warningDescriptionKey)}
                appearance="warning"
                className="alert-fail-warning"
              />
            )}
            <ProductFormCustomContentRadioSelect
              label=""
              name="indvIdentPrefMethod"
              customContentDictionary={customContentDictionary}
              isContentInside
              formatErrorMessage={formatMessageError()}
              onChange={handlePrefMethodChange}
            />
          </IdentityWrapper>
          <DocuSignBox title={getText('signer')} />
          <Captcha
            siteKeyType={SiteKeyType.safeid}
            success={handleCaptchaSuccess}
            timeout={() => {}}
            failure={() => {}}
            invisible
          />
        </Col>
      </Row>
    </div>
  );
};

export default IdentityValidation;
