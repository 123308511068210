import { ErrorData } from 'types/interfaces';
import { AccountData, AccountsStep1FormData, CurrencyData } from './types';
import { isAccountOfTypeSelected } from './utils';
import { ACCOUNT_TYPE, CLIENT_TYPE } from './constants';
import { CURRENCIES } from 'utils/constants';
import { makeItInvalid, makeItValid } from 'utils/domUtils';
import { fromState } from 'globalRedux/store';

const getCurrencies = (accounts: AccountData[]): Array<Array<boolean>> => {
  return accounts.map(
    ({ values = [] }: AccountData): Array<boolean> => {
      const currencies: Array<boolean> = values.map(({ value }: CurrencyData): boolean => value);
      return values && currencies;
    },
  );
};

const validation = ({
  clientType,
  accountsIndiv,
  accountsJoint,
  fhsaEligible,
}: AccountsStep1FormData): { _error: ErrorData } | null => {
  // This verification is temporary. Since ProductForm is still in JS. There is a weird case
  // where the type is not respected when we use the gotostep.
  if (!Array.isArray(accountsIndiv) || !Array.isArray(accountsJoint)) {
    return null;
  }

  let accounts: Array<Array<boolean>> = [];
  if (clientType === CLIENT_TYPE.INDIV) {
    accounts = getCurrencies(accountsIndiv);
  } else if (clientType === CLIENT_TYPE.JOINT) {
    accounts = getCurrencies(accountsJoint);
  }

  const clientIndividualType = clientType === CLIENT_TYPE.INDIV;
  const hasFhsaAccount = isAccountOfTypeSelected(accountsIndiv, ACCOUNT_TYPE.FHSA, CURRENCIES.CAD);
  const fhsaEligibleCheckbox = '#input-fhsaEligible';

  const isTouched = fromState<boolean, null>('reduxFormReducer.baoForms.anyTouched', false);
  const isSubmitted = fromState<boolean, null>('reduxFormReducer.baoForms.submitFailed', false);

  const isSubmittedForm = isTouched && isSubmitted;

  if (clientIndividualType && hasFhsaAccount) {
    if (!fhsaEligible) {
      isSubmittedForm && makeItInvalid(fhsaEligibleCheckbox);
      return { _error: { type: 'fhsaEligible.required' } };
    } else {
      makeItValid(fhsaEligibleCheckbox);
    }
  }

  const isValid: boolean = !!([] as any[]).concat(...accounts).filter((value: boolean): boolean => value).length;

  return isValid ? null : { _error: { type: 'oneAccountRequired' } };
};

export default validation;
