import React, { Fragment, ReactNode } from 'react';
import { getTextFactory } from 'utils/TextUtils';
import { deleteAuthent } from 'services/loginManager';
import { BOT_MANAGER_URLS, ROUTE, URLS } from 'utils/constants';
import ErrorIcon from 'assets/icons/error';

import { InjectedIntl } from 'react-intl';
import { AppErrorData } from 'types/interfaces';

import './styles.scss';
import { getCurrentEnv, signOutUnifiedLogin } from 'services/ssoRedirectService/oktaService';
import { redirectToUnifiedLogin } from 'services/ssoRedirectService';

export enum ERRORCODE {
  NETWORK_ERROR = 0,
  FORBIDDEN = 403, // used juste with BFF pages
  BOT_MANAGER = 403, // used juste with PendingRequest and NewAccountRequest
  CONFLICT = 409,
  INTERNAL_SERVER_ERROR = 500,
}

const botManagerErrorCode = 'AK000001';
export const botManagerDefaultErrorId = '18.1c353217.1581048110.1ca821';

interface BotManagerError {
  errorId: string;
  errorCode: string;
}

export interface ErrorResponse {
  status: ERRORCODE;
  body?: BotManagerError;
  error?: BotManagerError;
}

export const alternateContentRedirect = (intl: InjectedIntl, response: ErrorResponse): void => {
  const environment = getCurrentEnv();
  const language = intl.locale.toUpperCase();

  const error = response.error || response.body;
  const errorId = error && error.errorCode === botManagerErrorCode ? error.errorId : botManagerDefaultErrorId;

  window.location.href = `${BOT_MANAGER_URLS[environment][language]};ref=${errorId}`;
};

export const getErrorProps = (intl: InjectedIntl, status: number): AppErrorData => {
  const getText = getTextFactory(intl, 'error');

  const expiredRedirect = async (): Promise<void> => {
    try {
      await signOutUnifiedLogin();
    } catch (err) {}

    deleteAuthent();
    redirectToUnifiedLogin(intl.locale);
  };

  const forbiddenRedirect = (): void => {
    deleteAuthent();
    window.location.href = `${process.env.PUBLIC_URL}${ROUTE.CLIENT_REDIRECT}`;
  };

  const redirectToBNCD = (): void => {
    window.location.href = URLS.BNCD[intl.locale.toUpperCase()];
  };

  const expiredContent: ReactNode = (
    <Fragment>
      <ErrorIcon className="" />
      <p> {getText('expired')} </p>
    </Fragment>
  );

  const forbiddenErrorContent: ReactNode = (
    <Fragment>
      <ErrorIcon className="" />
      <p> {getText('unspecified')} </p>
    </Fragment>
  );

  const generalErrorContent: ReactNode = (
    <Fragment>
      <ErrorIcon className="" />
      <p> {getText('issues')} </p>
      <p> {getText('return')} </p>
    </Fragment>
  );

  const expiredError: AppErrorData = {
    title: getText('expiredModalTitle'),
    Content: expiredContent,
    text: getText('expired'),
    onClose: expiredRedirect,
    className: 'error_expired',
  };

  const forbiddenError: AppErrorData = {
    title: getText('modalTitle'),
    Content: forbiddenErrorContent,
    text: getText('unspecified'),
    onClose: forbiddenRedirect,
    className: 'error_forbidden',
  };

  const generalError: AppErrorData = {
    title: getText('modalTitle'),
    Content: generalErrorContent,
    text: getText('issues'),
    close: getText('close'),
    onClose: redirectToBNCD,
    className: 'error_general',
  };

  if (status === ERRORCODE.CONFLICT) return expiredError;
  if (status === ERRORCODE.FORBIDDEN) return expiredError;
  if (status === ERRORCODE.NETWORK_ERROR) return forbiddenError;

  return generalError;
};
