import { ROUTE } from 'utils/constants';

const config = {
  PATH: ROUTE.WELCOME,
  ID: 'WELCOME',
  SIDEBAR_IMAGE: false,
  BUTTON_HELP: true,
  BUTTON_LANG: true,
  BUTTON_QUIT: true,
  BUTTON_CONTINUE: false,
  FOOTER: true,
};

export default config;
