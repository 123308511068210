import React, { FunctionComponent } from 'react';
import { InjectedIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import CONFIG from './config';
import { Check } from '@nbc-design/icons/lib/web/Check';
import { InfoFill } from '@nbc-design/icons/lib/web/InfoFill';
import { Confirmation } from '@nbc-design/confirmation';
import { Heading } from '@nbc-design/heading';
import { Button } from '@nbc-design/button';
import { Card } from '@nbc-design/card';
import { Alert } from '@nbc-design/alert';
import { getTextFactory } from 'utils/TextUtils';

import './styles.scss';

export type ThankYouProps = {
  intl: InjectedIntl;
  displayMarginText: boolean;
};

const ThankYou: FunctionComponent<ThankYouProps> = (props: ThankYouProps) => {
  const { intl, displayMarginText } = props;
  const { locale } = intl;

  const getText = getTextFactory(intl, 'thankYou');
  const pageTitle = getText('pageTitle');

  return (
    <div className="thank_you__form">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Confirmation title={getText('pageTitle')} appearance="success" />

      <Card className="checklist">
        <ul>
          <li>
            <Check title="check" size={{ width: 16, height: 16 }} className="check-icon" data-test="image_checkIcon" />
            {getText('emailSentSoon')}
          </li>
          <li>
            <Check title="check" size={{ width: 16, height: 16 }} className="check-icon" data-test="image_checkIcon" />
            {getText('paperSign')}
          </li>
        </ul>
      </Card>

      {displayMarginText && (
        <Alert>
          <InfoFill title="info-fill" className="info-icon" data-test="info-icon" />
          <div className="dsc-alert__textContainer">
            <Heading type="h3" size={5} data-test="title">
              {getText('marge')}
            </Heading>
            <small className="dsc-alert__description" data-test="description">
              <p>{getText('marge1')}</p>
              <p>{getText('marge2')}</p>
              <p>{getText('marge3')}</p>
            </small>
          </div>
        </Alert>
      )}

      <Button
        type="link"
        appearance="secondary"
        href={CONFIG.LINKS[locale.toUpperCase()].WEBSITE}
        data-test="link_close"
        className="button_link button_close"
      >
        {getText('close')}
      </Button>
    </div>
  );
};

export default ThankYou;
