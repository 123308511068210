import { MessageDictionnary } from '../types/interfaces';
import AccountsStep1Messages from './Accounts/AccountsStep1.json';
import AccountsStep2Messages from './Accounts/AccountsStep2.json';
import AccountsStep3Messages from './Accounts/AccountsStep3.json';
import AssistedMessages from './Assisted/Assisted.json';
import BeneficiaryMessages from './Beneficiary/Beneficiary.json';
import CreateCredentialsMessages from './CreateCredentials/CreateCredentials.json';
import AboutYouMessages from './AboutYou/AboutYou.json';
import AddressSelectMessages from './Components/AddressSelect.json';
import CollectionMessages from './Components/Collection.json';
import CopyMessages from './Components/Copy.json';
import DatePickerMessages from './Components/DatePicker.json';
import ManualAddressMessages from './Components/ManualAddress.json';
import MultiChannelFormMessages from './Components/MultiChannelForm.json';
import DocumentsMessages from './Documents/Documents.json';
import EmploymentMessages from './Employment/Employment.json';
import EmploymentMessagesV2 from './EmploymentV2/Employment.json';
import ErrorsMessages from './Errors/Errors.json';
import FinalisationMessages from './Finalisation/Finalisation.json';
import FinancesMessages from './Finances/Finances.json';
import GlobalMessages from './Global/Global.json';
import IdentityVerificationMessages from './IdentityVerification/IdentityVerification.json';
import IdentityVerificationBNCMessages from './IdentityVerification/IdentityVerificationBNC.json';
import SideBarMessages from './Navigation/SideBar.json';
import TopNavMessages from './Navigation/TopNav.json';
import PersonnalInformationMessages from './PersonnalInformation/PersonnalInformation.json';
import RegulatoryMessages from './Regulatory/Regulatory.json';
import NewAccountRequestMessages from './Requests/NewAccountRequest.json';
import PendingRequestMessages from './Requests/PendingRequest.json';
import RequestAssistantMessages from './Requests/RequestAssistant.json';
import RequestsIdMessages from './Requests/RequestsId.json';
import SpecificsBankingInfoMessages from './Specifics/SpecificsBankingInfo.json';
import SpecificsOptionsMessages from './Specifics/SpecificsOptions.json';
import SummaryMessages from './Summary/Summary.json';
import TaxesMessages from './Taxes/Taxes.json';
import ThankYouMessages from './ThankYou/ThankYou.json';
import TransferMessages from './Transfer/Transfer.json';
import WelcomeMessages from './Welcome/Welcome.json';
import ESignMessages from './ESign/ESign.json';
import ESignFailMessages from './ESignFail/ESignFail.json';
import WaitingMessages from './Waiting/Waiting.json';
import ClientRedirectMessages from './ClientRedirect/ClientRedirect.json';
import ClientRedirectNewMessages from './ClientRedirectNew/ClientRedirectNew.json';
import IdentityValidationMessages from './IdentityValidation/IdentityValidation.json';
import AccessibilityMessages from './Accessibility/Accessibility.json';
import PaymentInformationMessages from './PaymentInformation/PaymentInformation.json';
import SpouseInformationMessages from './SpouseInformation/SpouseInformation.json';
import BeneficiaryInformation from './BeneficiaryInformation/BeneficiaryInformation.json';

export const getAllMessagesByLocale = (locale: string): MessageDictionnary => {
  return {
    ...AccountsStep1Messages[locale],
    ...AccountsStep2Messages[locale],
    ...AccountsStep3Messages[locale],
    ...AssistedMessages[locale],
    ...BeneficiaryMessages[locale],
    ...CreateCredentialsMessages[locale],
    ...AboutYouMessages[locale],
    ...AddressSelectMessages[locale],
    ...CollectionMessages[locale],
    ...CopyMessages[locale],
    ...DatePickerMessages[locale],
    ...ManualAddressMessages[locale],
    ...MultiChannelFormMessages[locale],
    ...DocumentsMessages[locale],
    ...EmploymentMessages[locale],
    ...EmploymentMessagesV2[locale],
    ...ErrorsMessages[locale],
    ...FinalisationMessages[locale],
    ...FinancesMessages[locale],
    ...GlobalMessages[locale],
    ...IdentityVerificationMessages[locale],
    ...IdentityVerificationBNCMessages[locale],
    ...SideBarMessages[locale],
    ...TopNavMessages[locale],
    ...PersonnalInformationMessages[locale],
    ...RegulatoryMessages[locale],
    ...NewAccountRequestMessages[locale],
    ...PendingRequestMessages[locale],
    ...RequestAssistantMessages[locale],
    ...RequestsIdMessages[locale],
    ...SpecificsBankingInfoMessages[locale],
    ...SpecificsOptionsMessages[locale],
    ...SummaryMessages[locale],
    ...TaxesMessages[locale],
    ...ThankYouMessages[locale],
    ...TransferMessages[locale],
    ...WelcomeMessages[locale],
    ...ESignMessages[locale],
    ...ESignFailMessages[locale],
    ...WaitingMessages[locale],
    ...ClientRedirectMessages[locale],
    ...ClientRedirectNewMessages[locale],
    ...IdentityValidationMessages[locale],
    ...AccessibilityMessages[locale],
    ...PaymentInformationMessages[locale],
    ...SpouseInformationMessages[locale],
    ...BeneficiaryInformation[locale],
  };
};
