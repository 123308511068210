import React from 'react';

export default ({ className = '' }) => (
  <svg width="96px" height="80px" viewBox="0 0 96 80" className={className}>
    <title>tutorial/secure-email_vXL</title>
    <desc>Created with Sketch.</desc>
    <g id="UI-Desktop---Client" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="111.000-BAO---Attente-Envoie" transform="translate(-672.000000, -136.000000)">
        <g id="tutorial/secure-email_vXL" transform="translate(656.000000, 112.000000)">
          <g id="Group" transform="translate(16.000000, 24.000000)">
            <path
              d="M8.70000017,2 L86.675938,2 C90.8880637,2 94.3184774,5.38449363 94.375239,9.59623681 L94.9897895,55.1962365 C95.0470964,59.448443 91.6464583,62.9419939 87.3942518,62.9993008 C87.359666,62.9997669 87.3250774,63 87.2904885,63 L58.0779815,63 L58.0779815,63 L8.70000017,63 C4.4474075,63 1,59.5525925 1,55.2999998 L1,9.70000017 C1,5.4474075 4.4474075,2 8.70000017,2 Z"
              id="bg"
              fill="#E5F1F5"
            ></path>
            <path
              d="M74,1 L87.6956522,1 C91.7297321,1 95,4.27026791 95,8.30434783 L95,56.6956522 C95,60.7297321 91.7297321,64 87.6956522,64 L74,64 C78.8975674,63.9511272 82.8421053,59.9670305 82.8421053,55.0692192 L82.8421053,9.93348165 C82.8421053,5.03522686 78.8979959,1.05035817 74,1 Z"
              id="shadow"
              fill="#D5E9EE"
            ></path>
            <path
              d="M87.9014991,0 C92.3731372,0 96,3.60512567 96,8.05736525 L96,30.0728402 C96,30.5750993 95.5939264,30.9822608 95.0930092,30.9822608 C94.5920921,30.9822608 94.1860185,30.5750993 94.1860185,30.0728402 L94.1860185,8.05736525 C94.1860185,6.42310286 93.5530711,4.93514645 92.5167746,3.82260803 L92.5049868,3.83185915 L51.7790739,31.0027386 C49.7830733,32.3343992 47.1856326,32.348843 45.1749457,31.0394626 L45.1749457,31.0394626 L3.44559921,3.86397095 C2.43165051,4.97193763 1.81398153,6.44334978 1.81398153,8.05736525 L1.81398153,55.9426348 C1.81398153,59.3865219 4.62612981,62.1811588 8.09972477,62.1811588 L50.6130211,62.1811588 C51.1139383,62.1811588 51.5200119,62.5883203 51.5200119,63.0905794 C51.5200119,63.5928385 51.1139383,64 50.6130211,64 L8.09972477,64 C3.62790045,64 0,60.3946906 0,55.9426348 L0,8.05736525 C0,3.60530941 3.62790045,0 8.09972477,0 L87.9014991,0 Z M87.9014991,1.81884119 L8.09972477,1.81884119 C7.01905078,1.81884119 6.0023983,2.08933445 5.11453029,2.56571804 L46.2663481,29.363503 C47.6068061,30.2364232 49.3384332,30.226794 50.6691003,29.3390203 L50.6691003,29.3390203 L90.8376134,2.53981695 C89.9615056,2.07953644 88.9623876,1.81884119 87.9014991,1.81884119 Z"
              id="Combined-Shape"
              fill="#00314D"
              fill-rule="nonzero"
            ></path>
            <path
              d="M71.4285714,52.2857143 C71.4285714,54.3370576 69.7656291,56 67.7142857,56 C65.6629424,56 64,54.3370576 64,52.2857143 L64,44.1902744 C64,35.2480133 68.6517377,30 77,30 C85.3482623,30 90,35.2480133 90,44.1902744 L90,52.2857143 C90,54.3370576 88.3370576,56 86.2857143,56 C84.2343709,56 82.5714286,54.3370576 82.5714286,52.2857143 L82.5714286,44.1902744 C82.5714286,39.1645506 81.0326901,37.4285714 77,37.4285714 C72.9673099,37.4285714 71.4285714,39.1645506 71.4285714,44.1902744 L71.4285714,52.2857143 Z"
              id="Top2"
              fill="#00314D"
              fill-rule="nonzero"
            ></path>
            <path
              d="M69.6666667,52.1666667 C69.6666667,53.1791887 68.8458554,54 67.8333333,54 C66.8208113,54 66,53.1791887 66,52.1666667 L66,44.1750145 C66,36.313979 69.8238278,32 77,32 C84.1761722,32 88,36.313979 88,44.1750145 L88,52.1666667 C88,53.1791887 87.1791887,54 86.1666667,54 C85.1541446,54 84.3333333,53.1791887 84.3333333,52.1666667 L84.3333333,44.1750145 C84.3333333,38.2471417 82.04605,35.6666667 77,35.6666667 C71.95395,35.6666667 69.6666667,38.2471417 69.6666667,44.1750145 L69.6666667,52.1666667 Z"
              id="Top1"
              fill="#FFFFFF"
              fill-rule="nonzero"
            ></path>
            <path
              d="M88.7946021,79 C91.6578113,79 94,76.5327835 94,73.4641226 L94,56.5358774 C94,53.4665122 91.6582043,51 88.7946021,51 L63.2064552,51 C60.3425958,51 58,53.4667742 58,56.5358774 L58,73.4641226 C58,76.5325216 60.3429888,79 63.2064552,79 L88.7946021,79 Z"
              id="body"
              stroke="#00314D"
              stroke-width="2"
              fill="#FFFFFF"
            ></path>
            <path
              d="M81,61.815 C81,59.708 78.9846698,58 76.5,58 C74.0141509,58 72,59.708 72,61.815 C72,63.189 72.8643868,64.383 74.1474057,65.055 L74.1474057,69.072 C74.1474057,70.136 75.1662736,71 76.4209906,71 C77.6768868,71 78.6945755,70.136 78.6945755,69.072 L78.6945755,65.128 C80.0636792,64.473 81,63.243 81,61.815 Z"
              id="trou"
              stroke="#00314D"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
