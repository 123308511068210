import { injectIntl } from 'react-intl';
import { formValues } from 'bnc-react-forms';

import config from './config';
import * as validation from './validation';
import IdentityVerification from './IdentityVerification';

export const VALIDATION = validation;
export const CONFIG = config;

export default injectIntl(formValues('indvIdentMethod', 'bankingAccount')(IdentityVerification));
