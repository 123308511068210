export const fundingSourceOtherValue = '00099';
export const numberOfDependentsOtherValue = 4;
export const MAX_AMOUNT = {
  ASSETS: 25000000000,
  LIABILITIES: 50000000000,
};

export const CALCULATOR_ASSETS_FIELDS = {
  LIQUIDITY: 'assetsLiquidity',
  FIXED_REVENUE: 'assetsFixedRevenue',
  EQUITY_SECURITIES: 'assetsEquitySecurities',
  OTHER: 'assetsOther',
  PROPERTY: 'assetsProperty',
};

export const CALCULATOR_LIABILITIES_FIELDS = {
  PERSONAL_LOAN: 'liabilitiesPersonalLoan',
  CREDIT_MARGINS: 'liabilitiesCreditMargins',
  MORTGAGE: 'liabilitiesMortgage',
  OTHER: 'liabilitiesOther',
};

export const CALCULATOR_MODE_ASSETS = 'assets';
export const CALCULATOR_MODE_LIABILITIES = 'liabilities';
