import { injectIntl } from 'react-intl';
import { formValues } from 'bnc-react-forms';
import Taxes, { TaxesProps } from './Taxes';
import config from './config';
import * as validation from './validation';
import { flowRight } from 'lodash';
import { withContainerCtxProvider } from 'providers/containerCtx/ContainerCtx';

export const VALIDATION = validation;
export const CONFIG = config;

export default flowRight(
  injectIntl,
  formValues(
    'displayNas',
    'isMainResidencyCA',
    'mainResidency',
    'isOtherResidency',
    'secondaryResidency',
    'displayCCB',
  ),
  withContainerCtxProvider<TaxesProps>('tax'),
)(Taxes);
