import React, { FunctionComponent } from 'react';
import { InjectedIntl } from 'react-intl';
import { Col, Row } from 'react-flexbox-grid';
import { Advisor } from '@nbc-design/icons/lib/web/Advisor';
import { Check } from '@nbc-design/icons/lib/web/Check';
import { Text } from '@nbc-design/text';
import Title from 'components/Title';
import { getTextFactory } from 'utils/TextUtils';
import Step from './components/Step';
import Heading from 'components/Heading';
import { GenericProps } from 'types/interfaces';
import SecureEmail from 'assets/icons/secureEmail';
import AlertMessage from './components/AlertMessage';

import './styles.scss';

export type WaitingProps = GenericProps & {
  intl: InjectedIntl;
  identityDocumentSelected?: boolean;
  hasProInvolvement?: boolean;
  indvIdentMethodClrdCheck?: boolean;
  email: string;
};

const Waiting: FunctionComponent<WaitingProps> = (props: WaitingProps) => {
  const {
    intl,
    identityDocumentSelected,
    hasProInvolvement,
    indvIdentMethodClrdCheck,
    email,
    meta: { requestCode },
  } = props;

  const getText = getTextFactory(intl, 'waiting');
  const getGlobalText = getTextFactory(intl, 'global');
  const getAccessibilityText = getTextFactory(intl, 'accessibility.icon');

  const showImportant = identityDocumentSelected || hasProInvolvement || indvIdentMethodClrdCheck;

  return (
    <div className="waiting__form">
      <SecureEmail className="esign-waiting-icon" data-test="esign_waiting_icon" />

      <Title label={getText('pageTitle')} />

      <Row className="identity">
        <Col xs={12} md={12} lg={12}>
          <p>{email}</p>
          <p>{`${getGlobalText('applicationNumber')} ${requestCode}`}</p>
        </Col>
      </Row>

      <Row className="steps">
        <Col xs={12} md={12} lg={12}>
          <div className="step_title" data-test="text_subscriptNow">
            {getText('steps')}
          </div>
        </Col>

        <Step
          icon="newsletter"
          dataTest="email"
          label={getText('stepLabel1')}
          description={getText('stepDescription1')}
        />
        <Step
          icon="sign-here"
          dataTest="sign"
          label={getText('stepLabel2')}
          description={getText('stepDescription2')}
        />
        <Step
          icon="simplified-digital-repository"
          dataTest="submit"
          label={getText('stepLabel3')}
          description={getText('stepDescription3')}
        />
      </Row>

      <AlertMessage
        title={getText('timeLimit')}
        description={getText('esignLimit')}
        ariaLabel={getAccessibilityText('alert')}
      />

      {showImportant && (
        <Row className="important">
          <Col xs={12} md={12} lg={12}>
            <Heading type="h3" size={5}>
              {getGlobalText('important').toUpperCase()}
            </Heading>
            <ul>
              {identityDocumentSelected && (
                <li data-test="identity_document">
                  <Check title="check" size="small" data-test="checkIcon" />
                  <Text type="p">{getText('sendID')}</Text>
                </li>
              )}
              {hasProInvolvement && (
                <li data-test="pro">
                  <Check title="check" size="small" data-test="checkIcon" />
                  <Text type="p">{getText('pro')}</Text>
                </li>
              )}
              {indvIdentMethodClrdCheck && (
                <li data-test="check">
                  <Check title="check" size="small" data-test="checkIcon" />
                  <Text type="p">{getText('check')}</Text>
                </li>
              )}
            </ul>
          </Col>
        </Row>
      )}

      {showImportant && (
        <Row className="bnc-address">
          <Col xs={12} md={12} lg={12}>
            <Heading type="h3" size={5}>
              {getText('byMail')}
            </Heading>
            <Text type="p">{getGlobalText('address1Line')}</Text>
            <Text type="p">{getGlobalText('address2Line')}</Text>
            <Text type="p">{getGlobalText('address3Line')}</Text>
            <Text type="p">{getGlobalText('address4Line')}</Text>
            <Text type="p">{getGlobalText('address5Line')}</Text>
          </Col>
        </Row>
      )}

      <div className="help-section">
        <Row>
          <Col xs={3} md={2} lg={2}>
            <Advisor title="advisor" className="advisor-icon" data-test="image_checkIcon" />
          </Col>
          <Col xs={9} md={10} lg={10} className="help-section-content">
            <Heading type="h3" size={5}>
              {getGlobalText('helpLabel')}
            </Heading>
            <Text type="p">{`${getGlobalText('helpQuestions')} ${getGlobalText('helpPhone')}`}</Text>
            <Text type="p">{getGlobalText('helpOpenedTime')}</Text>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Waiting;
