import { isNil } from 'lodash';
import {
  AccountType,
  Currency,
  PaymentDetail,
  PaymentInformationErrorData,
  PaymentInformationErrorsDictionary,
  PaymentInformationFormData,
  PaymentValidationData,
} from './types';

import { ERRORTYPE } from '../../utils/validation';
import { ACCOUNT_TYPE } from 'containers/AccountsStep1/constants';
import { CURRENCIES } from 'utils/constants';

export const PAYMENT_INFORMATION_ERROR_TYPE: PaymentInformationErrorsDictionary = {
  ...ERRORTYPE,
  MUST_BE_A_VALID_ACCOUNT_NUMBER: { type: 'mustBeValidAccountNumber' },
};
export const transferAccountValidator = (
  accountType: AccountType,
  currency: Currency,
  transferAccountNumber: string,
): boolean => {
  const getEndingCharacterAccount = (accountTypeKey: string) => {
    let result: string[];
    switch (accountTypeKey) {
      case 'RRIFUSD':
        result = ['B', 'F', 'H'];
        break;
      case 'RRIFCAD':
      case 'SRRIFCAD':
      case 'LIFCAD':
      case 'RLIFCAD':
      case 'FLIFCAD':
      case 'LRIFCAD':
      case 'PRIFCAD':
        result = ['A', 'E', 'G'];
        break;
      default:
        result = [];
    }
    return result;
  };

  const key: string = `${accountType.code}${currency.code}`;
  const endingCharacters: string[] = getEndingCharacterAccount(key);
  const regex: RegExp = /[^A-Za-z0-9]/;
  return !(
    transferAccountNumber &&
    endingCharacters.includes(transferAccountNumber.charAt(transferAccountNumber.length - 1)) &&
    transferAccountNumber.length === 7 &&
    !regex.test(transferAccountNumber)
  );
};

const usesValidator = (payments: PaymentDetail[]): PaymentValidationData => {
  const RRIFUSDIndex = payments?.findIndex(
    (payment) => payment.accountType.code === ACCOUNT_TYPE.RRIF && payment.currency.code === CURRENCIES.USD,
  );
  const RRIFCADIndex = payments?.findIndex(
    (payment) => payment.accountType.code === ACCOUNT_TYPE.RRIF && payment.currency.code === CURRENCIES.CAD,
  );
  const hasRRIFUSD: boolean = RRIFUSDIndex !== -1;
  const RRIFCAD: PaymentDetail = payments[RRIFCADIndex];
  const RRIFUSD: PaymentDetail = payments[RRIFUSDIndex];
  const USDSplit: number | undefined = RRIFUSD?.splitPercentage;
  const CADSplit: number | undefined = RRIFCAD?.splitPercentage;
  const totalSplit: number = USDSplit! + CADSplit!;

  const errors: Object | PaymentInformationErrorData = payments.map(
    (
      {
        accountType,
        currency,
        frequencyCd,
        startMonth,
        dayOfMonth,
        paymentTypeCd,
        referenceAgeTypeCd,
        transferModeCd,
        amount,
        transferAccountNumber,
        splitPercentage,
      }: PaymentDetail,
      index: number,
    ) => {
      const amountOnError: boolean = paymentTypeCd === '02' && !amount;
      const accountNumberOnError: boolean = transferModeCd === 'CDBN' && !transferAccountNumber;
      const transferAccountNumberOnError: boolean =
        transferAccountNumber !== undefined
          ? transferAccountValidator(accountType, currency, transferAccountNumber)
          : false;

      const error: PaymentInformationErrorData | object = {
        ...(hasRRIFUSD && !isNil(splitPercentage) && totalSplit !== 100
          ? { splitPercentage: PAYMENT_INFORMATION_ERROR_TYPE.REQUIRED }
          : {}),
        ...(!frequencyCd ? { frequencyCd: PAYMENT_INFORMATION_ERROR_TYPE.REQUIRED } : {}),
        ...(!startMonth ? { startMonth: PAYMENT_INFORMATION_ERROR_TYPE.REQUIRED } : {}),
        ...(!dayOfMonth ? { dayOfMonth: PAYMENT_INFORMATION_ERROR_TYPE.REQUIRED } : {}),
        ...(!paymentTypeCd ? { paymentTypeCd: PAYMENT_INFORMATION_ERROR_TYPE.REQUIRED } : {}),
        ...(!referenceAgeTypeCd ? { referenceAgeTypeCd: PAYMENT_INFORMATION_ERROR_TYPE.REQUIRED } : {}),
        ...(!transferModeCd ? { transferModeCd: PAYMENT_INFORMATION_ERROR_TYPE.REQUIRED } : {}),
        ...(amountOnError ? { amount: PAYMENT_INFORMATION_ERROR_TYPE.REQUIRED } : {}),
        ...(accountNumberOnError
          ? { transferAccountNumber: PAYMENT_INFORMATION_ERROR_TYPE.REQUIRED }
          : transferAccountNumberOnError
          ? { transferAccountNumber: PAYMENT_INFORMATION_ERROR_TYPE.MUST_BE_A_VALID_ACCOUNT_NUMBER }
          : {}),
      };
      return error;
    },
  );

  return { payments: errors };
};

const validation = ({ payments = [] }: PaymentInformationFormData): PaymentValidationData => {
  return {
    ...usesValidator(payments),
  };
};

export default validation;
