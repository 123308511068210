import React, { FunctionComponent } from 'react';
import { Heading as BncHeading, Props as BncHeadingProps } from '@nbc-design/heading';
import { Divider } from '@nbc-design/divider';

export type HeadingProps = Partial<BncHeadingProps> & {
  divider?: boolean;
};

const DEFAULT_DIVIDER = ['h2'];

const Heading: FunctionComponent<HeadingProps> = ({
  type = 'h2',
  dataTest = 'heading-test',
  children,
  divider = DEFAULT_DIVIDER.includes(type),
  ...rest
}) => (
  <BncHeading type={type} dataTest={dataTest} {...rest}>
    {children}
    {divider ? <Divider /> : null}
  </BncHeading>
);

export default Heading;
