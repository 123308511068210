import { HttpRequest } from 'types/interfaces';

const HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const callRequest = async <S>(callConfigs: HttpRequest): Promise<S> => {
  const { url, method, headers, body, resType = 'json', init = {} } = callConfigs;

  let response: Response;
  try {
    response = await fetch(url, {
      method,
      headers: {
        ...HEADERS,
        ...(headers ? headers : {}),
      },
      body,
      ...init,
    });
  } catch (err) {
    return Promise.reject(err);
  }

  if (response.ok) {
    if (method === 'DELETE') {
      return Promise.resolve({} as S);
    }
    return response[resType]();
  } else {
    console.error(await response.json());
  }
  return Promise.reject(response);
};
