import { ErrorData, AccountTypeData } from '../../types/interfaces';

// ************************************
// General
// ************************************
export type ValueData = {
  id?: string;
  code: string;
  value?: boolean;
};

export type OptionsTypeData = {
  value: string;
  label: string;
  readOnly: boolean;
  sortIndex?: number;
};

export enum RespPlanTypeCd {
  INDIVIDUAL = 'RESPIND',
  FAMILY = 'RESPFAM',
}

// ************************************
// Form data
// ************************************
export type TAccountData = {
  accountType: AccountTypeData;
  currency: AccountTypeData;
  registeredAgmtFlg?: string;
};

export type RespPlanData = TAccountData & {
  respPlanTypeCd?: RespPlanTypeCd;
  isFamilySibling?: boolean;
};

export type UtilisationData = TAccountData & {
  usageCode: string;
  usageOtherComment: string;
};

export type LegislationData = TAccountData & {
  province: string;
  values: ValueData[];
  eligibleProvinces?: string[];
};

export type AccountsStep3FormData = {
  legislations: LegislationData[];
  utilisations: UtilisationData[];
  respPlanTypes: RespPlanData[];
  promotionCd: string | null;
  tradingOptionsInd?: boolean;
  displayTradingOption?: boolean;
  promotionCdAvailable?: boolean;
};

// ************************************
// Validation
// ************************************
type ErrorDataInArray<T> = Partial<Record<keyof T, ErrorData>>[];

export type AccountsStep3FormValidation = {
  promotionCd?: ErrorData | object;
  utilisations?: ErrorDataInArray<UtilisationData>;
  legislations?: ErrorDataInArray<LegislationData>;
  respPlanTypes?: ErrorDataInArray<RespPlanData>;
};
