import React, { ReactNode, Component } from 'react';
import { InjectedIntl } from 'react-intl';
import { History } from 'history';
import { Heading } from '@nbc-design/heading';
import { getTextFactory } from 'utils/TextUtils';
import AboutYou from './components/AboutYou';
import CompletedCredentials from './components/CompletedCredentials';
import ExistedEmail from './components/ExistedEmail';
import CredentialsCreation from './components/CredentialsCreation';
import ProfileConfirmation from './components/ProfileConfirmation';

import './styles.scss';

export type CreateUserProfileProps = {
  intl: InjectedIntl;
  history: History;
  setUserCredentials: Function;
};

export type CreateUserProfileState = {
  step: number;
  existedEmail: boolean;
  rejectedEmail: string;
  isError: boolean;
};

class CreateUserProfile extends Component<CreateUserProfileProps, CreateUserProfileState> {
  private readonly setUserCredentials: Function;

  constructor(props) {
    super(props);

    this.setState = this.setState.bind(this);
    this.setUserCredentials = this.props.setUserCredentials.bind(this);
  }

  state: CreateUserProfileState = {
    step: 1,
    existedEmail: false,
    rejectedEmail: '',
    isError: false,
  };

  getText = getTextFactory(this.props.intl, 'createCredentials');

  profileCreationSteps = (step: number, isError: boolean) => (
    <div className="active-cards-wrapper">
      {step === 1 && (
        <CredentialsCreation
          intl={this.props.intl}
          history={this.props.history}
          setUserCredentials={this.setUserCredentials}
          setCardWrapperState={this.setState}
          isError={isError}
        />
      )}

      {step === 2 && <CompletedCredentials intl={this.props.intl} setCardWrapperState={this.setState} />}

      <AboutYou intl={this.props.intl} history={this.props.history} step={step} setCardWrapperState={this.setState} />
    </div>
  );

  render(): ReactNode {
    const { step, existedEmail, rejectedEmail, isError } = this.state;

    return (
      <div className="layout-content">
        <div className="create-user-profile-wrapper">
          {step !== 3 ? (
            <>
              <Heading type="h1" className="mc-my-xsmall">
                {this.getText('pageTitle')}
              </Heading>
              {!existedEmail ? (
                this.profileCreationSteps(step, isError)
              ) : (
                <ExistedEmail intl={this.props.intl} email={rejectedEmail} />
              )}
            </>
          ) : (
            <ProfileConfirmation intl={this.props.intl} />
          )}
        </div>
      </div>
    );
  }
}

export default CreateUserProfile;
