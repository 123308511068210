import {
  occupationTitleServiceListRequestedAction,
  occupationTitleListRequestFailedAction,
  occupationTitleListRequestSucceededAction,
  occupationTitleResetAction,
} from 'types/actions';
import OccupationKeys from 'services/OccupationTitleService/actionTypes';
import { OccupationTitle, OccupationTitleInvolvementOptions, OccupationTitleError } from 'types/reducers';
import { capitalizeFirstLetter } from 'utils/stringUtils';

export const occupationTitleServiceListRequested = (
  search: string = '',
  options: OccupationTitleInvolvementOptions = {} as OccupationTitleInvolvementOptions,
): occupationTitleServiceListRequestedAction => {
  return {
    type: OccupationKeys.OCCUPATION_TITLE_SERVICE_LIST_REQUESTED,
    search,
    options,
  };
};

export const occupationTitleServiceListRequestSucceeded = (
  occupationList: OccupationTitle[],
  options: OccupationTitleInvolvementOptions = {} as OccupationTitleInvolvementOptions,
): occupationTitleListRequestSucceededAction => {
  occupationList = mapOccupationDisplay(occupationList);
  return {
    type: OccupationKeys.OCCUPATION_TITLE_LIST_REQUEST_SUCCEEDED,
    occupationList,
    options,
  };
};

const mapOccupationDisplay = (occupationList: OccupationTitle[]) => {
  if (occupationList) {
    occupationList.forEach((occupation) => {
      occupation.label.en = capitalizeFirstLetter(occupation.label.en);
      occupation.label.fr = capitalizeFirstLetter(occupation.label.fr);
    });
  }
  return occupationList;
};

export const occupationTitleServiceListRequestFailed = (
  err: OccupationTitleError,
  options: OccupationTitleInvolvementOptions = {} as OccupationTitleInvolvementOptions,
): occupationTitleListRequestFailedAction => {
  return {
    type: OccupationKeys.OCCUPATION_TITLE_SERVICE_LIST_REQUEST_FAILED,
    err,
    options,
  };
};

export const occupationTitleServiceReset = (
  options: OccupationTitleInvolvementOptions = {} as OccupationTitleInvolvementOptions,
): occupationTitleResetAction => {
  return {
    type: OccupationKeys.OCCUPATION_TITLE_SERVICE_LIST_RESET,
    options,
  };
};
