import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { melissaServiceListRequested, melissaServiceReset } from 'bnc-react-services/services/MelissaService/actions';

import { RootState } from 'types/reducers';
import { getProductFormField } from '../utils';
import ProductFormAddressSelect from './ProductFormAddressSelect';
import {
  isMelissaLoadingSelector,
  availableAddressesSelector,
  melissaFetchingErrorsSelector,
} from './AddressSelectors';

const mapStateToProps = (state: RootState) => {
  return {
    isLoading: isMelissaLoadingSelector(state),
    addresses: availableAddressesSelector(state),
    error: melissaFetchingErrorsSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      searchAddress: melissaServiceListRequested,
      resetAddress: melissaServiceReset,
      dispatchUpdate: dispatch,
    },
    dispatch,
  );
};

export default compose<Function>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(getProductFormField(ProductFormAddressSelect));
