import React from 'react';
import List from '@nbc-design/list';
import { BeneficiaryInformationProps } from '../BeneficiaryInformation';
import { useContainerCtx } from 'providers/containerCtx/ContainerCtx';
import { BeneficiaryGender, formValueNames } from '../types';
import { Address } from 'components/productFormFields/ProductFormAddressSelect/types';
import { formatAddress } from 'components/productFormFields/ProductFormAddressSelect/utils';
import { Item } from '@nbc-design/list/dist/item';
import { formatGender } from '../utils';

export type BeneficiaryFieldListProps = {};

const BeneficiaryFieldList: React.FC<BeneficiaryFieldListProps> = (): JSX.Element | null => {
  const { intl, formValues, genericProps, getText } = useContainerCtx<BeneficiaryInformationProps>();
  const { formData } = formValues;
  const { currentAddress } = formData ?? {};

  const formatedCurrentAddress = formatAddress((currentAddress! as unknown) as Address, false);

  if (!formData) return null;
  return (
    <List
      className={'mc-mb-medium'}
      spacing={'medium'}
      hasSeparator
      hasTopSeparator={false}
      hasBottomSeparator={false}
      dataTest="list_confirmedBeneficiaryInfo"
    >
      {formValues.formData &&
        Object.keys(formValues.formData)
          .filter((key) => {
            return [
              formValueNames.FIRST_NAME,
              formValueNames.LAST_NAME,
              formValueNames.BIRTH_DATE,
              formValueNames.SIN,
              formValueNames.GENDER,
              formValueNames.ADDRESS,
            ].includes(key as formValueNames);
          })
          .map(
            (key): JSX.Element => {
              switch (key) {
                case formValueNames.SIN:
                  const SIN = formData[key];
                  return (
                    <Item
                      key={key}
                      data-test={'item_confirmedBeneficiaryInfo'}
                      label={getText(key)}
                      children={SIN?.substring(0, 7).replace(new RegExp('[0-9]', 'g'), '*') + ' ' + SIN?.substring(8)}
                    />
                  );
                case formValueNames.ADDRESS:
                  return (
                    <Item
                      key={key}
                      data-test={'item_confirmedBeneficiaryInfo'}
                      label={getText(key)}
                      children={formatedCurrentAddress}
                    />
                  );
                case formValueNames.GENDER:
                  return (
                    <Item
                      key={key}
                      label={getText(key)}
                      data-test={'item_confirmedBeneficiaryInfo'}
                      children={formatGender({
                        value: formData[key as formValueNames] as BeneficiaryGender,
                        refData:
                          genericProps.productForm.schema?.definitions?.RespBeneficiaryDetail?.properties?.[
                            formValueNames.GENDER
                          ].refData,
                        intl,
                      })}
                    />
                  );
                default:
                  return (
                    <Item
                      key={key}
                      data-test={'item_confirmedBeneficiaryInfo'}
                      label={getText(key)}
                      children={formData[key]}
                    />
                  );
              }
            },
          )}
    </List>
  );
};

export default BeneficiaryFieldList;
