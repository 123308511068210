import React from 'react';

export default ({ className, title }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" className={className}>
    <title>{title}</title>
    <g fill="#444">
      <path d="M2.923 9.222v1.809h1.809l6.308-6.308-1.81-1.809-6.307 6.308zm6.634-7.287l2.462 2.462c.18.18.18.472 0 .652l-6.77 6.77a.462.462 0 0 1-.326.135H2.462A.462.462 0 0 1 2 11.492V9.031c0-.123.049-.24.135-.327l6.77-6.769c.18-.18.472-.18.652 0zM2.462 14C2.207 14 2 13.776 2 13.5s.207-.5.462-.5h11.076c.255 0 .462.224.462.5s-.207.5-.462.5H2.462z" />
    </g>
  </svg>
);
