// ************************************
// General
// ************************************
import { RespPlanTypeCd } from '../AccountsStep3/types';
import { AddressDetailedData, AddressErrorData, ErrorData, Validation } from 'types/interfaces';
import { ErrorType } from 'components/productFormFields/types';
import { GenericErrorTypes } from 'utils/validation';

// ************************************
// Ts enums
// ************************************
export enum BeneficiaryGender {
  MALE = '1',
  FEMALE = '2',
}

export enum BeneficiaryRelationTypeCd {
  'PARENT' = 'PARENT',
  'STEPPARENT' = 'STEPPARENT',
  'GRNDPARENT' = 'GRNDPARENT',
  'GRNDPARENTLAW' = 'GRNDPARENTLAW',
  'UNCLEAUNT' = 'UNCLEAUNT',
  'BROTHERSISTER' = 'BROTHERSISTER',
  'BROTHERSISTERLAW' = 'BROTHERSISTERLAW',
  'COUSIN' = 'COUSIN',
  'EXSPOUSE' = 'EXSPOUSE',
  'FRIEND' = 'FRIEND',
  'HALFBROTHERSISTER' = 'HALFBROTHERSISTER',
  'NEPHEWNIECE' = 'NEPHEWNIECE',
  'SONDAUGHTERLAW' = 'SONDAUGHTERLAW',
  'SPOUSE' = 'SPOUSE',
  'STEPBROTHERSISTER' = 'STEPBROTHERSISTER',
  'STEPSONDAUGHTER' = 'STEPSONDAUGHTER',
  'MYSELF' = 'MYSELF',
  'TUTOR' = 'TUTOR',
}

// ************************************
// Form data
// ************************************

export enum formValueNames {
  FIRST_NAME = 'firstName',
  MIDDLE_NAME = 'middleName',
  LAST_NAME = 'lastName',
  BIRTH_DATE = 'birthDate',
  SIN = 'socialInsuranceNumber',
  GENDER = 'gender',
  ADDRESS = 'currentAddress',
  RELATIONSHIP = 'relationTypeCd',
  PARENT_RELATIONSHIP = 'parentRelationTypeCd',
  PARENT_FIRST_NAME = 'parentFirstName',
  PARENT_MIDDLE_NAME = 'parentMiddleName',
  PARENT_LAST_NAME = 'parentLastName',
  PERCENT = 'ownerPercentage',
  GRANT_REQUEST = 'requestGovGrt',
}

export enum OperationMode {
  CREATE = 'create',
  MODIFY = 'modify',
  DELETE = 'delete',
}

export enum BeneficiaryDocuments {
  SDE0093 = 'SDE0093',
  SDE0093A = 'SDE0093A',
  SDE0093B = 'SDE0093B',
  SDE0093D = 'SDE0093D',
  SDE0107 = 'SDE0107',
}

// ************************************
// General
// ************************************

export type BeneficiaryDataType = {
  bncId: string | null;
  firstName: string;
  middleName?: string;
  lastName: string;
  birthDate: string;
  parentFirstName?: string;
  parentMiddleName?: string;
  parentLastName?: string;
  parentRelationTypeCd?: string;
  socialInsuranceNumber?: string;
  currentAddress?: AddressDetailedData;
  gender?: BeneficiaryGender;
  relationTypeCd?: BeneficiaryRelationTypeCd;
  requestGovGrt?: boolean;
  ownerPercentage?: number | null;
  hasIncompleteInfo?: boolean;
  isConfirmed?: boolean;
  isCurrentUser?: boolean;
  useHolderAddress?: boolean;
  operationMode?: OperationMode;
  partyId?: number;
};

export type HolderDataType = {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  currentAddress?: AddressDetailedData;
};

// ************************************
// Form data
// ************************************
// API response payload
export type BeneficiaryFormData = {
  account: {
    respPlanTypeCd: RespPlanTypeCd;
  };
  eligibleBeneficiaries: BeneficiaryDataType[];
  beneficiaries: BeneficiaryDataType[];
  eligibleRelationTypes: BeneficiaryRelationTypeCd[];
  holder: HolderDataType;
};

export type BeneficiaryFormValues = BeneficiaryFormData & {
  formData: BeneficiaryDataType | null;
  validation: Validation | null;
};

export type FormValidationType = {
  [key: string]: FormValidationType | FormValidationType[] | ErrorType | AddressErrorData | undefined;
};

export type BeneficiaryInformationValidationReturnType = ValidationReturnType | undefined;

export type ValidationReturnType = {
  [x: string]: ValidationReturnType | [ValidationReturnType] | ErrorType | AddressErrorData | undefined;
};

export interface BeneficiaryInformationErrorsDictionary extends GenericErrorTypes {
  MUST_BE_UNDER_21: ErrorData;

  [name: string]: ErrorData;
}
