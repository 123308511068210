import React, { FunctionComponent } from 'react';
import { InjectedIntl } from 'react-intl';
import Title from 'components/Title';
import { Row, Col } from 'react-flexbox-grid';
import Highlight from 'components/Highlight';
import { createErrorFormatter } from 'utils/productFormUtils';
import { getTextFactory } from 'utils/TextUtils';
import Heading from 'components/Heading';
import { ProductFormCheckbox, ProductFormInput } from 'components/productFormFields';

import './styles.scss';

type RequestIdProps = {
  intl: InjectedIntl;
};

const RequestId: FunctionComponent<RequestIdProps> = (props: RequestIdProps) => {
  const { intl } = props;

  const getText = getTextFactory(intl, 'requestId');
  const formatErrorMessage = (prefix?: string, suffix?: string) => createErrorFormatter(intl, prefix, suffix);

  return (
    <div className="request-number__form">
      <Title label={getText('pageTitle')} />
      <Row>
        <Col xs={12} md={12} lg={12} data-test="field_requestCode">
          <ProductFormInput
            className="request-number-label"
            name="requestCode"
            isCopy={true}
            label={getText('requestCode')}
            formatErrorMessage={formatErrorMessage()}
          />
        </Col>
      </Row>
      <Highlight>
        <p data-test="text_explanation">{getText('explanation')}</p>
      </Highlight>
      <div className="assistant-row">
        <Heading type={'h2'} dataTest="label_reservedSection">
          {getText('reservedSection')}
        </Heading>
        <Row>
          <Col xs={12} md={12} lg={12} className="assistant-section-wrapper" data-test="field_hasAssistance">
            <ProductFormCheckbox
              name="hasAssistance"
              label={getText('hasAssistance')}
              formatErrorMessage={formatErrorMessage()}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default RequestId;
