import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';

export const SagaMiddlewareComponent: { getInstance: <S>() => SagaMiddleware<S> } = (function () {
  let instance: SagaMiddleware<object> | null = null;

  const getInstance = <S>(): SagaMiddleware<S> => {
    if (instance === null) {
      instance = createSagaMiddleware<object>();
    }
    return instance;
  };

  return {
    getInstance,
  };
})();

export const createMiddlewares = <S>(): SagaMiddleware<S>[] => {
  return [SagaMiddlewareComponent.getInstance()];
};
