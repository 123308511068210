import { AccountDataType, SafeIdFinancialDataType } from './types';
import { isAllowSafeIdFinancialExp } from './components/SafeId/BankingAccountSafeId';

export const hasSafeIdOpenSessionRequest = (safeIdExperiences: SafeIdFinancialDataType[] | undefined): boolean => {
  return !!safeIdExperiences?.find((safeIdExp) => safeIdExp.openSession);
};

export const hasSafeIdDeleteRequest = (safeIdExperiences: SafeIdFinancialDataType[] | undefined): boolean => {
  return !!safeIdExperiences?.find((safeIdExp) => safeIdExp.delete);
};

export const resetSafeIdDeleteRequest = (safeIdExperiences: SafeIdFinancialDataType[]): SafeIdFinancialDataType[] => {
  return safeIdExperiences.map((safeIdExperience) => ({ ...safeIdExperience, delete: false }));
};

export const hasManualBankAccount = (
  bankingAccounts: (AccountDataType | null)[],
  safeIdExperiences: SafeIdFinancialDataType[] | undefined,
): boolean => {
  return !!bankingAccounts?.find((bankAccount) => !isAllowSafeIdCurrency(safeIdExperiences, bankAccount?.currency));
};

export const findSafeIdFinancialExp = (
  safeIdFinancialExp: SafeIdFinancialDataType[] | undefined,
  currency: string | undefined,
): SafeIdFinancialDataType | undefined =>
  safeIdFinancialExp?.find((safeIdExp) => safeIdExp && safeIdExp.currency === currency);

export const findBankingAccount = (
  bankingAccounts: (AccountDataType | null)[],
  currency: string,
): AccountDataType | null | undefined =>
  bankingAccounts.find((bankingAccount) => bankingAccount && bankingAccount.currency === currency);

export const isAllowSafeIdCurrency = (
  safeIdFinancialExp: SafeIdFinancialDataType[] | undefined,
  currency: string | undefined,
) => isAllowSafeIdFinancialExp(findSafeIdFinancialExp(safeIdFinancialExp, currency));
