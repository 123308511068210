import React, { PureComponent } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { getSpouseInformationSlice } from '../selectors';
import { SpouseInformationSlice } from '../types';
import { getTextFactory } from 'utils/TextUtils';
import { InjectedIntl, injectIntl } from 'react-intl';
import SummarySection from './SummarySection';
import { BFF_STEPS_IDS } from 'utils/stepsConstants';
import SummaryField from './SummaryField';

export type SpouseInformationSectionPropsFromState = {
  spouseInformationSlice: SpouseInformationSlice;
};

export type SpouseInformationSectionProps = {
  goToStep: (stepId: string) => string;
  intl: InjectedIntl;
};

export class SpouseInformationSection extends PureComponent<
  SpouseInformationSectionProps & SpouseInformationSectionPropsFromState
> {
  getGlobalText = getTextFactory(this.props.intl, 'global');
  getSpouseInformationText = getTextFactory(this.props.intl, 'spouseInformation');
  getSummaryText = getTextFactory(this.props.intl, 'summary.spouseInformation');

  renderContent = (): JSX.Element | null => {
    const { spouseInformationSlice, intl } = this.props;

    if (!spouseInformationSlice) {
      return null;
    }

    const {
      firstName,
      lastName,
      middleName,
      birthDate,
      socialInsuranceNumber,
    }: SpouseInformationSlice = spouseInformationSlice;

    return (
      <div className="spouse-information-recap-content">
        <Row>
          <Col xs={12} md={6} xl={6}>
            <SummaryField dataTest="spouse_firstName" label={this.getGlobalText('firstName')}>
              {firstName}
            </SummaryField>
          </Col>
          <Col xs={12} md={6} xl={6}>
            <SummaryField dataTest="spouse_middleName" label={this.getGlobalText('middleName')}>
              {middleName}
            </SummaryField>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} xl={6}>
            <SummaryField dataTest="spouse_lastName" label={this.getGlobalText('lastName')}>
              {lastName}
            </SummaryField>
          </Col>
          <Col xs={12} md={6} xl={6}>
            <SummaryField dataTest="spouse_birthDate" label={this.getGlobalText('birthDate')}>
              {birthDate[intl.locale]}
            </SummaryField>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} xl={12}>
            <SummaryField
              dataTest="spouse_socialInsuranceNumber"
              label={this.getSpouseInformationText('socialInsuranceNumber')}
            >
              {socialInsuranceNumber}
            </SummaryField>
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    const { goToStep, intl, spouseInformationSlice } = this.props;

    return (
      <SummarySection
        intl={intl}
        name="spouseInformation"
        title={this.getSummaryText('pageTitle')}
        stepId={BFF_STEPS_IDS.SPOUSEINFORMATION}
        goToStep={goToStep}
        renderSectionContent={this.renderContent}
        editable={spouseInformationSlice && spouseInformationSlice.editable}
      />
    );
  }
}

const mapStateToProps = (state: SpouseInformationSlice) => {
  return {
    spouseInformationSlice: getSpouseInformationSlice(state),
  };
};

export default connect<SpouseInformationSectionPropsFromState, {}, SpouseInformationSection>(mapStateToProps)(
  injectIntl(SpouseInformationSection),
);
