import React, { FC, ChangeEvent, useState, useEffect } from 'react';
import { injectIntl, InjectedIntl } from 'react-intl';
import { Row, Col } from 'react-flexbox-grid';
import get from 'lodash/get';

import { getSchemaField } from 'bnc-react-forms';

import { ProductForm, ProductFormFieldChangeHandler } from 'types/interfaces';
import { isCountryUS } from 'utils';
import { createErrorFormatter } from 'utils/productFormUtils';
import { getTextFactory } from 'utils/TextUtils';
import HelpText from 'components/HelpText';
import { ProductFormSelect } from 'components/productFormFields';

import {
  handleSecondaryCountrySelectBoxCheck,
  filterNoRevealReasonUSA,
  handleNoRevealReasonFieldChange,
  getNoRevealReasonLegalInfoText,
} from '../utils';
import Identity from './Identity';

export type FiscalResidencyProps = {
  name: string;
  input: { name: string };
  change: ProductFormFieldChangeHandler;
  filter: () => {};
  index: number;
  data: { country: string };
  intl: InjectedIntl;
  productForm?: ProductForm;
};

const FiscalResidency: FC<FiscalResidencyProps> = ({
  name,
  input,
  data,
  change,
  intl,
  index,
  filter,
  productForm,
}: FiscalResidencyProps) => {
  const inputName = name ? name : input.name;
  const getText = getTextFactory(intl, 'tax');
  const getTextGlobal = getTextFactory(intl, 'global');
  const formatErrorMessage = (prefix?: string, fieldName?: string) => createErrorFormatter(intl, prefix, fieldName);
  const citizenshipUS = get(productForm, 'values.citizenshipUS');
  const country = get(data, 'country');
  const isUS = isCountryUS(country);
  const isIdentificationPresentField = country !== undefined;
  const identificationPresent: boolean = get(data, 'identificationPresent', false);
  const isNoRevealReasonField = isIdentificationPresentField && !identificationPresent;
  const [noRevealReasonLegalInfo, setNoRevealReasonLegalInfo] = useState<string>('');

  useEffect(() => {
    !isNoRevealReasonField
      ? setNoRevealReasonLegalInfo('')
      : getNoRevealReasonLegalInfoText(get(data, 'noRevealReason'), setNoRevealReasonLegalInfo, getText);
  }, [isNoRevealReasonField, data, getText]);

  return (
    <div className="fiscalResidency">
      <Row>
        <Col xs={12} data-test="field_secondaryCountry">
          <ProductFormSelect
            name={`${inputName}.country`}
            className="secondaryCountry"
            label={getText('secondaryCountry')}
            onChange={handleSecondaryCountrySelectBoxCheck(change, index)}
            placeholder={getTextGlobal('select')}
            mapSchema={(schema, productForm) =>
              getSchemaField(
                {
                  ...productForm.schema.properties.secondaryResidency.items,
                  definitions: productForm.schema.definitions,
                },
                'country',
              )
            }
            filter={filter}
            formatErrorMessage={formatErrorMessage()}
            disabled={citizenshipUS && isUS}
            sort
          />
        </Col>
      </Row>

      <Identity residency={data} fieldNamePrefix={inputName} change={change} />

      {isNoRevealReasonField && (
        <Row>
          <Col xs={12} data-test="field_noRevealReason">
            <ProductFormSelect
              name={`${inputName}.noRevealReason`}
              className={'noRevealReason'}
              label={getText('noRevealReason')}
              placeholder={getTextGlobal('select')}
              formatErrorMessage={formatErrorMessage('tax')}
              mapSchema={(schema, productForm) =>
                getSchemaField(
                  {
                    ...productForm.schema.properties.secondaryResidency.items,
                    definitions: productForm.schema.definitions,
                  },
                  'noRevealReason',
                )
              }
              filter={({ value }) => filterNoRevealReasonUSA(value, isUS)}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleNoRevealReasonFieldChange(e, setNoRevealReasonLegalInfo, getText)
              }
            />

            {noRevealReasonLegalInfo && (
              <HelpText className="noRevealReasonLegalInfo">{noRevealReasonLegalInfo}</HelpText>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default injectIntl(FiscalResidency);
