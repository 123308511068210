import React, { FunctionComponent } from 'react';
import { Card } from '@nbc-design/card';
import { SignHere } from '@nbc-design/icons/lib/web/SignHere';
import Heading from 'components/Heading';

type DocuSignBoxProps = {
  title: string;
};

const DocuSignBox: FunctionComponent<DocuSignBoxProps> = function (props: DocuSignBoxProps) {
  const { title } = props;
  return (
    <Card className="identity-validation-sign">
      <section className="dsb-header">
        <SignHere title="sign-here" size="medium" />
        <Heading type="h4" size={6} className="dsb-title">
          {title}
        </Heading>
      </section>
    </Card>
  );
};

export default DocuSignBox;
