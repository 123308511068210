import { injectIntl } from 'react-intl';
import { formValues } from 'bnc-react-forms';
import Transfer from './Transfer';
import config from './config';
import * as validation from './validation';
import { flowRight } from 'lodash';
import { withContainerCtxProvider } from '../../providers/containerCtx/ContainerCtx';
import { TransferFormData } from './types';

export const VALIDATION = validation;
export const CONFIG = config;

export default flowRight(
  injectIntl,
  formValues('accounts', 'transferAccountEmployer', 'transferAccountOtherFin', 'transferAccountInternal'),
  withContainerCtxProvider<TransferFormData>('transfer'),
)(Transfer);
