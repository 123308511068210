import React, { FC, ChangeEvent, useEffect } from 'react';
import { InjectedIntl } from 'react-intl';
import { Row, Col } from 'react-flexbox-grid';
import { get } from 'lodash';

import { Link } from '@nbc-design/link';

import { ProductForm, ProductFormFieldChangeHandler } from 'types/interfaces';
import { getAllRefData, createErrorFormatter } from 'utils/productFormUtils';
import { getTextFactory } from 'utils/TextUtils';
import BaoTooltip from 'components/BaoTooltip/BaoTooltip';
import Collection from 'components/Collection';
import { ProductFormRadioSelect } from 'components/productFormFields';

import { MainResidency, SecondaryResidency } from '../types';
import { BAO_FORMS, COUNTRIES } from 'utils/constants';
import { OTHER_RESIDENCY_ANSWERS } from '../constants';
import { enforceUSFiscalResidency } from '../utils';
import FiscalResidency from './FiscalResidency';
import { getFormFieldValue } from 'globalRedux/store';

export type OtherResidenciesProps = {
  productForm: ProductForm;
  isOtherResidency?: string;
  secondaryResidency?: SecondaryResidency[];
  change: ProductFormFieldChangeHandler;
  intl: InjectedIntl;
  isMainResidencyCA?: boolean;
  mainResidency?: MainResidency;
  isMainResidencyUS?: boolean;
};

const OtherResidencies: FC<OtherResidenciesProps> = ({
  isMainResidencyCA = undefined,
  mainResidency = {},
  isOtherResidency = '',
  secondaryResidency = [],
  isMainResidencyUS = undefined,
  intl,
  change,
  productForm,
}: OtherResidenciesProps) => {
  const formatErrorMessage = (prefix?: string, fieldName?: string) => createErrorFormatter(intl, prefix, fieldName);
  const getText = getTextFactory(intl, 'tax');
  const allRefData = getAllRefData({ productForm });
  const citizenshipUS = get(productForm, 'values.citizenshipUS');
  const isSecondaryResidencyField = isOtherResidency === OTHER_RESIDENCY_ANSWERS.YES;

  const handleIsOtherResidencyChange = (event: ChangeEvent<HTMLInputElement>) => {
    // reset the list of secondary residency countries if answer is NO
    if (event.target.value === OTHER_RESIDENCY_ANSWERS.NO) {
      change('secondaryResidency', []);
    }
  };

  const filterResidencies = ({ value }) => {
    const alreadySelected = [
      ...secondaryResidency.map((residency) => residency && residency.country),
      ...(isMainResidencyCA ? [COUNTRIES.CA] : [mainResidency.country]),
    ];
    return !alreadySelected.includes(value);
  };

  const areOtherResidencyRadioOptionsDisabled = () => {
    const mainFiscalResidency = getFormFieldValue(BAO_FORMS, 'mainResidency', undefined);
    const isMainFiscalResidencySelected: boolean =
      isMainResidencyCA || (mainFiscalResidency && mainFiscalResidency.country !== COUNTRIES.US);
    return citizenshipUS && isMainFiscalResidencySelected
      ? [OTHER_RESIDENCY_ANSWERS.YES, OTHER_RESIDENCY_ANSWERS.NO]
      : [];
  };

  useEffect(() => {
    enforceUSFiscalResidency(change);
  }, [change, isMainResidencyCA, isMainResidencyUS]);

  return (
    <>
      <Row>
        <Col xs={12} data-test="field_isOtherResidency" className="field_isOtherResidency label">
          <span>{getText('isOtherResidency1')}</span>
          <BaoTooltip
            name="isOtherResidency"
            stepId={productForm.meta.stepId}
            content={getText('isOtherResidencyUS')}
            position="top"
          >
            <Link href="#" underlined={true}>
              {getText('isOtherResidency2')}
            </Link>
          </BaoTooltip>
          <span>{getText('isOtherResidency3')}</span>
        </Col>

        <Col xs={12} data-test="field_isOtherResidency">
          <ProductFormRadioSelect
            name="isOtherResidency"
            formatErrorMessage={formatErrorMessage()}
            onChange={handleIsOtherResidencyChange}
            disabledOptions={areOtherResidencyRadioOptionsDisabled()}
            inline
          />
        </Col>
      </Row>

      {isSecondaryResidencyField && (
        <Row>
          <Col xs={12} data-test="field_secondaryResidency">
            <Collection
              name="secondaryResidency"
              typeComponent={FiscalResidency}
              data={secondaryResidency}
              allRefData={allRefData}
              change={change}
              filter={filterResidencies}
              addLabel={getText('addSecondaryResidency')}
              productForm={productForm}
              card
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default OtherResidencies;
