import OccupationTitleKeys from 'services/OccupationTitleService/actionTypes';
import {
  occupationTitleActionTypes,
  occupationTitleListRequestFailedAction,
  occupationTitleListRequestSucceededAction,
} from 'types/actions';
import { OccupationTitleServiceReducerState } from 'types/reducers';
import { AnyAction } from 'redux';

export const initialState: OccupationTitleServiceReducerState = {
  occupationList: null,
  isFetching: false,
  fetchingError: null,
  failure: false,
} as OccupationTitleServiceReducerState;

export default (
  state: OccupationTitleServiceReducerState = initialState,
  action: occupationTitleActionTypes | AnyAction,
) => {
  switch (action.type) {
    case OccupationTitleKeys.OCCUPATION_TITLE_SERVICE_LIST_REQUESTED: {
      const { name } = action.options;
      if (name) {
        return {
          ...state,
          [name]: {
            ...state[name],
            isFetching: true,
            fetchingError: null,
            failure: false,
          },
        };
      }

      return {
        ...state,
        isFetching: true,
        fetchingError: null,
        failure: false,
      };
    }
    case OccupationTitleKeys.OCCUPATION_TITLE_LIST_REQUEST_SUCCEEDED: {
      const { name } = action.options;
      if (name) {
        return {
          ...state,
          [name]: {
            ...state[name],
            occupationList: (action as occupationTitleListRequestSucceededAction).occupationList,
            isFetching: false,
            fetchingError: null,
            failure: false,
          },
        };
      }

      return {
        ...state,
        occupationList: (action as occupationTitleListRequestSucceededAction).occupationList,
        isFetching: false,
        fetchingError: null,
        failure: false,
      };
    }
    case OccupationTitleKeys.OCCUPATION_TITLE_SERVICE_LIST_REQUEST_FAILED: {
      const { name } = action.options;
      if (name) {
        return {
          ...state,
          [name]: {
            ...state[name],
            isFetching: false,
            fetchingError: null,
            failure: false,
          },
        };
      }
      return {
        ...state,
        isFetching: false,
        fetchingError: (action as occupationTitleListRequestFailedAction).err,
        failure: true,
      };
    }
    case OccupationTitleKeys.OCCUPATION_TITLE_SERVICE_LIST_RESET: {
      const { name } = action.options;
      if (name) {
        return {
          ...state,
          [name]: initialState,
        };
      }
      return initialState;
    }

    default:
      return state;
  }
};
