import React, { FunctionComponent } from 'react';
import { Alert } from '@nbc-design/alert';
import { Button } from '@nbc-design/button';
import { Card } from '@nbc-design/card';
import { Text } from '@nbc-design/text';
import TextList from '@nbc-design/text-list';
import { InjectedIntl } from 'react-intl';
import { getTextFactory } from 'utils/TextUtils';
import { AttentionOutlineThinColor } from '@nbc-design/icons/lib/web/AttentionOutlineThinColor';
import { redirectToUnifiedLogin } from 'services/ssoRedirectService';
import { sendClickAnalyticsEvent, sendStepLoadedEvent } from 'services/analytics';
import { EVENT_IDS } from 'services/analytics/constants';
import CreateCredentialsMessages from 'messages/CreateCredentials/CreateCredentials.json';
import { getBncId } from 'utils';

export type ExistedEmailProps = {
  intl: InjectedIntl;
  email: string;
};

const ExistedEmail: FunctionComponent<ExistedEmailProps> = (props: ExistedEmailProps) => {
  const { intl, email } = props;

  React.useEffect(() => {
    sendStepLoadedEvent('already existing customer', EVENT_IDS.ST, '', {
      eventInfo: {
        ucsId: 'UCS-bao-003',
        interaction: `warning:${CreateCredentialsMessages.en['createCredentials.existedEmailTitle']}`,
      },
      step: {
        stepId: '3',
        flowId: 'profile creation',
      },
      user: {
        bncId: getBncId(),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getText = getTextFactory(intl, 'createCredentials');
  const getAccessibilityText = getTextFactory(intl, 'accessibility');

  return (
    <Card border="shadow" className="spc-creationProfil__card mc-mb-normal">
      <div className="spc-creationProfil__popupCardBody">
        <AttentionOutlineThinColor title="attention-outline-thin-color" size={{ width: 64, height: 64 }} />
        <div className="text-wrapper">
          <Text className="mc-mb-normal popup-title">{getText('existedEmailTitle')}</Text>
          <Text className="email-explanation">
            {getText('existedEmailExplanation1')} {email} {getText('existedEmailExplanation2')}
          </Text>
          <TextList>
            <TextList.Item className="list-explanations">{getText('existedEmailExplanation3')}</TextList.Item>
            <TextList.Item className="list-explanations">{getText('existedEmailExplanation4')}</TextList.Item>
          </TextList>
          <Text size="xSmall" className="extra-explanation">
            {getText('existedEmailExplanation5')}
          </Text>
          <Alert
            appearance="information"
            title={
              <>
                <b>{getText('existedEmailExplanation6')}</b> {getText('existedEmailExplanation7')}
              </>
            }
            ariaLabelIcon={getAccessibilityText('icon.information')}
            size="small"
            className="info-block"
          />
        </div>
        <div className="button-wrapper">
          <Button
            appearance="primary"
            onClick={() => {
              sendClickAnalyticsEvent('already existing customer', 'Go to Login');
              redirectToUnifiedLogin(intl.locale);
            }}
          >
            {getText('existedEmailConnect')}
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default ExistedEmail;
