import React from 'react';
import { ACCOUNT_SOURCE } from '../constants';
import { useContainerCtx } from '../../../providers/containerCtx/ContainerCtx';
import { TransferFormData, TransferFormRadioKeys } from '../types';
import { Message } from '@nbc-design/message';
import { ProductFormRadioWithSubContent } from 'components/productFormFields/_layout';
import AccountSubContentContainer from './helpers/AccountSubContentContainer';

const TransferAccountsEmployer = () => {
  const { formValues, getText } = useContainerCtx<TransferFormData>();

  const { accounts } = formValues;
  const hasEmployerAccounts = accounts?.some((account) => account.transferSourceCd === ACCOUNT_SOURCE.EMPLYR);

  if (!hasEmployerAccounts) return null;
  return (
    <ProductFormRadioWithSubContent<TransferFormRadioKeys>
      name={'transferAccountEmployer'}
      label={getText('transferAccountEmployer')}
    >
      <AccountSubContentContainer
        filteredSource={ACCOUNT_SOURCE.EMPLYR}
        headerText={getText('accountTransferEmployer')}
        errorKey={'transferEmployerError'}
        MapAccountItem={() => <Message className="dsc-message-employer">{getText('transferApplication')}</Message>}
      />
    </ProductFormRadioWithSubContent>
  );
};

export default TransferAccountsEmployer;
