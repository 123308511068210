import { CURRENCIES, LANG } from './constants';
import Decimal from 'decimal.js';

const formatCurrency = (amount: number, currency: string, locale: string) => {
  const decimalAmount = [LANG.EN].includes(locale)
    ? new Decimal(amount).toFixed(2)
    : new Decimal(amount).toFixed(2).replace(/\./g, ',');
  switch (currency as CURRENCIES) {
    case CURRENCIES.USD:
      return `$${decimalAmount} ${currency}`;
    case CURRENCIES.CAD:
      return `${decimalAmount} $ ${currency}`;
    default:
      return `${decimalAmount}`;
  }
};

export default formatCurrency;
