import React, { Fragment, FunctionComponent, ReactNode } from 'react';
import get from 'lodash/get';
import { InjectedIntl } from 'react-intl';
import { Col, Row } from 'react-flexbox-grid';
import { isObject } from 'utils';
import EditIcon from 'assets/icons/edit';
import { getTextFactory } from 'utils/TextUtils';
import Heading from 'components/Heading';
import { ProductFormValue } from 'components/productFormFields';
import { EMPLOYMENT_VALUES } from '../../Employment/constants';
import { Link } from '@nbc-design/link';

import './styles.scss';

export type DetailsSectionProps = {
  intl: InjectedIntl;
  data: {};
  name: string;
  goToModify: () => void;
};

const DetailsSection: FunctionComponent<DetailsSectionProps> = (props: DetailsSectionProps) => {
  const { intl, data, name, goToModify } = props;

  const getAccessibilityText = getTextFactory(intl, 'accessibility.icon');

  const trans = (suffix: string, prefix = `summary.${name}`): string => {
    suffix = suffix === 'otherPersonFirstName' || suffix === 'proInvolvementFirstName' ? 'firstName' : suffix;
    suffix = suffix === 'otherPersonLastName' || suffix === 'proInvolvementLastName' ? 'lastName' : suffix;
    suffix = suffix === 'otherPersonStatus' ? 'status' : suffix;

    if (
      suffix === 'firstName' ||
      suffix === 'lastName' ||
      suffix === 'middleName' ||
      suffix === 'currentAddress' ||
      suffix === 'email' ||
      suffix === 'birthDate' ||
      suffix === 'none'
    ) {
      prefix = 'global';
    }

    if (prefix === 'identityCheckBnc') {
      prefix = 'identityVerificationBNC';
    }

    if (prefix === 'identityCheck') {
      prefix = 'specificsBankingInfo';
    }

    return intl.formatMessage({ id: `${prefix}.${suffix}` });
  };

  const toggleContent = (): void => {
    const element: HTMLDivElement | null = document.querySelector(`.${name}Details .section__content`);
    element && element.classList.toggle('close');
  };

  const renderFiscalResidency = (data: {}, prefix = 'mainResidency'): ReactNode => {
    return Object.keys(data).map(
      (key: string): ReactNode => {
        const label = trans(key, name);
        const lg = label && label.length > 40;
        const keyName = `${name}.${prefix}.${key}`;
        const fieldName = isObject(data[key]) ? `${keyName}.${intl.locale}` : keyName;

        return (
          <Col key={keyName} xs={12} md={lg ? 12 : 6} lg={lg ? 12 : 4} className="field" data-test={`field_${keyName}`}>
            <ProductFormValue name={fieldName} label={label} />
          </Col>
        );
      },
    );
  };

  const renderList = (data: [], key: string): ReactNode => {
    return data.map((fieldName: {}, index: number) => [
      <Col xs={12} md={12} lg={12} key={index}>
        {index === 0 && key === 'secondaryResidency' && (
          <p data-test={`field_${key}`} className="list">
            {trans('secondaryCountry', 'tax')}
          </p>
        )}
      </Col>,
      renderFiscalResidency(fieldName, `${key}[${index}]`),
    ]);
  };

  const renderNumberOfDependents = (data: number, lg: boolean, fieldName: string, label: string): ReactNode => {
    const numberOfDependents = data === 0 ? trans('none') : data.toString();
    return (
      <Col
        key={fieldName}
        xs={12}
        md={lg ? 12 : 6}
        lg={lg ? 12 : 4}
        className="field"
        data-test="field_numberOfDependents"
      >
        <ProductFormValue name={fieldName} label={label} displayedValue={numberOfDependents} />
      </Col>
    );
  };

  const renderPhoneNumbers = (phoneNumbers: string[], key: string, fieldName: string, label: string): ReactNode => {
    return (
      <Fragment>
        {phoneNumbers.map((_, index) => (
          <Col key={key} xs={12} md={6} lg={4} className="field" data-test={`field_name_${fieldName}`}>
            <ProductFormValue name={`${fieldName}[${index}]`} label={label} />
          </Col>
        ))}
      </Fragment>
    );
  };

  const renderMainHolderName = (
    holderData: {
      mainHolderName: {
        firstName: string;
        lastName: string;
      };
      oppositePartyRole: {
        code: string;
        en: string;
        fr: string;
      };
    },
    key: string,
    fieldName: string,
    label: string,
  ): ReactNode => {
    return (
      <Fragment>
        <Col key={key} xs={12} md={6} lg={4} className="field" data-test={`field_name_${fieldName}`}>
          <ProductFormValue
            name={'personalInformation.oppositePartyRole'}
            label={`${label}${holderData.mainHolderName.firstName} ${holderData.mainHolderName.lastName}?`}
          />
        </Col>
      </Fragment>
    );
  };

  const renderContent = (data: any, fieldNamePrefix: string): ReactNode => {
    return Object.keys(data).map(
      (key: string): ReactNode => {
        if (key === 'editable') {
          return null;
        }

        let label = trans(key, name);

        const lg = !!label && label.length > 40;
        const fieldName = `${fieldNamePrefix}.${isObject(data[key]) ? `${key}.${intl.locale}` : key}`;
        const dataTestlabel = `${fieldNamePrefix}_${key}`;

        if (name === 'tax' && key === 'mainResidency') return renderFiscalResidency(data[key]);
        if (name === 'tax' && key === 'secondaryResidency') return renderList(data[key], key);
        if (name === 'finances' && key === 'numberOfDependents') {
          return renderNumberOfDependents(data[key], lg, fieldName, label);
        }
        if (name === 'personalInformation' && key === 'phoneNumber') {
          return renderPhoneNumbers(data[key], key, fieldName, label);
        }

        if (name === 'personalInformation' && key === 'oppositePartyRole') {
          return null;
        }
        if (name === 'personalInformation' && key === 'mainHolderName') {
          return renderMainHolderName(data, key, fieldName, label);
        }

        if (name === 'identityCheck' && key === 'bankingAccount') return renderFiscalResidency(data[key], key);

        if (
          name === 'employment' &&
          key === 'employerName' &&
          get(data, ['status', 'code'], '') === EMPLOYMENT_VALUES.SELF_EMPLOYED
        ) {
          label = trans('companyName', name);
        }

        if (
          name === 'employmentV2' &&
          key === 'employerName' &&
          get(data, ['status', 'code'], '') === EMPLOYMENT_VALUES.SELF_EMPLOYED
        ) {
          label = trans('companyName', name);
        }

        return (
          <Col key={key} xs={12} md={lg ? 12 : 6} lg={lg ? 12 : 6} className="field" data-test={dataTestlabel}>
            <ProductFormValue name={fieldName} label={label} className="summary-form-value-section" />
          </Col>
        );
      },
    );
  };

  // do not render empty sections
  if (!data || Object.keys(data).length === 0) {
    return null;
  }

  return (
    <div data-test={`${name}-detailsSection`} className={`${name}Details summary__section`}>
      <Row className="section__header">
        <Col xs={11} md={11} xl={11} onClick={toggleContent} className="section__title">
          <Heading type="h3" size={5} data-test="label_page_title">
            {trans('pageTitle')}
          </Heading>
        </Col>
        {(data as any).editable && (
          <Col xs={1} md={1} xl={1} onClick={goToModify} className="section__icon" data-test="image_edit_icon">
            <Link href="#" underlined={false}>
              <EditIcon className="editIcon" title={getAccessibilityText('edit')} />
            </Link>
          </Col>
        )}
      </Row>
      <Row className={`section__content`}>{renderContent(data, name)}</Row>
    </div>
  );
};

export default DetailsSection;
