import React from 'react';
import { ACCOUNT_SOURCE } from '../constants';
import ProductFormRadioWithSubContent from 'components/productFormFields/_layout/ProductFormRadioWithSubContent/ProductFormRadioWithSubContent';
import { useContainerCtx } from 'providers/containerCtx/ContainerCtx';
import { TransferFormData } from '../types';
import AccountSubContentContainer from './helpers/AccountSubContentContainer';
import TransferAccountsInternalItem from './TransferAccountsInternalItem';

const TransferAccountsInternal = () => {
  const { formValues, getText } = useContainerCtx<TransferFormData>();

  const { accounts } = formValues;
  const hasInternalAccounts = accounts?.some((account) => account.transferSourceCd === ACCOUNT_SOURCE.INTERNAL);

  if (!hasInternalAccounts) return null;
  return (
    <ProductFormRadioWithSubContent label={getText('transferAccountInternal')} name={'transferAccountInternal'}>
      <AccountSubContentContainer
        filteredSource={ACCOUNT_SOURCE.INTERNAL}
        headerText={getText('accountTransferInternal')}
        errorKey={'transferInternalError'}
        MapAccountItem={TransferAccountsInternalItem}
      />
    </ProductFormRadioWithSubContent>
  );
};

export default TransferAccountsInternal;
