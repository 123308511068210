import { ConfigData } from 'types/interfaces';

const config: ConfigData = {
  ID: 'SPOUSE_INFOS_STEP',
  SIDEBAR_MENU: true,
  PROGRESS_BAR: true,
  FOOTER: true,
  BUTTON_HELP: true,
  BUTTON_LANG: true,
  BUTTON_QUIT: true,
};

export default config;
