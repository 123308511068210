import { Text } from '@nbc-design/text';
import Title from 'components/Title';
import { ProductFormSelect } from 'components/productFormFields';
import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { InjectedIntl } from 'react-intl';
import { GenericProps, ProductForm } from 'types/interfaces';
import { getTextFactory } from 'utils/TextUtils';
import { createErrorFormatter, isJointMenu } from 'utils/productFormUtils';
import { CONFIG } from '.';
import FinancialInvolvement from './components/FinancialInvolvement/FinancialInvolvement';
import { EmployerPhoneNumber, Occupation } from './types';
import './styles.scss';
import ActiveOccupations from './components/ActiveOccupations/ActiveOccupations';

type EmploymentProps = GenericProps & {
  intl: InjectedIntl;
  productForm?: ProductForm;
  occupationType?: string | null;
  startOccupationDate?: string | null;
  employmentType?: string | null;
  employmentCode?: string | null;
  employerName?: string | null;
  employerPhoneNumber?: EmployerPhoneNumber;
  activeOccupations?: Occupation[];
  proInvolvementRelation?: string;
  proInvolvementCompanyName?: string;
  proInvolvementFirstName?: string;
  proInvolvementLastName?: string;
  proInvolvementRelationOther?: string;
  hasBrokerageInvolvement?: boolean;
  hasProInvolvement?: boolean;
  hasAdminInvolvement?: boolean;
  hasTenPercentInvolvement?: boolean;
  hasTwentyPercentInvolvement?: boolean;
  adminInvolvementList?: object[];
  tenPercentInvolvementList?: object[];
  twentyPercentInvolvementList?: object[];
};

const Employment: FunctionComponent<EmploymentProps> = (props: EmploymentProps) => {
  const { intl, occupationType = null, meta }: EmploymentProps = props;

  const isJointStep = meta && meta.stepId === CONFIG.STEP_JOINT_ID;

  const getText = getTextFactory(intl, 'employmentV2');
  const getGlobalText = getTextFactory(intl, 'global');

  const formatErrorMessage = (prefix?: string) => {
    return createErrorFormatter(intl, prefix);
  };

  return (
    <div className="employment__form">
      {isJointMenu(meta) && (
        <Text size="large" className={isJointStep ? 'joint-spouse-title' : 'joint-applicant-title'}>
          {isJointStep ? getGlobalText('spouseMenuTitle') : getGlobalText('applicantMenuTitle')}
        </Text>
      )}

      <Title label={getText('pageTitle')} subTitle={getText('pageSubTitle')} />

      <Row className="collection-wrapper">
        <Col xs={12} md={6} lg={6} data-test="field_occupation_type">
          <ProductFormSelect
            name="occupationType"
            label={getText('occupationType')}
            formatErrorMessage={formatErrorMessage()}
            placeholder={getText('selectOccupation')}
            sort={true}
          />
        </Col>
      </Row>

      {occupationType !== null && <ActiveOccupations {...props} />}

      <FinancialInvolvement {...props} />
    </div>
  );
};

export default Employment;
