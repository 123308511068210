import React, { FunctionComponent } from 'react';
import { InjectedIntl } from 'react-intl';
import { Row, Col } from 'react-flexbox-grid';
import Heading from 'components/Heading';
import Title from 'components/Title';
import { getTextFactory } from 'utils/TextUtils';
import { createErrorFormatter } from 'utils/productFormUtils';
import { ProductFormCheckbox } from 'components/productFormFields';

export type IdentityVerificationBNCProps = {
  intl: InjectedIntl;
};

const IdentityVerificationBNC: FunctionComponent<IdentityVerificationBNCProps> = (props) => {
  const { intl } = props;

  const formatMessageError = () => createErrorFormatter(intl);
  const getTextIdentVerif = getTextFactory(intl, 'identityVerification');
  const getText = getTextFactory(intl, 'identityVerificationBNC');

  return (
    <div className="identityVerificationBNC__form">
      <Title label={getTextIdentVerif('pageTitle')} subTitle={getText('description')} />

      <Heading type="h3" size={5}>
        {getText('confirm')}
      </Heading>

      <Row>
        <Col xs={12} md={12} lg={12}>
          <ProductFormCheckbox
            name="identCheckConfirmation"
            label={getText('identCheckConfirmation')}
            formatErrorMessage={formatMessageError()}
          />
        </Col>
      </Row>
    </div>
  );
};

export default IdentityVerificationBNC;
