/**
 * Set a global error, will prevent going on next step
 *    return {
 *      _error: 'My global error',
 *    };
 *
 * @param {Object} values
 * @param {Object} props
 * @returns {Object|null}
 */
import { AccountsStep2FormData, AccountStep2Data, CurrencyData } from './types';
import { ErrorData } from '../../types/interfaces';

const validation = (
  { accounts = [] }: AccountsStep2FormData,
  { intl }: any,
): { _error: string } | { _error: ErrorData } | null => {
  const accountsList: boolean[] = accounts.map(
    ({ values = [] }: AccountStep2Data): boolean => !!values.filter(({ value }: CurrencyData): boolean => value).length,
  );
  const simpleList: boolean[] = ([] as any[]).concat(...accountsList);
  const isValid = !simpleList.filter((value: boolean): boolean => !value).length;

  return isValid
    ? null
    : {
        _error: intl.formatMessage({ id: 'yourAccounts.globalError' }),
      };
};

export default validation;
