import React, { FunctionComponent } from 'react';
import { Button } from '@nbc-design/button';
import { Card } from '@nbc-design/card';
import { Text } from '@nbc-design/text';
import { InjectedIntl } from 'react-intl';
import { getTextFactory } from 'utils/TextUtils';
import { LockedColor } from '@nbc-design/icons/lib/web/LockedColor';
import { Lock } from '@nbc-design/icons/lib/web/Lock';

import { redirectToUnifiedLogin } from 'services/ssoRedirectService';
import { sendClickAnalyticsEvent } from 'services/analytics';

export type ProfileConfirmationProps = {
  intl: InjectedIntl;
};

const ProfileConfirmation: FunctionComponent<ProfileConfirmationProps> = (props: ProfileConfirmationProps) => {
  const { intl } = props;

  const getText = getTextFactory(intl, 'createCredentials');

  return (
    <Card border="shadow" className="spc-creationProfil__card mc-mb-normal confirmation-card">
      <div className="spc-creationProfil__popupCardBody">
        <LockedColor
          size={{
            width: 128,
            height: 128,
          }}
          title="locked-color"
        />

        <div className="confirmation-text-wrapper">
          <Text className="mc-mb-xsmall confirmation-title">{getText('createdProfileConfirmation')}</Text>
          <Text className="mc-mb-normal confirmation-text">{getText('createdProfileExplanation')}</Text>
        </div>
        <div className="button-wrapper">
          <Button
            appearance="primary"
            icon={<Lock size="medium" title="lock" />}
            onClick={() => {
              sendClickAnalyticsEvent('profile creation confirmation', 'Go to Login');
              redirectToUnifiedLogin(intl.locale);
            }}
          >
            {getText('profileConnectButton')}
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default ProfileConfirmation;
