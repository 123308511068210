import React, { FunctionComponent } from 'react';
import BncRecaptcha from './components';
import Configs, { ConfigType } from 'configs';

export enum SiteKeyType {
  frontend,
  safeid,
}

export type CaptchaProps = {
  siteKeyType: SiteKeyType;
  success: Function;
  timeout: Function;
  failure: Function;
  invisible?: boolean;
};

const Captcha: FunctionComponent<CaptchaProps> = (props: CaptchaProps) => {
  const { siteKeyType, success, timeout, failure, invisible } = props;
  const { CAPTCHA }: ConfigType = Configs.params;

  return (
    <div data-test="field_captcha">
      <BncRecaptcha
        siteKey={siteKeyType === SiteKeyType.safeid ? CAPTCHA.SITE_KEY_SAFEID : CAPTCHA.SITE_KEY_FRONTEND}
        exchangeTokenEndpoint={CAPTCHA.EXCHANGE_TOKEN_ENDPOINT}
        googleRecaptchaEndpoint={CAPTCHA.GOOGLE_ENDPOINT}
        success={success}
        failure={failure}
        timeoutRecaptcha={timeout}
        invisible={invisible}
      />
    </div>
  );
};

export default Captcha;
