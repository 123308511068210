import { PROGRESS_BAR_STEPS } from 'utils/stepsConstants';
import { AppStep } from 'types/interfaces';

export const getProgressIndex = (stepId: string): number => {
  const menuStepsCount: number = PROGRESS_BAR_STEPS.length;
  const currentIndex: number = PROGRESS_BAR_STEPS.findIndex(
    (step: AppStep): boolean | undefined =>
      step.ID === stepId ||
      (step.SUB_STEP_IDS && !!step.SUB_STEP_IDS.find((subStep: string): boolean => subStep === stepId)),
  );

  return currentIndex === -1 ? menuStepsCount : currentIndex + 1;
};

export const getProgressPercentage = (stepId: string): number => {
  const menuStepsCount: number = PROGRESS_BAR_STEPS.length;
  const progressIndex = getProgressIndex(stepId);

  return (progressIndex * 100) / menuStepsCount;
};
