import React, { FunctionComponent, useMemo } from 'react';
import { AccountsStep3FormData, LegislationData } from '../types';
import LegislationSelector from './LegislationSelector';
import AccountSectionFormGroup from './_layout/AccountSectionFormGroup';
import { getRefData } from '../../../utils/productFormUtils';
import { useContainerCtx } from 'providers/containerCtx/ContainerCtx';
import { FEDERAL_ONLY, PROVINCIAL_ONLY } from '../constants';

export type LegislationSelectorsProps = {};

const LegislationSelectors: FunctionComponent<LegislationSelectorsProps> = () => {
  const {
    formValues: { legislations },
    genericProps,
    getText,
  } = useContainerCtx<AccountsStep3FormData>();

  const provinceRefData = getRefData(genericProps, 'province');

  const legislationsWithUpdatedValues = useMemo((): LegislationData[] => {
    return legislations?.map((legislation) => {
      const accountType = legislation.accountType;
      let values = legislation.values;

      if (accountType && FEDERAL_ONLY.includes(accountType.code)) {
        values[0].value = true;
      } else if (accountType && PROVINCIAL_ONLY.includes(accountType.code)) {
        values[1].value = true;
      }

      return {
        ...legislation,
        values,
      };
    });
  }, [legislations]);

  if (!legislations || legislations.length === 0) return null;
  return (
    <AccountSectionFormGroup
      testId={'jurisdictionSelectors'}
      label={{
        text: getText('jurisdiction'),
        htmlFor: `legislations[0].province`,
      }}
      tooltipContent={getText('jurisdictionInfo')}
    >
      {legislationsWithUpdatedValues.map((legislation, index) => (
        <LegislationSelector
          key={`legislationSelector-${index}`}
          index={index}
          legislation={legislation}
          provinceRefData={provinceRefData}
        />
      ))}
    </AccountSectionFormGroup>
  );
};

export default LegislationSelectors;
