import React, { FunctionComponent } from 'react';
import { Text } from '@nbc-design/text';
import { Alert } from '@nbc-design/alert';
import { AttentionFilledColor } from '@nbc-design/icons/lib/web/AttentionFilledColor';

export type AlertMessageProps = {
  title: string;
  description: string;
  ariaLabel: string;
};

const AlertMessage: FunctionComponent<AlertMessageProps> = ({ title, description, ariaLabel }) => (
  <Alert size={'normal'} appearance="warning" className="documents-warning" dataTest="limit_date">
    {
      <React.Fragment>
        <AttentionFilledColor size="small" title={ariaLabel} />
        <Text size="paragraph">
          {
            <React.Fragment>
              <span>{title}</span>
              <span>{description}</span>
            </React.Fragment>
          }
        </Text>
      </React.Fragment>
    }
  </Alert>
);

export default AlertMessage;
