import React, { ChangeEvent, FunctionComponent } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import get from 'lodash/get';

import { GenericProps, Properties, Values } from 'types/interfaces';
import { getTextFactory } from 'utils/TextUtils';
import { createErrorFormatter } from 'utils/productFormUtils';
import Title from 'components/Title';
import BankRef from './components/BankRef';
import IdentityValidationOptions from './components/IdentityValidationOptions';

import './style.scss';
import { AccountDataType } from '../SpecificsBankingInfo/types';

export type IdentityVerificationProps = GenericProps & {
  indvIdentMethod: string | undefined;
  firstParticipantBankAccount: object;
};

const IdentityVerification: FunctionComponent<IdentityVerificationProps> = (props: IdentityVerificationProps) => {
  const { intl, productForm, indvIdentMethod, change } = props;

  const getText = getTextFactory(intl, 'identityVerification');
  const bankingAccountSchema: { properties: Properties } = get(productForm, 'schema.definitions.BankingAccountUI');
  const defaultValues: Values = get(productForm, 'values', {});
  const showBankRef = indvIdentMethod === 'BANKREF';

  const handleValidationMethodChange = (event: ChangeEvent<HTMLInputElement>) => {
    change('firstParticipantBankAccount', defaultValues['firstParticipantBankAccount']);
  };

  const secondParticipantData: AccountDataType = get(productForm, 'values.secondParticipantBankAccount', undefined);

  return (
    <div className="identityVerification__form">
      <Title label={getText('pageTitle')} subTitle={getText('description')} />

      <Row>
        <Col xs={12} md={12} lg={12}>
          <IdentityValidationOptions
            name="indvIdentMethod"
            formatErrorMessage={createErrorFormatter(intl)}
            onChange={handleValidationMethodChange}
          />
          {showBankRef && (
            <>
              <BankRef
                name="firstParticipantBankAccount"
                description={
                  secondParticipantData ? getText('OptionBankRefTitleFirstParticipant') : getText('OptionBankRefTitle')
                }
                bankingAccountSchema={bankingAccountSchema}
                intl={intl}
                change={change}
              />
              {secondParticipantData && (
                <BankRef
                  name="secondParticipantBankAccount"
                  description={getText('OptionBankRefTitleSecondParticipant')}
                  bankingAccountSchema={bankingAccountSchema}
                  intl={intl}
                  change={change}
                />
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default IdentityVerification;
