import React, { PureComponent } from 'react';
import { InjectedIntl } from 'react-intl';
import RecaptchaSdk, { RecaptchaSdkType } from './RecaptchaSdk';
import { BNC_RECAPTCHA_ID } from './utils';

export type BncRecaptchaProps = {
  siteKey: string;
  exchangeTokenEndpoint: string;
  googleRecaptchaEndpoint: string;
  success: Function;
  failure: Function;
  timeoutRecaptcha: Function;
  intl: InjectedIntl;
  hasToReset?: boolean;
  resetDone?: Function;
  invisible?: boolean;
};

export interface BncRecaptchaType {
  bncCaptchaSdk: RecaptchaSdkType | null;
  reset: () => void;
}

class BncRecaptcha extends PureComponent<BncRecaptchaProps> implements BncRecaptchaType {
  bncCaptchaSdk: RecaptchaSdkType | null;

  constructor(props: BncRecaptchaProps) {
    super(props);

    this.bncCaptchaSdk = null;
  }

  componentDidMount() {
    const {
      siteKey,
      exchangeTokenEndpoint,
      googleRecaptchaEndpoint,
      success,
      failure,
      timeoutRecaptcha,
      intl,
      invisible,
    } = this.props;

    this.bncCaptchaSdk = new RecaptchaSdk(
      siteKey,
      exchangeTokenEndpoint,
      `${googleRecaptchaEndpoint}?hl=${intl.locale}`,
      BNC_RECAPTCHA_ID,
      invisible,
    );
    this.bncCaptchaSdk.initCallBacks(success, failure, timeoutRecaptcha);
    this.bncCaptchaSdk.show();
  }

  componentDidUpdate() {
    if (this.props.hasToReset) {
      this.reset();
      this.props.resetDone && this.props.resetDone();
    }
  }

  reset = (): void => {
    this.bncCaptchaSdk && this.bncCaptchaSdk.reset();
  };

  render() {
    return <div id={BNC_RECAPTCHA_ID} />;
  }
}

export default BncRecaptcha;
