import React, { FunctionComponent, MouseEvent, ReactElement } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { History } from 'history';
import { Button } from '@nbc-design/button';
import { Card } from '@nbc-design/card';
import { Heading } from '@nbc-design/heading';
import { Bank } from '@nbc-design/icons/lib/web/Bank';
import { FinancialGrowthColor } from '@nbc-design/icons/lib/web/FinancialGrowthColor';
import { RelocatingEmployee } from '@nbc-design/icons/lib/web/RelocatingEmployee';
import { Selfie } from '@nbc-design/icons/lib/web/Selfie';
import { Link } from '@nbc-design/link';
import { Radio } from '@nbc-design/radio';
import { Text } from '@nbc-design/text';
import Modal from 'components/Modal/Modal';
import { ROUTE } from 'utils/constants';
import { getTextFactory } from 'utils/TextUtils';
import { sendClickAnalyticsEvent, sendStepLoadedEvent } from 'services/analytics';
import { EVENT_IDS } from 'services/analytics/constants';
import { redirectToUnifiedLogin } from 'services/ssoRedirectService';
import { manageAutomaticRedirect } from 'services/ssoRedirectService/helper';
import { BAO_AUTHENT_TYPE, setAuthenticatedWith } from 'services/loginManager';
import DocumentsPopup from './components/DocumentsPopup';
import StepsPopup from './components/StepsPopup';
import { focusBody, scrollToTop } from 'utils/domUtils';
import { getBncId } from 'utils';

import './styles.scss';

export type ClientRedirectNewProps = InjectedIntlProps & {
  history: History;
};

export let modalProps = {
  title: '',
  Content: <div />,
  headerClass: 'no-border-bottom',
  onClose: () => {},
  isCloseHidden: false,
  hasIcon: false,
  hasFooter: true,
  close: '',
};

const ClientRedirectNew: FunctionComponent<ClientRedirectNewProps> = (props: ClientRedirectNewProps) => {
  const { intl, history } = props;

  const [choice, setChoice] = React.useState<string>('');

  const [showModal, setShowModal] = React.useState<boolean>(false);

  React.useEffect(() => {
    scrollToTop('.dsl-form__scrollZone');
    focusBody();

    sendStepLoadedEvent('welcome', EVENT_IDS.FS, '', {
      eventInfo: {
        ucsId: 'UCS-bao-0001',
      },
      step: {
        stepId: '1',
        flowId: 'generic',
      },
      user: {
        bncId: getBncId(),
      },
    });

    manageAutomaticRedirect(intl.locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getText = getTextFactory(intl, 'clientRedirectNew');

  const closeModal = (): void => {
    setShowModal(false);
  };

  const handleLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
    const { name } = event.target as HTMLAnchorElement;

    let modalTitle: string;
    let modalContent: ReactElement;
    let hasFooter: boolean;
    let close: string;

    if (name === 'documents') {
      modalTitle = getText('documents.modalTitle');
      modalContent = <DocumentsPopup intl={intl} />;
      hasFooter = false;
      close = '';
    } else {
      modalTitle = '';
      modalContent = <StepsPopup intl={intl} />;
      hasFooter = true;
      close = getText('steps.buttonTitle');
    }

    modalProps = {
      ...modalProps,
      ...{ title: modalTitle, Content: modalContent, hasFooter, close, onClose: closeModal },
    };
    setShowModal(true);
  };

  const goToCreateProfile = (): void => {
    setAuthenticatedWith(BAO_AUTHENT_TYPE);
    history.push({ pathname: ROUTE.CREATE_USER_PROFILE });
  };

  return (
    <div className="layout-content">
      <div className="client-redirect-wrapper">
        <div className="mc-d-flex mc-align-items-center mc-mb-medium">
          <FinancialGrowthColor size={{ width: 80, height: 80 }} title="financial-growth-color" />
          <div className="spc-choix__subtitle">
            <Heading type="h2" size={5}>
              {getText('pageHeader')}
            </Heading>
          </div>
        </div>
        <Heading type="h1" className="mc-mb-medium">
          {getText('pageTitle')}
        </Heading>

        <Card border="shadow" className="mc-mb-xsmall">
          <Radio
            id="choix1"
            name="choice"
            value="hasAccess"
            label={
              <Heading type={'span'} size={5}>
                {getText('hasAccess.cardTitle')}
              </Heading>
            }
            text={<div className="mc-mt-xsmall">{getText('hasAccess.explanation')}</div>}
            onChange={(e) => {
              setChoice(e.target.value);
              sendClickAnalyticsEvent('welcome', 'Already client');
            }}
          />
          {choice === 'hasAccess' && (
            <div className="spc-choix__hiddenContent">
              <Button
                size="large"
                appearance="primary"
                name="yes-button"
                className="mc-mb-xsmall"
                onClick={() => {
                  sendClickAnalyticsEvent('welcome', 'Go to Login');
                  redirectToUnifiedLogin(intl.locale);
                }}
              >
                {getText('hasAccess.buttonTitle')}
              </Button>
              <div className="no-account-link-wrapper">
                <Link
                  underlined
                  onClick={() => {
                    goToCreateProfile();
                  }}
                >
                  {getText('hasAccess.noAccountLink')}
                </Link>
              </div>
            </div>
          )}
        </Card>

        <Card border="shadow">
          <Radio
            id="choix2"
            name="choice"
            value="hasNoAccess"
            label={
              <Heading type={'span'} size={5}>
                {getText('hasNoAccess.cardTitle')}
              </Heading>
            }
            text={<div className="mc-mt-xsmall">{getText('hasNoAccess.explanation')}</div>}
            onChange={(e) => {
              setChoice(e.target.value);
              sendClickAnalyticsEvent('welcome', 'Become client');
            }}
          />

          {choice === 'hasNoAccess' && (
            <div className="spc-choix__hiddenContent">
              <Heading type="h3" size={6} className="mc-mb-normal mc-fs-4">
                {getText('openAccount.generalExplanation')}
              </Heading>
              <ul className="spc-avantCommencerList mc-mb-medium">
                <li className="mc-d-flex mc-align-items-center mc-pb-normal">
                  <Selfie title="selfie" size={{ width: 40, height: 40 }} className="spc-avantCommencerList__icon" />
                  <div>
                    <Text appearance="mutedDarkGrey" size="small">
                      {getText('openAccount.explanation2')}
                      &nbsp;
                      <Link underlined name="steps" onClick={(e) => handleLinkClick(e)}>
                        {getText('openAccount.link2')}
                      </Link>
                    </Text>
                  </div>
                </li>
                <li className="mc-d-flex mc-align-items-center mc-pb-normal">
                  <Bank title="bank" size={{ width: 40, height: 40 }} className="spc-avantCommencerList__icon" />
                  <div>
                    <Text appearance="mutedDarkGrey" size="small">
                      {getText('openAccount.explanation3')}
                    </Text>
                  </div>
                </li>

                <li className="mc-d-flex mc-align-items-center">
                  <RelocatingEmployee
                    title="relocating-employee"
                    size={{ width: 40, height: 40 }}
                    className="spc-avantCommencerList__icon"
                  />
                  <div>
                    <Text appearance="mutedDarkGrey" size="small">
                      {getText('openAccount.explanation4')}
                    </Text>
                  </div>
                </li>
              </ul>

              <Button
                size="large"
                appearance="primary"
                name="no-button"
                className="mc-mb-xsmall"
                onClick={() => {
                  goToCreateProfile();
                }}
              >
                {getText('openAccount.buttonTitle')}
              </Button>
            </div>
          )}
        </Card>
        <div className="pending-request-link-wrapper">
          <Link
            underlined
            onClick={() => {
              sendClickAnalyticsEvent('welcome', 'Go to Login');
              redirectToUnifiedLogin(intl.locale);
            }}
          >
            {getText('pendingRequest.link')}
          </Link>
        </div>
      </div>
      {showModal && <Modal {...modalProps} name={'open:identity verification'} text={''} />}
    </div>
  );
};

export default ClientRedirectNew;
