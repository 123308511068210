import { ErrorsDictionary } from 'types/interfaces';
import { DateTime } from 'luxon';
import { SpouseInformationErrorData, SpouseInformationErrorsDictionary, SpouseInformationFormData } from './types';
import { ERRORTYPE } from 'utils/validation';
import { isCleanSIN, isObfuscatedText, validSocialInsurance } from 'utils';

export const SPOUSE_INFORMATION_ERRORTYPE: SpouseInformationErrorsDictionary = {
  ...ERRORTYPE,
  MUST_BE_MAJOR: { type: 'spouseInformation.mustBeMajor' },
};
export const birthDateValidation = (values: SpouseInformationFormData): ErrorsDictionary => {
  const { birthDate } = values;

  if (isDateInvalid(birthDate)) {
    return { birthDate: SPOUSE_INFORMATION_ERRORTYPE.PATTERN };
  }
  if (isMajor(birthDate)) {
    return { birthDate: SPOUSE_INFORMATION_ERRORTYPE.MUST_BE_MAJOR };
  }
  return {};
};

const isDateInvalid = (birthDate: string) => {
  if (!birthDate) {
    return true;
  }

  const date = DateTime.fromISO(birthDate);
  return !date.isValid || date.year < 1900 || (date.diffNow('days').toObject().days || 0) >= 0;
};

const isMajor = (birthDate: string) => {
  const date = DateTime.fromISO(birthDate);
  const todayDate = DateTime.utc();
  const age = todayDate.diff(date, 'years').years;
  return age < 16;
};

export const validateSocialInsuranceNumber = (values: SpouseInformationFormData): ErrorsDictionary => {
  const { socialInsuranceNumber } = values;
  if (!socialInsuranceNumber) {
    return { socialInsuranceNumber: ERRORTYPE.REQUIRED };
  }
  const isValid: boolean =
    isObfuscatedText(socialInsuranceNumber) ||
    (isCleanSIN(socialInsuranceNumber) && validSocialInsurance(socialInsuranceNumber));
  return {
    ...(!isValid ? { socialInsuranceNumber: ERRORTYPE.INVALIDSIN } : {}),
  };
};

const validation = (values: SpouseInformationFormData): SpouseInformationErrorData => {
  return {
    ...birthDateValidation(values),
    ...validateSocialInsuranceNumber(values),
  };
};

export default validation;
