import { createProductFormService } from 'bnc-react-forms';
import requestsManager from 'bnc-react-services/managers/RequestsManager';
import get from 'lodash/get';

import { setupRequest } from 'utils/productFormUtils';

export const handleServiceError = (err?: unknown) => {
  const message = get(err, 'response.message', get(err, 'message', 'unknown error'));

  console.error(get(err, 'result', message));
};

export default function makeProductFormService() {
  return createProductFormService({
    setupRequest,
    callEffect: requestsManager.call,
    onError: handleServiceError,
  });
}
