import { ROUTE } from 'utils/constants';

const config = {
  PATH: ROUTE.CLIENT_REDIRECT_OLD,
  ID: 'CLIENT_REDIRECT_OLD',
  SIDEBAR_IMAGE: false,
  BUTTON_HELP: true,
  BUTTON_LANG: true,
};

export default config;
