import React, { FunctionComponent } from 'react';
import { InjectedIntl } from 'react-intl';
import { NbcDirectBrokerageFrColor } from '@nbc-design/icons/lib/web/NbcDirectBrokerageFrColor';
import { NbcDirectBrokerageEnColor } from '@nbc-design/icons/lib/web/NbcDirectBrokerageEnColor';
import { NbcColor } from '@nbc-design/icons/lib/web/NbcColor';
import Help from './Help';
import LocaleButton from './LocaleButton';
import Quit from './Quit';
import { ConfigData, ProductFormFieldChangeHandler } from 'types/interfaces';
import { getTextFactory } from 'utils/TextUtils';

import './styles.scss';

type changeLocaleParams = {
  locale: string;
  messages: {
    [id: string]: {};
  };
};

export type TopNavProps = {
  changeLocale: (data: changeLocaleParams) => void;
  change: ProductFormFieldChangeHandler;
  intl: InjectedIntl;
  config: ConfigData;
};

const TopNav: FunctionComponent<TopNavProps> = (props: TopNavProps) => {
  const { change, changeLocale, intl, config } = props;

  const getAccessibilityText = getTextFactory(intl, 'accessibility');
  const logoSize: {
    width: number;
    height: number;
  } = {
    width: 216,
    height: 70,
  };

  return (
    <div className="top-nav">
      <div className="logo-bnc">
        <div className="desktop-logo">
          {intl.locale === 'fr' ? (
            <NbcDirectBrokerageFrColor size={logoSize} title={getAccessibilityText('logo')} />
          ) : (
            <NbcDirectBrokerageEnColor size={logoSize} title={getAccessibilityText('logo')} />
          )}
        </div>
        <div className="mobile-logo">
          <NbcColor size="large" title={getAccessibilityText('logo')} />
        </div>
      </div>
      <div className="nav-buttons">
        <Help config={config} intl={intl} />
        <LocaleButton config={config} change={change} changeLocale={changeLocale} />
        <Quit config={config} intl={intl} />
      </div>
    </div>
  );
};

export default TopNav;
