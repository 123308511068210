import cx from 'classnames';
import get from 'lodash/get';
import noop from 'lodash/noop';
import React, { PureComponent } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { InjectedIntl } from 'react-intl';
import { Autocomplete } from '@nbc-design/autocomplete';
import { ISuggestion } from '@nbc-design/autocomplete/dist/interface';
import { FormGroup } from '@nbc-design/form-group';
import { Input } from '@nbc-design/input';
import { Text } from '@nbc-design/text';
import { ProductForm, RefDataContainer, Schema } from 'types/interfaces';
import { getTextFactory } from 'utils/TextUtils';
import { getFieldMaxLength, getFieldMinLength, isFieldRequired } from 'utils/productFormUtils';
import ProductFormDateFields from '../ProductFormDateFields';
import ProductFormSelect from '../ProductFormSelect';
import { FormComponentProps } from '../types';
import { getErrorMessage, getLabelText, isInvalidOnChange } from '../utils';
import { Address, searchedAddresses } from './types';
import { formatAddress, getSelectOptions } from './utils';
import { SOURCES, updateAddressFormData } from 'utils';

import './styles.scss';

export type ProductFormAddressSelectProps = {
  intl: InjectedIntl;
  placeholder: string;
  inline: boolean;
  addresses: Address[];
  isLoading: boolean;
  searchAddress: Function;
  productForm: ProductForm;
  manualModeLabel: string;
  isShowApt: boolean;
  dispatchUpdate: Function;
} & FormComponentProps;

export type ProductFormAddressSelectState = {
  apartment: string;
  isUpdated: boolean;
};

const validateField = (schemaField: RefDataContainer, value: string) => {
  if (!schemaField.pattern) {
    return true;
  }

  const { pattern, title } = schemaField;

  try {
    const regExp = new RegExp(pattern);
    return regExp.test(value);
  } catch (err) {
    console.error(`Error building the regExp for field "${title}" : "${pattern}"`, err);
    return false;
  }
};

class ProductFormAddressSelect extends PureComponent<ProductFormAddressSelectProps, ProductFormAddressSelectState> {
  constructor(props: ProductFormAddressSelectProps) {
    super(props);

    this.state = {
      apartment: get(props, 'input.value.complement', ''),
      isUpdated: false,
    };
  }

  static getDerivedStateFromProps(props: ProductFormAddressSelectProps, state: ProductFormAddressSelectState) {
    const apartment: string = get(props, 'input.value.complement', '');
    return apartment === state.apartment ? null : { apartment };
  }

  componentDidUpdate() {
    const { input, dispatchUpdate } = this.props;

    if (!this.state.isUpdated && input.value && !input.value.isManualMode) {
      updateAddressFormData(dispatchUpdate, input.name, false);

      this.setState({ isUpdated: true });
    }
  }

  handleInputChange = (event: string) => {
    const { searchAddress, input } = this.props;
    const onChange = input.onChange || noop;
    if (event === '') {
      onChange(null);
      return;
    }

    searchAddress(event, { name: searchedAddresses });
  };

  handleChange = (suggestion: ISuggestion | any) => {
    const { value, data } = suggestion || { value: '', data: null };
    const { input } = this.props;
    const onChange = input.onChange || noop;

    const eventParams =
      value === 'manualMode'
        ? {
            ...input.value,
            original: input.value,
            isManualMode: true,
            addrSource: SOURCES.MANUAL,
          }
        : data
        ? { ...data, addrSource: SOURCES.MANUAL }
        : null;
    onChange(eventParams);
  };

  handleApartmentChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { input } = this.props;
    const apartment = event.currentTarget.value;
    input.onChange && input.onChange({ ...input.value, complement: apartment });
  };

  render() {
    const {
      addresses,
      productForm,
      manualModeLabel,
      input,
      isLoading,
      helpText,
      placeholder,
      isReadOnly,
      isShowApt = true,
      intl,
      formatErrorMessage,
    } = this.props;
    const getText = getTextFactory(intl, 'address');
    const getGlobalText = getTextFactory(intl, 'global');
    const getErrorText = getTextFactory(intl, 'error');

    const { name, value } = input;
    const schema: Schema = this.props.schema as Schema;
    const { apartment } = this.state;

    // the 'full' property on the address may be empty after reloading the page
    const formattedAddress = value ? get(value, 'full', null) || formatAddress(value, true) : '';

    if (value && value.isManualMode) {
      this.setState({ isUpdated: false });
      return null;
    }

    const complementSchema: RefDataContainer = get(schema, 'properties.complement', {});
    const invalidAddress = isInvalidOnChange(this.props);
    const invalidApartment = !validateField(complementSchema, apartment);
    const options = getSelectOptions(addresses, productForm, schema, intl);
    const label = getLabelText(this.props);

    const manualEntrySuggestions: ISuggestion[] = [
      {
        label: manualModeLabel || intl.formatMessage({ id: 'address.manualMode' }),
        value: 'manualMode',
      },
    ];

    const fuse = {
      isCaseSensitive: false,
      includeScore: false,
      shouldSort: true,
      includeMatches: false,
      findAllMatches: false,
      minMatchCharLength: 1,
      location: 0,
      threshold: 0.6,
      distance: 100,
      useExtendedSearch: false,
      ignoreLocation: false,
      ignoreFieldNorm: false,
      fieldNormWeight: 1,
    };

    return (
      <Row>
        <Col xs={12} md={9}>
          <FormGroup
            label={{
              text: label,
              htmlFor: `address-autocomplete-${name}`,
            }}
            validate={{
              hasError: invalidAddress,
              errorMsg: getErrorMessage(this.props),
            }}
            description={{
              id: `help-${name}`,
              text: helpText,
            }}
          >
            {isReadOnly ? (
              <Text type="span">{formattedAddress}</Text>
            ) : (
              <Autocomplete
                id={`address-autocomplete-${name}`}
                name={name}
                isLoading={isLoading}
                placeholder={placeholder}
                suggestions={options}
                staticSuggestions={manualEntrySuggestions}
                fuzzySearchOptions={fuse}
                value={formattedAddress}
                className={cx({ error: invalidAddress })}
                onChange={this.handleInputChange}
                onSelect={this.handleChange}
                isExactSearch={false}
                displayListWithoutValue
              />
            )}
          </FormGroup>
        </Col>

        {isShowApt === true && (
          <Col xs={12} md={3}>
            <FormGroup
              label={{
                text: getText('appartmentLabel'),
                htmlFor: `address-input-${name}`,
              }}
              validate={{
                hasError: invalidApartment,
                errorMsg: getErrorText('pattern'),
              }}
              optionalText={getGlobalText('optional')}
            >
              {isReadOnly ? (
                <Text type="span">{apartment}</Text>
              ) : (
                <Input
                  id={`address-input-${name}`}
                  name={name}
                  value={apartment}
                  required={isFieldRequired(schema, 'complement')}
                  minLength={getFieldMinLength(schema, 'complement')}
                  maxLength={getFieldMaxLength(schema, 'complement')}
                  onChange={this.handleApartmentChange}
                />
              )}
            </FormGroup>
          </Col>
        )}

        {name === 'currentAddress' && (
          <>
            {/* Start Date */}
            <Col xs={12} md={9} data-test="field_startDate">
              <ProductFormDateFields
                name="currentAddressStartDate"
                label={getText('startDate')}
                placeholder={getText('select.placeholder')}
                formatErrorMessage={formatErrorMessage}
              />
            </Col>

            {/* Ownership */}
            <Col xs={12} md={3} data-test="field_ownership">
              <ProductFormSelect
                name="currentAddressOwnership"
                label={getText('ownership')}
                placeholder={getText('select.placeholder')}
                formatErrorMessage={formatErrorMessage}
              />
            </Col>
          </>
        )}
      </Row>
    );
  }
}

export default ProductFormAddressSelect;
