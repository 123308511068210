import { DateFields, Props as DateFieldsProps } from '@nbc-design/date-fields';
import { Fieldset } from '@nbc-design/fieldset';
import { Text } from '@nbc-design/text';
import trimStart from 'lodash/trimStart';
import React, { FC } from 'react';
import { InjectedIntl } from 'react-intl';

import AnalyticsHelper from 'services/analytics/AnalyticsHelper';
import { ProductFormFieldChangeHandler, PropType } from 'types/interfaces';
import { getTextFactory } from 'utils/TextUtils';
import { DateFieldsValue, FormComponentProps, InputType } from '../types';
import { getErrorMessage, getLabelText, isInvalidOnChange, validateDateFields } from '../utils';
import './style.css';

type LocaleType = 'fr' | 'en';
type DateFieldsValueOnChange = PropType<Required<Pick<DateFieldsProps, 'onChange'>>, 'onChange'>;

export type ProductFormDateFieldsProps = FormComponentProps & {
  intl: InjectedIntl;
  change: ProductFormFieldChangeHandler;
  disabled?: boolean;
  tooltip?: React.ReactNode;
  isUpdate?: boolean;
  dateFieldsOptions?: Pick<DateFieldsProps, 'display'>;
};

const toDateValue = (input: InputType): Required<DateFieldsValue> => {
  if (input && input.value && typeof input.value === 'string') {
    const dateParts = input.value.split('-');
    return {
      day: dateParts[2] || '',
      month: dateParts[1] || '',
      year: dateParts[0] || '',
    };
  }
  return {
    day: '',
    month: '',
    year: '',
  };
};

const cleanDatePart = (datePart: string | undefined, defaultValue: string) => {
  return typeof datePart === 'string' ? trimStart(datePart, '0') : defaultValue;
};

const toISODate = (date: Required<DateFieldsValue>) => {
  return `${date.year.padStart(4, '0')}-${date.month.padStart(2, '0')}-${date.day.padStart(2, '0')}`;
};

const readyToValidate = ({ day, month, year }: Required<DateFieldsValue>) => !!(year && month && day);

const ProductFormDateFields: FC<ProductFormDateFieldsProps> = (props) => {
  const { tooltip, input, isReadOnly, disabled, intl, change, isUpdate, dateFieldsOptions, meta } = props;
  const { submitFailed } = props.meta;

  const getAccessibilityText = getTextFactory(intl, 'accessibility');
  const onShowTooltip = AnalyticsHelper.handleShowTooltip(input.name);

  const [dateValue, setDate] = React.useState(
    isUpdate
      ? {
          day: dateFieldsOptions?.display?.day === false ? '01' : '',
          month: dateFieldsOptions?.display?.month === false ? '01' : '',
          year: '',
        }
      : toDateValue(input),
  );

  const handleOnChange: DateFieldsValueOnChange = (value) => {
    const newValue = {
      day: cleanDatePart(value.day, dateValue.day),
      month: cleanDatePart(value.month, dateValue.month),
      year: cleanDatePart(value.year, dateValue.year),
    };

    setDate(newValue);

    if (submitFailed || readyToValidate(newValue)) {
      if (change) {
        change(input.name, toISODate(newValue));
      } else {
        input.onChange && input.onChange(toISODate(newValue));
      }
    }
  };

  const invalid = isInvalidOnChange(props) || (meta.touched && !!meta.error);

  return (
    <Fieldset
      legend={getLabelText(props)}
      tooltip={{ content: tooltip, ariaLabel: getAccessibilityText('information'), onShowTooltip }}
      validate={{ hasError: invalid, errorMsg: getErrorMessage(props) }}
    >
      {isReadOnly ? (
        <Text type="p">{input.value}</Text>
      ) : (
        <DateFields
          id={`dateFields-${input.name}`}
          locale={intl.locale as LocaleType}
          display={dateFieldsOptions?.display}
          defaultValue={dateValue}
          onChange={handleOnChange}
          validate={validateDateFields(dateValue, submitFailed || readyToValidate(dateValue), dateFieldsOptions)}
          disabled={disabled}
        />
      )}
    </Fieldset>
  );
};

export default ProductFormDateFields;
