import React from 'react';
import { FormGroup, Props as FormGroupProps } from '@nbc-design/form-group';
import { getTextFactory } from '../../../../utils/TextUtils';
import AnalyticsHelper from '../../../../services/analytics/AnalyticsHelper';
import { useContainerCtx } from '../../../../providers/containerCtx/ContainerCtx';
import { AccountsStep3FormData } from '../../types';

export type AccountSectionFormGroupProps = FormGroupProps & {
  testId: string;
  className?: string;
  tooltipContent?: string;
};
const AccountSectionFormGroup: React.FC<AccountSectionFormGroupProps> = ({
  children,
  label,
  testId,
  tooltipContent,
  className,
}) => {
  const { intl } = useContainerCtx<AccountsStep3FormData>();

  const getAccessibilityText = getTextFactory(intl, 'accessibility');
  const onShowTooltip = AnalyticsHelper.handleShowTooltip('usageCode');

  return (
    <div className={`account-section mc-mb-normal ${className}`} data-test={`account-section-${testId}`}>
      <FormGroup
        label={label}
        tooltip={{
          content: tooltipContent,
          ariaLabel: getAccessibilityText('information'),
          onShowTooltip,
        }}
      >
        {children}
      </FormGroup>
    </div>
  );
};

export default AccountSectionFormGroup;
