import get from 'lodash/get';

import { FinancesComponentsProps } from './types';
import { ProductFormFieldChangeHandler } from 'types/interfaces';
import { CALCULATOR_ASSETS_FIELDS, CALCULATOR_LIABILITIES_FIELDS } from './constants';
import { getFormFieldValue, getState } from 'globalRedux/store';

export const updateCalculator = (
  change: ProductFormFieldChangeHandler,
  props: FinancesComponentsProps,
  fields: string[],
): void => {
  fields.forEach((fieldName) => {
    change(fieldName, props[fieldName] as number);
  });
};

export const hasAssetsErrors = (form: string): boolean => {
  return hasErrors(form, Object.values(CALCULATOR_ASSETS_FIELDS));
};

export const hasLiabilitiesErrors = (form: string): boolean => {
  return hasErrors(form, Object.values(CALCULATOR_LIABILITIES_FIELDS));
};

export const updateAssets = (change: ProductFormFieldChangeHandler, props: FinancesComponentsProps): void => {
  updateCalculator(change, props, Object.values(CALCULATOR_ASSETS_FIELDS));
};

export const updateLiabilities = (change: ProductFormFieldChangeHandler, props: FinancesComponentsProps): void => {
  updateCalculator(change, props, Object.values(CALCULATOR_LIABILITIES_FIELDS));
};

export const getFormFieldError = (formName: string, fieldName: string) => {
  return get(getState(), `reduxFormReducer.${formName}.syncErrors.${fieldName}`);
};

export const hasErrors = (formName: string, fields: string[]): boolean => {
  return fields.some((fieldName) => getFormFieldError(formName, fieldName));
};

export const updateNetWorth = (change: ProductFormFieldChangeHandler, assets: number, liabilities: number): void => {
  change('netWorth', assets - liabilities);
};

export const sumAssetsTotal = (formName: string) => {
  return Object.values(CALCULATOR_ASSETS_FIELDS).reduce((total, fieldName) => {
    return total + getFormFieldValue(formName, fieldName, 0);
  }, 0);
};

export const sumLiabilitiesTotal = (formName: string) => {
  return Object.values(CALCULATOR_LIABILITIES_FIELDS).reduce((total, fieldName) => {
    return total + getFormFieldValue(formName, fieldName, 0);
  }, 0);
};

export const addCalculatorRollbackState = (state, props): void => {
  Object.values(CALCULATOR_ASSETS_FIELDS)
    .concat(Object.values(CALCULATOR_LIABILITIES_FIELDS))
    .forEach((fieldName) => {
      state[fieldName] = props[fieldName];
    });
};

export const rollbackCalculatorState = (state, change): void => {
  Object.values(CALCULATOR_ASSETS_FIELDS)
    .concat(Object.values(CALCULATOR_LIABILITIES_FIELDS))
    .forEach((fieldName) => {
      change(fieldName, state[fieldName] as number);
    });
};
