import { Button } from '@nbc-design/button';
import { Card } from '@nbc-design/card';
import { Heading } from '@nbc-design/heading';
import { AccordionOpen } from '@nbc-design/icons/lib/web/AccordionOpen';
import React, { FunctionComponent } from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { getTextFactory } from 'utils/TextUtils';

type BankingOpenSafeIdBoxProps = {
  currency: string;
  intl: InjectedIntl;
  handleOnClickAddButton: (currency: string) => void;
};

const BankingOpenSafeIdBox: FunctionComponent<BankingOpenSafeIdBoxProps> = (props: BankingOpenSafeIdBoxProps) => {
  const { currency, intl, handleOnClickAddButton } = props;
  const getText = getTextFactory(intl, 'specificsBankingInfo');

  return (
    <Card border="none" className="bankingAccountSafeIdOpen">
      <Heading type="h6">
        {getText('safeIdCurrency')} {currency}
      </Heading>
      <Button
        appearance="secondary"
        size="small"
        icon={<AccordionOpen title="accordion-open" />}
        iconPosition="left"
        dataTest="bank-safeid-add"
        children={getText('safeIdCurrency.add')}
        onClick={() => handleOnClickAddButton(currency)}
      />
    </Card>
  );
};

export default injectIntl(BankingOpenSafeIdBox);
