import React, { ChangeEvent, FC, ReactElement, ReactNode } from 'react';
import { InjectedIntl } from 'react-intl';
import { Card } from '@nbc-design/card';
import { Checkbox } from '@nbc-design/checkbox';
import { Fieldset } from '@nbc-design/fieldset';
import { Link } from '@nbc-design/link';
import { Message } from '@nbc-design/message';
import { Text } from '@nbc-design/text';
import TextList from '@nbc-design/text-list';
import { InfoFillColor } from '@nbc-design/icons/lib/web/InfoFillColor';

import { ProductFormFieldChangeHandler, RefData } from 'types/interfaces';
import { AccountData, AccountOptionStatus } from '../types';
import { ACCOUNT_TYPE, CLIENT_TYPE } from '../constants';

import { createErrorFormatter, getLabel } from 'utils/productFormUtils';
import { getTextFactory } from 'utils/TextUtils';
import { getAccountTooltip, isAccountDisabled, isAccountOfTypeSelected, isAccountSectionOpen } from '../utils';
import { ProductFormCheckbox } from 'components/productFormFields';
import { CURRENCIES, URLS } from 'utils/constants';

import cx from 'classnames';
import BaoTooltip from 'components/BaoTooltip/BaoTooltip';
import config from '../config';

export interface AccountSelectorProps {
  accounts: AccountData[];
  account: AccountData;
  accountsRefData: RefData[];
  change: ProductFormFieldChangeHandler;
  clientType: CLIENT_TYPE;
  currenciesRefData: RefData[];
  intl: InjectedIntl;
}

const AccountSelector: FC<AccountSelectorProps> = ({
  accounts,
  account,
  accountsRefData,
  change,
  clientType,
  currenciesRefData,
  intl,
}: AccountSelectorProps) => {
  const { code, values } = account;
  const { locale } = intl;

  const isAccountSelected = !!values.find((item) => item.value);

  const isFhsaAccountSelected = !!(
    code === ACCOUNT_TYPE.FHSA && values.find((item) => item.code === 'CAD' && item.value)
  );

  const [isSectionOpen, setSectionOpen] = React.useState<boolean>(isAccountSelected || false);
  const [isFhsa, setFhsa] = React.useState<boolean>(isFhsaAccountSelected || false);

  const getText = getTextFactory(intl, 'yourAccount');

  const clientTypePrefix = clientType === CLIENT_TYPE.INDIV ? 'accountsIndiv' : 'accountsJoint';
  const formatErrorMessage = (prefix?: string, fieldName?: string) => createErrorFormatter(intl, prefix, fieldName);

  const isCashSelected =
    isAccountSectionOpen(ACCOUNT_TYPE.CASH) || isAccountOfTypeSelected(accounts, ACCOUNT_TYPE.CASH);
  const isMarginSelected =
    isAccountSectionOpen(ACCOUNT_TYPE.MARGIN) || isAccountOfTypeSelected(accounts, ACCOUNT_TYPE.MARGIN);
  const isMgnsSelected =
    isAccountSectionOpen(ACCOUNT_TYPE.MGNS) || isAccountOfTypeSelected(accounts, ACCOUNT_TYPE.MGNS);

  const isCashDisabled = code === ACCOUNT_TYPE.CASH && (isMarginSelected || isMgnsSelected);
  const isMarginDisabled = code === ACCOUNT_TYPE.MARGIN && isCashSelected;
  const isMgnsDisabled = code === ACCOUNT_TYPE.MGNS && isCashSelected;

  const disabledAccountCheckbox = isCashDisabled || isMarginDisabled || isMgnsDisabled;

  const disabledCashMessage = isCashDisabled && getText('accountCASH');
  const disabledMarginMessage = isMarginDisabled && getText('accountMGN');
  const disabledMgnsMessage = isMgnsDisabled && getText('accountMGNS');

  const displayedDisabledMessage = disabledCashMessage || disabledMarginMessage || disabledMgnsMessage;

  const handleAccountSelection = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = target;

    setSectionOpen(!isSectionOpen);

    const forcedCondition = isMgnsSelected && name === 'MGN-display-section';
    const autoSelection =
      isAccountOfTypeSelected(accounts, ACCOUNT_TYPE.MGNS) &&
      isAccountOfTypeSelected(accounts, ACCOUNT_TYPE.MARGIN) &&
      name === 'MGNS-display-section';

    if (isSectionOpen && !checked && !forcedCondition) {
      const accountIndex = accounts.findIndex((userAccount) => code === userAccount.code);
      // We flush currency selection for unselected account
      change(`${clientTypePrefix}[${accountIndex}].values[0].value`, false);
      change(`${clientTypePrefix}[${accountIndex}].values[1].value`, false);
    }

    if (autoSelection) {
      const marginAccountIndex = accounts.findIndex((userAccount) => userAccount.code === ACCOUNT_TYPE.MARGIN);
      change(`${clientTypePrefix}[${marginAccountIndex}].values[0].value`, false);
      change(`${clientTypePrefix}[${marginAccountIndex}].values[1].value`, false);
    }

    change(name, checked);
  };

  const getLegend = (): ReactNode => (
    <Checkbox
      id={`${code}-display-section`}
      name={`${code}-display-section`}
      label={getLabel(accountsRefData, locale, code)}
      checked={isSectionOpen}
      disabled={disabledAccountCheckbox}
      onChange={handleAccountSelection}
    />
  );

  const generateTooltip = (name: string, content: string): JSX.Element => {
    return (
      <BaoTooltip
        name={name}
        key={name}
        stepId={config.ID!}
        content={content}
        position="right"
        className="account-selection-tooltip"
      />
    );
  };

  const getFhsaExplanation = (): ReactNode => (
    <Card className="fhsa-explanation">
      <div className="fhsa-information">
        <InfoFillColor size="small" title="info-fill-color" />
        <Text className="fhsa-explanation-title">{getText('fhsaExplanationTitle')}</Text>
      </div>

      <TextList size="small" className="fhsa-explanation-list">
        <TextList.Item className="fhsa-explanation-item">{getText('fhsaExplanation1')}</TextList.Item>
        <TextList.Item className="fhsa-explanation-item">{getText('fhsaExplanation2')}</TextList.Item>
        <TextList.Item className="fhsa-explanation-item">{getText('fhsaExplanation3')}</TextList.Item>
        <TextList.Item className="fhsa-explanation-item">{getText('fhsaExplanation4')}</TextList.Item>
      </TextList>

      <Link
        href={URLS.MORE_DETAILS[locale.toUpperCase()]}
        target="_blank"
        rel="noopener noreferrer"
        underlined
        data-test="link_moreDetails"
        className="more-details-link"
      >
        <Text>{getText('moreDetails')}</Text>
      </Link>

      <ProductFormCheckbox
        name="fhsaEligible"
        formatErrorMessage={formatErrorMessage()}
        label={getText('fhsaConfirmation')}
      />
    </Card>
  );

  const introduction = {
    id: `fields_${code}_introduction`,
    text: getText(`description${code}`),
  };

  const description = disabledAccountCheckbox && {
    id: `fields_${code}_description`,
    text: <Message datatype={`fields_${code}_Information`}>{displayedDisabledMessage}</Message>,
  };

  const currencyChoiceMessage: ReactElement = <Text className="currency-choice-text">{getText('currencyChoice')}</Text>;

  const getItemsFromAccountValues = (): JSX.Element[] => {
    const accountIndex = accounts.findIndex((userAccount) => code === userAccount.code);

    return values.map((value, index) => {
      const { label, shortDescription } = currenciesRefData[index][locale];
      const accountOptionStatus: AccountOptionStatus = isAccountDisabled(code, accounts);
      const { disabled } = accountOptionStatus.currencies[value.code];

      const handleAccountChange = ({ target }: ChangeEvent<HTMLInputElement>): void => {
        const { name, checked } = target;

        // Enforce 'Margin' account selection upon 'Short Margin' account selection
        if (code === ACCOUNT_TYPE.MGNS) {
          const marginAccountIndex = accounts.findIndex((userAccount) => userAccount.code === ACCOUNT_TYPE.MARGIN);
          change(`${clientTypePrefix}[${marginAccountIndex}].values[${index}].value`, checked);
        }

        if (code === ACCOUNT_TYPE.FHSA) {
          setFhsa(checked);
        }

        // Check CAD when checking USD for given account types
        if ([ACCOUNT_TYPE.RRIF].includes(code)) {
          const valueIndex = account?.values?.findIndex((_value) => _value.code === CURRENCIES.CAD);
          checked && change(`${clientTypePrefix}[${accountIndex}].values[${valueIndex}].value`, true);
        }

        change(name, checked);
      };

      return (
        <Checkbox
          id={`${clientTypePrefix}[${accountIndex}].values[${index}].value`}
          name={`${clientTypePrefix}[${accountIndex}].values[${index}].value`}
          key={`${clientTypePrefix}[${accountIndex}].values[${index}].value`}
          label={
            <>
              {`${shortDescription} (${label})`} {getAccountTooltip(code, disabled, generateTooltip, getText)}
            </>
          }
          checked={value.value}
          disabled={disabled}
          className={cx({ 'is-disabled': disabled })}
          onChange={handleAccountChange}
        />
      );
    });
  };

  return (
    <Fieldset
      id={`fields_${code}`}
      dataTest={`fields_${code}`}
      introduction={introduction}
      legend={getLegend()}
      description={description || undefined}
      className={cx({ 'is-disabled': disabledAccountCheckbox })}
    >
      {isSectionOpen && (
        <div className="account-currencies">
          {currencyChoiceMessage}
          {getItemsFromAccountValues()}
          {isFhsa && getFhsaExplanation()}
        </div>
      )}
    </Fieldset>
  );
};

export default AccountSelector;
