import React, { FunctionComponent } from 'react';
import { Card } from '@nbc-design/card';
import { Heading } from '@nbc-design/heading';
import { Edit } from '@nbc-design/icons/lib/web/Edit';
import { SuccessInverseThinColor } from '@nbc-design/icons/lib/web/SuccessInverseThinColor';
import { Link } from '@nbc-design/link';
import { Text } from '@nbc-design/text';
import { InjectedIntl } from 'react-intl';
import { getTextFactory } from 'utils/TextUtils';
import { fromState } from 'globalRedux/store';

export type CompletedCredentialsProps = {
  intl: InjectedIntl;
  setCardWrapperState: Function;
};

const CompletedCredentials: FunctionComponent<CompletedCredentialsProps> = (props: CompletedCredentialsProps) => {
  const { intl, setCardWrapperState } = props;

  const getText = getTextFactory(intl, 'createCredentials');

  return (
    <Card border="shadow" className="spc-creationProfil__card mc-mb-normal">
      <div className="spc-creationProfil__cardBody">
        <SuccessInverseThinColor title="success-inverse-thin-color" size={{ width: 32, height: 32 }} />
        <div className="spc-creationProfil__stepTitle">
          <div className="mc-d-flex mc-align-items-center mc-justify-content-between">
            <Heading type="h2" size={5} className="mc-text-grey400">
              {getText('credentialsConfirmation')}
            </Heading>

            <Text size="small">
              <Link
                icon={<Edit title="edit" />}
                type="button"
                onClick={() => {
                  // manage submit button disabled state
                  setCardWrapperState({ step: 1, isSubmitted: false });
                }}
              >
                {getText('editLink')}
              </Link>
            </Text>
          </div>
        </div>
        <div className="spc-creationProfil__cardSpacer"></div>
        <div className="spc-creationProfil__cardContent user-email">
          <Text appearance="mutedDarkGrey">{fromState<string, null>('baoReducer.userCredentials.email', '')}</Text>
        </div>
      </div>
    </Card>
  );
};

export default CompletedCredentials;
