import OktaAuth from 'libs/okta-auth-js.min';
// import OktaAuth from '@okta/okta-auth-js';

import {
  deleteAuthent,
  saveToken,
  saveTokenType,
  loadToken,
  setAuthenticatedWith,
  OKTA_AUTHENT_TYPE,
  clearAuthenticationWith,
  signout,
} from 'services/loginManager';
import { loadItem, loadLocale, getLocale } from 'utils';
import getLogger from 'utils/getLogger';
import { URLS } from 'utils/constants';

import { SSO_DATA } from './constants';

export const ID_TOKEN_KEY = 'idToken';
export const ACCESS_TOKEN_KEY = 'accessToken';

const logger = getLogger('oktaService');

let authClient: OktaAuth | null = null;

const saveAccessToken = (accessToken: OktaAccessToken) => {
  setAuthenticatedWith(OKTA_AUTHENT_TYPE);
  saveToken(accessToken.value);
  saveTokenType(accessToken.tokenType);
};

/**
 * Get the current environment to be used for the SSO login.
 */
export const getCurrentEnv = (): string => {
  const currentEnv = loadItem('SSO_ENV') || loadItem('DEV_ENV') || loadItem('CURRENT_ENV');

  if (!currentEnv) {
    throw new Error('SSO environment not set');
  }
  return currentEnv && currentEnv.toUpperCase();
};

/**
 * Get the urls of okta endpoint, in case they differ from the issuer.
 * @param locale
 */
export const getCustomUrls = (
  env: string,
): {
  authorizeUrl?: string;
  userinfoUrl?: string;
  tokenUrl?: string;
  logoutUrl?: string;
  revokeUrl?: string;
} => {
  const baseUrl: string = SSO_DATA[env].BASE_URL;
  const issuerBaseUrl: string = SSO_DATA[env].ISSUER_BASE_URL;
  if (baseUrl === issuerBaseUrl) {
    return {};
  }

  const buildUrl = (path: string): string => `${baseUrl}/oauth2/${SSO_DATA[env].AUTHN_ID}${path}`;
  return {
    authorizeUrl: buildUrl(`/v1/authorize`),
    tokenUrl: buildUrl(`/v1/token`),
    // BAO-19339
    // the route in DP see https://git.bnc.ca/projects/APP5203/repos/sbip/browse/oktasso_1.8.0.yaml#2222
    logoutUrl: buildUrl(`/logout`),
    revokeUrl: buildUrl(`/v1/revoke`),
    userinfoUrl: buildUrl(`/v1/userinfo`),
  };
};

/**
 * Get a singleton instance of the OktaAuth SDK client. Instantiate it if needed.
 *
 * @param locale the two-letters language code of the current language, i.e. `en` or `fr`
 */
export const getOktaClient = (locale: string): OktaAuth => {
  if (!authClient) {
    const env = getCurrentEnv();
    const lang = locale.toUpperCase();

    const redirectUri = `${window.location.origin}${SSO_DATA[env].REDIRECT_URI[lang]}`;

    const customUrls = getCustomUrls(env);
    const config: OktaAuthConstructorOptions = {
      // Required config
      issuer: `${SSO_DATA[env].ISSUER_BASE_URL}/oauth2/${SSO_DATA[env].AUTHN_ID}`,

      // custom urls for when the issuer url is not the base url of other requests
      ...customUrls,

      // Required for login flow using getWithRedirect()
      redirectUri,
      idp: SSO_DATA[env].IDP[lang],
      clientId: SSO_DATA[env].CLIENT_ID,
      responseType: SSO_DATA[env].RESPONSE_TYPE,

      // Ignore token signature verification to avoid calls to /.well-known/openid-configuration
      //  and subsequent call to /v1/keys
      ignoreSignature: true,

      // Set to true to use PKCE flow instead (defaults to true)
      // https://github.com/okta/okta-auth-js#implicit-oauth-20-flow
      pkce: false,

      // Parse authorization code from hash fragment instead of search query
      responseMode: 'fragment',

      // Configure TokenManager to use sessionStorage instead of localStorage
      tokenManager: {
        autoRenew: true,
        storage: 'localStorage',
      },

      // Handle session expiration / token renew failure
      onSessionExpired: handleSessionExpired,
    };
    authClient = new OktaAuth(config);

    // Triggered when a token has expired
    authClient.tokenManager.on('expired', handleTokenExpired);

    // Triggered when a token has been renewed
    authClient.tokenManager.on('renewed', handleTokenRenewed);

    // Triggered when an OAuthError is returned via the API (typically during auto-renew)
    authClient.tokenManager.on('error', handleTokenError);
  }

  return authClient;
};

/**
 * Resets the Okta SDK client instance, *for use in unit test only*
 */
export const resetOktaClient = () => {
  authClient = null;
};

export const handleSessionExpired = (...args) => {
  logger.log('session expired, re-authorization is required', ...args);
};

export const handleTokenExpired = (key, expiredToken: AnyOktaToken) => {
  logger.log('Token with key', key, ' has expired:', expiredToken);
  if (key === ACCESS_TOKEN_KEY && expiredToken.value === loadToken()) {
    getOktaClient(loadLocale())
      .tokenManager.get(key)
      .then((token: AnyOktaToken) => {
        logger.log(`Got token ${key} before it expires`, token);
      })
      .catch((err) => {
        logger.log(`Could not get token ${key} before it expires, deleting authentication`, err);
        deleteAuthent();
      });
  }
};

export const handleTokenRenewed = (key, newToken: AnyOktaToken, oldToken: AnyOktaToken) => {
  logger.log('Token with key ', key, ' was renewed:', newToken);
  // Save the token for use by the backend if it's the right type
  if (key === ACCESS_TOKEN_KEY) {
    saveAccessToken(newToken as OktaAccessToken);
  }
};

export const handleTokenError = (err) => {
  logger.error(err);

  // err.name
  // err.message
  // err.errorCode
  // err.errorSummary
  // err.tokenKey
  // err.accessToken
  if (err.errorCode === 'login_required' && err.accessToken) {
    // The Okta session has expired or was closed outside the application
    // The application should return to an unauthenticated state
    // This error can also be handled using the 'onSessionExpired' option
    // deleteAuthent();
  }
};

export const handleParseUrlError = (err) => {
  logger.error(`parseFromUrl`, err);
  // Handle OAuthError
  deleteAuthent();
};

/**
 * Get and validate the token currently saved in the token manager.
 * Returns a promise that resolves once the saved token are cleared, never rejects.
 *
 * @param locale the two-letters language code of the current language, i.e. `en` or `fr`
 */
export const checkAccessTokenValidity = (locale: string): Promise<OktaAccessToken | null> =>
  getOktaClient(locale)
    .tokenManager.get<OktaAccessToken>(ACCESS_TOKEN_KEY)
    .then((token) => {
      saveAccessToken(token);
      return token;
    })
    .catch((err) => {
      clearAuthenticationWith();
      // the user might not be authentified with okta, this is not an error per se.
      return null;
    });

/**
 * Parses the token from the url and validate it using the Okta SDK.
 *
 * @param locale the two-letters language code of the current language, i.e. `en` or `fr`
 */
export const persistUnifiedLogin = (locale: string): Promise<void> => {
  const oktaClient = getOktaClient(locale);
  return oktaClient.token
    .parseFromUrl()
    .then(function (res) {
      // Save the tokens
      const idToken = res.tokens[ID_TOKEN_KEY] as OktaIdToken;
      oktaClient.tokenManager.add(ID_TOKEN_KEY, idToken);

      const accessToken = res.tokens[ACCESS_TOKEN_KEY] as OktaAccessToken;
      oktaClient.tokenManager.add(ACCESS_TOKEN_KEY, accessToken);

      // Save the access token for use by the backend
      saveAccessToken(accessToken);
    })
    .catch((err) => {
      handleParseUrlError(err);
      throw err;
    });
};

/**
 * Ensure that the user is signed out from both Okta and BAO.
 */
export const signoutAll = async (): Promise<void> => {
  return Promise.all([signout(), undefined]).then(() => {});
};

/**
 * Signout from Okta session using the redirection flow.
 */
export const signOutUnifiedLogin = async (): Promise<void> => {
  const locale = getLocale().toUpperCase();
  const bncdUrl = URLS.BNCD[locale];

  // should cause a redirection
  getOktaClient(loadLocale()).signOut({
    postLogoutRedirectUri: bncdUrl,
  });

  return Promise.resolve(undefined);
};
