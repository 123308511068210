import React, { FunctionComponent } from 'react';
import { InjectedIntl } from 'react-intl';

import { ConfigData, GenericProps } from 'types/interfaces';

import Menu from './components/Menu';
import './style.scss';

export type SidebarProps = GenericProps & {
  intl: InjectedIntl;
  currentStepId?: string;
  goToStep?: (id: string) => void;
  config: ConfigData;
  requestCode: string;
  isJointMenu: boolean;
  userName?: string;
  isSafeIDSuccess?: boolean;
  setUpdateOfPersonalInfosForm: Function;
};

const Sidebar: FunctionComponent<SidebarProps> = (props: SidebarProps) => {
  const {
    currentStepId,
    goToStep,
    config,
    requestCode,
    isJointMenu,
    userName,
    isSafeIDSuccess,
    setUpdateOfPersonalInfosForm,
  } = props;

  if (!config.SIDEBAR_MENU) {
    return null;
  }

  return (
    <div className={`sidebar with-menu ${userName ? 'with-name' : 'without-name'}`}>
      <Menu
        dispatch={props.dispatch}
        isHierarchical={false} // sub-menu are hidden at the moment
        isJointMenu={isJointMenu}
        currentStepId={currentStepId}
        requestCode={requestCode}
        userName={userName}
        goToStep={goToStep}
        isBlocked={isSafeIDSuccess}
        setUpdateOfPersonalInfosForm={setUpdateOfPersonalInfosForm}
      />
    </div>
  );
};

export default Sidebar;
