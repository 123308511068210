import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { createProductForm } from 'bnc-react-forms';

import { BAO_FORMS } from 'utils/constants';
import App from './App';

export default compose<any>(
  injectIntl,
  createProductForm({
    form: BAO_FORMS,
    query: { processScenarioId: 'baoFlowEngine' },
    validateRegisteredOnly: true,
    shouldError: () => true,
  }),
)(App);
