import { injectIntl } from 'react-intl';

import { formValues } from 'bnc-react-forms';
import AccountsStep3 from './AccountsStep3';
import config from './config';
import * as validation from './validation';
import { withContainerCtxProvider } from '../../providers/containerCtx/ContainerCtx';
import { AccountsStep3FormData } from './types';
import { flowRight } from 'lodash';

export const VALIDATION = validation;
export const CONFIG = config;

export default flowRight(
  injectIntl,
  formValues(
    'legislations',
    'utilisations',
    'respPlanTypes',
    'displayTradingOption',
    'promotionCdAvailable',
    'promotionCd',
    'tradingOptionsInd',
  ),
  withContainerCtxProvider<AccountsStep3FormData>('legislation'),
)(AccountsStep3);
