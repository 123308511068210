import React from 'react';
import { ProductFormRadioBoolean } from '../../../components/productFormFields';
import { useContainerCtx } from '../../../providers/containerCtx/ContainerCtx';
import { BeneficiaryFormValues, OperationMode } from '../types';
import BeneficiaryDocumentAlert from './BeneficiaryDocumentAlert';
import { isNil } from 'lodash';

type RadioTrusteeOnBehalfProps = {
  index?: number;
};
const RadioTrusteeOnBehalf: React.FC<RadioTrusteeOnBehalfProps> = ({ index = 0 }) => {
  const {
    formValues,
    getText,
    getTextGlobal,
    formatErrorMessage,
    bncReactForm,
  } = useContainerCtx<BeneficiaryFormValues>();
  const { beneficiaries } = formValues;
  const { requestGovGrt } = beneficiaries[index];
  const { change } = bncReactForm;

  const handleChange = (): void => {
    if (isNil(beneficiaries[index].operationMode)) {
      let updatedBeneficiaries = beneficiaries;
      updatedBeneficiaries[index].operationMode = OperationMode.MODIFY;
      change(`beneficiaries`, updatedBeneficiaries);
    }
  };

  return (
    <div className={'mc-mb-large'} data-test={'radio-trust-on-behalf-container'}>
      <ProductFormRadioBoolean
        data-test="radio-trust-on-behalf-input"
        tooltip={getText('hasBeneficiaryTrustee.tooltip')}
        className="inline"
        label={<div className={'mc-fw-medium'}>{getText('hasBeneficiaryTrustee.label')}</div>}
        name={`beneficiaries[${index}].requestGovGrt`}
        yesLabel={getTextGlobal('button.yes')}
        noLabel={getTextGlobal('button.no')}
        formatErrorMessage={formatErrorMessage()}
        onChange={() => {
          handleChange();
        }}
        inline
      />

      {beneficiaries[index] && requestGovGrt && <BeneficiaryDocumentAlert beneficiary={beneficiaries[index]} />}
    </div>
  );
};

export default RadioTrusteeOnBehalf;
