import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Accounts } from '@nbc-design/icons/lib/web/Accounts';
import { Heading } from '@nbc-design/heading';
import { Text } from '@nbc-design/text';
import { TAccountData } from '../../types';
import { useContainerCtx } from '../../../../providers/containerCtx/ContainerCtx';

// ***********************************************************************
// Ts Type definitions
// ***********************************************************************
export type AccountCardProps = {
  isSelected?: boolean;
  label?: React.ReactNode;
  account: TAccountData;
};

// ***********************************************************************
// Component
// ***********************************************************************
export const AccountCard: React.FC<AccountCardProps> = ({ isSelected = false, label, children, account }) => {
  const { locale, getText } = useContainerCtx();

  const { currency, accountType } = account;
  const currencyText = currency[locale] === 'CAD' ? getText('currencyCAD') : getText('currencyUSD');

  return (
    <div
      className={`accountCard ${isSelected ? 'selected' : ''}`}
      data-test={`account-card-${account.accountType.code}-${account.currency.code}`}
    >
      <Row className={'mc-align-items-flex-start'}>
        <Col xs={1} className={'mc-d-flex mc-justify-content-center'}>
          <Accounts className="mc-text-grey400" title="accounts" />
        </Col>
        <Col xs={11} className="label">
          <div>
            <Heading type="h3" size={5} className="mc-fw-semi-bold account" data-test={`label_${accountType.code}`}>
              {accountType[locale]}
              {label}
            </Heading>
          </div>
          <div>
            <Text size="lead" className="mc-mb-xxsmall currency" data-test={`currency_${accountType.code}`}>
              {currencyText}
            </Text>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={11} xsOffset={1}>
          {children}
        </Col>
      </Row>
    </div>
  );
};
