import React, { MouseEvent } from 'react';
import { InjectedIntl } from 'react-intl';
import { Col, Row } from 'react-flexbox-grid';
import { History, LocationState } from 'history';
import { Button } from '@nbc-design/button';
import { Bank } from '@nbc-design/icons/lib/web/Bank';
import { Id } from '@nbc-design/icons/lib/web/Id';
import { SecureFill } from '@nbc-design/icons/lib/web/SecureFill';
import { Text } from '@nbc-design/text';
import Title from 'components/Title';
import { ROUTE } from 'utils/constants';
import { getTextFactory } from 'utils/TextUtils';
import getLogger from 'utils/getLogger';
import { sendStepLoadedEvent } from 'services/analytics';
import { EVENT_IDS } from 'services/analytics/constants';
import { ConfigData } from 'types/interfaces';
import FormFooter from 'components/FormFooter';
import { isAuthenticatedWith, OKTA_AUTHENT_TYPE, signinSSO, signupSSO } from 'services/loginManager';
import { checkAccessTokenValidity, persistUnifiedLogin } from 'services/ssoRedirectService/oktaService';
import { getBncId } from 'utils';

import CONFIG from './config';
import './styles.scss';

export type AssistedProps = {
  intl: InjectedIntl;
  history: History<LocationState>;
  config: ConfigData;
};

export type AssistedState = {
  ready: boolean;
};

const logger = getLogger('Assisted');

export const redirectToLogin = (history: History<LocationState>) => {
  history.push({
    pathname: ROUTE.CLIENT_REDIRECT,
    hash: '',
  });
};

export const goToPreviousStep = (history: History<LocationState>) => () => {
  history.push({
    pathname: ROUTE.CLIENT_REDIRECT,
  });
};

export default class Assisted extends React.Component<AssistedProps, AssistedState> {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
    };
    this.handleRedirectRequest = this.handleRedirectRequest.bind(this);
  }

  componentDidMount() {
    const { history, intl } = this.props;

    sendStepLoadedEvent(CONFIG.ID, EVENT_IDS.FS, '', {
      user: {
        bncId: getBncId(),
      },
    });

    if (history.location.hash.includes('access_token')) {
      return persistUnifiedLogin(intl.locale)
        .then(() => {
          this.setState({ ready: true });
        })
        .catch((err) => {
          redirectToLogin(history);
          return;
        });
    }

    if (!isAuthenticatedWith(OKTA_AUTHENT_TYPE)) {
      this.setState({ ready: true });
      return;
    }

    // User already has saved okta credential that could be valid
    checkAccessTokenValidity(intl.locale).then((accessToken) => {
      this.setState({ ready: true });
    });
  }

  handleRedirectRequest(event: MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();

    const { history, intl } = this.props;
    const { name } = event.target as HTMLButtonElement;

    if (isAuthenticatedWith(OKTA_AUTHENT_TYPE)) {
      checkAccessTokenValidity(intl.locale)
        .then((accessToken) => {
          if (accessToken === null) {
            redirectToLogin(history);
            return;
          }

          const authenticationOperation = name === 'newRequest' ? signupSSO : signinSSO;
          return authenticationOperation(accessToken.value, accessToken.tokenType)
            .then(() => {
              logger.log(`[AUTH] redirecting to STEPPER_MANAGER page`);
              window.location.assign(`${process.env.PUBLIC_URL}${ROUTE.STEPPER_MANAGER}`);
            })
            .catch((err) => {
              logger.error(`[AUTH] ERROR redirecting, redirecting to CLIENT_REDIRECT page`, err);
              redirectToLogin(history);
            });
        })
        .catch((err) => {
          logger.error(
            `[AUTH] ERROR getting the access token before signin / signup, redirecting to CLIENT_REDIRECT page`,
            err,
          );
          redirectToLogin(history);
        });
    } else {
      // BAO login, handled later
      history.push({
        pathname: name === 'newRequest' ? ROUTE.NEW_REQUEST : ROUTE.PENDING_REQUEST,
      });
    }
  }

  render() {
    const { intl, history, config } = this.props;
    const { ready } = this.state;

    if (!ready) {
      return null;
    }

    const getText = getTextFactory(intl, 'assisted');

    return (
      <div className="assisted-redirector__container">
        <Title label={getText('pageTitle')} tabTitle={getText('tabTitle')} />

        <Text type="p" size={'lead'} className={'sub-title'}>
          {getText('explanation')}
        </Text>

        <ul className="options-list">
          <li>
            <Row className="option-row">
              <Col xs={3} md={3} xl={3} className="icons-container" data-test="image_bankIcon">
                <Bank title="bank" size="large" className="icons" />
              </Col>
              <Col xs={9} md={9} lg={9} data-test="text_checkRequirement">
                <Text size={'paragraph'}>{getText('checkRequirement')}</Text>

                <p className="requirement-desc">{getText('checkRequirementDesc')}</p>
              </Col>
            </Row>
          </li>
          <li>
            <Row className="option-row">
              <Col xs={3} md={3} xl={3} className="icons-container" data-test="image_IdIcon">
                <Id title="id" size="large" className="icons" />
              </Col>
              <Col xs={9} md={9} lg={9} data-test="text_IDRequirement">
                <Text size={'paragraph'}>{getText('IDRequirement')}</Text>
                <p className="requirement-desc">{getText('IDRequirementDesc')}</p>
              </Col>
            </Row>
          </li>
        </ul>

        <Row className="button-row">
          <Col xs={12} md={12} lg={12} className="buttons-list" data-test="fields_buttons">
            <Button
              key={0}
              name={'newRequest'}
              type="button"
              onClick={(e) => this.handleRedirectRequest(e)}
              appearance="secondary"
              className="action-button"
            >
              {getText('newRequest')}
            </Button>
            <Button
              key={1}
              name={'pendingRequest'}
              type="button"
              onClick={(e) => this.handleRedirectRequest(e)}
              appearance="secondary"
              className="action-button"
            >
              {getText('pendingRequest')}
            </Button>
          </Col>
        </Row>

        <Row className="security-explanation-row">
          <Col xs={12} md={12} lg={12} className="security-explanation-col" data-test="text_security_explanation">
            <SecureFill title="secure-fill" size="small" className="security-icon" />
            {getText('securityInformation')}
          </Col>
        </Row>

        {config.FOOTER && !isAuthenticatedWith(OKTA_AUTHENT_TYPE) && (
          <FormFooter config={config} goToPreviousStep={goToPreviousStep(history)} />
        )}
      </div>
    );
  }
}
