import React, { ChangeEvent, ClipboardEvent, Component, FocusEvent, ReactNode, SyntheticEvent } from 'react';
import { InjectedIntl } from 'react-intl';
import { Col, Row } from 'react-flexbox-grid';
import { History } from 'history';
import { Button } from '@nbc-design/button';
import { Card } from '@nbc-design/card';
import { DateFields, Props as DateFieldsProps } from '@nbc-design/date-fields';
import { Fieldset } from '@nbc-design/fieldset';
import { FormGroup } from '@nbc-design/form-group';
import { Heading } from '@nbc-design/heading';
import { Input } from '@nbc-design/input';
import { Radio } from '@nbc-design/radio';
import { createUserProfile } from 'services/loginManager';
import { getDateFieldsErrors, getFieldsValidation } from '../validation';
import {
  sendOutOfFormFlowAnalyticsEvent,
  sendOutOfFormFlowErrorAnalyticsEvent,
  sendStepLoadedEvent,
} from 'services/analytics';
import { EVENT_IDS } from 'services/analytics/constants';
import { getTextFactory } from 'utils/TextUtils';
import Captcha from 'components/Captcha';
import { SiteKeyType } from 'components/Captcha/Captcha';
import { BncId, PropType } from 'types/interfaces';
import cx from 'classnames';
import LoadingMessenger from './LoadingMessenger';
import AnalyticsHelper from 'services/analytics/AnalyticsHelper';

export type AboutYouProps = {
  intl: InjectedIntl;
  history: History;
  step: number;
  setCardWrapperState: Function;
};

export type BirthDateFieldsValue = {
  day: string;
  month: string;
  year: string;
};

type LocaleType = 'fr' | 'en';
type DateFieldsValueOnChange = PropType<Required<Pick<DateFieldsProps, 'onChange'>>, 'onChange'>;

export type AboutYouState = {
  formValues: {
    title: string;
    firstName: string;
    middleName: string;
    lastName: string;
    birthDate: string;
  };
  touchedFields: {
    title: boolean;
    firstName: boolean;
    middleName: boolean;
    lastName: boolean;
    birthDate: boolean;
  };
  formErrors: {
    title: string;
    firstName: string;
    middleName: string;
    lastName: string;
    birthDate: string;
    connection: string;
  };
  isSubmitted: boolean;
  isLoading: boolean;
  isFormValid: boolean;
  bncId: string;
  dateFieldsCompleted: boolean;
};

class AboutYou extends Component<AboutYouProps, AboutYouState> {
  state: AboutYouState = {
    formValues: {
      title: '',
      firstName: '',
      middleName: '',
      lastName: '',
      birthDate: '',
    },
    touchedFields: {
      title: false,
      firstName: false,
      middleName: false,
      lastName: false,
      birthDate: false,
    },
    formErrors: { title: '', firstName: '', middleName: '', lastName: '', birthDate: '', connection: '' },
    isSubmitted: false,
    isLoading: false,
    isFormValid: false,
    bncId: 'bncId-mock',
    dateFieldsCompleted: false,
  };

  hasAnalyticsSent = false;

  spinnerTimeStart = 0;

  getText = getTextFactory(this.props.intl, 'aboutYou');
  getTextError = getTextFactory(this.props.intl, 'error');
  getGlobalText = getTextFactory(this.props.intl, 'global');
  onShowTooltip = AnalyticsHelper.handleShowTooltip('UCS-bao-HD');
  config = {
    ID: 'ABOUT_YOU',
  };

  getCard2Classes = (currentStep: number): string => {
    return cx({ 'spc-creationProfil__card': true, 'is-disabled': currentStep !== 2 });
  };

  componentDidUpdate = () => {
    !this.hasAnalyticsSent &&
      this.props.step === 2 &&
      sendStepLoadedEvent('personal information', EVENT_IDS.ST, '', {
        eventInfo: {
          ucsId: 'UCS-bao-004',
        },
        step: {
          stepId: '4',
          flowId: 'profile creation',
        },
      });

    this.hasAnalyticsSent = true;
  };

  validateForm() {
    this.formErrorsValues() && sendOutOfFormFlowErrorAnalyticsEvent(this.state.formErrors, 'personal information');

    this.setState({ isFormValid: this.hasRequiredValues() && !this.formErrorsValues() });
  }

  setError = (name: string, error: string): void => {
    this.setState(({ formErrors }) => ({
      formErrors: {
        ...formErrors,
        [name]: error ? this.getTextError(error) : '',
      },
    }));
  };

  hasRequiredValues = (): boolean => {
    const { middleName, ...requiredValues } = this.state.formValues;
    return Object.values(requiredValues).every((value) => value !== '');
  };

  formErrorsValues = (): string | undefined => {
    return Object.values(this.state.formErrors).find((value) => value !== '');
  };

  validateField = (fieldName: string, value: string): void => {
    const { formErrors } = getFieldsValidation({
      fieldName,
      value,
    });

    this.setError(fieldName, formErrors[fieldName]);
  };

  redirectAfterProfileCreation = (bncIdData: BncId | string | null): void => {
    const bncId = bncIdData && typeof bncIdData === 'object' ? bncIdData.bncId : bncIdData;

    if (!bncId) {
      return;
    }

    sendStepLoadedEvent('profile creation confirmation', EVENT_IDS.ST, '', {
      eventInfo: {
        ucsId: 'UCS-bao-006',
        interaction: 'Confirmation:Your profile has been created successfully',
      },
      step: {
        stepId: '6',
        flowId: 'profile creation',
        timeSpinner: (new Date().getTime() - this.spinnerTimeStart).toString(),
      },
      user: {
        bncId,
      },
    });

    this.setState({ bncId, isLoading: false }, () => {
      this.props.setCardWrapperState({ step: 3, isSubmitted: true, isError: false });
    });
  };

  executeRecaptcha = (event: SyntheticEvent): void => {
    event.preventDefault();
    (window as any).grecaptcha.reset();
    (window as any).grecaptcha.execute();
  };

  handleError = (): void => {
    this.setState({ bncId: this.getText('errorIAMX'), isLoading: false }, () => {
      this.props.setCardWrapperState({ step: 1, isSubmitted: true, isError: true });
      console.log('BNC ID', this.state.bncId);
    });

    this.setError('connection', 'connection');

    sendOutOfFormFlowAnalyticsEvent('' + this.formErrorsValues(), '' + this.config.ID);
  };

  validateAllFields = (
    title: string,
    firstName: string,
    middleName: string,
    lastName: string,
    birthDate: string,
  ): void => {
    this.validateField('title', title);
    this.validateField('firstName', firstName);
    this.validateField('middleName', middleName);
    this.validateField('lastName', lastName);
    this.validateField('birthDate', birthDate);
    this.validateForm();
  };

  handleFormSubmit = (): void => {
    const {
      formValues: { title, firstName, middleName, lastName, birthDate },
    } = this.state;

    this.validateAllFields(title, firstName, middleName, lastName, birthDate);

    const language = this.props.intl.locale.toUpperCase();

    this.setState({ isSubmitted: true, isLoading: this.state.isFormValid });

    if (this.state.isLoading) {
      this.spinnerTimeStart = new Date().getTime();
    }

    this.state.isFormValid &&
      createUserProfile(title, firstName?.trim(), middleName?.trim(), lastName?.trim(), birthDate, language)
        .then(this.redirectAfterProfileCreation)
        .catch(this.handleError);
  };

  onChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    const {
      target: { name, value },
    } = event;

    const formValues = this.state.formValues;
    formValues[name] = value.trim();

    this.setState({ formValues }, () => {
      this.state.touchedFields[name] && this.validateField(name, value.trim());
    });
  };

  onDateChange: DateFieldsValueOnChange = (
    value: { day?: string; month?: string; year?: string } = {},
    fullDate,
  ): void => {
    const formValues = this.state.formValues;
    formValues['birthDate'] = this.toISODate(fullDate as BirthDateFieldsValue);

    const touchedFields = this.state.touchedFields;
    touchedFields['birthDate'] = true;

    const dateFieldsCompleted = this.birthDateReadyToValidate(fullDate as BirthDateFieldsValue);

    this.setState({ formValues, touchedFields, dateFieldsCompleted }, () => {
      this.state.touchedFields['birthDate'] &&
        dateFieldsCompleted &&
        this.validateField('birthDate', this.state.formValues.birthDate);
    });
  };

  toISODate = (date: BirthDateFieldsValue = { year: '0000', month: '00', day: '00' }) => {
    return `${date.year.padStart(4)}-${date.month.padStart(2, '0')}-${date.day.padStart(2, '0')}`;
  };

  birthDateReadyToValidate = (date: Required<BirthDateFieldsValue>): boolean => {
    return !!(date.year && date.month && date.day);
  };

  onBlur = (event: FocusEvent<HTMLInputElement | HTMLSelectElement>): void => {
    const {
      target: { name, value },
    } = event;

    const touchedFields = this.state.touchedFields;
    touchedFields[name] = true;

    this.setState({ touchedFields }, () => {
      this.validateField(name, value.trim());
    });
  };

  onPaste = (event: ClipboardEvent<HTMLInputElement>): void => {
    event.preventDefault();
  };

  render(): ReactNode {
    const { formErrors, isLoading } = this.state;
    const { step, intl } = this.props;

    return (
      <>
        <Card border="shadow" className={this.getCard2Classes(step)}>
          <form onSubmit={this.executeRecaptcha}>
            <div className="about-you__form">
              <div className="spc-creationProfil__cardBody spc-creationProfil__cardBody--fullContentMobile">
                <div className="spc-creationProfil__stepNumber">2</div>
                <div className="spc-creationProfil__stepTitle">
                  <Heading type="h2" size={5}>
                    {this.getText('stepTitle')}
                  </Heading>
                </div>
                <div></div>
                <div className="spc-creationProfil__cardContent card2">
                  <Row>
                    <Col xs={12} md={12} lg={12}>
                      <Fieldset validate={{ hasError: !!formErrors.title, errorMsg: formErrors.title }}>
                        <div className="radio-inline">
                          <Radio
                            id="radio-title-man"
                            name="title"
                            label={this.getText('titleManLabel')}
                            key="14"
                            value="14"
                            onChange={this.onChange}
                            onBlur={this.onBlur}
                            disabled={isLoading}
                          />
                          <Radio
                            id="radio-title-woman"
                            name="title"
                            label={this.getText('titleWomanLabel')}
                            key="15"
                            value="15"
                            onChange={this.onChange}
                            onBlur={this.onBlur}
                            disabled={isLoading}
                          />
                        </div>
                      </Fieldset>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} lg={12}>
                      <FormGroup
                        label={{
                          text: this.getText('firstNameLabel'),
                          htmlFor: 'first-name-input',
                        }}
                        validate={{
                          hasError: !!formErrors.firstName,
                          errorMsg: formErrors.firstName,
                        }}
                        data-test="label_firstName"
                        length="large"
                      >
                        <Input
                          id="first-name-input"
                          name="firstName"
                          type="text"
                          onChange={this.onChange}
                          onBlur={this.onBlur}
                          onPaste={this.onPaste}
                          data-test="field_firstName"
                          maxLength={32}
                          disabled={isLoading}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} lg={12}>
                      <FormGroup
                        label={{
                          text: this.getText('middleNameLabel'),
                          htmlFor: 'middle-name-input',
                        }}
                        validate={{
                          hasError: !!formErrors.middleName,
                          errorMsg: formErrors.middleName,
                        }}
                        optionalText={this.getGlobalText('optional')}
                        data-test="label_middleName"
                        length="large"
                      >
                        <Input
                          id="middle-name-input"
                          name="middleName"
                          type="text"
                          onChange={this.onChange}
                          onBlur={this.onBlur}
                          onPaste={this.onPaste}
                          data-test="field_middleName"
                          maxLength={32}
                          disabled={isLoading}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} lg={12}>
                      <FormGroup
                        label={{
                          text: this.getText('lastNameLabel'),
                          htmlFor: 'last-name-input',
                        }}
                        validate={{
                          hasError: !!formErrors.lastName,
                          errorMsg: formErrors.lastName,
                        }}
                        data-test="label_lastName"
                        length="large"
                      >
                        <Input
                          id="last-name-input"
                          name="lastName"
                          type="text"
                          onChange={this.onChange}
                          onBlur={this.onBlur}
                          onPaste={this.onPaste}
                          data-test="field_lastName"
                          maxLength={32}
                          disabled={isLoading}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} lg={12}>
                      <Fieldset
                        legend={this.getText('birthDateLabel')}
                        tooltip={{
                          content: this.getText('tooltipText'),
                          ariaLabel: this.getText('tooltipText'),
                          onShowTooltip: this.onShowTooltip,
                        }}
                        validate={{
                          hasError: !!formErrors.birthDate,
                          errorMsg: formErrors.birthDate,
                        }}
                        data-test="label_birthDate"
                        length="large"
                      >
                        <DateFields
                          id="dateFields-birthDate"
                          locale={this.props.intl.locale as LocaleType}
                          onChange={this.onDateChange}
                          validate={getDateFieldsErrors(
                            this.state.formValues.birthDate,
                            this.state.dateFieldsCompleted,
                          )}
                          disabled={isLoading}
                        />
                      </Fieldset>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="spc-creationProfil__cardFooter">
                <Button appearance="primary" type="submit">
                  {this.getText('createProfile')}
                </Button>
              </div>
            </div>
          </form>
          {isLoading && <LoadingMessenger intl={intl} />}
        </Card>
        {this.props.step === 2 && (
          <Captcha
            siteKeyType={SiteKeyType.frontend}
            success={this.handleFormSubmit}
            timeout={() => {
              // This is intentional
            }}
            failure={() => {
              // This is intentional
            }}
            invisible
          />
        )}
      </>
    );
  }
}

export default AboutYou;
