import React, { FC } from 'react';
import { InjectedIntl } from 'react-intl';
import { Heading } from '@nbc-design/heading';

import { AccountData } from '../types';
import AccountSelector from './AccountSelector';
import { getTextFactory } from 'utils/TextUtils';
import { ProductForm, ProductFormFieldChangeHandler, RefData } from 'types/interfaces';
import { CLIENT_TYPE } from '../constants';
import BaoTooltip from 'components/BaoTooltip/BaoTooltip';

export interface AccountsSectionProps {
  accounts: AccountData[];
  accountsRefData: RefData[];
  change: ProductFormFieldChangeHandler;
  clientType: CLIENT_TYPE;
  currenciesRefData: RefData[];
  id: string;
  intl: InjectedIntl;
  totalAccounts: AccountData[];
  productForm: ProductForm;
}

const AccountsSection: FC<AccountsSectionProps> = (props: AccountsSectionProps) => {
  const {
    id,
    totalAccounts,
    accounts,
    intl,
    change,
    clientType,
    accountsRefData,
    currenciesRefData,
    productForm,
  } = props;

  const getText = getTextFactory(intl, 'yourAccount');

  return (
    <div className={id}>
      <div className="accounts-section-title">
        <Heading type="h2" size={4}>
          {getText(id)}
        </Heading>
        <BaoTooltip
          name={id}
          stepId={productForm.meta.stepId}
          content={getText(`accountsSectionTooltip.${id}`)}
          position="top"
          className="accounts-section-tooltip"
        />
      </div>
      <div className="accounts-wrapper">
        {accounts.map((account) => (
          <AccountSelector
            key={`${id}-${account.code}`}
            accounts={totalAccounts}
            account={account}
            accountsRefData={accountsRefData}
            change={change}
            clientType={clientType}
            currenciesRefData={currenciesRefData}
            intl={intl}
          />
        ))}
      </div>
    </div>
  );
};

export default AccountsSection;
