import { connect } from 'react-redux';
import * as actionCreators from 'services/baoService/actions';
import { selectors } from 'bnc-react-forms';
import { injectIntl } from 'react-intl';
import Sidebar from './Sidebar';
import { BAO_FORMS } from 'utils/constants';
import get from 'lodash/get';

const mapStateToProps = (state) => {
  const form = selectors.getForm(state, { form: BAO_FORMS });
  return {
    currentStepId: form ? form.currentStep : '',
    userName: get(state, 'baoReducer.userData')
      ? `${get(state, 'baoReducer.userData.firstName')} ${get(state, 'baoReducer.userData.lastName')}`
      : '',
    isSafeIDSuccess: get(state, 'baoReducer.isSafeIDSuccess'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUpdateOfPersonalInfosForm: () => dispatch(actionCreators.updatePersonalInfosForm()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Sidebar));
