import React, { FC, ReactNode } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { AppStep } from 'types/interfaces';
import { ROUTE, URLS } from 'utils/constants';
import { STEPS_WITH_PATH } from 'utils/stepsConstants';
import RedirectPage from 'components/RedirectPage';
import { AppProps } from './App';
import BffStepRoute from './BffStepRoute';
import LayoutRoute from './LayoutRoute';

export const AppsRoutes: FC<AppProps> = (props) => {
  const {
    intl: { locale },
  } = props;

  return (
    <Switch>
      {STEPS_WITH_PATH.map(
        (step: AppStep, index: number): ReactNode => (
          <Route
            key={index}
            path={step.PATH}
            render={(routerProps) => <LayoutRoute {...props} {...routerProps} step={step} />}
            exact
          />
        ),
      )}

      <Redirect path={ROUTE.MORE_INFORMATION} to={URLS.MORE_INFORMATION[locale.toUpperCase()]} push />

      <Route path={`${ROUTE.REDIRECT_PAGE}/:variant`} component={RedirectPage} />

      <Route path={ROUTE.STEPPER_MANAGER} render={(routerProps) => <BffStepRoute {...props} {...routerProps} />} />
    </Switch>
  );
};

export default AppsRoutes;
