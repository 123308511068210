import { injectIntl } from 'react-intl';
import { formValues } from 'bnc-react-forms';
import Waiting from './Waiting';
import config from './config';

export const CONFIG = config;

export default injectIntl(
  formValues('identityDocumentSelected', 'hasProInvolvement', 'indvIdentMethodClrdCheck', 'email')(Waiting),
);
