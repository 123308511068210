import React, { ChangeEvent, Component, ReactNode, SyntheticEvent } from 'react';
import { InjectedIntl } from 'react-intl';
import { History } from 'history';
import { FormGroup } from '@nbc-design/form-group';
import { Password } from '@nbc-design/password';
import Title from 'components/Title';
import { Col, Row } from 'react-flexbox-grid';
import { ROUTE } from 'utils/constants';
import FormFooter from 'components/FormFooter';
import { signup } from 'services/loginManager';
import Captcha from 'components/Captcha';
import { getTextFactory } from 'utils/TextUtils';
import { sendStepLoadedEvent } from 'services/analytics';
import { EVENT_IDS } from 'services/analytics/constants';
import CONFIG from './config';

import PasswordCriteria, { PasswordCriterionProps } from './components/PasswordCriteria';
import './styles.scss';
import { SiteKeyType } from 'components/Captcha/Captcha';
import { alternateContentRedirect, ERRORCODE, ErrorResponse } from 'components/errorManager/utils';
import { getBncId } from 'utils';

export type NewAccountRequestProps = {
  intl: InjectedIntl;
  history: History;
};

export type NewAccountRequestState = {
  password: string;
  isLoading: boolean;
  isValidPassword: boolean;
};

class NewAccountRequest extends Component<NewAccountRequestProps, NewAccountRequestState> {
  state: NewAccountRequestState = {
    password: '',
    isLoading: false,
    isValidPassword: false,
  };

  getText = getTextFactory(this.props.intl, 'newRequest');
  getTextError = getTextFactory(this.props.intl, 'error');

  componentDidMount = () => {
    sendStepLoadedEvent(CONFIG.ID, EVENT_IDS.ST, '', {
      user: {
        bncId: getBncId(),
      },
    });
  };

  validationConditions = (): PasswordCriterionProps[] => {
    const { password } = this.state;
    return [
      { id: 'oneLetter', label: this.getText('passwordRequirement1'), isValid: /[a-zA-Z]+/.test(password) },
      { id: 'oneNumber', label: this.getText('passwordRequirement2'), isValid: /\d+/.test(password) },
      { id: 'noSpace', label: this.getText('passwordRequirement3'), isValid: /^\S+$/.test(password) },
      {
        id: 'length',
        label: this.getText('passwordRequirement4'),
        isValid: password.length >= 8 && password.length <= 25,
      },
    ];
  };

  validatePassword = (): void => {
    const isValidPassword = this.validationConditions().reduce(
      (validPassword, { isValid }) => validPassword && isValid,
      true,
    );
    this.setState({ isValidPassword });
  };

  userAuthSuccess = (): void => {
    window.location.href = `${process.env.PUBLIC_URL}${ROUTE.STEPPER_MANAGER}`;
  };

  executeRecaptcha = (event: SyntheticEvent): void => {
    event.preventDefault();

    (window as any).grecaptcha.execute();
  };

  handleError = (response: ErrorResponse): void => {
    response.status === ERRORCODE.BOT_MANAGER && alternateContentRedirect(this.props.intl, response);
  };

  handleFormSubmit = (): void => {
    const { password } = this.state;

    this.setState({ isLoading: true });

    signup(password).then(this.userAuthSuccess).catch(this.handleError);
  };

  errorClass = (error: string): string => {
    return error === '' ? '' : 'has-error';
  };

  onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    this.setState({ password: value }, () => {
      this.validatePassword();
    });
  };

  isFormValid = (): boolean => {
    const { isValidPassword } = this.state;
    return isValidPassword;
  };

  render(): ReactNode {
    const { history } = this.props;
    const { password } = this.state;
    const isFormValid = this.isFormValid();

    const validations = this.validationConditions();
    let notValidConditions = validations
      .filter((condition) => !condition.isValid)
      .map((condition) => `${condition.id}-condition`)
      .join(' ');
    if (!notValidConditions.length) {
      notValidConditions = 'requirements-met';
    }

    return (
      <form onSubmit={this.executeRecaptcha} className="layout-content">
        <div className="new-account-request__form">
          <Title label={this.getText('pageTitle')} subTitle={this.getText('explanation')} />

          <Row>
            <Col xs={6} md={6} lg={6}>
              <FormGroup
                label={{ text: this.getText('passwordLabel'), htmlFor: 'password' }}
                data-test="label_password"
              >
                <Password
                  id="password"
                  name="password"
                  maxLength={25}
                  value={password}
                  ariaLabelShow={this.getText('showPassword')}
                  ariaLabelHide={this.getText('hidePassword')}
                  onChange={this.onChange}
                  autoComplete="off"
                  aria-describedby={notValidConditions}
                />
              </FormGroup>

              <PasswordCriteria
                criteria={this.validationConditions()}
                requirementMetLabel={this.getText('requirementsMet')}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={6} md={6} lg={6}>
              <Captcha
                siteKeyType={SiteKeyType.frontend}
                success={this.handleFormSubmit}
                timeout={() => {
                  // This is intentional
                }}
                failure={() => {
                  // This is intentional
                }}
                invisible
              />
            </Col>
          </Row>
        </div>

        <FormFooter
          goToPreviousStep={() => {
            history.push({
              pathname: ROUTE.ASSISTED,
            });
          }}
          disableSubmit={!isFormValid}
        />
      </form>
    );
  }
}

export default NewAccountRequest;
