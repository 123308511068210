import React, { FunctionComponent } from 'react';
import { AccountsStep3FormData } from '../types';
import UtilisationSelector from './UtilisationSelector';
import { INVESTCUBE } from '../constants';
import { useContainerCtx } from '../../../providers/containerCtx/ContainerCtx';
import AccountSectionFormGroup from './_layout/AccountSectionFormGroup';
import { getRefData } from '../../../utils/productFormUtils';
import get from 'lodash/get';

export type UtilisationSelectorsProps = {};

const UtilisationSelectors: FunctionComponent<UtilisationSelectorsProps> = () => {
  const {
    genericProps,
    formValues: { utilisations },
    getText,
    getTextGlobal,
  } = useContainerCtx<AccountsStep3FormData>();

  const utilisationsRefData = getRefData(genericProps, 'utilisations');
  const utilisationCommentMaxLength: string = get(
    genericProps.productForm,
    'schema.definitions.AccountUtilisation.properties.usageOtherComment.maxLength',
  );

  if (!utilisations || utilisations.length === 0) return null;
  return (
    <AccountSectionFormGroup
      testId={'utilisationSelectors'}
      label={{
        text: getText('utilisation'),
        htmlFor: `utilisations[0].usageCode`,
      }}
      tooltipContent={getText('utilisationInfo')}
    >
      {utilisations.map((utilisation, index) => {
        return (
          <UtilisationSelector
            key={`utilisationSelector-${index}`}
            index={index}
            utilisation={utilisation}
            label={utilisation.registeredAgmtFlg === INVESTCUBE ? <span> - {getTextGlobal('N')}</span> : ''}
            utilisationsRefData={utilisationsRefData}
            utilisationCommentMaxLength={utilisationCommentMaxLength}
          />
        );
      })}
    </AccountSectionFormGroup>
  );
};

export default UtilisationSelectors;
