import React, { ChangeEvent, FunctionComponent } from 'react';
import { Combo, Props as ComboProps } from '@nbc-design/combo';
import { Fieldset } from '@nbc-design/fieldset';
import { Radio } from '@nbc-design/radio';
import { Text } from '@nbc-design/text';
import { InjectedIntl } from 'react-intl';
import { FormComponentProps, OptionsType } from '../types';
import { getLabelText, getErrorMessage, isInvalidOnChange, getSchemaOptions, getOptionsReadOnlyLabel } from '../utils';
import { getTextFactory } from 'utils/TextUtils';
import AnalyticsHelper from 'services/analytics/AnalyticsHelper';

export type ProductFormComboRadioProps = FormComponentProps & {
  intl: InjectedIntl;
  disabledOptions: string[];
  items?: ComboProps['items'];
  tooltip?: React.ReactNode;
};

const ProductFormComboRadio: FunctionComponent<ProductFormComboRadioProps> = (props: ProductFormComboRadioProps) => {
  const { tooltip, helpText = '', input, isReadOnly, intl, disabledOptions = [], items } = props;

  const invalid = isInvalidOnChange(props);
  const options: OptionsType[] = getSchemaOptions(props) || [];

  const readOnlyLabel = getOptionsReadOnlyLabel(options, input.value);
  const onShowTooltip = AnalyticsHelper.handleShowTooltip(input.name);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (input.onChange) {
      input.onChange(event);
    }
  };

  const radioItems =
    items ||
    (options || []).map((option: OptionsType): ComboProps['items'][0] => ({
      key: option.value,
      children: (
        <Radio
          id={`radio-${input.name}-${option.value}`}
          key={option.value}
          label={option.label}
          value={option.value}
          name={input.name}
          checked={input.value && input.value === option.value}
          onChange={handleChange}
          disabled={option.readOnly || disabledOptions.indexOf(option.value) !== -1}
        />
      ),
      disabled: option.readOnly || disabledOptions.indexOf(option.value) !== -1,
    }));

  const label = getLabelText(props);
  const getAccessibilityText = getTextFactory(intl, 'accessibility');

  return (
    <Fieldset
      legend={label}
      tooltip={{ content: tooltip, ariaLabel: getAccessibilityText('information'), onShowTooltip }}
      validate={{ hasError: invalid, errorMsg: getErrorMessage(props) || '' }}
      description={{ text: helpText, id: 'dscFieldset__description' }}
    >
      {isReadOnly ? (
        <Text type={'p'}>{readOnlyLabel}</Text>
      ) : (
        <Combo id={`combo-${input.name}`} type="form" items={radioItems} />
      )}
    </Fieldset>
  );
};

export default ProductFormComboRadio;
