import React, { PropsWithChildren } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { ProductFormRadioBoolean } from 'components/productFormFields/index';
import { useContainerCtx } from 'providers/containerCtx/ContainerCtx';
import { Divider } from '@nbc-design/divider';
import { ProductFormRadioBooleanProps } from '../../ProductFormRadioBoolean/ProductFormRadioBoolean';

type TransferAccountSectionProps<TKey extends string> = ProductFormRadioBooleanProps & {
  label: string;
  name: TKey;
  hasDivider?: boolean;
};

const ProductFormRadioWithSubContent = <TKey extends string>({
  label,
  name,
  children,
  hasDivider = true,
  ...radioProps
}: PropsWithChildren<TransferAccountSectionProps<TKey>>) => {
  const { formValues, getTextGlobal, formatErrorMessage } = useContainerCtx();

  return (
    <div className={'mc-mb-large'}>
      {hasDivider && <Divider className={'mc-pt-normal'} />}

      <Row className="request_assistant_advisor">
        <Col xs={12} md={12} lg={12} data-test={`field_${name}`}>
          <ProductFormRadioBoolean
            className="inline"
            label={<div className={'mc-fw-medium'}>{label}</div>}
            name={name}
            yesLabel={getTextGlobal('button.yes')}
            noLabel={getTextGlobal('button.no')}
            formatErrorMessage={formatErrorMessage()}
            inline
            {...radioProps}
          />
        </Col>
      </Row>

      {formValues?.[name] && (
        <Row className={'mc-bg-blue025 mc-br-small mc-mt-normal mc-p-small mc-py-normal'}>
          <Col xs={12} md={12} lg={12}>
            {children}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ProductFormRadioWithSubContent;
