import React, { ChangeEvent, FC, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import get from 'lodash/get';
import { Alert } from '@nbc-design/alert';
import { DocusignColor } from '@nbc-design/icons/lib/web/DocusignColor';
import { SecureFill } from '@nbc-design/icons/lib/web/SecureFill';
import { ONLINE } from './constants';
import { GenericProps } from 'types/interfaces';
import { getTextFactory } from 'utils/TextUtils';
import Title from 'components/Title';
import Heading from 'components/Heading';
import HelpText from 'components/HelpText';
import { ProductFormCheckbox } from 'components/productFormFields';
import ConfirmInfo from './components/ConfirmInfo';
import ValidationCompleted from './components/ValidationCompleted';
import IdentityWrapper from '../IdentityValidation/components/IdentityWrapper';

import '../IdentityValidation/styles.scss';
import './styles.scss';

type ESignProps = GenericProps & {
  displayPaperSign?: boolean;
  displayIdentitySection?: boolean;
  paperSignSelected?: boolean;
  handleSubmit: Function;
  completedSteps: string[];
  goToStep: (step: string) => void;
  setPreviousStepsBlocked: Function;
};

const ESign: FC<ESignProps> = (props: ESignProps) => {
  const { intl, displayPaperSign, change, productForm, handleSubmit, goToStep, setPreviousStepsBlocked } = props;

  const getIdentityValidationText = getTextFactory(intl, 'identityValidation');
  const getAccessibilityText = getTextFactory(intl, 'accessibility.icon');
  const getText = getTextFactory(intl, 'esign');
  const buttonText = getTextFactory(intl, 'global.button');

  // Hacking, it seems that BFF does not send all schema properties and definitions in the format expected by components
  productForm.schema.properties.esignEmail.maxLength = 64;
  productForm.schema.properties.esignEmail.pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.source;
  if (productForm.schema.definitions) {
    productForm.schema.definitions.TelephoneUI.properties.phoneNumber.pattern = '^[0-9]{3}-[0-9]{3}-[0-9]{4}$';
  }

  const indvIdentPrefMethod = get(productForm, ['values', 'indvIdentPrefMethod'], '');
  const indvIdentMethod = get(productForm, ['values', 'indvIdentMethod'], '');
  const displayValidationIdentitySection = get(productForm, ['values', 'displayValidationIdentitySection'], false);
  const displayIdentitySection = get(productForm, ['values', 'displayIdentitySection'], false);
  const phoneNumber = get(productForm, ['values', 'phoneNumber', 'phoneNumber'], '');
  const email = get(productForm, ['values', 'esignEmail'], '');

  const [contactInfo, setContactInfo] = useState({
    esignEmailValid: true,
    'phoneNumber.phoneNumberValid': true,
  });

  indvIdentPrefMethod === ONLINE && setPreviousStepsBlocked();

  const onChangePaperSign = ({ target: { checked } }) => {
    change('paperSignSelected', checked);
  };

  const handleEmailPhoneValuechange = (event: ChangeEvent<HTMLInputElement>, isValid?: boolean) => {
    setContactInfo({ ...contactInfo, [`${event.target.name}Valid`]: isValid });
  };

  return (
    <div className="esign-form">
      <Title
        label={getIdentityValidationText('pageTitle')}
        subTitle={getIdentityValidationText('subTitle')}
        tabTitle={getText('tabTitle')}
      />

      <div data-test="security-explanation-text">
        <Alert
          title={getIdentityValidationText('securityInformation')}
          size="small"
          icon={<SecureFill size="small" title={getIdentityValidationText('securityInformation')} />}
        />
      </div>

      {displayValidationIdentitySection && (
        <ValidationCompleted
          getText={getText}
          getIdentityValidationText={getIdentityValidationText}
          goToStep={goToStep}
          indvIdentPrefMethod={indvIdentPrefMethod}
          indvIdentMethod={indvIdentMethod}
        />
      )}

      <div id="confirm-info">
        <IdentityWrapper
          title={getText('electronicSign')}
          subTitle={getText('validationExplanation')}
          continueText={buttonText('simpleSign')}
          iconName="sign-here"
          buttonType={!displayIdentitySection ? 'submit' : 'button'}
          buttonAction={
            !(contactInfo.esignEmailValid && contactInfo['phoneNumber.phoneNumberValid']) ? undefined : handleSubmit
          }
          hasButton
        >
          <div className="esign-section">
            <div className="esign-section-confirmation">
              <ConfirmInfo
                change={change}
                phoneNumber={phoneNumber}
                email={email}
                intl={intl}
                handleOnChange={handleEmailPhoneValuechange}
              />
              <HelpText>{getText('helpPhone')}</HelpText>
              <Row className="safely">
                <Col>
                  <HelpText>{getText('safely')}</HelpText>
                </Col>
              </Row>
            </div>

            <div className="esign-section-content">
              <Row>
                <Col>
                  <DocusignColor
                    size={{ width: 80, height: 18 }}
                    className="docusign-icon"
                    title={getAccessibilityText('docusign')}
                  />
                  <p className="docusign-description">{getText('docuSignDescription')}</p>
                </Col>
              </Row>
            </div>
          </div>

          {displayPaperSign && (
            <div className="paper-sign">
              <Heading type="h4" size={6}>
                {getText('paperSign')}
              </Heading>
              <Row>
                <Col>
                  <ProductFormCheckbox
                    label={getText('paperSignWanted')}
                    name="paperSignSelected"
                    onChange={onChangePaperSign}
                    className="paper-sign-text"
                  />
                </Col>
              </Row>
            </div>
          )}
        </IdentityWrapper>
      </div>
    </div>
  );
};

export default ESign;
