import React from 'react';
import Dialog from '@nbc-design/dialog';
import { useContainerCtx } from '../../../providers/containerCtx/ContainerCtx';
import { Button } from '@nbc-design/button';
import { Text } from '@nbc-design/text';
import { Heading } from '@nbc-design/heading';
import { BeneficiaryFormValues } from '../types';

type DialogCloseConfirmationProps = {
  handleCloseClick: Function;
  handleCloseDialog: Function;
};
const DialogCloseConfirmation: React.FC<DialogCloseConfirmationProps> = ({ handleCloseClick, handleCloseDialog }) => {
  const { getText, getTextGlobal } = useContainerCtx<BeneficiaryFormValues>();

  return (
    <Dialog data-test="modal-close">
      <Dialog.Body>
        <Heading type={'h4'}>{getText('closeDialog.heading')}</Heading>
        <Text>{getText('closeDialog.text')}</Text>
      </Dialog.Body>

      <Dialog.Footer>
        <Dialog.CloseBtn
          data-test="confirm-modal-cancel-button"
          appearance="secondary"
          onClick={() => {
            handleCloseDialog();
          }}
        >
          {getTextGlobal('button.cancel')}
        </Dialog.CloseBtn>
        <Button
          data-test="confirm-modal-close-button"
          appearance="primary"
          onClick={() => {
            handleCloseClick();
          }}
        >
          {getTextGlobal('button.quit')}
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

export default DialogCloseConfirmation;
