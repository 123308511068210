import React, { FunctionComponent, Fragment } from 'react';
import { Heading } from '@nbc-design/heading';
import { Text } from '@nbc-design/text';
import { Helmet } from 'react-helmet';
import { InjectedIntl } from 'react-intl';
import { getTextFactory } from 'utils/TextUtils';

export type TitleProps = {
  intl: InjectedIntl;
  dataTest?: string;
  label: string;
  subTitle?: string;
  tabTitle?: string;
  classNameTitle?: string;
  classNameSubtitle?: string;
};

const Title: FunctionComponent<TitleProps> = ({
  intl,
  dataTest = 'label_page',
  label,
  subTitle,
  tabTitle,
  classNameTitle,
  classNameSubtitle,
}) => {
  const getText = getTextFactory(intl, 'global');

  return (
    <Fragment>
      <Heading type="h1" size={1} data-test={`${dataTest}_title`} className={classNameTitle}>
        {label}
      </Heading>
      {subTitle && (
        <Text
          size="lead"
          className={classNameSubtitle ? `sub-title ${classNameSubtitle}` : 'sub-title'}
          data-test={`${dataTest}_subTitle`}
        >
          {subTitle}
        </Text>
      )}
      <Helmet>
        <title>{`${tabTitle || label} - ${getText('BAO')}`}</title>
      </Helmet>
    </Fragment>
  );
};

export default Title;
