import { ErrorsDictionary, RefData } from 'types/interfaces';
import {
  BeneficiaryDataType,
  BeneficiaryGender,
  BeneficiaryRelationTypeCd,
  FormValidationType,
  OperationMode,
  ValidationReturnType,
  formValueNames,
} from './types';
import { InjectedIntl } from 'react-intl';
import { DateTime } from 'luxon';
import { RespPlanTypeCd } from 'containers/AccountsStep3/types';
import { ERRORTYPE, addressValidation, isPOBOX, requiredValidation } from 'utils/validation';
import { isCleanSIN, isObfuscatedText, validSocialInsurance } from 'utils';
import { getDateFieldsErrors } from 'containers/CreateUserProfile/validation';
import { isNil } from 'lodash';

type formatGenderProps = {
  value: BeneficiaryGender;
  refData?: RefData[];
  intl: InjectedIntl;
};

export const isIndividualOnlyBeneficiary = (
  beneficiaries: BeneficiaryDataType[],
  respPlanTypeCd: RespPlanTypeCd,
): boolean =>
  (respPlanTypeCd === RespPlanTypeCd.INDIVIDUAL && beneficiaries.length === 1 && beneficiaries[0].isCurrentUser) ??
  false;

export const areAllContributionFieldsEmpty = (
  beneficiaries: BeneficiaryDataType[],
  currentBeneficiary?: BeneficiaryDataType,
): boolean =>
  getBeneficiariesNotDeleted(beneficiaries)
    ?.filter(
      (beneficiary) =>
        beneficiary.bncId !== currentBeneficiary?.bncId && beneficiary.firstName !== currentBeneficiary?.firstName,
    )
    .every((beneficiary) => isNil(beneficiary.ownerPercentage));

export const formatGender = ({ value, refData, intl }: formatGenderProps): string => {
  return refData?.find((ref) => ref.valueDomainCode === value)?.[intl.locale]?.label;
};

export const calculatedTotalContribution = (
  beneficiaries: BeneficiaryDataType[],
  currentBeneficiary?: BeneficiaryDataType,
): number =>
  areAllContributionFieldsEmpty(getBeneficiariesNotDeleted(beneficiaries), currentBeneficiary)
    ? 100
    : getBeneficiariesNotDeleted(beneficiaries)?.reduce((acc, curr) => acc + Number(curr.ownerPercentage ?? 0), 0);

export const getAllFieldNames = (): string[] => {
  return [
    ...Object.keys(formValueNames).map((key): string => `formData.${formValueNames[key]}`),
    ...['city', 'country', 'postalCode', 'province', 'streetName', 'streetNumber'].map(
      (key) => `formData.currentAddress.${key}`,
    ),
  ];
};

export const getBeneficiariesNotDeleted = (beneficiaries: BeneficiaryDataType[] = []): BeneficiaryDataType[] =>
  beneficiaries.filter((beneficiary) => beneficiary.operationMode !== OperationMode.DELETE);

export const calculateAge = (birthDate?: string, fromDate?: string): number | undefined => {
  if (
    birthDate === undefined ||
    !DateTime.fromISO(birthDate).isValid ||
    (fromDate !== undefined && !DateTime.fromISO(fromDate).isValid)
  ) {
    return undefined;
  }
  return Math.floor(
    (fromDate ? DateTime.fromISO(fromDate) : DateTime.utc()).diff(DateTime.fromISO(birthDate), 'years').years,
  );
};

export const birthDateValidation = (values: BeneficiaryDataType): ErrorsDictionary => {
  const { birthDate } = values;

  if (!birthDate) {
    return { birthDate: ERRORTYPE.REQUIRED };
  }

  const date = DateTime.fromISO(birthDate);
  if (!date.isValid) {
    return { birthDate: ERRORTYPE.PATTERN };
  }

  if ((date.diffNow('days').toObject().days || 0) >= 0) {
    return { birthDate: ERRORTYPE.PATTERN };
  }

  if (date.year < 1900) {
    return { birthDate: ERRORTYPE.PATTERN };
  }

  return {};
};

export const validateSocialInsuranceNumber = ({ socialInsuranceNumber }: BeneficiaryDataType): ErrorsDictionary => {
  if (!socialInsuranceNumber) {
    return { socialInsuranceNumber: ERRORTYPE.REQUIRED };
  }
  const isValid: boolean =
    isObfuscatedText(socialInsuranceNumber) ||
    (isCleanSIN(socialInsuranceNumber) && validSocialInsurance(socialInsuranceNumber));
  return {
    ...(!isValid ? { socialInsuranceNumber: ERRORTYPE.INVALIDSIN } : {}),
  };
};

export const validateBeneficiary = (
  beneficiary: BeneficiaryDataType,
  beneficiaries: BeneficiaryDataType[],
  respPlanTypeCd: RespPlanTypeCd,
): ValidationReturnType => {
  const {
    currentAddress,
    isConfirmed,
    birthDate,
    ownerPercentage,
    relationTypeCd,
    parentFirstName,
    parentRelationTypeCd,
    parentLastName,
  } = beneficiary;
  const beneficiaryAccountRequiredFields = [
    formValueNames.FIRST_NAME,
    formValueNames.LAST_NAME,
    formValueNames.BIRTH_DATE,
    formValueNames.GENDER,
    formValueNames.SIN,
    formValueNames.ADDRESS,
  ];

  const beneficiaryFormRequiredFields =
    relationTypeCd === BeneficiaryRelationTypeCd.MYSELF
      ? [formValueNames.RELATIONSHIP]
      : [formValueNames.RELATIONSHIP, formValueNames.GRANT_REQUEST];

  const isLegalCarer = [
    BeneficiaryRelationTypeCd.PARENT,
    BeneficiaryRelationTypeCd.TUTOR,
    BeneficiaryRelationTypeCd.MYSELF,
  ].includes(relationTypeCd!);

  const accountFieldsValidationResponse = requiredValidation(beneficiary, beneficiaryAccountRequiredFields);
  const formFieldValidationResponse = requiredValidation(beneficiary, beneficiaryFormRequiredFields);
  const dateErrors = getDateFieldsErrors(birthDate, true);
  const addressErrors = { ...addressValidation(currentAddress), ...isPOBOX(currentAddress) };

  const validation: FormValidationType = {
    ...{ ...accountFieldsValidationResponse, ...formFieldValidationResponse },
    ...(currentAddress?.isManualMode
      ? Object.values(addressErrors).length && { currentAddress: { ...addressErrors } }
      : Object.values(addressErrors).length && { currentAddress: ERRORTYPE.REQUIRED }),
    ...validateSocialInsuranceNumber(beneficiary),
    ...birthDateValidation(beneficiary),
    ...(Object.values(dateErrors).find((value) => value) && { birthDate: ERRORTYPE.PATTERN }),
    ...(respPlanTypeCd === RespPlanTypeCd.FAMILY &&
      !areAllContributionFieldsEmpty(beneficiaries, beneficiary) &&
      isNil(ownerPercentage) && {
        ownerPercentage: ERRORTYPE.REQUIRED,
      }),
    ...(!isLegalCarer && !parentFirstName && { parentFirstName: ERRORTYPE.REQUIRED }),
    ...(!isLegalCarer && !parentLastName && { parentLastName: ERRORTYPE.REQUIRED }),
    ...(!isLegalCarer && !parentRelationTypeCd && { parentRelationTypeCd: ERRORTYPE.REQUIRED }),
    ...(respPlanTypeCd === RespPlanTypeCd.FAMILY &&
      !isNil(ownerPercentage) &&
      (ownerPercentage < 1 || ownerPercentage > 100) && {
        ownerPercentage: ERRORTYPE.PATTERN,
      }),
  };

  return {
    ...(Object.keys(validation).length ? { formData: { ...validation } } : {}),
    ...(Object.keys(accountFieldsValidationResponse).length && isConfirmed
      ? { account: { ...accountFieldsValidationResponse } }
      : {}),
  };
};

export const filterRelationshipOptions = (
  refData: RefData[] | undefined,
  eligibleRelationTypes: BeneficiaryRelationTypeCd[],
): RefData[] | undefined => {
  // logic here
  return refData
    ?.filter((ref) => eligibleRelationTypes?.includes(ref.valueDomainCode as BeneficiaryRelationTypeCd))
    ?.sort(
      (a, b) =>
        eligibleRelationTypes.indexOf(a.valueDomainCode as BeneficiaryRelationTypeCd) -
        eligibleRelationTypes.indexOf(b.valueDomainCode as BeneficiaryRelationTypeCd),
    )
    ?.map((option) => {
      return {
        en: {
          label: option.en?.label.toLowerCase(),
          name: option.en?.name?.toLowerCase(),
          shortDescription: option.en?.shortDescription?.toLowerCase(),
        },
        fr: {
          label: option.fr?.label?.toLowerCase(),
          name: option.fr?.name?.toLowerCase(),
          shortDescription: option.fr?.shortDescription?.toLowerCase(),
        },
        valueDomainCode: option.valueDomainCode,
      };
    });
};

export const isBeneficiaryProfileComplete = (
  beneficiary: BeneficiaryDataType,
  beneficiaries: BeneficiaryDataType[],
  respPlanTypeCd: RespPlanTypeCd,
) => {
  if (
    beneficiary.hasIncompleteInfo !== true &&
    !validateBeneficiary(beneficiary, beneficiaries, respPlanTypeCd).formData
  ) {
    if (respPlanTypeCd === RespPlanTypeCd.FAMILY) {
      return areAllContributionFieldsEmpty(beneficiaries)
        ? !beneficiary.ownerPercentage
        : !!beneficiary.ownerPercentage;
    }
    return true;
  } else {
    return false;
  }
};
