import React, { PureComponent } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { getTransferSlice } from '../selectors';
import { AccountData, TransferSlice } from '../types';
import { getTextFactory } from 'utils/TextUtils';
import { InjectedIntl, injectIntl } from 'react-intl';
import SummarySection from './SummarySection';
import { BFF_STEPS_IDS } from 'utils/stepsConstants';
import SummaryField from './SummaryField';
import SummaryFieldValue from './SummaryFieldValue';
import SummaryFieldLabel from './SummaryFieldLabel';
import { ACCOUNT_SOURCE } from 'containers/Transfer/constants';
import { ACCOUNT_TYPE, ACCOUNTS_WITH_PROVINCE } from 'containers/AccountsStep1/constants';
import isEmpty from 'lodash/isEmpty';

export type TransferSectionPropsFromState = {
  transfertSlice: TransferSlice;
};

export type TransferSectionProps = {
  goToStep: (stepId: string) => string;
  intl: InjectedIntl;
};

export class TransferSection extends PureComponent<TransferSectionProps & TransferSectionPropsFromState> {
  getGlobalText = getTextFactory(this.props.intl, 'global');
  getTransferText = getTextFactory(this.props.intl, 'transfer');
  getSummaryText = getTextFactory(this.props.intl, 'summary.transfer');

  renderTransferAnswerRow = (transferAccount: boolean): JSX.Element => {
    const transferAnswer = this.getTransferText(transferAccount ? 'answerYes' : 'answerNo');

    return (
      <Row className="transfer-answer-row">
        <Col xs={12} md={12} xl={12}>
          <SummaryField dataTest="transfert_transferAccount" label={this.getTransferText('transferAccountOtherFin')}>
            {transferAnswer}
          </SummaryField>
        </Col>
      </Row>
    );
  };

  renderTransferEmployerAnswerRow = (transferAccount: boolean): JSX.Element => {
    const transferAnswer = this.getTransferText(transferAccount ? 'answerYes' : 'answerNo');

    return (
      <Row className="transfer-answer-row">
        <Col xs={12} md={12} xl={12}>
          <SummaryField dataTest="transfert_transferAccount" label={this.getTransferText('transferAccountEmployer')}>
            {transferAnswer}
          </SummaryField>
        </Col>
      </Row>
    );
  };

  renderTransferInternalAnswerRow = (transferAccount: boolean): JSX.Element => {
    const transferAnswer = this.getTransferText(transferAccount ? 'answerYes' : 'answerNo');

    return (
      <Row className="transfer-answer-row">
        <Col xs={12} md={12} xl={12}>
          <SummaryField dataTest="transfert_transferAccount" label={this.getTransferText('transferAccountInternal')}>
            {transferAnswer}
          </SummaryField>
        </Col>
      </Row>
    );
  };

  renderAccountsLabelRow = (): JSX.Element => {
    return (
      <Row className="transfer-accounts-label-row">
        <Col xs={12} md={12} xl={12}>
          <SummaryFieldLabel data-test={'field_accounts'}>{this.getTransferText('accountTransfer')}</SummaryFieldLabel>
        </Col>
      </Row>
    );
  };

  renderAccountsEmployerLabelRow = (): JSX.Element => {
    return (
      <Row className="transfer-accounts-label-row">
        <Col xs={12} md={12} xl={12}>
          <SummaryFieldLabel data-test={'field_accounts'}>
            {this.getTransferText('accountTransferEmployer')}
          </SummaryFieldLabel>
        </Col>
      </Row>
    );
  };

  renderAccountsInternalLabelRow = (): JSX.Element => {
    return (
      <Row className="transfer-accounts-label-row">
        <Col xs={12} md={12} xl={12}>
          <SummaryFieldLabel data-test={'field_accounts'}>
            {this.getTransferText('accountTransferInternal')}
          </SummaryFieldLabel>
        </Col>
      </Row>
    );
  };

  renderAccountValueRow = (account: AccountData, index: number): JSX.Element => {
    const { locale } = this.props.intl;
    const { acctType, currency, registeredFlag, province, location } = account;

    const provinceLabelText = location && !isEmpty(location) ? ` ${location[locale]}` : '';
    const transferAccount = `${acctType[locale]}${
      ACCOUNTS_WITH_PROVINCE.includes(acctType?.code as ACCOUNT_TYPE) && province ? ' ' + province?.[locale] : ''
    }${provinceLabelText} ${currency} ${this.getGlobalText(registeredFlag)}`;

    return (
      <Row key={index} className="transfer-account-value-row">
        <Col xs={12} md={12} lg={12} key={index}>
          <SummaryFieldValue data-test={`field_accounts-${index}`}>{transferAccount}</SummaryFieldValue>
        </Col>
      </Row>
    );
  };

  renderContent = (): JSX.Element | null => {
    const { transfertSlice } = this.props;

    if (!transfertSlice) {
      return null;
    }

    const {
      accounts,
      transferAccountOtherFin,
      transferAccountEmployer,
      transferAccountInternal,
      canTransferInternal,
      canTransferFromEmployer,
      editable,
    }: TransferSlice = transfertSlice;
    const accountsOtherFin = accounts?.filter((account) => account.transferSourceCd === ACCOUNT_SOURCE.OTHFIN);
    const accountsEmployer = accounts?.filter((account) => account.transferSourceCd === ACCOUNT_SOURCE.EMPLYR);
    const accountsInternal = accounts?.filter((account) => account.transferSourceCd === ACCOUNT_SOURCE.INTERNAL);

    const hasAccountsOtherFin: boolean = accountsOtherFin && accountsOtherFin.length > 0;
    const hasAccountsTransferAccepted: boolean = hasAccountsOtherFin && transferAccountOtherFin;
    const hasAccountsEmployer: boolean = accountsEmployer && accountsEmployer.length > 0;
    const hasAccountTransferEmployerAccepted =
      canTransferFromEmployer && hasAccountsEmployer && transferAccountEmployer;
    const hasAccountsInternal: boolean = accountsInternal && accountsInternal.length > 0;
    const hasAccountsTransferInternalAccepted: boolean =
      canTransferInternal && hasAccountsInternal && transferAccountInternal;

    return (
      <div className="transfert-recap-content">
        {editable && this.renderTransferAnswerRow(transferAccountOtherFin)}
        {hasAccountsTransferAccepted && this.renderAccountsLabelRow()}
        {hasAccountsTransferAccepted && accountsOtherFin.map(this.renderAccountValueRow)}
        {canTransferFromEmployer && this.renderTransferEmployerAnswerRow(transferAccountEmployer)}
        {hasAccountTransferEmployerAccepted && this.renderAccountsEmployerLabelRow()}
        {hasAccountTransferEmployerAccepted && accountsEmployer.map(this.renderAccountValueRow)}
        {canTransferInternal && this.renderTransferInternalAnswerRow(transferAccountInternal)}
        {hasAccountsTransferInternalAccepted && this.renderAccountsInternalLabelRow()}
        {hasAccountsTransferInternalAccepted && accountsInternal.map(this.renderAccountValueRow)}
      </div>
    );
  };

  render() {
    const { goToStep, intl, transfertSlice } = this.props;

    return (
      <SummarySection
        intl={intl}
        name="transfer"
        title={this.getSummaryText('pageTitle')}
        stepId={BFF_STEPS_IDS.TRANSFER}
        goToStep={goToStep}
        renderSectionContent={this.renderContent}
        editable={transfertSlice && transfertSlice.editable}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    transfertSlice: getTransferSlice(state),
  };
};

export default connect<TransferSectionPropsFromState, {}, TransferSection>(mapStateToProps)(
  injectIntl(TransferSection),
);
