import React, { FunctionComponent, Fragment } from 'react';
import { InjectedIntl } from 'react-intl';

import { getTextFactoryReturnType } from 'utils/TextUtils';
import PdfViewer from 'components/PdfViewer';
import { DocumentItem } from '../types';

export type DocumentViewerProps = {
  intl: InjectedIntl;
  getGlobalText: getTextFactoryReturnType;
  getText: getTextFactoryReturnType;
  documents: DocumentItem[];
  documentsURLs: string[];
  pdfScale: number;
};

const DocumentViewer: FunctionComponent<DocumentViewerProps> = (props) => {
  const { intl, documents, documentsURLs, pdfScale } = props;

  if (documents.length !== documentsURLs.length) {
    return null;
  }

  return (
    <Fragment>
      {documents.map((doc, i) => {
        const title = `${i + 1}. ${doc.title[intl.locale]}`;
        const documentId = doc.documentId[intl.locale];
        const file = documentsURLs[i];

        return <PdfViewer key={`PdfViewer__${documentId}`} title={title} file={file} scale={pdfScale} />;
      })}
    </Fragment>
  );
};

export default DocumentViewer;
