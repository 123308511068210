import React, { FC } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { InjectedIntl } from 'react-intl';
import { Card } from '@nbc-design/card';
import { Link } from '@nbc-design/link';
import { Text } from '@nbc-design/text';

import { ProductFormFieldChangeHandler } from 'types/interfaces';
import { getTextFactory } from 'utils/TextUtils';
import { createErrorFormatter } from 'utils/productFormUtils';
import { ProductFormCheckbox } from 'components/productFormFields';

type SafeIdConsentProps = {
  intl: InjectedIntl;
  change: ProductFormFieldChangeHandler;
};

const SafeIdConsent: FC<SafeIdConsentProps> = (props: SafeIdConsentProps) => {
  const { intl } = props;
  const getText = getTextFactory(intl, 'identityValidation');
  const formatErrorMessage = createErrorFormatter(intl);

  return (
    <Row className="us-sources" data-test="us-sources-content">
      <Col xs={12} md={12} lg={12}>
        <Card border="none">
          <Text size="small">{getText('cosentTitle')}</Text>
          <Text size="xSmall" appearance="muted">
            {getText('consentDescription1')}
          </Text>
          <Text size="xSmall" appearance="muted">
            {getText('consentDescription2')}
          </Text>
          <Text size="xSmall" appearance="muted">
            {getText('consentMoreInfo')}&nbsp;
            <Link href={getText('link')} rel="noopener noreferrer" target="_blank">
              {getText('consentPolitique')}
            </Link>
          </Text>
          <ProductFormCheckbox
            name="onlineAcceptance"
            label={getText('consentAccept')}
            formatErrorMessage={formatErrorMessage}
          />
        </Card>

        <Card>
          <Text size="small">{getText('smsAlert-title')}</Text>
          <Text size="xSmall" appearance="muted">
            {getText('smsAlert-description')}
            <Link href={getText('smsAlert-hyperlink')} rel="noopener noreferrer" target="_blank">
              {getText('smsAlert-hyperlink')}
            </Link>
          </Text>
        </Card>
      </Col>
    </Row>
  );
};

export default SafeIdConsent;
