import React, { ChangeEvent, FC } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import get from 'lodash/get';
import { getTextFactory } from 'utils/TextUtils';
import { RefData } from 'types/interfaces';
import { Alert } from '@nbc-design/alert';
import { Combo, Props as ComboProps } from '@nbc-design/combo';
import { Fieldset } from '@nbc-design/fieldset';
import { Heading } from '@nbc-design/heading';
import { Radio } from '@nbc-design/radio';
import { Text } from '@nbc-design/text';
import { Selfie1People } from '@nbc-design/icons/lib/web/Selfie1People';
import { Selfie2People } from '@nbc-design/icons/lib/web/Selfie2People';
import { getProductFormField, getSchemaOptions } from 'components/productFormFields/utils';
import { FormComponentProps, OptionsType } from 'components/productFormFields/types';
import { CLIENT_TYPE } from '../constants';

export type AccountTypeSelectorProps = InjectedIntlProps & FormComponentProps;

export const AccountTypeSelector: FC<AccountTypeSelectorProps> = (props: AccountTypeSelectorProps) => {
  const { intl, schema, input } = props;

  const getText = getTextFactory(intl, 'yourAccount');
  const accountTypeRefData: RefData[] = get(schema, 'refData', []);

  const isAccountTypeSelectorBlocked = accountTypeRefData.filter((type: RefData) => type.readOnly).length === 2;

  const renderRadio = (option: OptionsType): React.ReactElement => {
    const { label, value, readOnly = false } = option;

    const buildLabel = (icon: JSX.Element) => {
      return (
        <div className="mc-d-flex mc-align-items-center">
          {icon}
          <div className="client-type-description">
            <Text className="client-type-title">{label}</Text>
            <Text appearance="mutedDarkGrey" size="small">
              {getText(`clientType${value}`)}
            </Text>
          </div>
        </div>
      );
    };

    return (
      <Radio
        key={value}
        id={`${input.name}-radio-${value}`}
        dataTest={`${input.name}-radio-${value}`}
        name={input.name}
        label={
          value === CLIENT_TYPE.INDIV
            ? buildLabel(<Selfie1People size="large" title="selfie-1-people" />)
            : buildLabel(<Selfie2People size="large" title="selfie-2-people" />)
        }
        value={value}
        onChange={input.onChange as (event: ChangeEvent<HTMLInputElement>) => void}
        checked={input.value === value}
        disabled={readOnly}
      />
    );
  };

  const comboItems: ComboProps['items'] = getSchemaOptions(props).map((option: OptionsType) => {
    return {
      children: renderRadio(option),
      disabled: false,
    };
  });

  return (
    <div className="account-type-selector">
      <Heading type="h2" data-test="heading_accountType" size={4}>
        {getText('clientType')}
      </Heading>

      <Fieldset>
        <Combo
          id={input.name}
          type="form"
          border="shadow"
          items={comboItems}
          className={isAccountTypeSelectorBlocked ? 'disabled-combo' : ''}
        />
      </Fieldset>

      {isAccountTypeSelectorBlocked && (
        <Alert title={getText('clientTypeBlocked')} appearance="information" size="small" />
      )}
    </div>
  );
};

export default getProductFormField(injectIntl(AccountTypeSelector));
