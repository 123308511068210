import configs from 'configs';
import { saveItem, loadItem, deleteItem } from 'utils';
import { callRequest } from 'services/callRequestManager';
import { Authentification, BncId, HttpRequest, RequestCode } from 'types/interfaces';
import { getBaoHeaders } from 'utils/productFormUtils';
import { UAParser } from 'ua-parser-js';

import { fromState } from 'globalRedux/store';

export const saveToken = (token: string) => saveItem('token', token);
export const loadToken = () => loadItem('token');
export const deleteToken = () => deleteItem('token');

export const saveTokenType = (tokenType: string) => saveItem('tokenType', tokenType);
export const loadTokenType = () => loadItem('tokenType');
export const deleteTokenType = () => deleteItem('tokenType');

export const saveSession = (session: string) => saveItem('session', session);
export const loadSession = () => loadItem('session');
export const deleteSession = () => deleteItem('session');

export const saveAlternateContentError = (errorCode: string) => saveItem('bot-manager-reference', errorCode);

export const saveAuthent = (authent: Authentification, authentType: ALL_AUTHENT_TYPES): Authentification => {
  // token, no matter if authentications is Okta or BAO
  saveToken(authent.token);
  if (authent.tokenType) {
    saveTokenType(authent.tokenType);
  }

  // this is BAO session, not Okta
  saveSession(authent.session);

  // save which authentication method is used
  setAuthenticatedWith(authentType);

  return authent;
};

export const deleteAuthent = (): void => {
  // Either Okta or BAO token
  deleteToken();
  // In case of Okta token, the token type
  deleteTokenType();
  // BAO session
  deleteSession();
  // Forget how we were authenticated
  clearAuthenticationWith();
};

export const AUTHENT_TYPE = 'authentType';
export const BAO_AUTHENT_TYPE = 'BAO';
export const OKTA_AUTHENT_TYPE = 'OKTA';
export type ALL_AUTHENT_TYPES = typeof BAO_AUTHENT_TYPE | typeof OKTA_AUTHENT_TYPE;

/**
 * Check wether the user is authenticated with the given method.
 * @param authentType One of the authentication method.
 */
export const isAuthenticatedWith = (authentType: ALL_AUTHENT_TYPES): boolean => {
  return localStorage.getItem(AUTHENT_TYPE) === authentType;
};

/**
 * Store the current authentication method.
 * @param authentType One of the authentication method.
 */
export const setAuthenticatedWith = (authentType: ALL_AUTHENT_TYPES): void => {
  localStorage.setItem(AUTHENT_TYPE, authentType);
};

/**
 * Remove the current authentication method from memory.
 */
export const clearAuthenticationWith = (): void => {
  localStorage.removeItem(AUTHENT_TYPE);
};

export const requestConfig = (endpoint: string, headers?: HeadersInit): HttpRequest => {
  const url = `${configs.params.LOGIN_MANAGER_SERVER}/${endpoint}`;
  const method = 'POST';
  return {
    url,
    method,
    headers,
  };
};

export const baoLoginRequest = 'baoLoginRequest';
export const oktaLoginRequest = 'oktaLoginRequest';

export const saveBAOauthentication = (
  endpoint: string,
  body: { requestPassword: string; requestCode?: string; '@type': string },
): Promise<Authentification | null> => {
  if (body.requestPassword === '' || body.requestCode === '') return Promise.resolve(null);

  const config: HttpRequest = {
    ...requestConfig(endpoint),
    body: JSON.stringify(body),
  };

  return callRequest<Authentification>(config).then((res: Authentification) => saveAuthent(res, BAO_AUTHENT_TYPE));
};

export const signup = (requestPassword: string = '') =>
  saveBAOauthentication('signup', { requestPassword, '@type': baoLoginRequest });

export const signin = (requestPassword: string = '', requestCode: string = '') =>
  saveBAOauthentication('signin', { requestPassword, requestCode, '@type': baoLoginRequest });

export const signout = (): Promise<void> => {
  const config: HttpRequest = requestConfig('signout', getBaoHeaders());
  return callRequest<{}>(config).then(deleteAuthent);
};

export const signinSSO = (accessToken: string, tokenType: string): Promise<Authentification> =>
  callRequest<Authentification>({
    url: `${configs.params.LOGIN_MANAGER_SERVER}/signin`,
    method: 'POST',
    body: JSON.stringify({ '@type': oktaLoginRequest }),
    headers: {
      Authorization: `${tokenType} ${accessToken}`,
    },
  })
    /* token is not available for SSO signip, patch it in */
    .then(({ session }) => ({ session, token: accessToken, tokenType }))
    .then((authent) => saveAuthent(authent, OKTA_AUTHENT_TYPE));

export const signupSSO = (accessToken: string, tokenType: string): Promise<Authentification> =>
  callRequest<Authentification>({
    url: `${configs.params.LOGIN_MANAGER_SERVER}/signup`,
    method: 'POST',
    body: JSON.stringify({ '@type': oktaLoginRequest }),
    headers: {
      Authorization: `${tokenType} ${accessToken}`,
    },
  })
    /* token is not available for SSO signip, patch it in */
    .then(({ session }) => ({ session, token: accessToken, tokenType }))
    .then((authent) => saveAuthent(authent, OKTA_AUTHENT_TYPE));

export const signoutSSO = (): Promise<void> => {
  const tokenType = loadTokenType();
  const accessToken = loadToken();

  return callRequest<void>({
    url: `${configs.params.LOGIN_MANAGER_SERVER}/signout`,
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${accessToken}`,
    },
  }).then(deleteAuthent);
};

export const validateUserEmail = (email: string = ''): Promise<{ emailInUse: boolean } | null> => {
  if (email === '') {
    return Promise.resolve(null);
  }

  return callRequest<{ emailInUse: boolean }>({
    url: `${configs.params.CREATE_PROFILE_SERVER}/validate`,
    method: 'POST',
    body: JSON.stringify({
      email,
    }),
  });
};

export const createUserProfile = (
  title: string = '',
  firstName: string = '',
  middleName: string = '',
  lastName: string = '',
  birthDate: string = '',
  language: string = '',
): Promise<BncId | string | null> => {
  if (title === '' || firstName === '' || lastName === '' || birthDate === '' || language === '') {
    return Promise.resolve(null);
  }

  return callRequest<BncId | string>({
    url: `${configs.params.CREATE_PROFILE_SERVER}/create`,
    method: 'POST',
    body: JSON.stringify({
      title,
      firstName,
      middleName,
      lastName,
      birthDate,
      language,
      email: fromState<string, null>('baoReducer.userCredentials.email', ''),
      password: fromState<string, null>('baoReducer.userCredentials.encryptedPassword', ''),
    }),
  });
};

export const validateApplication = (): Promise<RequestCode> => {
  return callRequest<RequestCode>({
    url: `${configs.params.CREATE_PROFILE_SERVER}/account-request`,
    method: 'GET',
    headers: getBaoHeaders(),
  });
};

export const getUserDeviceType = (parser: UAParser): string => {
  const device = parser.getDevice(); // Possible 'device.type': console, mobile, tablet, smarttv, wearable, embedded

  return device && device.type && device.type === 'mobile' ? 'deviceType=MOBILE' : 'deviceType=DESKTOP';
};
