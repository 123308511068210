import React, { FunctionComponent } from 'react';
import { AccountsStep3FormData } from '../types';
import { RespPlanSelector } from './RespPlanSelector';
import { useContainerCtx } from '../../../providers/containerCtx/ContainerCtx';
import { getRefData } from '../../../utils/productFormUtils';
import AccountSectionFormGroup from './_layout/AccountSectionFormGroup';

type RespPlanSelectorsProps = {};

export const RespPlanSelectors: FunctionComponent<RespPlanSelectorsProps> = () => {
  const { formValues, genericProps, getText } = useContainerCtx<AccountsStep3FormData>();

  const { respPlanTypes } = formValues;
  const respPlansRefData = getRefData(genericProps, 'respPlanTypes');

  if (!respPlanTypes || respPlanTypes.length === 0) return null;
  return (
    <AccountSectionFormGroup
      testId={'respPlansSelectors'}
      className="respPlans"
      label={{
        text: getText('respPlanTypeCd'),
        htmlFor: `respPlanTypes[0].respPlanTypeCd`,
      }}
      tooltipContent={getText('respPlanTypeCdInfo')}
    >
      {respPlanTypes.map((respPlan, index) => (
        <RespPlanSelector key={`respPlans-${index}`} index={index} respPlan={respPlan} refData={respPlansRefData} />
      ))}
    </AccountSectionFormGroup>
  );
};
