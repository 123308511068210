import React, { FC, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Layout from 'components/Layout';
import { CONFIG as THANKYOU_CONFIG } from 'containers/ThankYou';
import { EVENT_IDS } from 'services/analytics/constants';
import { sendStepLoadedEvent } from 'services/analytics';
import { getBncId } from 'utils';

import { AppProps } from './App';

export type BffStepRouteProps = AppProps & RouteComponentProps;

const BffStepRoute: FC<BffStepRouteProps> = (props) => {
  const {
    currentStep,
    meta: { requestCode },
  } = props;

  useMemo(() => {
    if (!currentStep) {
      return;
    }

    const eventId = currentStep === THANKYOU_CONFIG.ID ? EVENT_IDS.FF : EVENT_IDS.ST;

    sendStepLoadedEvent(currentStep, eventId, requestCode, {
      user: {
        bncId: getBncId(),
      },
    });
  }, [currentStep, requestCode]);

  return <Layout {...props} />;
};

export default BffStepRoute;
