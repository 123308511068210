import React, { FunctionComponent } from 'react';
import { InjectedIntl } from 'react-intl';
import { Text } from '@nbc-design/text';
import TextList from '@nbc-design/text-list';
import { getTextFactory } from 'utils/TextUtils';

export type DocumentsPopupProps = {
  intl: InjectedIntl;
};

const DocumentsPopup: FunctionComponent<DocumentsPopupProps> = (props: DocumentsPopupProps) => {
  const { intl } = props;

  const getText = getTextFactory(intl, 'clientRedirectNew');

  return (
    <div className="documents-popup">
      <Text size="small" className="mc-mb-normal documents-explanation">
        {getText('documents.explanation')}
      </Text>
      <TextList size="paragraph" className="documents-list-explanation">
        <Text size={'small'} className="mc-mb-xsmall">
          {getText('documents.listExplanation')}
        </Text>
        <TextList.Item className="mc-mb-xxsmall">{getText('documents.listItem1')}</TextList.Item>
        <TextList.Item className="mc-mb-xxsmall">{getText('documents.listItem2')}</TextList.Item>
        <TextList.Item className="mc-mb-xxsmall">{getText('documents.listItem3')}</TextList.Item>
        <TextList.Item className="mc-mb-xxsmall">{getText('documents.listItem4')}</TextList.Item>
        <TextList.Item className="mc-mb-xxsmall">{getText('documents.listItem5')}</TextList.Item>
      </TextList>
    </div>
  );
};

export default DocumentsPopup;
