import React, { FC } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { InjectedIntl, injectIntl } from 'react-intl';
import includes from 'lodash/includes';
import get from 'lodash/get';

import { AllRefData, RefData, ProductForm, ProductFormFieldChangeHandler } from 'types/interfaces';
import { getTextFactoryReturnType } from 'utils/TextUtils';
import AddressInput from 'components/AddressInput';
import { ProductFormCheckbox, ProductFormSelect, ProductFormInput } from 'components/productFormFields';
import { Address } from 'components/productFormFields/ProductFormAddressSelect/types';
import ReadOnlyAddresses from './ReadOnlyAddresses';

export type PersonnalInfoAddressesProps = {
  productForm: ProductForm;
  getText: getTextFactoryReturnType;
  getGlobalText: getTextFactoryReturnType;
  formatErrorMessage: Function;
  hasMailingAddress: boolean;
  mailingAddressUsage: string | null;
  change: ProductFormFieldChangeHandler;
  showMainAddressValue?: boolean;
  showSelectOtherAddress?: boolean;
  intl: InjectedIntl;
  dispatch: Function;
};

const PersonnalInfoAddresses: FC<PersonnalInfoAddressesProps> = ({
  productForm,
  hasMailingAddress,
  mailingAddressUsage,
  showMainAddressValue = true,
  showSelectOtherAddress = true,
  formatErrorMessage,
  intl,
  change,
  getGlobalText,
  getText,
  dispatch,
}) => {
  const futureAddresses: Address[] = get(productForm, 'values.futureAddresses') || [];

  const provincesToRemove = ['CA-YT', 'CA-NU', 'CA-NT'];
  const provinceCA: RefData[] = get(productForm, 'schema.definitions.AddressUI.properties.provinceCA.refData', []);
  const provinceUS: RefData[] = get(productForm, 'schema.definitions.AddressUI.properties.provinceUS.refData', []);
  const currentAddressRefData: AllRefData = {
    provinceCA: provinceCA.filter((province) => !includes(provincesToRemove, province.valueDomainCode)),
    provinceUS,
  };
  const mailingAddressRefData: AllRefData = { provinceCA, provinceUS };

  return (
    <>
      {/* Primary Address */}
      {showMainAddressValue && (
        <div className="current-address" data-test="field_currentAddress">
          <AddressInput
            name="currentAddress"
            textPrefix="personalInformation"
            helpText="indicated"
            allRefData={currentAddressRefData}
            errorLabel={'address'}
            change={change}
            label={getGlobalText('currentAddress')}
            dispatch={dispatch}
          />

          {futureAddresses.length > 0 && <ReadOnlyAddresses addresses={futureAddresses} intl={intl} />}
        </div>
      )}

      {/* Mailing Address */}
      {showSelectOtherAddress && (
        <div className="has-mailing-address" data-test="field_hasMailingAddress">
          <ProductFormCheckbox
            name="hasMailingAddress"
            label={getText('hasMailingAddress')}
            formatErrorMessage={formatErrorMessage()}
          />
        </div>
      )}

      {hasMailingAddress && (
        <>
          <Row key="mailingAddressUsage">
            <Col xs={12} md={8} data-test="field_mailingAddressUsage">
              <ProductFormSelect
                name="mailingAddressUsage"
                label={getText('mailingAddressUsage')}
                placeholder={getGlobalText('select')}
                formatErrorMessage={formatErrorMessage()}
              />
            </Col>
          </Row>

          {mailingAddressUsage === 'OTHER' && (
            <Row>
              <Col xs={12} md={8} data-test="field_mailingAddressUsageComment">
                <ProductFormInput
                  name="mailingAddressUsageComment"
                  label={getText('mailingAddressUsageComment')}
                  formatErrorMessage={formatErrorMessage()}
                />
              </Col>
            </Row>
          )}

          <div data-test="field_mailingAddress">
            <AddressInput
              name="mailingAddress"
              textPrefix="personalInformation"
              errorLabel={'address'}
              allRefData={mailingAddressRefData}
              label={getGlobalText('mailingAddress')}
              change={change}
              dispatch={dispatch}
            />
          </div>
        </>
      )}
    </>
  );
};

export default injectIntl(PersonnalInfoAddresses);
