import React, { FunctionComponent } from 'react';
import { Button } from '@nbc-design/button';
import { Card } from '@nbc-design/card';
import { Combo, Props as ComboProps } from '@nbc-design/combo';
import { Fieldset } from '@nbc-design/fieldset';
import { Radio } from '@nbc-design/radio';
import { Text } from '@nbc-design/text';
import { InjectedIntlProps } from 'react-intl';
import { History } from 'history';
import { getTextFactory } from 'utils/TextUtils';
import { AttentionOutlineThinColor } from '@nbc-design/icons/lib/web/AttentionOutlineThinColor';
import { sendClickAnalyticsEvent, sendStepLoadedEvent } from 'services/analytics';
import { EVENT_IDS } from 'services/analytics/constants';
import CreateCredentialsMessages from 'messages/CreateCredentials/CreateCredentials.json';
import { ROUTE } from 'utils/constants';
import { getBncId, saveItem } from 'utils';
import { redirectToLogin } from '../../Welcome/Welcome';
import getLogger from 'utils/getLogger';
import { signinSSO, signupSSO } from 'services/loginManager';

export type ExistedApplicationProps = InjectedIntlProps & {
  history: History;
  accessToken: OktaAccessToken;
};

const ExistedApplication: FunctionComponent<ExistedApplicationProps> = (props: ExistedApplicationProps) => {
  const { intl, history, accessToken } = props;

  const [isClicked, setClicked] = React.useState<boolean>(false);
  const [choice, setChoice] = React.useState<string>('');

  React.useEffect(() => {
    sendStepLoadedEvent('already existed application', EVENT_IDS.ST, '', {
      eventInfo: {
        ucsId: 'UCS-bao-003',
        interaction: `warning:${CreateCredentialsMessages.en['createCredentials.existedApplicationTitle']}`,
      },
      step: {
        stepId: '3',
        flowId: 'profile creation',
      },
      user: {
        bncId: getBncId(),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getText = getTextFactory(intl, 'createCredentials');

  const logger = getLogger('Welcome');

  const manageRedirect = () => {
    setClicked(true);

    if (!choice) {
      return;
    }

    const authenticationOperation = choice === 'completCurrentApplication' ? signinSSO : signupSSO;
    return authenticationOperation(accessToken.value, accessToken.tokenType)
      .then((response) => {
        console.log('RESPONSE: ', response);
        saveItem('newRequest', 'true');
        logger.log(`[AUTH] redirecting to STEPPER_MANAGER page`);
        window.location.assign(`${process.env.PUBLIC_URL}${ROUTE.STEPPER_MANAGER}`);
      })
      .catch((err) => {
        console.log('ERROR: ', err);
        logger.error(`[AUTH] ERROR redirecting, redirecting to CLIENT_REDIRECT page`, err);
        redirectToLogin(history);
      });
  };

  const renderOptions = (): ComboProps['items'] => [
    {
      children: (
        <Radio
          id="choix1"
          name="choice"
          value="completCurrentApplication"
          label={getText('existedApplicationExplanation1')}
          text={getText('existedApplicationExplanation2')}
          onChange={(e) => {
            setChoice(e.target.value);
            sendClickAnalyticsEvent('welcome', 'Complet former application');
          }}
        />
      ),
    },
    {
      children: (
        <Radio
          id="choix2"
          name="choice"
          value="createNewApplication"
          label={getText('existedApplicationExplanation3')}
          text={getText('existedApplicationExplanation4')}
          onChange={(e) => {
            setChoice(e.target.value);
            sendClickAnalyticsEvent('welcome', 'Start new application');
          }}
        />
      ),
    },
  ];

  return (
    <Card border="shadow" className="spc-creationProfil__card mc-mb-normal">
      <div className="spc-creationProfil__popupCardBody">
        <AttentionOutlineThinColor title="attention-outline-thin-color" size={{ width: 64, height: 64 }} />
        <div className="text-wrapper">
          <Text className="mc-mb-normal popup-title">{getText('existedApplicationTitle')}</Text>
          <Text className="application-question">{getText('existedApplicationQuestion')}</Text>
          <Fieldset validate={{ hasError: isClicked && !choice, errorMsg: getText('existedApplicationError') }}>
            <div className="radio-inline">
              <Combo id="existed application" type="form" items={renderOptions()} />
            </div>
          </Fieldset>
        </div>
        <div className="button-wrapper">
          <Button
            appearance="primary"
            onClick={() => {
              sendClickAnalyticsEvent('already existed application', 'Manage application');
              manageRedirect();
            }}
          >
            {getText('existedApplicationButton')}
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default ExistedApplication;
