import get from 'lodash/get';
import { delay } from 'redux-saga';
import { takeLatest, put, call } from 'redux-saga/effects';
import { createFormService, reduxFormActionTypes, reduxFormActionCreators } from 'bnc-react-forms';
import { FormAction } from 'redux-form';

import { BFF_STEPS, BFF_STEPS_IDS } from 'utils/stepsConstants';
import { FORM_ID } from 'utils/productFormUtils';
import getLogger from 'utils/getLogger';
import * as actionCreators from './actions';
import registerValidators, { FormBaoServiceData } from './validation';
import { AppStep } from 'types/interfaces';
import { BaoActionTypes } from 'types/actions';
import { sendErrorAnalyticsEvent } from 'services/analytics';

export const SUBMIT_MESSAGE_DELAY = 20000;

export const FormService: FormBaoServiceData = createFormService(FORM_ID);

registerValidators(FormService);

const logger = getLogger('baoService');

const scrollToFormTop = (smooth: boolean) => {
  const scrollZone: Element | null = document.querySelector('.dsl-form__scrollZone');
  if (!scrollZone) {
    logger.warn(`Could not find the scroll container`);
    return;
  }

  // scroll to top smoothly if desired and supported
  if (smooth) {
    scrollZone.scrollIntoView({ behavior: 'smooth' });
    return;
  }

  // default to scrolling instantly
  scrollZone.scrollTo(0, 0);
};

export function* watchStepChanged(action: BaoActionTypes) {
  yield put(reduxFormActionCreators.destroy(action.meta.form));
  // When switching steps, scroll back to top
  yield call((): void => scrollToFormTop(false));

  //TODO: THIS TOO IS TO BE EMOVE WHEN WE FIND A BETTER WAY;
  if (action.payload.meta && action.payload.meta.stepId === BFF_STEPS_IDS.ACCOUNTSSTEP3) {
    const promotionCd: string | null = get(action.payload.values, 'promotionCd', null);
    if (promotionCd) {
      yield put(reduxFormActionCreators.change(action.meta.form, 'promotionCdAvailable', true));
    }
  }
  // When reaching a submit step, trigger the message loop
  // The loop runs until the saga is cancelled on the next step change
  if (action && BFF_STEPS.find((x: AppStep): boolean => x.ID === action.currentStep)) {
    yield call(loopSubmitMessages);
  }
}

function* loopSubmitMessages() {
  let messageIndex = 0;
  while (true) {
    yield delay(SUBMIT_MESSAGE_DELAY);
    messageIndex += 1;
    yield put(actionCreators.setSubmitMessageIndex(messageIndex));
    if (messageIndex === 999999999) {
      // just making sure this code is not invariant
      break;
    }
  }
}

export function* watchErrors() {
  // When an error occurs, scroll back to top to display the message
  yield call((): void => {
    scrollToFormTop(true);
  });
}

export const scrollToInvalidField = (): Element | null => {
  const invalidField: Element | null = document.querySelector('.has-error');
  if (invalidField) {
    invalidField.scrollIntoView({ behavior: 'smooth' });
  }
  return invalidField;
};

const focusInvalidInput = (field: Element): void => {
  const input: HTMLElement | null = field.querySelector('input');
  if (input) {
    input.focus();
  }
};

export function* watchValidationErrors(action: FormAction) {
  // no error, proceed
  if (!action.error) {
    return;
  }

  // for some reason, wait 100ms...
  yield delay(100);

  // list of fields to check to send to analytics
  const fields: string[] = get(action, 'meta.fields', []);
  yield call(sendErrorAnalyticsEvent, fields);

  const field: Element | null = yield call(scrollToInvalidField);
  if (field) {
    yield delay(500);
    yield call(focusInvalidInput, field);
  }
}

export default function* rootSaga() {
  yield takeLatest(FormService.onChangeStep(), watchStepChanged);
  yield takeLatest(FormService.onError(), watchErrors);
  yield takeLatest(reduxFormActionTypes.SET_SUBMIT_FAILED, watchValidationErrors);
}
