import React, { ChangeEvent, FC, ReactNodeArray } from 'react';
import { InjectedIntl } from 'react-intl';
import { FormGroup } from '@nbc-design/form-group';

import { FormComponentProps, OptionsType } from '../types';
import { getSchemaOptions, getErrorMessage, isInvalidOnChange } from '../utils';
import BaoRadioSelect from './components/BaoRadioSelect';
import './style.scss';

export type ProductFormCustomContentRadioSelectProps = FormComponentProps & {
  intl: InjectedIntl;
  customContentDictionary: { [key: string]: { [key: string]: ReactNodeArray } };
  isContentInside?: boolean;
  name: string;
  onChange: Function;
  isCombo?: boolean;
};

const ProductFormCustomContentRadioSelect: FC<ProductFormCustomContentRadioSelectProps> = (
  props: ProductFormCustomContentRadioSelectProps,
) => {
  const { input, label, customContentDictionary, isContentInside, className, onChange, isCombo } = props;

  const invalid = isInvalidOnChange(props);
  const options: OptionsType[] = getSchemaOptions(props);

  options.forEach((option: OptionsType): void => {
    const { description, customContent, readOnlyContent } =
      customContentDictionary && customContentDictionary[option.value];
    option.customContent = customContent;
    option.isContentInside = isContentInside;
    option.description = description;
    option.readOnlyContent = readOnlyContent;
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    input.onChange && input.onChange(event) && onChange(event);
  };

  return (
    <FormGroup className={className} validate={{ hasError: invalid, errorMsg: getErrorMessage(props) }}>
      <BaoRadioSelect
        id={`${input.name}`}
        isCombo={isCombo}
        options={options}
        label={label}
        selectedValue={input.value}
        onChange={handleChange}
      />
    </FormGroup>
  );
};

export default ProductFormCustomContentRadioSelect;
