import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { formValues } from 'bnc-react-forms';
import PersonalInformation from './PersonalInformation';
import config from './config';
import * as validation from './validation';
import get from 'lodash/get';
import { RootState } from 'types/reducers';

export const VALIDATION = validation;
export const CONFIG = config;

const mapStateToProps = (state: RootState) => {
  return {
    isMultiHolderMode: get(state, 'baoReducer.isMultiHolderMode'),
  };
};

export default connect(mapStateToProps)(
  injectIntl(
    formValues(
      'hasMailingAddress',
      'phoneNumbers',
      'citizenship',
      'mailingAddressUsage',
      'displayRelationshipInput',
      'shouldUpdateRelationship',
      'mainHolderName',
    )(PersonalInformation),
  ),
);
