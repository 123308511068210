import React, { FC } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Button } from '@nbc-design/button';
import { Link } from '@nbc-design/link';
import { Edit } from '@nbc-design/icons/lib/web/Edit';
import { getTextFactory } from 'utils/TextUtils';
import { InjectedIntl } from 'react-intl';
import { ProductFormFieldChangeHandler } from 'types/interfaces';

export type SpouseInfoReviewProps = {
  intl: InjectedIntl;
  setFormEditing: Function;
  clearSpouseData: Function;
  fileSpouseData: Function;
  spouseData: string;
  profileConfirmed: boolean;
  change: ProductFormFieldChangeHandler;
};

const SpouseInfoReview: FC<SpouseInfoReviewProps> = (props: SpouseInfoReviewProps) => {
  const { intl, setFormEditing, clearSpouseData, fileSpouseData, spouseData, profileConfirmed, change } = props;

  const getText = getTextFactory(intl, 'spouseInformation');

  const handleEditClick = (): void => {
    fileSpouseData();
    setFormEditing(true);
    change('operationMode', 'modify');
  };

  const handleSpouseChangedClick = () => {
    clearSpouseData();
    setFormEditing(true);
    change('operationMode', 'replace');
  };

  return (
    <div className="spouse-info-review" data-test="spouse-info-review">
      <Row className="name-row">
        <Col xs={12} md={3} className="name-label">
          <span className="label-info">{getText('nameLabel')}</span>
        </Col>
        <Col xs={8} md={3}>
          <span className="label-value">{spouseData}</span>
        </Col>
        {!profileConfirmed && (
          <Col xs={4} md={6} data-test="edit-btn">
            <Link type="button" onClick={() => handleEditClick()} icon={<Edit size="medium" />}>
              {getText('edit')}
            </Link>
          </Col>
        )}
      </Row>

      <Button
        type="button"
        size="small"
        data-test="situation-changed-btn"
        onClick={() => handleSpouseChangedClick()}
        appearance="secondary"
      >
        {getText('situationChanged')}
      </Button>
    </div>
  );
};

export default SpouseInfoReview;
