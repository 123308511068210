import React from 'react';
import PropTypes from 'prop-types';
import { Message } from '@nbc-design/message';
import { Row, Col } from 'react-flexbox-grid';
import '../styles.scss';

export type ErrorMessageDisplayProps = {
  visible: boolean;
  message?: string | null;
};

const ErrorMessageDisplay: React.FunctionComponent<ErrorMessageDisplayProps> = (props) => {
  const { visible, message } = props;

  if (!visible) return null;

  return (
    <Row className="error-message has-error">
      <Col xs={6} md={12} lg={12}>
        {visible && <Message appearance="error">{message}</Message>}
      </Col>
    </Row>
  );
};

ErrorMessageDisplay.propTypes = {
  visible: PropTypes.bool.isRequired,
  message: PropTypes.string,
};

export default ErrorMessageDisplay;
