import React, { FunctionComponent, ChangeEvent, ReactNode, Fragment } from 'react';
import { Combo, Props as ComboProps } from '@nbc-design/combo';
import { Fieldset } from '@nbc-design/fieldset';
import { Radio } from '@nbc-design/radio';
import { Text } from '@nbc-design/text';

import { OptionsType } from '../../types';

export type BaoRadioSelectProps = {
  id: string;
  options: OptionsType[];
  label?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedValue: string;
  isCombo?: boolean;
};

const BaoRadioSelect: FunctionComponent<BaoRadioSelectProps> = (props: BaoRadioSelectProps) => {
  const { id, options, label, onChange, selectedValue, isCombo } = props;

  const renderCustomContent = (option: OptionsType, isContentInside: boolean): ReactNode | null => {
    const { value, customContent } = option;

    const isCustomContentVisible = value === selectedValue;

    if (!customContent || !isCustomContentVisible || isContentInside !== !!option.isContentInside) {
      return null;
    }

    return customContent;
  };

  const renderReadOnlyContent = (option: OptionsType): ReactNode | null => {
    const { readOnly, readOnlyContent } = option;
    return readOnly && readOnlyContent;
  };

  const renderRadio = (option: OptionsType): React.ReactElement => {
    const { label, value, description, readOnly } = option;
    const [defaultText] = description || ['', ''];
    const isChecked = selectedValue === value;
    return (
      <Fragment key={value}>
        <Radio
          id={`${id}-radio-${value}`}
          name={`radio-${value}`}
          text={defaultText}
          label={label}
          value={value}
          onChange={onChange}
          checked={isChecked}
          disabled={readOnly}
        />
        {renderCustomContent(option, true)}
      </Fragment>
    );
  };

  const renderOptions = (options: OptionsType[]): ComboProps['items'] => {
    return options.map((option) => {
      const { description, selected: isOpen } = option;
      const [, collapseText] = description || ['', ''];
      return {
        children: renderRadio(option),
        collapse: {
          ariaLabelClose: '',
          ariaLabelOpen: '',
          description: collapseText,
          isOpen,
        },
        disabled: false,
      };
    });
  };

  return (
    <Fieldset>
      <Text type={'p'}>{label}</Text>
      {options.map((option) => renderReadOnlyContent(option))}
      {!!isCombo === true ? <Combo id={id} type="form" items={renderOptions(options)} /> : options.map(renderRadio)}
      {options.map((option) => renderCustomContent(option, false))}
    </Fieldset>
  );
};

export default BaoRadioSelect;
