import { SSO_DATA } from './constants';
import { getCurrentEnv, getOktaClient, getCustomUrls } from './oktaService';

/**
 * Redirect the user to sso login to obtain necessary Okta tokens.
 *
 * @param locale the two-letters language code of the current language, i.e. `en` or `fr`
 */
export const redirectToUnifiedLogin = (locale: string): void => {
  const environment = getCurrentEnv();
  const language = locale.toUpperCase();

  const authClient = getOktaClient(locale);
  const customUrls = getCustomUrls(environment);
  authClient.token.getWithRedirect({
    responseType: ['token', 'id_token'],
    scopes: ['openid'],
    // state: locale,
    idp: SSO_DATA[environment].IDP[language],
    // custom urls for when the issuer url is not the base url of other requests
    ...customUrls,
  });
};
