import { formValues } from 'bnc-react-forms';
import { injectIntl } from 'react-intl';
import Employment from './Employment';
import config from './config';
import * as validation from './validation';

export const CONFIG = config;
export const VALIDATION = validation;

export default injectIntl(
  formValues(
    'occupationType',
    'startOccupationDate',
    'employmentType',
    'employmentCode',
    'employerName',
    'emplUID',
    'proInvolvementRelation',
    'proInvolvementCompanyName',
    'proInvolvementFirstName',
    'proInvolvementLastName',
    'proInvolvementRelationOther',
    'activeOccupations',
    'hasProInvolvement',
    'hasAdminInvolvement',
    'hasTenPercentInvolvement',
    'hasTwentyPercentInvolvement',
    'adminInvolvementList',
    'tenPercentInvolvementList',
    'twentyPercentInvolvementList',
    'employerAddress',
    'employerPhoneNumber',
  )(Employment),
);
