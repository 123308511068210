export const EMPLOYMENT_VALUES = {
  ENTREPRENEUR: 'EP',
  STUDENT: 'ET',
  RETIRED: 'RE',
  SALARIED: 'SL',
  UNEMPLOYED: 'CH',
  SELF_EMPLOYED: 'PL',
  EMPLOYEE: 'OS',
  OTHER: 'AU',
  TRADING_RELATION_STATUS_I_AM: 'INDV',
  TRADING_RELATION_STATUS_MY_SPOUSE: 'INSP',
  TRADING_RELATION_STATUS_MY_PARENTS: 'INPA',
  TRADING_RELATION_STATUS_MY_CHILD: 'INCH',
  TRADING_RELATION_STATUS_OTHER: 'INAT',
  TRADING_INVOLVEMENT_YES: true,
  TRADING_INVOLVEMENT_NO: false,
};
