import { ERRORTYPE } from '../../utils/validation';
import { IdentityVerificationBNCErrorData, IdentityVerificationBNCFormData } from './types';

const validation = ({ identCheckConfirmation }: IdentityVerificationBNCFormData): IdentityVerificationBNCErrorData => {
  return {
    ...(!identCheckConfirmation ? { identCheckConfirmation: ERRORTYPE.REQUIRED } : {}),
  };
};

export default validation;
