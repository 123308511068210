import React, { FunctionComponent } from 'react';
import DetailsSection from './components/DetailsSection';
import { InjectedIntl } from 'react-intl';
import Title from 'components/Title';
import { BFF_STEPS_IDS } from 'utils/stepsConstants';
import { getTextFactory } from 'utils/TextUtils';
import AccountsSection from './components/AccountsSection';
import BeneficiarySection from './components/BeneficiarySection';
import TaxSection from './components/TaxSection';
import TransferSection from './components/TransferSection';
import BankingInformationSection from './components/BankingInformationSection';
import SpouseInformationSection from './components/SpouseInformationSection';
import PaymentDetailSection from './components/PaymentDetailSection';
import { Alert } from '@nbc-design/alert';
import { Text } from '@nbc-design/text';
import { ACCOUNT_TYPE, AUTOMATED_DECISION_ACCOUNT_TYPES } from '../AccountsStep1/constants';
import { AccountData, PaymentSlice } from './types';
import { Meta, ProductFormFieldChangeHandler } from 'types/interfaces';
import { isJointMenu } from 'utils/productFormUtils';
import CONFIG from './config';
import { initialize } from 'redux-form';
import { BAO_FORMS } from 'utils/constants';
import { cloneDeep, get } from 'lodash';
import { RootState } from 'types/reducers';
import { getState } from 'globalRedux/store';

import './styles.scss';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import BeneficiaryInformationSection from './components/BeneficiaryInformationSection';

export type SummaryProductForm = {
  values: {
    accounts: {
      accounts: AccountData[];
    };
    personalInformation: {};
    employment: {
      profession: {
        en: string;
        fr: string;
      };
    };
    employmentV2: {
      profession: {
        en: string;
        fr: string;
      };
    };
    finances: {};
    tax: {};
    regulatory: {};
    requestAssistant: {};
    bankingInformation: {};
    specificsOptions: {};
    identityCheck: {};
    identityCheckBnc: {};
    transfer: {};
    beneficiary: {};
    spouseInfos: {};
    payment: {
      payments: PaymentSlice[];
    };
    respBeneficiary: {};
  };
};

export type SummaryProps = {
  intl: InjectedIntl;
  productForm: SummaryProductForm;
  meta: Meta;
  goToStep: (stepId: string) => string;
  dispatch: Function;
  change: ProductFormFieldChangeHandler;
};

const Summary: FunctionComponent<SummaryProps> = (props: SummaryProps) => {
  const {
    intl,
    productForm: {
      values: {
        accounts,
        personalInformation,
        employment,
        employmentV2,
        finances,
        regulatory,
        requestAssistant,
        specificsOptions,
        identityCheck,
        identityCheckBnc,
        beneficiary,
        spouseInfos,
        payment,
      },
    },
    goToStep,
    meta,
    dispatch,
    change,
  } = props;

  const isJointStep = meta && meta.stepId === CONFIG.STEP_JOINT_ID;

  const goToSpecificStep = (stepId: string) => () => {
    dispatch(initialize(BAO_FORMS, {}));
    goToStep(stepId);
  };
  const getGlobalText = getTextFactory(intl, 'global');
  const getText = getTextFactory(intl, 'summary');

  const state: RootState | null = getState();
  const productFormReducerData = get(state, 'productFormReducer.baoForms.productForm.values', {});

  const fileSummaryData = () => {
    dispatch(initialize(BAO_FORMS, cloneDeep(productFormReducerData)));
  };

  const mapDisplayEmployment = () => {
    if (employment && employment.profession) {
      employment.profession.en = capitalizeFirstLetter(employment.profession.en);
      employment.profession.fr = capitalizeFirstLetter(employment.profession.fr);
      change('employment.profession', employment.profession);
    }

    if (employmentV2 && employmentV2.profession) {
      employmentV2.profession.en = capitalizeFirstLetter(employmentV2.profession.en);
      employmentV2.profession.fr = capitalizeFirstLetter(employmentV2.profession.fr);
      change('employmentV2.profession', employmentV2.profession);
    }
  };

  const getStepEmployment = () => {
    return employment ? BFF_STEPS_IDS.EMPLOYMENT : BFF_STEPS_IDS.EMPLOYMENTV2;
  };

  const getStepJointEmployment = () => {
    return employment ? BFF_STEPS_IDS.EMPLOYMENTJOINT : BFF_STEPS_IDS.EMPLOYMENTJOINTV2;
  };

  React.useEffect(() => {
    isJointMenu(meta) && fileSummaryData();
    mapDisplayEmployment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta]);

  const accountsList: AccountData[] = !isJointStep ? accounts.accounts : [];
  const isAutomatedDecision = accountsList.find((account: AccountData) =>
    AUTOMATED_DECISION_ACCOUNT_TYPES.includes(account.acctType.code as ACCOUNT_TYPE),
  );

  return (
    <div className="summary__form">
      <Title label={getText('pageTitle')} subTitle={getText('subTitle')} />

      {!isJointStep && (
        <DetailsSection
          intl={intl}
          data={requestAssistant}
          name="requestAssistant"
          goToModify={goToSpecificStep(BFF_STEPS_IDS.REQUESTASSISTANT)}
        />
      )}

      {!isJointStep && <AccountsSection goToStep={goToStep} />}

      {!isJointStep && (
        <DetailsSection
          intl={intl}
          data={specificsOptions}
          name="specificsOptions"
          goToModify={goToSpecificStep(BFF_STEPS_IDS.SPECIFICSOPTIONS)}
        />
      )}

      {isJointMenu(meta) && (
        <Text size="large" className={isJointStep ? 'joint-spouse-title' : 'joint-applicant-title'}>
          {isJointStep ? getGlobalText('spouseMenuTitle') : getGlobalText('applicantMenuTitle')}
        </Text>
      )}

      <DetailsSection
        intl={intl}
        data={personalInformation}
        name="personalInformation"
        goToModify={goToSpecificStep(
          !isJointStep ? BFF_STEPS_IDS.PERSONALINFORMATION : BFF_STEPS_IDS.PERSONALINFORMATIONJOINT,
        )}
      />

      <DetailsSection
        intl={intl}
        data={employment ? employment : employmentV2}
        name={employment ? 'employment' : 'employmentV2'}
        goToModify={goToSpecificStep(!isJointStep ? getStepEmployment() : getStepJointEmployment())}
      />

      <DetailsSection
        intl={intl}
        data={finances}
        name="finances"
        goToModify={goToSpecificStep(!isJointStep ? BFF_STEPS_IDS.FINANCES : BFF_STEPS_IDS.FINANCESJOINT)}
      />

      <TaxSection isJointStep={isJointStep} goToStep={goToStep} />

      <BankingInformationSection isJointStep={isJointStep} goToStep={goToStep} />

      {!isJointStep && (
        <DetailsSection
          intl={intl}
          data={identityCheckBnc}
          name="identityCheckBnc"
          goToModify={goToSpecificStep(BFF_STEPS_IDS.IDENTITYVERIFICATIONBNC)}
        />
      )}

      <DetailsSection
        intl={intl}
        data={identityCheck}
        name="identityCheck"
        goToModify={goToSpecificStep(BFF_STEPS_IDS.IDENTITYVERIFICATION)}
      />

      {!isJointStep && beneficiary && <BeneficiarySection goToStep={goToStep} />}

      {!isJointStep && <TransferSection goToStep={goToStep} />}

      {payment && <PaymentDetailSection goToStep={goToStep} />}

      {spouseInfos && <SpouseInformationSection goToStep={goToStep} />}

      {accounts?.accounts?.find((account) => account.acctType.code === ACCOUNT_TYPE.RESP) && (
        <BeneficiaryInformationSection goToStep={goToStep} />
      )}

      {!isJointStep && (
        <DetailsSection
          intl={intl}
          data={regulatory}
          name="regulatory"
          goToModify={goToSpecificStep(BFF_STEPS_IDS.REGULATORY)}
        />
      )}

      {isAutomatedDecision && <Alert title={getText('automatedDecision')} appearance="information" size="small" />}
    </div>
  );
};

export default Summary;
