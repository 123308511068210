import React, { FC } from 'react';
import { InjectedIntl } from 'react-intl';
import { cloneDeep, get } from 'lodash';
import { Alert } from '@nbc-design/alert';
import { Link } from '@nbc-design/link';
import { Text } from '@nbc-design/text';
import { getTextFactory } from 'utils/TextUtils';
import { SpouseInformationErrorData } from './types';
import { GenericProps } from 'types/interfaces';
import Title from 'components/Title';
import SpouseForm from './components/SpouseForm';
import SpouseInfoReview from './components/SpouseInfoReview';
import { BAO_FORMS } from 'utils/constants';
import { initialize } from 'redux-form';
import { RootState } from 'types/reducers';
import { getState } from 'globalRedux/store';
import { AttentionFilledColor } from '@nbc-design/icons/lib/web/AttentionFilledColor';

import './style.scss';

export type SpouseInfoProps = GenericProps & {
  intl: InjectedIntl;
  isSrrsp: boolean;
  hasIncompleteInfo: boolean;
  profileConfirmed: boolean;
  error?: SpouseInformationErrorData;
};

const SpouseInformation: FC<SpouseInfoProps> = (props: SpouseInfoProps) => {
  const {
    intl,
    isSrrsp = false,
    hasIncompleteInfo = false,
    profileConfirmed = false,
    productForm,
    change,
    dispatch,
  } = props;

  const [isEditing, setIsEditing] = React.useState(false);
  const [wasModified, setModified] = React.useState(false);

  const state: RootState | null = getState();
  const productFormReducerData = get(state, 'productFormReducer.baoForms.productForm.values', {});

  const clearSpouseData = () => {
    dispatch(
      initialize(BAO_FORMS, {
        isSrrsp,
        hasIncompleteInfo,
        profileConfirmed,
      }),
    );
  };

  const fileSpouseData = () => {
    dispatch(initialize(BAO_FORMS, cloneDeep(productFormReducerData)));
    setModified(false);
  };

  const isConfirmedUncompletedProfile = hasIncompleteInfo && profileConfirmed;

  const getText = getTextFactory(intl, 'spouseInformation');
  const getAccessibilityText = getTextFactory(intl, 'accessibility.icon');

  const handleCancelClick = (): void => {
    setIsEditing(false);
    setModified(true);
  };

  React.useEffect(() => {
    isConfirmedUncompletedProfile && clearSpouseData();
    wasModified && fileSpouseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasIncompleteInfo, profileConfirmed, wasModified]);

  const spouseFirstName = get(productForm, 'values.firstName', '');
  const spouseLastName = get(productForm, 'values.lastName', '');
  const spouseData = spouseFirstName && spouseLastName && `${spouseFirstName} ${spouseLastName}`;

  return (
    <div id="spouse-information-form">
      <Title label={getText('pageTitle')} />

      {hasIncompleteInfo && (
        <Alert
          key="safeIdConsent-readOnly"
          icon={<AttentionFilledColor size="small" title={getAccessibilityText('alert')} />}
          title={
            profileConfirmed ? getText('uncompletedConfirmedProfileWarning') : getText('uncompletedProfileWarning')
          }
          description={
            profileConfirmed
              ? getText('uncompletedConfirmedProfileWarningText')
              : getText('uncompletedProfileWarningText')
          }
          appearance="warning"
          className="alert-fail-warning"
        />
      )}

      <div className="subtitle-container">
        <div>
          <Text>
            {!spouseData || isEditing || isConfirmedUncompletedProfile
              ? getText('pageSubTitleAlt')
              : getText('pageSubTitle')}
          </Text>
        </div>
        {isEditing && (
          <div data-test="cancel">
            <Link type="button" onClick={() => handleCancelClick()} className="cancel-btn">
              {getText('cancel')}
            </Link>
          </div>
        )}
      </div>

      {!hasIncompleteInfo && (!spouseData || isEditing) ? (
        <SpouseForm intl={intl} change={change} isSrrsp={isSrrsp} />
      ) : (
        !hasIncompleteInfo && (
          <SpouseInfoReview
            intl={intl}
            setFormEditing={setIsEditing}
            clearSpouseData={clearSpouseData}
            fileSpouseData={fileSpouseData}
            spouseData={spouseData}
            profileConfirmed={profileConfirmed}
            change={change}
          />
        )
      )}

      {hasIncompleteInfo && (
        <SpouseForm intl={intl} change={change} isSrrsp={isSrrsp} isUpdate={isConfirmedUncompletedProfile} />
      )}
    </div>
  );
};

export default SpouseInformation;
