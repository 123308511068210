const config = {
  ID: 'SUMMARY_STEP',
  SUB_STEP_IDS: ['SUMMARY_STEP', 'SUMMARY_STEP_JOINT'],
  STEP_JOINT_ID: 'SUMMARY_STEP_JOINT',
  SIDEBAR_MENU: true,
  PROGRESS_BAR: true,
  PROGRESS_BAR_COUNT: true,
  FOOTER: true,
  BUTTON_HELP: true,
  BUTTON_LANG: true,
  BUTTON_QUIT: true,
};

export default config;
