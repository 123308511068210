export const passwordRegex = /^(?=.*[0-9])(?=.*[A-Z])^[\S*]{12,25}$/;
export const requestCodeRegex = /^[a-zA-Z0-9]+$/;
export const nonDigitForFloatRegex = /[^\d ,.]/g;
export const nonDigitRegex = /[^\d]/g;
export const startBy00Regex = /^0{2,}/;
export const emailRegex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
export const nameFormatRegex = /^[a-zA-ZÀ-ÿæ' -]+$/;
export const areaCodeRegex = /^[+][0-9]{0,3}\s*$/;
export const extensionNumberRegex = /^[0-9]+$/;
export const phoneNumberCanadaUSARegex = /^[2-9]\d{2}[2-9]\d{6}$/;
