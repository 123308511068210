import React, { FC, useEffect, useState } from 'react';
import createCtx from 'utils/createCtx';
import { BeneficiaryDataType } from '../types';
import { BeneficiaryInformationCtxProps } from './BeneficiaryInformationCtx.type';

const [useBeneficiaryInformationCtx, BeneficiaryInformationProvider] = createCtx<BeneficiaryInformationCtxProps>(
  'useContainerCtx',
);

const withBeneficiaryInformationCtxProvider = () => (Component: React.FC<any>) => {
  const ProviderComponent: FC = (props) => {
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [beneficiaryFormIndex, setBeneficiaryFormIndex] = useState<number | undefined>();
    const [currentBeneficiary, setCurrentBeneficiary] = useState<BeneficiaryDataType>();
    const [beneficiaryToEdit, setBeneficiaryToEdit] = useState<BeneficiaryDataType>();
    const [beneficiaryToDelete, setBeneficiaryToDelete] = useState<BeneficiaryDataType>();

    useEffect(() => {
      if (!beneficiaryToEdit) return;
      setIsFormModalOpen(true);
    }, [beneficiaryToEdit]);

    /***************************************************************************
     * Render
     ***************************************************************************/
    return (
      <BeneficiaryInformationProvider
        value={{
          currentBeneficiary,
          setCurrentBeneficiary,

          beneficiaryFormIndex,
          setBeneficiaryFormIndex,

          beneficiaryToEdit,
          setBeneficiaryToEdit,

          beneficiaryToDelete,
          setBeneficiaryToDelete,

          isFormModalOpen,
          setIsFormModalOpen,
        }}
      >
        <Component {...props} />
      </BeneficiaryInformationProvider>
    );
  };

  ProviderComponent.displayName = `withBeneficiaryInformationCtxProvider(${Component.displayName || 'Component'})`;
  return ProviderComponent;
};

export { useBeneficiaryInformationCtx, withBeneficiaryInformationCtxProvider };
