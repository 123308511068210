import React, { useState } from 'react';
import Dialog, { Props as DialogProps } from '@nbc-design/dialog';
import { useContainerCtx } from '../../../providers/containerCtx/ContainerCtx';
import { BeneficiaryDataType, BeneficiaryFormValues, OperationMode } from '../types';
import { Button } from '@nbc-design/button';
import { Text } from '@nbc-design/text';
import { Heading } from '@nbc-design/heading';
import { useBeneficiaryInformationCtx } from '../providers/BeneficiaryInformationCtx';
import { RespPlanTypeCd } from 'containers/AccountsStep3/types';
import { get } from 'lodash';
import { getState } from 'globalRedux/store';

type DialogDeleteConfirmationProps = {
  beneficiaryToDelete: BeneficiaryDataType;
  dialogProps?: Partial<DialogProps>;
};
const DialogDeleteConfirmation: React.FC<DialogDeleteConfirmationProps> = ({ beneficiaryToDelete, dialogProps }) => {
  const { formValues, getText, getTextGlobal, bncReactForm } = useContainerCtx<BeneficiaryFormValues>();
  const { setBeneficiaryToDelete } = useBeneficiaryInformationCtx();
  const { beneficiaries, account } = formValues;
  const { respPlanTypeCd } = account;
  const { change, untouch } = bncReactForm;

  const [triggerClosing, setTriggerClosing] = useState(false);

  const onBeneficiaryDelete = () => {
    const initialBeneficiaries: BeneficiaryDataType[] = get(
      getState(),
      'productFormReducer.baoForms.productForm.values.beneficiaries',
    );
    const isBeneficiaryFromInitial = initialBeneficiaries?.find(
      (initialBeneficiary) => initialBeneficiary.bncId === beneficiaryToDelete.bncId,
    );

    if (isBeneficiaryFromInitial) {
      beneficiaries[beneficiaries.findIndex((beneficiary) => beneficiary === beneficiaryToDelete)].operationMode =
        OperationMode.DELETE;

      change('beneficiaries', beneficiaries);
    } else {
      const filteredBeneficiaries = beneficiaries.filter((beneficiary) => beneficiary !== beneficiaryToDelete);

      change('beneficiaries', filteredBeneficiaries);
    }

    if (respPlanTypeCd === RespPlanTypeCd.INDIVIDUAL) {
      untouch(
        `beneficiaries[${beneficiaries.findIndex((beneficiary) => beneficiary === beneficiaryToDelete)}]requestGovGrt`,
      );
    }

    setBeneficiaryToDelete(undefined);
    setTriggerClosing(true);
  };

  return (
    <Dialog data-test="modal-delete-beneficiary" {...dialogProps} triggerClosing={triggerClosing}>
      <Dialog.Body>
        <Heading type={'h4'}>{getText('deleteDialog.heading')}</Heading>
        <Text>{getText('deleteDialog.text')}</Text>
      </Dialog.Body>

      <Dialog.Footer>
        <Dialog.CloseBtn data-test="cancel-beneficiary-delete-button" appearance="secondary">
          {getTextGlobal('button.cancel')}
        </Dialog.CloseBtn>
        <Button data-test="confirm-beneficiary-delete-button" appearance="primary" onClick={onBeneficiaryDelete}>
          {getTextGlobal('button.delete')}
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

export default DialogDeleteConfirmation;
