import React, { ChangeEvent, ClipboardEvent, Component, FocusEvent, ReactNode, SyntheticEvent } from 'react';
import { InjectedIntl } from 'react-intl';
import { Col, Row } from 'react-flexbox-grid';
import { History } from 'history';
import { FormGroup } from '@nbc-design/form-group';
import { Input } from '@nbc-design/input';
import { Password } from '@nbc-design/password';

import Title from 'components/Title';
import Captcha from 'components/Captcha';
import { ROUTE } from 'utils/constants';
import FormFooter from 'components/FormFooter';
import { signin } from 'services/loginManager';
import { getFieldsValidation, isFormValid } from './validation';
import { sendOutOfFormFlowAnalyticsEvent, sendStepLoadedEvent } from 'services/analytics';
import { EVENT_IDS } from 'services/analytics/constants';
import CONFIG from './config';
import { getTextFactory } from 'utils/TextUtils';
import ErrorMessageDisplay from 'components/errorManager/ErrorMessageDisplay';
import './styles.scss';
import { SiteKeyType } from 'components/Captcha/Captcha';
import { alternateContentRedirect, ERRORCODE, ErrorResponse } from 'components/errorManager/utils';
import { getBncId } from 'utils';

export type PendingRequestProps = {
  intl: InjectedIntl;
  history: History;
};

export type PendingRequestState = {
  touchedFields: { requestCode: boolean; password: boolean };
  formErrors: { requestCode: string; password: string; connection: string };
  isLoading: boolean;
  isFieldsValid: boolean;
  password?: string;
  requestCode?: string;
};

class PendingRequest extends Component<PendingRequestProps, PendingRequestState> {
  state: PendingRequestState = {
    touchedFields: { requestCode: false, password: false },
    formErrors: { requestCode: '', password: '', connection: '' },
    isLoading: false,
    isFieldsValid: false,
  };

  getText = getTextFactory(this.props.intl, 'pendingRequest');
  getNewRequestTexts = getTextFactory(this.props.intl, 'newRequest');
  getTextError = getTextFactory(this.props.intl, 'error');

  componentDidMount = () => {
    sendStepLoadedEvent(CONFIG.ID, EVENT_IDS.ST, '', {
      user: {
        bncId: getBncId(),
      },
    });
  };

  setError = (name: string, error: string): void => {
    this.setState(({ formErrors }) => ({
      formErrors: {
        ...formErrors,
        [name]: error ? this.getTextError(error) : '',
      },
    }));
  };

  validateField = (fieldName: string, value: string): void => {
    const { password, requestCode } = this.state;
    const { isFieldsValid, errors } = getFieldsValidation({
      fieldName,
      value,
      password,
      requestCode,
    });

    this.setError(fieldName, errors[fieldName]);
    this.setState({ isFieldsValid });
  };

  userAuthSuccess = (): void => {
    window.location.href = `${process.env.PUBLIC_URL}${ROUTE.STEPPER_MANAGER}`;
  };

  executeRecaptcha = (event: SyntheticEvent): void => {
    event.preventDefault();

    (window as any).grecaptcha.execute();
  };

  handleError = (response: ErrorResponse): void => {
    this.setError('connection', 'connection');
    this.setState({ isLoading: false });

    const errorValue = Object.values(this.state.formErrors).find((value) => value !== '');
    sendOutOfFormFlowAnalyticsEvent('' + errorValue, '' + CONFIG.ID);

    response.status === ERRORCODE.BOT_MANAGER && alternateContentRedirect(this.props.intl, response);
  };

  handleFormSubmit = (): void => {
    const { requestCode, password } = this.state;

    this.setState({ isLoading: true });

    signin(password, requestCode?.trim()).then(this.userAuthSuccess).catch(this.handleError);
  };

  errorClass = (error: string): string => {
    return error === '' ? '' : 'has-error';
  };

  onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { name, value },
    } = event;

    this.setState({ [(name as 'password') || 'requestCode']: value.trim() }, () => {
      this.state.touchedFields[name] && this.validateField(name, value.trim());
    });
  };

  onBlur = (event: FocusEvent<HTMLInputElement>): void => {
    const {
      target: { name, value },
    } = event;

    const touchedFields = this.state.touchedFields;
    touchedFields[name] = true;

    this.setState({ touchedFields }, () => {
      this.validateField(name, value);
    });
  };

  onPaste = (event: ClipboardEvent<HTMLInputElement>): void => {
    event.preventDefault();
  };

  isFormValid = (): boolean => {
    const { isFieldsValid } = this.state;
    return isFormValid({ isFieldsValid });
  };

  render(): ReactNode {
    const { formErrors } = this.state;
    const { history } = this.props;
    const { requestCode: requestCodeError, password: passWordError } = formErrors;
    const isFormValid = this.isFormValid();

    return (
      <form onSubmit={this.executeRecaptcha} className="layout-content">
        <div className="pending-request__form">
          <Title label={this.getText('pageTitle')} subTitle={this.getText('pageSubTitle')} />
          <Row>
            <Col xs={6} md={6} lg={6}>
              <FormGroup
                label={{ text: this.getText('requestCodeLabel'), htmlFor: 'request-number-input' }}
                validate={{ hasError: !!requestCodeError, errorMsg: requestCodeError }}
                data-test="label_requestCode"
              >
                <Input
                  id="request-number-input"
                  name="requestCode"
                  type="text"
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  data-test="field_requestCode"
                  maxLength={20}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={6} lg={6}>
              <FormGroup
                label={{ text: this.getText('passwordLabel'), htmlFor: 'password-input' }}
                validate={{ hasError: !!passWordError, errorMsg: passWordError }}
                data-test="label_password"
              >
                <Password
                  id="password-input"
                  name="password"
                  type="password"
                  maxLength={25}
                  onChange={this.onChange}
                  onPaste={this.onPaste}
                  onBlur={this.onBlur}
                  data-test="field_password"
                  ariaLabelShow={this.getNewRequestTexts('showPassword')}
                  ariaLabelHide={this.getNewRequestTexts('hidePassword')}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={6} lg={6}>
              <Captcha
                siteKeyType={SiteKeyType.frontend}
                success={this.handleFormSubmit}
                timeout={() => {}}
                failure={() => {}}
                invisible
              />
            </Col>
          </Row>
          <ErrorMessageDisplay visible={!!formErrors.connection} message={formErrors.connection} />
        </div>
        <FormFooter
          goToPreviousStep={() => {
            history.push({
              pathname: ROUTE.ASSISTED,
            });
          }}
          disableSubmit={!isFormValid}
        />
      </form>
    );
  }
}

export default PendingRequest;
