import Captcha from 'components/Captcha';
import { SiteKeyType } from 'components/Captcha/Captcha';
import Collection from 'components/Collection';
import Heading from 'components/Heading';
import _ from 'lodash';
import React, { FunctionComponent, MouseEvent } from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { ErrorMessage, ProductFormFieldChangeHandler, RefData, Schema } from 'types/interfaces';
import { saveCaptchaResponse } from 'utils';
import { getTextFactory } from 'utils/TextUtils';
import { AccountDataType, SafeIdFinancialDataType } from '../../types';
import { getSelectableCurrencies } from '../BankingAccountManual';
import BankingAccountSafeId, { isAllowSafeIdFinancialExp } from './BankingAccountSafeId';
import BankingAccount from '../BankingAccount';
import { Col, Row } from 'react-flexbox-grid';
import { Button } from '@nbc-design/button';
import { Fieldset } from '@nbc-design/fieldset';
import { AccordionOpen } from '@nbc-design/icons/lib/web/AccordionOpen';
import {
  findBankingAccount,
  findSafeIdFinancialExp,
  hasManualBankAccount,
  hasSafeIdDeleteRequest,
  isAllowSafeIdCurrency,
  resetSafeIdDeleteRequest,
} from '../../utils';

import '../../styles.scss';
import BankingSendCheque from '../BankingSendCheque';

export type BankingAccountsSafeIdProps = {
  safeIdFinancialExpName: string;
  bankingAccountName: string;
  safeIdFinancialExpSchema: Schema;
  bankingAccountSchema: Schema;
  change: ProductFormFieldChangeHandler;
  intl: InjectedIntl;
  refData?: RefData[];
  handleSubmit: Function;
  safeIdFinancialExp: SafeIdFinancialDataType[];
  bankingAccounts: (AccountDataType | null)[];
  submitFailed: boolean;
  error: ErrorMessage | null | undefined;
};

export const buildCustomRefDataCurrency = (
  currencyRefData: RefData[],
  safeIdFinancialExp: SafeIdFinancialDataType[],
  bankingAccounts: (AccountDataType | null)[],
): RefData[][] => {
  const customRefData: RefData[][] = [];
  bankingAccounts.forEach(() => {
    const refData = [...currencyRefData];
    currencyRefData.forEach((e: RefData, index: number) => {
      const currency: string = e.valueDomainCode;
      const isCurrencySafeId: boolean = isAllowSafeIdFinancialExp(
        safeIdFinancialExp.find(({ currency: safeIdCurrency }) => safeIdCurrency === currency),
      );
      if (isCurrencySafeId) {
        refData[index] = { ...currencyRefData[index], readOnly: true };
      }
    });
    customRefData.push(refData);
  });
  return customRefData;
};

const BankingAccountsSafeId: FunctionComponent<BankingAccountsSafeIdProps> = (props: BankingAccountsSafeIdProps) => {
  //NOSONAR
  const {
    intl,
    safeIdFinancialExp = [],
    change,
    error,
    submitFailed,
    handleSubmit,
    safeIdFinancialExpSchema,
    bankingAccountSchema,
    refData,
    safeIdFinancialExpName,
    bankingAccounts = [],
    bankingAccountName,
  } = props;
  const getText = getTextFactory(intl, 'specificsBankingInfo');
  const selectedCurrencies = getSelectableCurrencies(refData || []);
  const safeIdFinancialExpCollection: SafeIdFinancialDataType[] = [];
  const customRefData: RefData[][] = buildCustomRefDataCurrency(refData || [], safeIdFinancialExp, bankingAccounts);
  const addManualCurrencies: string[] = [];

  // Construct the SafeId Financial experience collection; Create new experience if not exist for each currency
  // Prepare addCurrency button for manual form, if SafeId Financial experience not allowed for each currency
  selectedCurrencies.forEach((currency) => {
    const safeIdFinancialExpExist = findSafeIdFinancialExp(safeIdFinancialExp, currency);
    const bankingAccountExist = findBankingAccount(bankingAccounts, currency);
    safeIdFinancialExpCollection.push({
      ...(safeIdFinancialExpExist || { currency }),
      ...(bankingAccountExist ? { account: bankingAccountExist } : {}),
    });
    if (safeIdFinancialExpExist && !isAllowSafeIdFinancialExp(safeIdFinancialExpExist) && !bankingAccountExist) {
      addManualCurrencies.push(currency);
    }
  });

  if (!_.isEqual(safeIdFinancialExpCollection, safeIdFinancialExp)) {
    change(safeIdFinancialExpName, safeIdFinancialExpCollection);
  }

  //submit the form when delete SafeId Account is requested
  if (hasSafeIdDeleteRequest(safeIdFinancialExp)) {
    handleSubmit();
    change(safeIdFinancialExpName, resetSafeIdDeleteRequest(safeIdFinancialExp));
  }

  const handleCaptchaSuccess = (token) => {
    saveCaptchaResponse(token);
    handleSubmit();
  };

  const handleAddManualForm = (event: MouseEvent<HTMLButtonElement>, currency: string): void => {
    event.preventDefault();
    const bankingAccountExist = findBankingAccount(bankingAccounts, currency);
    !bankingAccountExist && change(bankingAccountName, [...bankingAccounts, { currency }]);
  };

  return (
    <div className="bankingAccountsSafeId">
      <Heading type="h2" size={4} divider={false}>
        {getText('safeIdBankingAccountsTitle')}
      </Heading>
      {safeIdFinancialExp.length > 0 && (
        <Fieldset
          validate={{
            hasError: submitFailed && !!error,
            errorMsg: getText('bankingInfoRequired'),
          }}
        >
          <Collection
            data={safeIdFinancialExp}
            change={change}
            typeComponent={BankingAccountSafeId}
            name={safeIdFinancialExpName}
            card={false}
            deleteButton={false}
            addLabel={getText('addBankingAccount')}
            customSchema={safeIdFinancialExpSchema}
            maxItems={safeIdFinancialExp.length}
          />
        </Fieldset>
      )}
      {addManualCurrencies.map((currency: string, index: number) => (
        <Row className="add-manual-bank" key={index}>
          <Col xs={12} md={12} lg={12}>
            <Button
              icon={<AccordionOpen title={getText('addBankingAccount')} />}
              type="button"
              appearance="secondary"
              data-test="add-button"
              size="small"
              onClick={(e) => handleAddManualForm(e, currency)}
            >
              {getText(`addBankingAccount.${_.toLower(currency)}`)}
            </Button>
          </Col>
        </Row>
      ))}
      {bankingAccounts.length > 0 && (
        <>
          <Collection
            data={bankingAccounts}
            change={change}
            typeComponent={BankingAccount}
            name={bankingAccountName}
            card={true}
            addLabel={getText('addBankingAccount')}
            customSchema={bankingAccountSchema}
            minItems={0}
            maxItems={bankingAccounts.length}
            customRefData={customRefData}
            isHidden={(account: AccountDataType | null): boolean =>
              isAllowSafeIdCurrency(safeIdFinancialExp, account?.currency)
            }
          />
          {hasManualBankAccount(bankingAccounts, safeIdFinancialExp) && <BankingSendCheque />}
        </>
      )}
      <Captcha
        siteKeyType={SiteKeyType.safeid}
        success={handleCaptchaSuccess}
        timeout={() => {}}
        failure={() => {}}
        invisible
      />
    </div>
  );
};

export default injectIntl(BankingAccountsSafeId);
