import { fork } from 'redux-saga/effects';
import { loadLocale } from 'utils';
import createMelissaService from 'bnc-react-services/services/MelissaService';
import createStockSymbolService from '../services/StockSymbolService';
import createOccupationService from '../services/OccupationTitleService';
import propagateGlobalErrors from 'bnc-react-services/utils/propagateErrorsHandler';
import requestsManager from 'bnc-react-services/managers/RequestsManager';
import Configs from '../configs';
import baoSaga from '../services/baoService';
import makeProductFormService from '../services/productFormService';
import { getBaoHeaders } from 'utils/productFormUtils';

export default function* root() {
  yield [
    makeProductFormService(),
    createMelissaService({
      endpoint: Configs.params.MELISSA_SERVICE.ENDPOINT,
      id: Configs.params.MELISSA_SERVICE.ID,
      callEffect: requestsManager.call,
      onError: propagateGlobalErrors,
    }),
    createStockSymbolService({
      endpoint: Configs.params.STOCK_SYMBOL_SERVICE.ENDPOINT,
      method: 'GET',
      headers: {
        Authorization: ' : ',
      },
      id: Configs.params.STOCK_SYMBOL_SERVICE.ID,
      callEffect: requestsManager.call,
      onError: propagateGlobalErrors,
    }),
    createOccupationService({
      endpoint: Configs.params.OCCUPATION_TITLE_SERVICE.ENDPOINT,
      method: 'GET',
      headers: getBaoHeaders(),
      callEffect: requestsManager.call,
      onError: propagateGlobalErrors,
      language: loadLocale(),
    }),
    baoSaga,
  ].map((saga: [{ [key: string]: () => any }, string]) => fork(saga));
}
