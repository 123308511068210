import React, { FC } from 'react';
import { InjectedIntl } from 'react-intl';
import { DateTimeFormatOptions } from 'luxon';
import get from 'lodash/get';

import { ProductForm } from 'types/interfaces';
import { getTextFactory } from 'utils/TextUtils';
import { Col, Row } from 'react-flexbox-grid';
import { ProductFormDateFields } from '../../../components/productFormFields';
import { createErrorFormatter, isFieldDisabled } from '../../../utils/productFormUtils';

export type PersonalInfoProps = {
  productForm: ProductForm;
  intl: InjectedIntl;
};

const buildRow = (label: string, value: string, dataTest: string, extraClass: string) => (
  <div className={`row ${extraClass}`}>
    <div className="col-xs-12 col-md-4">
      <span className="label-info">{label}</span>
    </div>
    <div className="col-xs-12 col-md-8">
      <span className="label-value" data-test={dataTest}>
        {value}
      </span>
    </div>
  </div>
);

const PersonalInfo: FC<PersonalInfoProps> = ({ productForm, intl, children }) => {
  const getGlobalText = getTextFactory(intl, 'global');

  const firstName = get(productForm, ['values', 'firstName']);
  const lastName = get(productForm, ['values', 'lastName']);
  const birthDate = get(productForm, ['values', 'birthDate']);
  const displayDateOptions = { year: 'numeric', month: 'long', day: 'numeric' } as DateTimeFormatOptions;
  const [year, month, day] =
    birthDate !== undefined ? birthDate.split('-').map((value: string) => +value) : [undefined, undefined, undefined];
  const formattedBirthDate = new Date(year, month - 1, day).toLocaleDateString(intl.locale, displayDateOptions);

  return (
    <section>
      {buildRow(
        `${getGlobalText('firstName')}, ${getGlobalText('lastName')}`,
        `${firstName} ${lastName}`,
        'fullName',
        'full-name-row',
      )}

      {birthDate !== undefined ? (
        buildRow(getGlobalText('birthDate'), formattedBirthDate, 'birthDate', 'birth-date-row')
      ) : (
        <Row>
          <Col xs={12} md={8} data-test="field_birthDate">
            <ProductFormDateFields
              name="birthDate"
              label={getGlobalText('birthDate')}
              formatErrorMessage={createErrorFormatter(intl)}
              disabled={isFieldDisabled(get(productForm, 'schema'), 'birthDate')}
            />
          </Col>
        </Row>
      )}

      <section className="address-container">{children}</section>
    </section>
  );
};

export default PersonalInfo;
