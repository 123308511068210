import { injectIntl } from 'react-intl';
import { formValues } from 'bnc-react-forms';
import withSizes from 'react-sizes';
import Documents from './Documents';
import config from './config';
import * as validation from './validation';

export const VALIDATION = validation;
export const CONFIG = config;

const mapSizesToProps = ({ width }) => ({
  width: width as number,
});

export default injectIntl(formValues('documentsAcceptance')(withSizes(mapSizesToProps)(Documents)));
