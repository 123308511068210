import React from 'react';
import { TransferAccountDataType, TransferFormData } from '../types';
import { Message } from '@nbc-design/message';
import { ProductFormInput } from 'components/productFormFields';
import { useContainerCtx } from 'providers/containerCtx/ContainerCtx';
import { ErrorData } from 'types/interfaces';

type TransferAccountsInternalItemProps = {
  accountData: TransferAccountDataType;
  index: number;
  error?: Partial<Record<keyof TransferAccountDataType, ErrorData>>;
};

const TransferAccountsInternalItem: React.FC<TransferAccountsInternalItemProps> = ({ accountData, index, error }) => {
  const {
    getText,
    formatErrorMessage,
    bncReactForm: { submitFailed, change },
  } = useContainerCtx<TransferFormData>();

  return (
    <div>
      <ProductFormInput
        label={getText('accountNumber')}
        name={`accounts[${index}].accountNumber`}
        inputProps={{
          length: 'small',
          onKeyUp: (e) => {
            e.preventDefault();
            change(`accounts[${index}].accountNumber`, e.currentTarget.value.toUpperCase());
          },
        }}
        inputRawValue={accountData?.accountNumber?.toUpperCase()}
        maxLength={7}
        hasError={!!error?.accountNumber}
        // formatErrorMessage={formatErrorMessage('transferInternalError', 'accountNumber')}
      />

      {submitFailed && !!error?.accountNumber && (
        <Message appearance="error">
          {formatErrorMessage('transferInternalError', 'accountNumber')({ ...error.accountNumber })}
        </Message>
      )}
    </div>
  );
};

export default TransferAccountsInternalItem;
