import { getProductFormField } from '../utils';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import { injectIntl } from 'react-intl';
import { stockSymbolServiceListRequested, stockSymbolServiceReset } from 'services/StockSymbolService/actions';
import ProductFormStockSymbolSelect from './ProductFormStockSymbolSelect';

export default compose<any>(
  connect(
    (state, ownProps) => {
      return {
        isLoading: get(state, ['stockSymbolServiceReducer', ownProps.name, 'isFetching'], false),
        stockSymbolList: get(state, ['stockSymbolServiceReducer', ownProps.name, 'stockSymbolList']),
        error: get(state, ['stockSymbolServiceReducer', ownProps.name, 'fetchingError']),
      };
    },
    {
      searchStockSymbol: stockSymbolServiceListRequested,
      resetStockSymbol: stockSymbolServiceReset,
    },
  ),
  injectIntl,
)(getProductFormField(ProductFormStockSymbolSelect));
