import React, { FunctionComponent, ReactNode } from 'react';
import IdeeIcon from 'assets/icons/idee';

import './style.scss';

export type HighlightProps = {
  children: ReactNode;
};

const Highlight: FunctionComponent<HighlightProps> = (props: HighlightProps) => {
  const { children } = props;

  return (
    <div className="highlight">
      <div className="highlight__icon">
        <IdeeIcon className="" />
      </div>

      {children}
    </div>
  );
};

export default Highlight;
