import React, { FunctionComponent } from 'react';
import { InjectedIntl } from 'react-intl';
import { Row, Col } from 'react-flexbox-grid';
import { Advisor } from '@nbc-design/icons/lib/web/Advisor';
import { AttentionOutlineColor } from '@nbc-design/icons/lib/web/AttentionOutlineColor';
import { Text } from '@nbc-design/text';
import Title from 'components/Title';
import { getTextFactory } from 'utils/TextUtils';
import Heading from 'components/Heading';

import './styles.scss';

export type ESignFailProps = {
  intl: InjectedIntl;
};

const ESignFail: FunctionComponent<ESignFailProps> = (props: ESignFailProps) => {
  const { intl } = props;

  const getText = getTextFactory(intl, 'esignFail');
  const getGlobalText = getTextFactory(intl, 'global');

  return (
    <div className="esign_fail__form">
      <AttentionOutlineColor
        title="attention-outline-color"
        size={{ width: 100, height: 100 }}
        className="warning-icon"
        data-test="icon_warning"
      />
      <Title label={getText('pageTitle')} />

      <div className="help-section">
        <Row>
          <Col xs={3} md={2} lg={2}>
            <Advisor title="advisor" size={{ width: 100, height: 100 }} data-test="icon_conseillers" />
          </Col>
          <Col xs={9} md={10} lg={10} className="help-section-content">
            <Heading type="h3" size={5}>
              {getGlobalText('helpLabel')}
            </Heading>
            <Text type="p">{`${getGlobalText('helpQuestions')} ${getGlobalText('helpPhone')}`}</Text>
            <Text type="p">{getGlobalText('helpOpenedTime')}</Text>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ESignFail;
