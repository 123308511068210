import { injectIntl } from 'react-intl';
import Regulatory from './Regulatory';
import config from './config';
import { formValues } from 'bnc-react-forms';
import * as validation from './validation';

export const VALIDATION = validation;
export const CONFIG = config;

export default injectIntl(
  formValues(
    'displayEndorserOnAccount',
    'hasTraderOnAccount',
    'proxyType',
    'firstParticipant',
    'secondParticipant',
    'displayJointSurvivorship',
  )(Regulatory),
);
