import React, { ChangeEvent, FC, FocusEvent } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import get from 'lodash/get';

import { GenericProps } from 'types/interfaces';
import { isCountryUS, socialInsuranceFormatter } from 'utils';
import { createErrorFormatter, getRefData, isJointMenu } from 'utils/productFormUtils';
import { getTextFactory } from 'utils/TextUtils';
import Heading from 'components/Heading';
import HelpText from 'components/HelpText';
import Title from 'components/Title';
import { ProductFormInput, ProductFormRadioBoolean, ProductFormSelect } from 'components/productFormFields';

import { MainResidency, SecondaryResidency } from './types';
import { filterNoRevealReasonUSA, handleMainCountrySelectBoxCheck } from './utils';
import Identity from './components/Identity';
import OtherResidencies from './components/OtherResidencies';
import CONFIG from './config';
import { Text } from '@nbc-design/text';
import { ErrorFieldFilledColor } from '@nbc-design/icons/lib/web/ErrorFieldFilledColor';
import CanadianChildrenBenefits from './components/CanadianChildrenBenefits';
import './styles.scss';
import { PROVINCES } from 'utils/constants';

export type TaxesProps = GenericProps & {
  isOtherResidency?: string;
  secondaryResidency?: SecondaryResidency[];
  isMainResidencyCA?: boolean;
  mainResidency?: MainResidency;
  displayNas?: boolean;
};

const Taxes: FC<TaxesProps> = ({
  isMainResidencyCA = undefined,
  mainResidency = {},
  isOtherResidency = '',
  secondaryResidency = [],
  schema = undefined,
  refData = undefined,
  intl,
  change,
  productForm,
  meta,
}: TaxesProps) => {
  const [sinAnonymisedPartial, setSinAnonymisedPartial] = React.useState('');

  const getText = getTextFactory(intl, 'tax');
  const getTextGlobal = getTextFactory(intl, 'global');

  const isCountrySelector = isMainResidencyCA === false;
  const mainCountry = get(mainResidency, 'country');
  const mainResidencyProvince = get(mainResidency, 'province');
  const isMainResidencyUS = isCountryUS(mainCountry);
  const identificationPresent: boolean = get(mainResidency, 'identificationPresent', false);
  const isNoRevealReasonField = mainCountry && !identificationPresent;
  const isProvinceField = isMainResidencyCA || (isCountrySelector && isMainResidencyUS);

  const hasJointSurvivorship = get(productForm, ['values', 'hasJointSurvivorship'], false);

  const formatErrorMessage = (prefix?: string, fieldName?: string) => createErrorFormatter(intl, prefix, fieldName);

  const handleIsMainResidencyCAChange = (value: boolean): void => {
    change('isMainResidencyCA', value);
    change('mainResidency.province', '');
  };

  const handleSocialInsuranceNumberOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    const { name, value } = event.target;

    change(name, socialInsuranceFormatter(value));
  };

  const handleSocialInsuranceNumberOnFocus = (event: FocusEvent<HTMLInputElement>): void => {
    setSinAnonymisedPartial('');
  };

  const handleSocialInsuranceNumberOnBlur = (event: FocusEvent<HTMLInputElement>): void => {
    event.preventDefault();
    const { value } = event.target;

    const firstPart = value.substring(0, 7);
    const lastPart = value.substring(8);
    const anonymisedValue = firstPart.replace(new RegExp('[0-9]', 'g'), '*') + ' ' + lastPart;

    setSinAnonymisedPartial(anonymisedValue);
  };

  const getSurvivorshipWarning = (): React.ReactNode => (
    <div className="survivorship-warning-text">
      <ErrorFieldFilledColor size="xsmall" title="error-field-filled-color" />
      <Text data-test="fields_proxy_Information" className="product-form-input__help legal">
        {getText('survivorshipWarning')}
      </Text>
    </div>
  );

  const usResidentWarningText = isCountrySelector && isMainResidencyUS ? getText('IsUsResident') : undefined;
  const survivorshipWarningText =
    hasJointSurvivorship && isMainResidencyCA && mainResidencyProvince === PROVINCES.QC
      ? getSurvivorshipWarning()
      : undefined;

  const isJointStep = meta && meta.stepId === CONFIG.STEP_JOINT_ID;

  return (
    <div className="tax__form">
      {isJointMenu(meta) && (
        <Text size="large" className={isJointStep ? 'joint-spouse-title' : 'joint-applicant-title'}>
          {isJointStep ? getTextGlobal('spouseMenuTitle') : getTextGlobal('applicantMenuTitle')}
        </Text>
      )}

      <Title data-test="title" label={getText('pageTitle')} subTitle={getText('pageSubTitle')} />

      <Heading type="h2" data-test="heading_countriesOfResidence" size={4}>
        {getText('residenceCountries')}
      </Heading>

      <Row>
        <Col xs={12} data-test="field_isMainResidencyCA">
          <ProductFormRadioBoolean
            name="isMainResidencyCA"
            className="isMainResidencyCA"
            label={getText('isMainResidencyCA')}
            yesLabel={getTextGlobal('button.yes')}
            noLabel={getTextGlobal('button.no')}
            onChange={handleIsMainResidencyCAChange}
            formatErrorMessage={formatErrorMessage()}
            inline
          />
        </Col>
      </Row>

      {isCountrySelector && (
        <Row>
          <Col xs={12} md={8} data-test="field_mainResidency_country">
            <ProductFormSelect
              name="mainResidency.country"
              label={getText('mainResidency')}
              placeholder={getTextGlobal('select')}
              onChange={handleMainCountrySelectBoxCheck(change)}
              filter={({ value }) => value !== 'CA'}
              formatErrorMessage={formatErrorMessage()}
              sort
              helpText={usResidentWarningText}
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={12} md={8} data-test="field_mainResidency_province">
          <ProductFormSelect
            label={getText('provinceCA')}
            placeholder={getTextGlobal('select')}
            name="mainResidency.province"
            conditional={() => isProvinceField && !!isMainResidencyCA}
            sort
            refData={getRefData({ productForm, schema, refData }, 'provinceCA')}
            formatErrorMessage={formatErrorMessage()}
            helpText={survivorshipWarningText}
          />

          <ProductFormSelect
            label={getText('provinceUS')}
            placeholder={getTextGlobal('select')}
            name="mainResidency.province"
            conditional={() => isProvinceField && !isMainResidencyCA}
            sort
            refData={getRefData({ productForm, schema, refData }, 'provinceUS')}
            formatErrorMessage={formatErrorMessage()}
          />
        </Col>
      </Row>

      {!isMainResidencyCA && <Identity residency={mainResidency} fieldNamePrefix="mainResidency" change={change} />}

      {isNoRevealReasonField && (
        <Row>
          <Col xs={12} data-test="field_mainResidency_noRevealReason">
            <ProductFormSelect
              name="mainResidency.noRevealReason"
              className="noRevealReason"
              label={getText('noRevealReason')}
              placeholder={getTextGlobal('select')}
              formatErrorMessage={formatErrorMessage('tax')}
              filter={({ value }) => filterNoRevealReasonUSA(value, isMainResidencyUS)}
            />
          </Col>
        </Row>
      )}

      <OtherResidencies
        productForm={productForm}
        isOtherResidency={isOtherResidency}
        secondaryResidency={secondaryResidency}
        change={change}
        intl={intl}
        isMainResidencyCA={isMainResidencyCA}
        mainResidency={mainResidency}
        isMainResidencyUS={isMainResidencyUS}
      />

      <Heading type="h2" data-test="heading_socialInsuranceNumber" size={4}>
        {getText('socialInsuranceNumber')}
      </Heading>

      <HelpText data-test="helpText_socialInsuranceNumber">{getText('NASDescription')}</HelpText>

      <Row>
        <Col xs={12} md={4} data-test="field_socialInsuranceNumber">
          <ProductFormInput
            name="socialInsuranceNumber"
            ariaLabel={getText('socialInsuranceNumber')}
            onChange={handleSocialInsuranceNumberOnChange}
            onFocus={handleSocialInsuranceNumberOnFocus}
            onBlur={handleSocialInsuranceNumberOnBlur}
            formatErrorMessage={formatErrorMessage('tax', 'socialInsuranceNumber')}
            inputRawValue={sinAnonymisedPartial}
          />
        </Col>
      </Row>

      <CanadianChildrenBenefits />
    </div>
  );
};

export default Taxes;
