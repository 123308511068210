import { TransferAccountDataType, TransferAccountsError, TransferErrorData, TransferFormData } from './types';
import { ACCOUNT_SOURCE, REGEX_ACCOUNT_NUMBER } from './constants';
import { ERRORTYPE } from '../../utils/validation';
import { isArray } from 'lodash';

const hasSelectedAccounts = (accountSrcCd: ACCOUNT_SOURCE, accounts?: TransferAccountDataType[]): boolean => {
  return accounts?.some((account) => account.transferSourceCd === accountSrcCd && account.transfer) ?? false;
};

export const validateAccountList = (
  accounts: TransferAccountDataType[] = [],
  hasAccount = false,
  accountSrcCd: ACCOUNT_SOURCE,
): boolean | undefined => {
  return hasAccount && !hasSelectedAccounts(accountSrcCd, accounts);
};

export const isValidAccountFormat = (accountNb: string, currencyCode: string, accountCode: string): boolean => {
  if (!REGEX_ACCOUNT_NUMBER.test(accountNb)) return false;

  const lastChar = accountNb.charAt(accountNb.length - 1).toUpperCase();

  if (['RRIF', 'RRSP'].includes(accountCode) && currencyCode === 'USD') return lastChar === '7';
  if (['RRIF', 'RRSP'].includes(accountCode) && currencyCode === 'CAD') return lastChar === 'S';
  if (['SRRIF', 'SRRSP'].includes(accountCode) && currencyCode === 'CAD') return lastChar === 'R';
  if (['LIF', 'FLIF', 'RLIF', 'LRIF', 'PRIF', 'LIRA', 'LRSP', 'RLSP'].includes(accountCode) && currencyCode === 'CAD') {
    return lastChar === 'N';
  }

  return true;
};

const accountInternalValidator = (accounts: TransferAccountDataType[] = []): TransferAccountsError['accounts'] => {
  const errors = [...accounts].map(({ accountNumber, currency, accountType, transfer, transferSourceCd }) => {
    if (transferSourceCd !== ACCOUNT_SOURCE.INTERNAL) return {};
    if (!transfer) return {};

    const accountNbError = !accountNumber
      ? { accountNumber: ERRORTYPE.REQUIRED }
      : !isValidAccountFormat(accountNumber, currency.code, accountType.code)
      ? { accountNumber: ERRORTYPE.PATTERN }
      : {};

    return {
      ...accountNbError,
    };
  });

  return errors;
};

const validateInternalAccountsFields = (accounts: TransferFormData['accounts']): TransferErrorData => {
  if (!hasSelectedAccounts(ACCOUNT_SOURCE.INTERNAL, accounts)) return {};

  const accountErrors = accountInternalValidator(accounts);
  const hasFormatErrors = accountErrors?.some((error) => Object.keys(error).length > 0);

  if (!hasFormatErrors) return {};

  return {
    _error: {
      type: 'accountsFieldsFormat',
      accounts: accountErrors,
    },
  };
};

const validation = (values: TransferFormData): TransferErrorData => {
  if (!isArray(values.accounts)) return {};

  const transferAccountOtherFinError = validateAccountList(
    values.accounts,
    values.transferAccountOtherFin,
    ACCOUNT_SOURCE.OTHFIN,
  );

  const transferAccountEmployerError = validateAccountList(
    values.accounts,
    values.transferAccountEmployer,
    ACCOUNT_SOURCE.EMPLYR,
  );

  const transferAccountInternalError = validateAccountList(
    values.accounts,
    values.transferAccountInternal,
    ACCOUNT_SOURCE.INTERNAL,
  );

  if (transferAccountOtherFinError || transferAccountEmployerError || transferAccountInternalError) {
    return {
      _error: {
        type: 'oneAccountRequired',
        transferFinancialError: transferAccountOtherFinError,
        transferEmployerError: transferAccountEmployerError,
        transferInternalError: transferAccountInternalError,
      },
    };
  }

  return {
    ...validateInternalAccountsFields(values.accounts),
  };
};

export default validation;
