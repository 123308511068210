import { FinalisationErrorData, FinalisationFormData } from './types';

const validation = ({ allLinksOpened, isBNCD }: FinalisationFormData): FinalisationErrorData | null => {
  const isValid = isBNCD || allLinksOpened;

  return isValid
    ? null
    : {
        _error: { type: 'globalError' },
      };
};

export default validation;
