import React, { FunctionComponent } from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { getTextFactory } from 'utils/TextUtils';
import { Col, Row } from 'react-flexbox-grid';
import { Text } from '@nbc-design/text';

type BankingSendChequeProps = {
  intl: InjectedIntl;
};

const BankingSendCheque: FunctionComponent<BankingSendChequeProps> = (props: BankingSendChequeProps) => {
  const { intl } = props;
  const getText = getTextFactory(intl, 'specificsBankingInfo');
  const getGlobalText = getTextFactory(intl, 'global');

  return (
    <Row className="send-cheque-wrapper">
      <Col xs={12} md={12} lg={12} data-test="text_sendChequeDetails" className="send-cheque-details">
        <Text type="p">{getText('sendChequeText')}</Text>
      </Col>
      <Col xs={12} md={12} lg={12} data-test="text_sendChequeAddress" className="send-cheque-address">
        <Text type="p">{getGlobalText('address1Line')}</Text>
        <Text type="p">{getGlobalText('address2Line')}</Text>
        <Text type="p">{getGlobalText('address3Line')}</Text>
        <Text type="p">{getGlobalText('address4Line')}</Text>
        <Text type="p">{getGlobalText('address5Line')}</Text>
      </Col>
    </Row>
  );
};

export default injectIntl(BankingSendCheque);
