import React, { FunctionComponent } from 'react';
import { isProduction } from 'utils';

type AppVersionProps = {
  version: string;
};

const AppVersion: FunctionComponent<AppVersionProps> = ({ version }) =>
  isProduction() ? null : <div className="app-version">FE V.{version}</div>;

export default AppVersion;
