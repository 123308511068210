export const PUBLIC_PATH = '/bao';

export const DEFAULT_ENV = 'dev';

export enum ENV {
  TEST = 'test',
  DEV = 'development',
  TI = 'ti',
  PROD = 'production',
}

export enum NODE_ENVS {
  TEST = 'test',
  DEV = 'development',
  PROD = 'production',
}

// i18n
export const LANG = {
  EN: 'en',
  FR: 'fr',
};
const hostnameEN = 'accounts.nbdb.ca';
const currentLocation = window.location;
const hostname = currentLocation.hostname;

export const LANG_DEFAULT = hostname === hostnameEN ? LANG.EN : LANG.FR;

export enum COUNTRIES {
  CA = 'CA',
  US = 'US',
  FR = 'FR',
}

export enum PROVINCES {
  QC = 'CA-QC',
  BC = 'CA-BC',
  AB = 'CA-AB',
  MB = 'CA-MB',
  NB = 'CA-NB',
  NS = 'CA-NS',
  ON = 'CA-ON',
  SK = 'CA-SK',
  NL = 'CA-NL',
}

export enum CURRENCIES {
  CAD = 'CAD',
  USD = 'USD',
}

export const BREAKPOINT = { XS: 576, SM: 577, MD: 768, LG: 992, XL: 1200, XXL: 1440 };

export const BAO_FORMS = 'baoForms';

export enum ROUTE {
  CLIENT_REDIRECT = '/',
  CLIENT_REDIRECT_OLD = '/client-redirect-old',
  REDIRECT_PAGE = '/redirect',
  WELCOME = '/welcome',
  ASSISTED = '/assisted',
  NEW_REQUEST = '/new-request',
  PENDING_REQUEST = '/pending-request',
  MORE_INFORMATION = '/moreInformation',
  STEPPER_MANAGER = '/steps',
  CREATE_USER_PROFILE = '/create-profile',
}

export const URLS = {
  DEV: {
    EN: 'apadev.fbn-nbf.local',
    FR: 'apadev.fbn-nbf.local',
  },
  TI: {
    EN: 'ti-en.fbn-nbf.local',
    FR: 'ti-fr.fbn-nbf.local',
  },
  TEST: {
    EN: 'test-en.fbn-nbf.local',
    FR: 'test-fr.fbn-nbf.local',
  },
  PP: {
    EN: 'https://pprod-en.fbn-nbf.local/bao/',
    FR: 'https://pprod-fr.fbn-nbf.local/bao/',
  },
  BNCD: {
    EN: 'https://nbdb.ca',
    FR: 'https://www.bncd.ca',
  },
  MORE_INFORMATION: {
    EN: 'https://nbdb.ca/accounts/types.html',
    FR: 'https://bncd.ca/comptes/types.html',
  },
  FORMS: {
    EN: 'https://nbdb.ca/forms.html',
    FR: 'https://bncd.ca/formulaires.html',
  },
  MORE_DETAILS: {
    EN:
      'https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/first-home-savings-account/opening-closing-and-fhsa.html',
    FR:
      'https://www.canada.ca/fr/agence-revenu/services/impot/particuliers/sujets/compte-epargne-libre-impot-achat-premiere-propriete/ouvrir-ferme-un-celiapp.html',
  },
};

export const BOT_MANAGER_URLS = {
  DEV: {
    EN: 'https://client.bnc.dev.bngf.local/nbdb/botmanager',
    FR: 'https://client.bnc.dev.bngf.local/cdbn/botmanager',
  },
  TI: {
    EN: 'https://client.bnc.dev.bngf.local/nbdb/botmanager',
    FR: 'https://client.bnc.dev.bngf.local/cdbn/botmanager',
  },
  TEST: {
    EN: 'https://client.bnc.ta.bngf.local/nbdb/botmanager',
    FR: 'https://client.bnc.ta.bngf.local/cdbn/botmanager',
  },
  PP: {
    EN: 'https://client.bnc.ta.bngf.local/nbdb/botmanager',
    FR: 'https://client.bnc.ta.bngf.local/cdbn/botmanager',
  },
  PROD: {
    EN: 'https://client.bnc.ca/nbdb/botmanager',
    FR: 'https://client.bnc.ca/cdbn/botmanager',
  },
};

export const RECAPTCHA_KEY = 'captcha-response';
export const RECAPTCHA_SITE_KEY_TYPE = 'captcha-sitekeytype';
