import React, { FunctionComponent } from 'react';
import SummaryFieldLabel from './SummaryFieldLabel';
import SummaryFieldValue from './SummaryFieldValue';

export type SummaryFieldProps = {
  label: string;
  dataTest: string;
};

const SummaryField: FunctionComponent<SummaryFieldProps> = (props) => {
  const { dataTest, label, children } = props;

  return (
    <div data-test={`${dataTest}`}>
      <SummaryFieldLabel>{label}</SummaryFieldLabel>
      <SummaryFieldValue>{children}</SummaryFieldValue>
    </div>
  );
};

export default SummaryField;
