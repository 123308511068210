import { Alert } from '@nbc-design/alert';
import { Checkbox } from '@nbc-design/checkbox';
import { Combo, Props as ComboProps } from '@nbc-design/combo';
import { Fieldset } from '@nbc-design/fieldset';
import { ErrorFieldFilledColor } from '@nbc-design/icons/lib/web/ErrorFieldFilledColor';
import { Label } from '@nbc-design/label';
import { NbcColor } from '@nbc-design/icons/lib/web/NbcColor';
import Collection from 'components/Collection';
import Heading from 'components/Heading';
import { ProductFormRadioBoolean, ProductFormSelect } from 'components/productFormFields';
import Title from 'components/Title';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { InjectedIntl } from 'react-intl';
import { GenericProps, PropType, RefData, Schema } from 'types/interfaces';
import { createErrorFormatter, getAllRefData, getLabel, isJointMenu } from 'utils/productFormUtils';
import { getTextFactory } from 'utils/TextUtils';
import BankingAccount from './components/BankingAccount';
import {
  isAllowSafeIdFinancialExp,
  isDecisionFail,
  isDecisionSuccess,
  isSafeIdFinancialExpError,
} from './components/SafeId/BankingAccountSafeId';
import BankingAccountsSafeId from './components/SafeId/BankingAccountsSafeId';
import { AccountDataType, SafeIdFinancialDataType } from './types';
import BankingSendCheque from './components/BankingSendCheque';
import CONFIG from './config';
import { Text } from '@nbc-design/text';

import './styles.scss';

export type ComboItemsProps = PropType<ComboProps, 'items'>;

export type SpecificsBankingInfoProps = GenericProps & {
  intl: InjectedIntl;
  confirmedBankingAccounts?: AccountDataType[];
  bankingAccounts?: AccountDataType[];
  safeIdFinancialExp?: SafeIdFinancialDataType[];
  programParticipation?: boolean;
  maxBankingAccounts?: number;
  hasFERRUSD?: boolean;
  handleSubmit: Function;
};

export const isNotEmpty = (e: any) => !isEmpty(e);
export const isAccountSelected = (e: AccountDataType | {}): boolean => get(e, 'selected', false);

export const isRestrictedCurrency = (currency: string, currencyRefData: RefData[]): boolean => {
  const restrictedCurrencies: string[] = currencyRefData
    .filter((e) => {
      return e.readOnly;
    })
    .map((e) => e.valueDomainCode);
  return restrictedCurrencies.some(isNotEmpty) && restrictedCurrencies.includes(currency);
};

export const buildCustomCurrencyRefData = (
  bankingAccounts: AccountDataType[] | null[],
  maxBankingAccounts: number,
  selectedCurrencies: string[],
  currencyRefData: RefData[],
): RefData[][] => {
  const customRefData: RefData[][] = [];
  for (let i = 0; i < maxBankingAccounts; i++) {
    const refData = [...currencyRefData];

    currencyRefData.forEach((e: RefData, index: number) => {
      const currency: string = e.valueDomainCode;
      const selectedCurrency: string = bankingAccounts[i]
        ? (bankingAccounts[i] || ({} as AccountDataType)).currency
        : '';
      const enforceRestrictedCurrency: boolean =
        maxBankingAccounts > 1 || isRestrictedCurrency(currency, currencyRefData);

      if (selectedCurrency !== currency && selectedCurrencies.includes(currency) && enforceRestrictedCurrency) {
        refData[index] = { ...currencyRefData[index], readOnly: true };
      }
    });
    customRefData.push(refData);
  }

  return customRefData;
};

export const isAccountDisabled = (
  account: AccountDataType,
  maxBankingAccounts: number,
  selectedCurrencies: string[],
  currencyRefData: RefData[],
): boolean => {
  const hasReachedMaxBankingAccounts: boolean = selectedCurrencies.length >= maxBankingAccounts;
  return (
    !account.selected &&
    (selectedCurrencies.includes(account.currency) ||
      hasReachedMaxBankingAccounts ||
      isRestrictedCurrency(account.currency, currencyRefData))
  );
};

export const hasSafeIdFinancialsDown = (safeIdExperiences: SafeIdFinancialDataType[]): boolean => {
  return !!safeIdExperiences.find((safeIdExp) => isSafeIdFinancialExpError(safeIdExp));
};

export const hasSafeIdFinancialsFail = (safeIdExperiences: SafeIdFinancialDataType[]): boolean => {
  return !!safeIdExperiences.find((safeIdExp) => isDecisionFail(safeIdExp.decision));
};

export const hasSafeIdFinancialsSuccess = (safeIdExperiences: SafeIdFinancialDataType[]): boolean => {
  return !!safeIdExperiences.find((safeIdExp) => isDecisionSuccess(safeIdExp.decision));
};

export const isSafeIdFinancialsDenied = (safeIdExperiences: SafeIdFinancialDataType[]): boolean => {
  return safeIdExperiences.length > 0 && !safeIdExperiences.find((safeIdExp) => isAllowSafeIdFinancialExp(safeIdExp));
};

export const getSafeIdUrl = (safeIdExperiences: SafeIdFinancialDataType[]) => {
  const safeIdExpOpenSession = safeIdExperiences.find((safeIdExp) => safeIdExp.url);
  return safeIdExpOpenSession?.url;
};

const SpecificsBankingInfo: FunctionComponent<SpecificsBankingInfoProps> = (props: SpecificsBankingInfoProps) => {
  //NOSONAR
  const {
    intl,
    bankingAccounts = [null],
    safeIdFinancialExp = [],
    confirmedBankingAccounts = [],
    change,
    programParticipation = null,
    productForm,
    maxBankingAccounts = 1,
    submitFailed,
    error,
    handleSubmit,
    hasFERRUSD,
    meta,
  } = props;

  const [linkAnotherBankingAccount, setLinkAnotherBankingAccount] = useState(bankingAccounts.some(isNotEmpty));

  const bankingAccountSchema: Schema = get(productForm, 'schema.definitions.BankingAccountUI');
  const safeIdFinancialExpSchema: Schema = get(productForm, 'schema.definitions.SafeIdFinancialExpUI');
  const currencyRefData: RefData[] = get(bankingAccountSchema, 'properties.currency.refData', []);
  const productIdRefData: RefData[] = get(bankingAccountSchema, 'properties.productId.refData', []);
  const safeIdFinancialsEnabled: boolean = get(productForm, 'values.safeIdFinancialsEnabled');
  const safeIdFinancialExpValues: SafeIdFinancialDataType[] = get(productForm, 'values.safeIdFinancialExp') || [];
  const safeIdFinancialsDown =
    get(productForm, 'values.safeIdFinancialsDown') || hasSafeIdFinancialsDown(safeIdFinancialExpValues);

  const formatErrorMessage = createErrorFormatter(intl);
  const getText = getTextFactory(intl, 'specificsBankingInfo');
  const getAccessibilityText = getTextFactory(intl, 'accessibility.icon');
  const getTextGlobal = getTextFactory(intl, 'global');
  const allRefData = getAllRefData(props);
  const safeIdFinancialsFail = hasSafeIdFinancialsFail(safeIdFinancialExpValues);
  const safeIdFinancialsSuccess = hasSafeIdFinancialsSuccess(safeIdFinancialExpValues);
  const safeIdFinancialsDenied = isSafeIdFinancialsDenied(safeIdFinancialExpValues);

  const showConfirmedBankingAccounts: boolean = confirmedBankingAccounts.length > 0;
  const showSafeIdBankingAccounts: boolean =
    safeIdFinancialsEnabled &&
    !safeIdFinancialsDenied &&
    (safeIdFinancialsSuccess || !safeIdFinancialsDown) &&
    !showConfirmedBankingAccounts;
  const selectedConfirmedAccounts = confirmedBankingAccounts.filter(isAccountSelected);
  const hasReachedMaxConfirmedAccounts: boolean = selectedConfirmedAccounts.length >= maxBankingAccounts;
  const allSelectedAccounts: (AccountDataType | null)[] = [...selectedConfirmedAccounts, ...bankingAccounts];
  const selectedCurrencies: string[] = uniq(
    allSelectedAccounts.map((e) => (e || ({} as AccountDataType)).currency).filter(isNotEmpty),
  );
  const customRefData: RefData[][] = buildCustomCurrencyRefData(
    bankingAccounts,
    maxBankingAccounts,
    selectedCurrencies,
    currencyRefData,
  );

  // Redirect to SafeId Financial for the target currency
  const safeIdUrl = getSafeIdUrl(safeIdFinancialExpValues);
  safeIdUrl && window.location.assign(safeIdUrl);

  // Control checkbox for confirmed banking accounts
  const handleConfirmedBankingAccountsChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = event.target;
    change(name, checked);
    if (hasReachedMaxConfirmedAccounts) {
      setLinkAnotherBankingAccount(false);
    }
  };
  const confirmedBankingAccountsCheckItems: ComboItemsProps = confirmedBankingAccounts.map((account, index) => {
    const currencyLabel: string = getLabel(currencyRefData, intl.locale, account.currency);
    const productIdLabel: string = getLabel(productIdRefData, intl.locale, account.productId || '');

    return {
      children: (
        <Checkbox
          id={`confirmedBankingAccounts(${index})`}
          label={
            <Label
              id={`labelConfirmedBankingAccounts(${index})`}
              htmlFor={`confirmedBankingAccounts(${index})`}
              optionalText={`${account.institutionNo}-${account.branchNo}-${account.accountNo}`}
            >
              <NbcColor size="medium" title="nbc-color" />
              {`${productIdLabel} (${currencyLabel})`}
            </Label>
          }
          name={`confirmedBankingAccounts[${index}].selected`}
          text={account.institutionName}
          checked={account.selected}
          onChange={handleConfirmedBankingAccountsChange}
          disabled={isAccountDisabled(account, maxBankingAccounts, selectedCurrencies, currencyRefData)}
        />
      ),
    };
  });

  // Control special checkbox to toggle unconfirmed banking accounts
  const showBankingAccounts: boolean =
    (linkAnotherBankingAccount || !showConfirmedBankingAccounts) &&
    !hasReachedMaxConfirmedAccounts &&
    !showSafeIdBankingAccounts;
  const handleLinkAnotherBankingAccountChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { checked } = event.target;
    change('bankingAccounts', [null]);
    setLinkAnotherBankingAccount(checked);
  };
  if (showConfirmedBankingAccounts) {
    confirmedBankingAccountsCheckItems.push({
      children: (
        <Checkbox
          id="linkAnotherBankingAccount"
          label={getText('linkAnotherBankingAccount')}
          name="linkAnotherBankingAccount"
          checked={linkAnotherBankingAccount}
          onChange={handleLinkAnotherBankingAccountChange}
          disabled={!linkAnotherBankingAccount && selectedCurrencies.length >= maxBankingAccounts}
        />
      ),
    });
  }

  const isMultiHolderMode = isJointMenu(meta);
  const isJointStep = meta && meta.stepId === CONFIG.STEP_JOINT_ID;

  return (
    <div className="specific-banking-info__form">
      {isMultiHolderMode && (
        <Text size="large" className={isJointStep ? 'joint-spouse-title' : 'joint-applicant-title'}>
          {isJointStep ? getTextGlobal('spouseMenuTitle') : getTextGlobal('applicantMenuTitle')}
        </Text>
      )}

      <Title label={getText('pageTitle')} />

      {hasFERRUSD && (
        <>
          <Alert
            appearance={'information'}
            dataTest={'form-alert-FERRUSD'}
            ariaLabelIcon={getAccessibilityText('icon.information')}
            type={'h1'}
            isClosable={false}
            className="mc-mb-normal"
            description={
              <>
                <Text className="FERRUSD-banking-explanation mc-mb-xxsmall">{getText('hasFERRUSDOptionLead')}</Text>
                <ul className="FERRUSD-banking-explanation-list">
                  <li className="FERRUSD-banking-explanation-list-item">
                    <span className="FERRUSD-banking-explanation-list-item-bold">
                      {getText('hasFERRUSDOption1.intro')}
                    </span>
                    {getText('hasFERRUSDOption1')}
                  </li>
                  <li className="FERRUSD-banking-explanation-list-item">
                    <span className="FERRUSD-banking-explanation-list-item-bold">
                      {getText('hasFERRUSDOption2.intro')}
                    </span>
                    {getText('hasFERRUSDOption2')}
                  </li>
                </ul>
              </>
            }
          ></Alert>
        </>
      )}

      <Text size="lead" className="sub-title" data-test={`label_page_subTitle`}>
        {getText('intro')}
      </Text>

      {!isMultiHolderMode && (safeIdFinancialsDown || safeIdFinancialsFail) && (
        <Row>
          <Col xs={12} md={12} lg={12}>
            <Alert
              icon={<ErrorFieldFilledColor size="small" title={getAccessibilityText('alert')} />}
              title={getText(safeIdFinancialsDown ? 'safeIdDownTitle' : 'safeIdFailTitle')}
              description={getText(safeIdFinancialsDown ? 'safeIdDownDescription' : 'safeIdFailDescription')}
              appearance="error"
              className="alert-fail-warning"
            />
          </Col>
        </Row>
      )}

      {showConfirmedBankingAccounts && (
        <Row>
          <Col xs={12} md={9} lg={9} data-test="field_confirmedBankingAccounts" className="confirmed-bank-accounts">
            <Fieldset
              legend={getText('confirmedBankingAccountsTitle')}
              validate={{
                hasError: submitFailed && !!error,
                errorMsg: getText('oneBankingAccountRequired'),
              }}
            >
              <Combo id={`combo-confirmedAccount`} type="form" items={confirmedBankingAccountsCheckItems} />
            </Fieldset>
          </Col>
        </Row>
      )}

      {showBankingAccounts && (
        <Row>
          <Col xs={12} md={12} lg={12} data-test="field_bankingAccounts" className="banking-accounts-wrapper">
            <Heading type="h2" size={4} divider={false}>
              {getText('bankingAccountsTitle')}
            </Heading>
            <Collection
              data={bankingAccounts}
              change={change}
              typeComponent={BankingAccount}
              name="bankingAccounts"
              card={true}
              addLabel={getText('addBankingAccount')}
              customSchema={bankingAccountSchema}
              allRefData={allRefData}
              maxItems={maxBankingAccounts - selectedConfirmedAccounts.length}
              {...(customRefData ? { customRefData } : {})}
            />
          </Col>
        </Row>
      )}

      {showSafeIdBankingAccounts && (
        <Row>
          <Col xs={12} md={12} lg={12} data-test="field_safeIdBankingAccounts" className="banking-accounts-wrapper">
            <BankingAccountsSafeId
              safeIdFinancialExpName="safeIdFinancialExp"
              bankingAccountName="bankingAccounts"
              safeIdFinancialExpSchema={safeIdFinancialExpSchema}
              bankingAccountSchema={bankingAccountSchema}
              change={change}
              handleSubmit={handleSubmit}
              safeIdFinancialExp={safeIdFinancialExp}
              bankingAccounts={bankingAccounts}
              refData={currencyRefData}
              submitFailed={submitFailed}
              error={error}
            />
          </Col>
        </Row>
      )}

      {!showSafeIdBankingAccounts && <BankingSendCheque />}

      <Row>
        <Col xs={12} md={12} lg={12} data-test="field_programParticipation">
          <ProductFormRadioBoolean
            name="programParticipation"
            inline
            yesLabel={getTextGlobal('button.yes')}
            noLabel={getTextGlobal('button.no')}
            label={getText('programParticipation')}
            formatErrorMessage={formatErrorMessage}
            tooltip={getText('professionnels')}
          />
        </Col>
      </Row>

      {programParticipation && (
        <Row>
          <Col xs={12} md={10} lg={8} data-test="field_programPlaceholder">
            <ProductFormSelect
              name="program"
              placeholder={getText('programPlaceholder')}
              formatErrorMessage={formatErrorMessage}
              sort
              ariaLabel={getText('programPlaceholder')}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default SpecificsBankingInfo;
