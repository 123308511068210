import { ERRORTYPE } from '../../utils/validation';
import { RequestAssistantFormData, RequestAssistantValidationData } from './types';

const validation = (values: RequestAssistantFormData): RequestAssistantValidationData => {
  const { isBNCDAdvisor, requestSource, transit, employeeNo }: RequestAssistantFormData = values;

  return {
    ...(isBNCDAdvisor && !requestSource ? { requestSource: ERRORTYPE.REQUIRED } : {}),
    ...(!transit ? { transit: ERRORTYPE.REQUIRED } : {}),
    ...(!employeeNo ? { employeeNo: ERRORTYPE.REQUIRED } : {}),
  };
};

export default validation;
