import React, { PureComponent } from 'react';
import onClickOutside from 'react-onclickoutside';
import { InjectedIntl } from 'react-intl';
import { writeText } from 'clipboard-polyfill';
import { Link } from '@nbc-design/link';
import { Copy as CopyIcon } from '@nbc-design/icons/lib/web/Copy';
import { SuccessReversedColor } from '@nbc-design/icons/lib/web/SuccessReversedColor';

import './style.scss';

type CopyProps = { value: string; intl: InjectedIntl };

export class Copy extends PureComponent<CopyProps, { isCopied: boolean }> {
  constructor(props) {
    super(props);
    this.state = { isCopied: false };
  }

  componentDidUpdate() {
    if (this.state.isCopied) {
      writeText(this.props.value);
    }
  }

  handleClickOutside() {
    this.setState({ isCopied: false });
  }

  render() {
    const { intl } = this.props;
    const { isCopied } = this.state;
    return (
      <Link type="button" className="button_copy" onClick={() => this.setState({ isCopied: true })}>
        {isCopied && (
          <div className="message">
            <SuccessReversedColor title="success-reversed-color" className="info-icon" size="xsmall" />
            {intl.formatMessage({ id: 'copy.message' })}
          </div>
        )}
        <CopyIcon title={intl.formatMessage({ id: 'copy.button' })} className="copy-icon" size="small" />{' '}
        {intl.formatMessage({ id: 'copy.button' })}
      </Link>
    );
  }
}

const EnhancedCopy = onClickOutside(Copy);
export default ({ value, intl }: CopyProps) => <EnhancedCopy value={value} intl={intl} />;
