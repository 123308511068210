import { callRequest } from '../callRequestManager';
import { getBFFHeaders } from 'utils/productFormUtils';
import { HttpRequest } from 'types/interfaces';

export const getDocument = (url: string): Promise<any> => {
  const headers = getBFFHeaders();

  const config: HttpRequest = {
    url,
    method: 'GET',
    headers,
    resType: 'blob',
  };

  return callRequest<any>(config);
};
