import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { ProductFormFieldChangeHandler } from 'types/interfaces';
import { NO_REVEAL_TIN_REASON, USA_REMOVE_REASON, OTHER_RESIDENCY_ANSWERS } from './constants';
import { isObfuscatedText, ssnFormatter } from '../../utils';
import { getFormFieldValue } from 'globalRedux/store';
import { BAO_FORMS, COUNTRIES } from 'utils/constants';
import { Residency } from './types';

export const handleMainCountrySelectBoxCheck = (change: ProductFormFieldChangeHandler) => (event, value) => {
  change('mainResidency.identificationPresent', !!value);
  change('mainResidency.country', value);
  enforceUSFiscalResidency(change);
};

export const handleSecondaryCountrySelectBoxCheck = (change: ProductFormFieldChangeHandler, index) => (
  event,
  value,
) => {
  change(`secondaryResidency.${index}.identificationPresent`, !!value);
};

export const getNoRevealReasonLegalInfoText = (
  value: string,
  setNoRevealReasonLegalInfo: Dispatch<SetStateAction<string>>,
  getText: (suffix: string) => string,
) => {
  switch (value) {
    case NO_REVEAL_TIN_REASON.NOT_YET_RECEIVED:
      setNoRevealReasonLegalInfo(getText('noRevealReason.notYetReceived'));
      break;
    case NO_REVEAL_TIN_REASON.NO_TIN:
      setNoRevealReasonLegalInfo(getText('noRevealReason.noTIN'));
      break;
    case NO_REVEAL_TIN_REASON.NOT_ON_HAND:
      setNoRevealReasonLegalInfo(getText('noRevealReason.notOnHand'));
      break;
  }
};

export const handleNoRevealReasonFieldChange = (
  { target: { value } }: ChangeEvent<HTMLInputElement>,
  setNoRevealReasonLegalInfo: Dispatch<SetStateAction<string>>,
  getText: (suffix: string) => string,
): void => {
  getNoRevealReasonLegalInfoText(value, setNoRevealReasonLegalInfo, getText);
};

// The option 'Country does not emit SSN / TIN' should be filtered out when main country is USA
export const filterNoRevealReasonUSA = (value, isMainResidencyUS) =>
  !(isMainResidencyUS && value === USA_REMOVE_REASON);

export const formatSsnItin = (change: ProductFormFieldChangeHandler, event) => {
  event.preventDefault();
  const { name, value } = event.target;
  change(name, ssnFormatter(value));
};

export const clearValueIfObfuscated = (change: ProductFormFieldChangeHandler, event): void => {
  const { name, value } = event.target;

  if (isObfuscatedText(value)) {
    change(name, null);
  }
};

export const filterCountryOutOfSecondaryFiscalResidencies = (
  change: ProductFormFieldChangeHandler,
  secondaryFiscalResidencies: Residency[],
  country: COUNTRIES | undefined,
): void => {
  if (secondaryFiscalResidencies.length > 0 && country) {
    const filteredSecondaryFiscalResidencies = secondaryFiscalResidencies.filter(
      (secondaryFiscalResidency) => secondaryFiscalResidency === null || secondaryFiscalResidency.country !== country,
    );

    if (filteredSecondaryFiscalResidencies.length === 0) {
      change('isOtherResidency', '');
    }
    change('secondaryResidency', filteredSecondaryFiscalResidencies);
  }
};

export const addUSAsSecondaryFiscalResidency = (
  change: ProductFormFieldChangeHandler,
  secondaryFiscalResidencies: Residency[],
): void => {
  const isUSSelected: Residency | undefined = secondaryFiscalResidencies.find(
    (secondaryFiscalResidency) => secondaryFiscalResidency.country === COUNTRIES.US,
  );

  if (!isUSSelected) {
    change('isOtherResidency', OTHER_RESIDENCY_ANSWERS.YES);
    change('secondaryResidency', [
      {
        identificationPresent: true,
        country: COUNTRIES.US,
      },
      ...secondaryFiscalResidencies,
    ]);
  } else {
    const index: number = secondaryFiscalResidencies.findIndex(
      (secondaryFiscalResidency) => secondaryFiscalResidency === isUSSelected,
    );
    change('secondaryResidency', [
      ...secondaryFiscalResidencies.splice(index, 1),
      ...secondaryFiscalResidencies.slice(0, index),
      ...secondaryFiscalResidencies.slice(index, secondaryFiscalResidencies.length),
    ]);
  }
};

export const enforceUSFiscalResidency = (change: ProductFormFieldChangeHandler) => {
  const citizenshipUS = getFormFieldValue(BAO_FORMS, 'citizenshipUS', undefined);

  if (citizenshipUS) {
    const mainFiscalResidency: Residency = getFormFieldValue(BAO_FORMS, 'mainResidency', undefined);
    const isMainFiscalResidencyCA: boolean = getFormFieldValue(BAO_FORMS, 'isMainResidencyCA', undefined);
    const secondaryFiscalResidencies: Residency[] = getFormFieldValue(BAO_FORMS, 'secondaryResidency', []);

    if (isMainFiscalResidencyCA) {
      filterCountryOutOfSecondaryFiscalResidencies(change, secondaryFiscalResidencies, COUNTRIES.CA);
      addUSAsSecondaryFiscalResidency(change, secondaryFiscalResidencies);
    } else if (isMainFiscalResidencyCA === false && mainFiscalResidency) {
      mainFiscalResidency.country === COUNTRIES.US
        ? filterCountryOutOfSecondaryFiscalResidencies(change, secondaryFiscalResidencies, COUNTRIES.US)
        : addUSAsSecondaryFiscalResidency(change, secondaryFiscalResidencies);
    }
  }
};
