import { ConfigData } from 'types/interfaces';

const config: ConfigData = {
  ID: 'FINALISATION_STEP',
  BG_WHITE: true,
  FOOTER: true,
  PROGRESS_BAR_COUNT: true,
  BUTTON_HELP: true,
  BUTTON_LANG: true,
  BUTTON_SUBMIT: true,
  BUTTON_QUIT: true,
};

export default config;
