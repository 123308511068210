import React, { FunctionComponent } from 'react';
import { InjectedIntl } from 'react-intl';
import { getErrorProps } from '../utils';
import Modal from '../../Modal/Modal';

export type ErrorModalProps = {
  isFailure: boolean;
  productForm: { error: string; status: number };
  intl: InjectedIntl;
};

const ErrorModal: FunctionComponent<ErrorModalProps> = (props: ErrorModalProps) => {
  const { isFailure, productForm, intl } = props;

  const error = isFailure && productForm && productForm.error ? productForm : null;

  if (!error) return null;

  const { status } = error;

  const errorProps = getErrorProps(intl, status);

  return <Modal {...errorProps} name={errorProps.title} text={errorProps.text} />;
};

export default ErrorModal;
