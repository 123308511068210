import { Link } from '@nbc-design/link';
import { Popover } from '@nbc-design/popover';
import chequeEn from 'assets/images/cheque-en.png';
import chequeFr from 'assets/images/cheque-fr.png';
import { ProductFormComboRadio, ProductFormInput } from 'components/productFormFields';
import { AccountDataType } from 'containers/IdentityVerification/types';
import get from 'lodash/get';
import React, { ChangeEvent, FunctionComponent } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { InjectedIntl, injectIntl } from 'react-intl';
import { RefData, RefDataContainer, Schema } from 'types/interfaces';
import { createAddressErrorFormatter, createErrorFormatter, isFieldDisabled } from 'utils/productFormUtils';
import { getTextFactory } from 'utils/TextUtils';

import '../styles.scss';

export type BankingAccountManualProps = {
  name: string;
  input: {
    name: string;
  };
  customSchema: Schema;
  change: (key: string, value: string) => void;
  intl: InjectedIntl;
  refData?: RefData[];
  data?: AccountDataType;
};

export const getSelectableCurrencies = (currencyRefData: RefData[]): string[] => {
  return currencyRefData
    .filter((e) => {
      return !e.readOnly;
    })
    .map((e) => e.valueDomainCode);
};

const BankingAccountManual: FunctionComponent<BankingAccountManualProps> = (props: BankingAccountManualProps) => {
  const { name, input, intl, customSchema, change, refData, data } = props;

  const inputName = name ? name : input.name;

  React.useEffect(() => {
    if (data) {
      change(`${inputName}.institutionName`, data.institutionName);
      change(`${inputName}.currency`, data.currency);
      change(`${inputName}.branchNo`, data.branchNo);
      change(`${inputName}.institutionNo`, data.institutionNo);
      change(`${inputName}.accountNo`, data.accountNo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const check = { fr: chequeFr, en: chequeEn }[intl.locale];
  const getSchema = (propertyName: string): RefDataContainer => customSchema.properties[propertyName];
  const getText = getTextFactory(intl, 'specificsBankingInfo');
  const formatErrorMessage = (prefix?: string, suffix?: string) =>
    prefix === 'address' ? createAddressErrorFormatter(intl) : createErrorFormatter(intl, prefix, suffix);

  const onlyDigitOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    const {
      target: { name: targetName, value },
    } = event;

    const newVal = value.replace(/[^\d]/g, '');
    change(targetName, newVal);
  };

  const selectableCurrencies = getSelectableCurrencies(refData || []);
  if (selectableCurrencies.length === 1 && selectableCurrencies[0] !== get(data, 'currency')) {
    change(`${inputName}.currency`, selectableCurrencies[0]);
  }

  return (
    <div className="banking-account-manual">
      <Row>
        <Col xs={12} md={9} lg={9}>
          <ProductFormInput
            label={getText('institutionName')}
            name={`${inputName}.institutionName`}
            formatErrorMessage={formatErrorMessage()}
            customSchema={getSchema('institutionName')}
            disabled={isFieldDisabled(customSchema, 'institutionName')}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={9} lg={9} data-test="field_currency">
          <ProductFormComboRadio
            name={`${inputName}.currency`}
            label={getText('currency')}
            formatErrorMessage={formatErrorMessage()}
            refData={refData}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={4} lg={4}>
          <ProductFormInput
            label={getText('branchNo')}
            name={`${inputName}.branchNo`}
            formatErrorMessage={formatErrorMessage()}
            customSchema={getSchema('branchNo')}
            onChange={onlyDigitOnChange}
            helpText={getText('branchNoInfo')}
            ariaDescription={getText('branchNoInfo')}
            disabled={isFieldDisabled(customSchema, 'branchNo')}
          />
        </Col>
        <Col xs={12} md={4} lg={4}>
          <ProductFormInput
            label={getText('institutionNo')}
            name={`${inputName}.institutionNo`}
            formatErrorMessage={formatErrorMessage()}
            customSchema={getSchema('institutionNo')}
            onChange={onlyDigitOnChange}
            helpText={getText('institutionNoInfo')}
            ariaDescription={getText('institutionNoInfo')}
            disabled={isFieldDisabled(customSchema, 'institutionNo')}
          />
        </Col>
        <Col xs={12} md={4} lg={4}>
          <ProductFormInput
            label={getText('accountNo')}
            name={`${inputName}.accountNo`}
            formatErrorMessage={formatErrorMessage()}
            customSchema={getSchema('accountNo')}
            onChange={onlyDigitOnChange}
            helpText={getText('accountNoInfo')}
            ariaDescription={getText('accountNoInfo')}
            disabled={isFieldDisabled(customSchema, 'accountNo')}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={12} data-test="field_checkDetails" className="check-details">
          <Popover
            content={
              <div className="check-details__content">
                <span>{getText('checkDetailsDescription')}</span>
                <img className="specimen-check" src={check} alt="specimen check" />
              </div>
            }
            title={getText('pageTitle')}
            position="top"
          >
            <Link href="#" underlined={false}>
              {getText('checkDetailsLinkText')}
            </Link>
          </Popover>
        </Col>
      </Row>
    </div>
  );
};

export default injectIntl(BankingAccountManual);
