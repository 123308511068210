export const checkStatus = (response: Response): Response => {
  if (response.ok) {
    return response;
  }

  const error: Error = new Error(response.statusText);
  error['response'] = response.json();
  throw error;
};

export const parseJson = (response: Response): Promise<any> => response.json();
