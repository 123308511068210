import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import './styles.scss';
import { useContainerCtx } from 'providers/containerCtx/ContainerCtx';
import { BaoDrilledProps } from 'types/interfaces';
import { Button } from '@nbc-design/button';
import { FundBuy } from '@nbc-design/icons/lib/web/FundBuy';
import BeneficiaryModal from './components/BeneficiaryModal';
import { BeneficiaryFormValues, BeneficiaryInformationValidationReturnType, OperationMode } from './types';
import BeneficiaryListing from './components/BeneficiaryListing';
import RadioTrusteeOnBehalf from './components/RadioTrusteeOnBehalf';
import { useBeneficiaryHelpers } from './hooks/useBeneficiaryHelpers';
import validation from './validation';
import { useBeneficiaryInformationCtx } from './providers/BeneficiaryInformationCtx';
import { getAllFieldNames, getBeneficiariesNotDeleted } from './utils';
import { NEW_BENEFICIARY_KEYWORD } from './constant';
import { ERRORTYPE } from 'utils/validation';
import { Alert } from '@nbc-design/alert';
import { Heading } from '@nbc-design/heading';
import { Text } from '@nbc-design/text';
import { RespPlanTypeCd } from 'containers/AccountsStep3/types';

export type BeneficiaryInformationProps = BaoDrilledProps & BeneficiaryFormValues;

const BeneficiaryInformation: FunctionComponent<BeneficiaryInformationProps> = () => {
  const { formValues, getText, getTextGlobal, bncReactForm } = useContainerCtx<BeneficiaryFormValues>();
  const { isIndividualOnlyBeneficiary, areAllProfilesCompleted } = useBeneficiaryHelpers();
  const { formData, beneficiaries, account } = formValues;
  const { change, untouch, touch, reset, error, submitFailed } = bncReactForm;
  const {
    beneficiaryFormIndex,
    isFormModalOpen,
    setCurrentBeneficiary,
    setBeneficiaryToEdit,
    setBeneficiaryFormIndex,
    setIsFormModalOpen,
  } = useBeneficiaryInformationCtx();

  const handleCloseModal = () => {
    setIsFormModalOpen(false);
    setCurrentBeneficiary(undefined);
    setBeneficiaryToEdit(undefined);
    setBeneficiaryFormIndex(undefined);
    resetFormValidation();
  };

  const displayRequiredError = submitFailed && error?.beneficiaries?.type === ERRORTYPE.REQUIRED.type;
  const displayContributionsError = error?.contribution?.type === ERRORTYPE.PATTERN.type;

  const allFieldNames = getAllFieldNames();

  const resetFormValidation = () => {
    untouch(...allFieldNames);
    change('formData', null);
  };

  const resetForm = () => {
    const _beneficiaries = beneficiaries;
    reset();
    change('beneficiaries', _beneficiaries);
  };

  const handleSubmitModalForm = () => {
    touch(...allFieldNames);

    const validationResponse: BeneficiaryInformationValidationReturnType = validation(formValues);
    if (formData && !validationResponse?.formData) {
      if (beneficiaryFormIndex !== undefined && formData && beneficiaries[beneficiaryFormIndex] !== formData) {
        let updatedBeneficiaries = beneficiaries;
        updatedBeneficiaries[beneficiaryFormIndex] = formData;
        updatedBeneficiaries[beneficiaryFormIndex].hasIncompleteInfo = false;
        if (!formData.bncId || formData.bncId === NEW_BENEFICIARY_KEYWORD) {
          updatedBeneficiaries[beneficiaryFormIndex].bncId = null;
          updatedBeneficiaries[beneficiaryFormIndex].operationMode = OperationMode.CREATE;
        } else {
          updatedBeneficiaries[beneficiaryFormIndex].operationMode = OperationMode.MODIFY;
        }

        change(`beneficiaries`, updatedBeneficiaries);
      }
      handleCloseModal();
    }
  };

  return (
    <div id="beneficiary_form">
      <Heading type="h1" size={1} className="mc-pb-xsmall md:mc-pb-normal" data-test={'label_page_title_title'}>
        {getText('pageTitle')}
      </Heading>

      <Helmet>
        <title>{`${getText('pageTitle')} - ${getTextGlobal('BAO')}`}</title>
      </Helmet>

      {(!areAllProfilesCompleted || displayContributionsError) && (
        <Alert
          appearance="error"
          title={getText('alert.profileIncomplete')}
          className={'mc-mb-normal'}
          dataTest={'alert-profile-error'}
        />
      )}

      {displayRequiredError && (
        <Alert
          appearance="error"
          title={getText('alert.beneficiaryRequired')}
          className={'mc-mb-normal'}
          dataTest={'alert-beneficiary-required-error'}
        />
      )}

      <Text size="lead" className="sub-title" data-test="label_page_title_subTitle">
        {getText('subTitle')}
      </Text>

      <BeneficiaryListing displayContributionsError={displayContributionsError} />

      {isIndividualOnlyBeneficiary && (
        <RadioTrusteeOnBehalf
          index={beneficiaries.findIndex((beneficiary) => beneficiary.operationMode !== OperationMode.DELETE)}
        />
      )}

      <Button
        data-test="button-open-modal-new-beneficiary-form"
        appearance="secondary"
        disabled={
          account?.respPlanTypeCd === RespPlanTypeCd.INDIVIDUAL && getBeneficiariesNotDeleted(beneficiaries).length > 0
        }
        children={getText('addBeneficiary')}
        icon={<FundBuy size={'small'} />}
        onClick={() => {
          setBeneficiaryToEdit(undefined);
          setIsFormModalOpen(true);
        }}
      />

      {isFormModalOpen && (
        <BeneficiaryModal
          handleSubmitForm={() => {
            handleSubmitModalForm();
          }}
          handleCloseModal={() => {
            handleCloseModal();
          }}
          resetFormValidation={() => {
            resetFormValidation();
          }}
          resetForm={() => {
            resetForm();
          }}
        />
      )}
    </div>
  );
};

export default BeneficiaryInformation;
