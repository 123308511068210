import React from 'react';
import { Provider } from 'react-redux';
import { addLocaleData } from 'react-intl';
import { IntlProvider } from 'react-intl-redux';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';

import { BAOStore } from 'types/reducers';
import configs from 'configs';
import { getLocale } from 'utils';
import { getCurrentEnv } from 'services/baoLoginManager';
import { isAuthenticatedWith, OKTA_AUTHENT_TYPE } from 'services/loginManager';
import { checkAccessTokenValidity } from 'services/ssoRedirectService/oktaService';
import App from '../App';

export type MainProps = {
  store: BAOStore;
};

export type MainState = {
  isReady: boolean;
};

class Main extends React.Component<MainProps, MainState> {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
    };
  }

  componentDidMount() {
    addLocaleData([...en, ...fr]);
    document.documentElement.setAttribute('lang', getLocale());

    getCurrentEnv()
      .then((currentEnv) => configs.init(currentEnv))
      .then(() => {
        // check for existing, valid stored Okta credentials
        // the token will be stored automatically if it's renewed this way
        // continue unauthenticated if there's no valid access token
        return isAuthenticatedWith(OKTA_AUTHENT_TYPE) ? checkAccessTokenValidity(getLocale()) : null;
      })
      .then(() => {
        this.setState({ isReady: true });
      });
  }

  render() {
    if (!this.state.isReady) {
      return null;
    }

    return (
      <Provider store={this.props.store}>
        <IntlProvider>
          <App />
        </IntlProvider>
      </Provider>
    );
  }
}

export default Main;
