import React, { FunctionComponent } from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { Sidebar } from '@nbc-design/sidebar';
import { StepsType } from '@nbc-design/sidebar/dist/types';
import { Text } from '@nbc-design/text';

import { AppStep } from 'types/interfaces';
import { getAllIndividualSteps, getGivenStep } from '../utils';
import { getTextFactory } from 'utils/TextUtils';
import {
  APPLICANT_STEP_ID,
  APPLICANT_STEPS,
  APPLICANT_STEPS_IDS_LIST,
  BFF_STEPS_IDS,
  SPOUSE_STEP_ID,
  SPOUSE_STEPS,
  SPOUSE_STEPS_IDS_LIST,
} from 'utils/stepsConstants';
import { initialize } from 'redux-form';
import { BAO_FORMS } from '../../../utils/constants';

export type MenuProps = {
  intl: InjectedIntl;
  requestCode: string;
  userName?: string;
  currentStepId?: string;
  goToStep?: (id: string) => void;
  isHierarchical: boolean;
  isJointMenu: boolean;
  isBlocked?: boolean;
  setUpdateOfPersonalInfosForm: Function;
  dispatch: Function;
};

const Menu: FunctionComponent<MenuProps> = (props: MenuProps) => {
  const {
    currentStepId = '',
    intl,
    requestCode,
    userName = '',
    goToStep,
    isHierarchical,
    isJointMenu,
    isBlocked,
    setUpdateOfPersonalInfosForm,
  } = props;
  const getTextGlobal = getTextFactory(intl, 'global');
  const getTextMenu = getTextFactory(intl, 'leftMenu');

  const handleMenuClick = (step: AppStep) => (): void => {
    props.dispatch(initialize(BAO_FORMS, {}));
    goToStep && step.ID && goToStep(step.ID);
  };

  const isCurrentStep = (step: AppStep): boolean => {
    if (currentStepId === step.ID) {
      return true;
    }

    return (step.subSteps || []).some(isCurrentStep);
  };

  const getMenuChoices = (steps: AppStep[], flat: boolean, parentID?: string): StepsType[] => {
    if (steps.length === 0) {
      return [];
    }

    return steps
      .filter((step) => !!step.ID)
      .map(
        (step: AppStep): StepsType => {
          const { subSteps = [], ID = '' } = step;

          const hierarchicalId = `${parentID ? `${parentID}.` : ''}${ID}`;
          const label = getTextMenu(`step.${hierarchicalId}`);
          return {
            id: ID,
            label,
            onClick: handleMenuClick(step),
            isCurrent: isCurrentStep(step),
            submenu: flat ? undefined : getMenuChoices(subSteps, false, hierarchicalId),
          };
        },
      );
  };

  const identification = {
    label: userName ? getTextGlobal('welcome') : '',
    name: userName,
  };

  const makeMenuSection = (role: string, subMenuContent: StepsType[]): StepsType[] => {
    const isSpouse = role === 'spouse';
    const isSectionActive = isSpouse
      ? SPOUSE_STEPS_IDS_LIST.includes(currentStepId as SPOUSE_STEP_ID)
      : APPLICANT_STEPS_IDS_LIST.includes(currentStepId as APPLICANT_STEP_ID);

    return [
      {
        id: `${role}MenuSection`,
        label: getTextMenu(`${role}MenuTitle`),
        isCurrent: isSectionActive,
        onClick: async () => {
          await setUpdateOfPersonalInfosForm();

          goToStep && goToStep(isSpouse ? BFF_STEPS_IDS.PERSONALINFORMATIONJOINT : BFF_STEPS_IDS.PERSONALINFORMATION);
        },
        submenu: subMenuContent,
      },
    ];
  };

  const getJointMenuChoices = (): StepsType[] => {
    const commonStartSteps: AppStep[] = getGivenStep('ACCOUNT_STEP_ONE', currentStepId);
    const commonStartMenuChoices: StepsType[] = getMenuChoices(commonStartSteps, true);

    /* Applicant section */
    const applicantSteps: StepsType[] = getMenuChoices(APPLICANT_STEPS, true);
    const applicantMenuChoices: StepsType[] = makeMenuSection('applicant', applicantSteps);

    /* Spouse section */
    const spouseSteps: StepsType[] = getMenuChoices(SPOUSE_STEPS, true);
    const spouseMenuChoices: StepsType[] = makeMenuSection('spouse', spouseSteps);

    const transferSteps: AppStep[] = getGivenStep('TRANSFER_STEP', currentStepId);

    const regulatorySteps: AppStep[] = getGivenStep('REGULATOR_STEP_ONE', currentStepId);
    const summarySteps: AppStep[] = getGivenStep('SUMMARY_STEP', currentStepId);
    const documentsSteps: AppStep[] = getGivenStep('DOCUMENTS_STEP', currentStepId);

    const commonEndSteps: AppStep[] = [...transferSteps, ...regulatorySteps, ...summarySteps, ...documentsSteps];
    const commonEndMenuChoices: StepsType[] = getMenuChoices(commonEndSteps, true);

    return [...commonStartMenuChoices, ...applicantMenuChoices, ...spouseMenuChoices, ...commonEndMenuChoices];
  };

  const getIndividualMenuChoices = (): StepsType[] => {
    const allIndividualSteps: AppStep[] = getAllIndividualSteps(currentStepId);
    return getMenuChoices(allIndividualSteps, !isHierarchical);
  };

  const menu: StepsType[] = !isJointMenu ? getIndividualMenuChoices() : getJointMenuChoices();

  const blockPreviousSteps = {
    isBlocked: !!isBlocked,
    tooltipText: getTextMenu('isBlockedStep'),
  };

  const getAccessibilityText = getTextFactory(intl, 'accessibility.sidebar');

  const svgTitles = {
    success: getAccessibilityText('completed'),
    lock: getAccessibilityText('locked'),
    open: getAccessibilityText('open'),
    close: getAccessibilityText('close'),
  };

  return menu ? (
    <Sidebar
      title={
        <span className="wrapper-request-number">
          <span>{getTextGlobal('yourDemand')}</span>
          <Text size="small" type="span">
            {requestCode}
          </Text>
        </span>
      }
      identification={identification}
      menu={menu}
      blockPreviousSteps={blockPreviousSteps}
      svgTitles={svgTitles}
    />
  ) : null;
};

export default injectIntl(Menu);
