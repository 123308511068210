export default {
  ID: 'IDENTITY_CHECK_STEP_TWO',
  SIDEBAR_MENU: true,
  PROGRESS_BAR_COUNT: true,
  PROGRESS_BAR: true,
  FOOTER: true,
  BUTTON_HELP: true,
  BUTTON_LANG: true,
  BUTTON_QUIT: true,
  BUTTON_CONTINUE: true,
};
