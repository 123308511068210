import { ERRORTYPE } from '../../utils/validation';
import { isNullOrUndefined } from '../../utils';
import { BeneficiaryErrorData, BeneficiaryFormData } from './types';

export const validateBeneficiary = (values: BeneficiaryFormData): BeneficiaryErrorData => {
  const { beneficiary, successor } = values;

  if (beneficiary && isNullOrUndefined(successor)) {
    return { successor: ERRORTYPE.REQUIRED };
  }

  return {};
};

const validation = (values: BeneficiaryFormData): BeneficiaryErrorData => {
  return {
    ...validateBeneficiary(values),
  };
};

export default validation;
