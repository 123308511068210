import { isNil } from 'lodash';
import { RefData } from '../../types/interfaces';
import { PaymentDetail, PaymentTypeCd, PaymentDetailFieldTypes } from './types';

export const filterPaymentTypes = (refData: RefData[], paymentTypes: PaymentTypeCd[]): RefData[] =>
  refData?.filter((ref: RefData) => paymentTypes?.includes(ref.valueDomainCode as PaymentTypeCd));

export const syncPaymentFields = (
  fields: string[],
  payments: PaymentDetail[],
  change: Function,
  sourceIndex: number,
  targetIndex: number,
) => {
  fields.forEach((field) => {
    const key = field as keyof PaymentDetail;
    const sourceValue: PaymentDetailFieldTypes = payments[sourceIndex]?.[key];
    const targetValue: PaymentDetailFieldTypes = payments[targetIndex]?.[key];
    if (sourceValue !== targetValue) {
      change(`payments[${targetIndex}].${key}`, sourceValue);
    }
  });
};

export const initializeSplitPercentage = (
  fieldName: string,
  payments: PaymentDetail[],
  change: Function,
  sourceIndex: number,
  targetIndex: number,
) => {
  const sourceValue = payments[sourceIndex]?.[fieldName];
  const targetValue = payments[targetIndex]?.[fieldName];

  if (!isNil(sourceValue)) {
    const sourceValueCast: number = Number(sourceValue);
    const finalValue: number = Math.max(0, Math.min(100, sourceValueCast));
    if (!isNaN(finalValue)) {
      change(`payments.[${sourceIndex}].splitPercentage`, finalValue);
      change(`payments.[${targetIndex}].splitPercentage`, 100 - finalValue);
    } else {
      change(`payments.[${sourceIndex}].splitPercentage`, null);
      change(`payments.[${targetIndex}].splitPercentage`, null);
    }
  } else if (!isNil(targetValue)) {
    const targetValueCast: number = Number(targetValue);
    const finalTargetValue: number = Math.max(0, Math.min(100, targetValueCast));
    if (!isNaN(finalTargetValue)) {
      change(`payments.[${sourceIndex}].splitPercentage`, 100 - finalTargetValue);
      change(`payments.[${targetIndex}].splitPercentage`, finalTargetValue);
    } else {
      change(`payments.[${sourceIndex}].splitPercentage`, null);
      change(`payments.[${targetIndex}].splitPercentage`, null);
    }
  } else {
    change(`payments.[${sourceIndex}].splitPercentage`, null);
    change(`payments.[${targetIndex}].splitPercentage`, null);
  }
};
