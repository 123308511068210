import { injectIntl } from 'react-intl';
import { formValues } from 'bnc-react-forms';
import RequestAssistant from './RequestAssistant';
import config from './config';
import * as validation from './validation';

export const VALIDATION = validation;
export const CONFIG = config;

export default injectIntl(formValues('isBNCDAdvisor')(RequestAssistant));
