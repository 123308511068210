import React from 'react';
import { useContainerCtx } from '../../../providers/containerCtx/ContainerCtx';
import { BeneficiaryFormValues } from '../types';
import DialogDeleteConfirmation from './DialogDeleteConfirmation';
import BeneficiaryListingItem from './BeneficiaryListingItem';
import { Text } from '@nbc-design/text';
import { useBeneficiaryHelpers } from '../hooks/useBeneficiaryHelpers';
import { useBeneficiaryInformationCtx } from '../providers/BeneficiaryInformationCtx';
import { getBeneficiariesNotDeleted } from '../utils';

type BeneficiaryListingProps = { displayContributionsError: boolean };

const BeneficiaryListing: React.FC<BeneficiaryListingProps> = ({ displayContributionsError }) => {
  const { getText, formValues } = useContainerCtx<BeneficiaryFormValues>();
  const { beneficiaryToDelete, setBeneficiaryToDelete } = useBeneficiaryInformationCtx();
  const { calculatedTotalContribution, isFamilyRespPlanType, areAllContributionFieldsEmpty } = useBeneficiaryHelpers();
  const { beneficiaries } = formValues;

  if (!beneficiaries || beneficiaries.length === 0) return null;
  return (
    <div className={'mc-mb-large'} data-test={'beneficiary-listing'}>
      <div className={'mc-mb-normal'}>
        {getBeneficiariesNotDeleted(beneficiaries)?.map((beneficiary) => (
          <BeneficiaryListingItem
            key={`${beneficiary.bncId} ${beneficiary.firstName} ${beneficiary.lastName}`}
            beneficiary={beneficiary}
          />
        ))}

        {!!beneficiaryToDelete && (
          <DialogDeleteConfirmation
            beneficiaryToDelete={beneficiaryToDelete}
            dialogProps={{ onDialogClose: () => setBeneficiaryToDelete(undefined) }}
          />
        )}
      </div>

      {isFamilyRespPlanType && !areAllContributionFieldsEmpty && (
        <div data-test={'contribution-infos'}>
          <Text className={`mc-mb-xxsmall${displayContributionsError && ' contribution-error-red'}`}>
            <strong>
              {getText('totalContributions')} {calculatedTotalContribution}%
            </strong>
          </Text>
          <Text>{getText('totalContributionText')}</Text>
        </div>
      )}
    </div>
  );
};

export default BeneficiaryListing;
