import React, { FunctionComponent, Fragment } from 'react';
import { Fieldset } from '@nbc-design/fieldset';
import { Radio } from '@nbc-design/radio';
import { Text } from '@nbc-design/text';
import cx from 'classnames';
import { FormComponentProps, FormFieldProps } from '../types';
import { getLabelText, getErrorMessage, isInvalidOnChange } from '../utils';
import { getTextFactory } from 'utils/TextUtils';
import AnalyticsHelper from 'services/analytics/AnalyticsHelper';

import './style.scss';

export type ProductFormRadioBooleanProps = {
  yesLabel?: string;
  noLabel?: string;
  yesText?: string;
  noText?: string;
  switchMode?: boolean;
  inline?: boolean;
  tooltip?: React.ReactNode;
};

type DrilledProductFormRadioBooleanProps = FormComponentProps & FormFieldProps<ProductFormRadioBooleanProps>;

const ProductFormRadioBoolean: FunctionComponent<DrilledProductFormRadioBooleanProps> = (props) => {
  const {
    input,
    intl,
    yesLabel,
    noLabel,
    yesText,
    noText,
    helpText,
    tooltip,
    schema,
    isReadOnly,
    inline,
    className,
    meta,
  } = props;

  const invalid = isInvalidOnChange(props) || (meta.touched && !!meta.error);
  const label = getLabelText(props);
  const getAccessibilityText = getTextFactory(intl, 'accessibility');
  const onShowTooltip = AnalyticsHelper.handleShowTooltip(input.name);

  const handleChange = (nextValue: boolean) => {
    if (input.onChange) {
      input.onChange(nextValue);
    }
  };

  const yesRadio = (
    <Radio
      id={`radio-yes-${input.name}`}
      label={yesLabel || (schema && schema.yesLabel) || 'Yes'}
      text={yesText}
      name={input.name}
      checked={input.value === true}
      onChange={() => handleChange(true)}
    />
  );

  const noRadio = (
    <Radio
      id={`radio-no-${input.name}`}
      label={noLabel || (schema && schema.noLabel) || 'No'}
      text={noText}
      name={input.name}
      checked={input.value === false}
      onChange={() => handleChange(false)}
    />
  );

  const radios = inline ? (
    <div className="radio-inline">
      {yesRadio}
      {noRadio}
    </div>
  ) : (
    <Fragment>
      {yesRadio}
      {noRadio}
    </Fragment>
  );

  return (
    <Fieldset
      legend={label}
      tooltip={{ content: tooltip, ariaLabel: getAccessibilityText('information'), onShowTooltip }}
      validate={{ hasError: invalid, errorMsg: getErrorMessage(props) || '' }}
      description={{ text: helpText || '', id: 'dscFieldset__description' }}
      className={cx('radio-label', className)}
    >
      {isReadOnly ? <Text type={'p'}>{label}</Text> : radios}
    </Fieldset>
  );
};

export default ProductFormRadioBoolean;
