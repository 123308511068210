import { put, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import OccupationTitleKeys from 'services/OccupationTitleService/actionTypes';
import { OccupationTitleConfig } from 'types/interfaces';
import { AnyAction } from 'redux';

import * as actions from './actions';
import { getOccupationTitle } from './api';

export const DEBOUNCE_TIME = 300;

export default function createOccupationTitleService(config: OccupationTitleConfig) {
  const { endpoint, method, headers, callEffect, onError, debounce = DEBOUNCE_TIME } = config;

  if (!endpoint) {
    throw new Error('Occupation API endpoint is required.');
  }

  if (typeof callEffect !== 'function') {
    throw new Error('API call helper function is required. This is usually provided by utils/requestManager.');
  }

  if (typeof onError !== 'function') {
    throw new Error(
      'Error propagator function is required. This is usually provided by utils/propagate-global-errors.',
    );
  }

  function* getOccupationTitleSaga(action: AnyAction) {
    if (debounce > 0) {
      // debounce requests
      yield delay(debounce);
    }

    const { search, options } = action;

    try {
      const occupationTitleList = yield callEffect &&
        callEffect(
          getOccupationTitle,
          {
            endpoint,
            method,
            headers,
            ...options,
          },
          search,
        );
      yield put(actions.occupationTitleServiceListRequestSucceeded(occupationTitleList, options));
    } catch (err) {
      onError && onError(err);
      yield put(actions.occupationTitleServiceListRequestFailed(err, options));
    }
  }

  return function* rootSaga() {
    yield takeLatest(OccupationTitleKeys.OCCUPATION_TITLE_SERVICE_LIST_REQUESTED, getOccupationTitleSaga);
  };
}
