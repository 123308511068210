import React, { PureComponent } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { getAccountsSlice } from '../selectors';
import { AccountData, AccountsSlice } from '../types';
import { getTextFactory } from 'utils/TextUtils';
import { InjectedIntl, injectIntl } from 'react-intl';
import SummarySection from './SummarySection';
import { BFF_STEPS_IDS } from 'utils/stepsConstants';
import SummaryField from './SummaryField';
import SummaryFieldLabel from './SummaryFieldLabel';
import SummaryFieldValue from './SummaryFieldValue';
import { ACCOUNT_TYPE } from '../../AccountsStep1/constants';

export type AccountsSectionPropsFromState = {
  accountsSlice: AccountsSlice;
};

export type AccountsSectionProps = {
  goToStep: (stepId: string) => string;
  intl: InjectedIntl;
};

export class AccountsSection extends PureComponent<AccountsSectionProps & AccountsSectionPropsFromState> {
  getSummaryText = getTextFactory(this.props.intl, 'summary.accounts');

  getClientType = (bffClientType: string): string => {
    if (bffClientType === 'INDIV') {
      return this.getSummaryText('clientType.indiv');
    } else if (bffClientType === 'JOINT') {
      return this.getSummaryText('clientType.joint');
    } else if (bffClientType === 'JTWROS') {
      return this.getSummaryText('clientType.joint.survivorship');
    }

    return '';
  };

  getHasOption = (bffHasOption: boolean): string => {
    return this.getSummaryText(bffHasOption ? 'hasOptions.yes' : 'hasOptions.no');
  };

  renderAccountRow = (account: AccountData, index: number) => {
    const locale = this.props.intl.locale;

    let accountModeDisplay = '';
    if (account.acctType.code === ACCOUNT_TYPE.RESP && account.respPlanType) {
      accountModeDisplay = `${account.respPlanType[locale]}`;
    }

    let accountLocationDisplay = '';
    if (account.locationType) {
      if (account.locationType === 'PAISO') {
        accountLocationDisplay = `(${this.getSummaryText('federal')})`;
      } else if (account.locationType === 'PRISO' && account.location) {
        accountLocationDisplay = `(${account.location[locale]})`;
      }
    }

    const accountTypeDisplay = `${account.acctType[locale]} ${accountModeDisplay} ${account.currency} ${accountLocationDisplay}`;
    const accountUsageDisplay = account.acctUsage ? `${account.acctUsage[locale]}` : '';
    const dataTest = `${account.acctType.code}_${account.currency.replace('$ ', '')}`;

    return (
      <Row key={index} data-test={dataTest}>
        <Col xs={6} md={6} xl={6} data-test="account">
          <SummaryFieldValue>{accountTypeDisplay}</SummaryFieldValue>
        </Col>
        <Col xs={6} md={6} xl={6} data-test="utilisation">
          <SummaryFieldValue>{accountUsageDisplay}</SummaryFieldValue>
        </Col>
      </Row>
    );
  };

  renderContent = () => {
    const { accountsSlice } = this.props;

    if (!accountsSlice) {
      return null;
    }

    const { promoCode, siblingBenInd } = accountsSlice;

    const clientType = this.getClientType(accountsSlice.clientType);
    const hasOption = this.getHasOption(accountsSlice.hasOptions);
    const familySiblingYesAnswer = this.getSummaryText(
      siblingBenInd ? 'isFamilySiblingYesAnswer' : 'isFamilySiblingNoAnswer',
    );

    return (
      <div className="accounts-recap-content">
        <Row>
          <Col xs={12} md={12} xl={12}>
            <SummaryField dataTest="accounts_clientType" label={this.getSummaryText('clientType')}>
              {clientType}
            </SummaryField>
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={6} xl={6}>
            <SummaryFieldLabel>{this.getSummaryText('acctType')}</SummaryFieldLabel>
          </Col>
          <Col xs={6} md={6} xl={6}>
            <SummaryFieldLabel>{this.getSummaryText('acctUsage')}</SummaryFieldLabel>
          </Col>
        </Row>

        {accountsSlice.accounts.map(this.renderAccountRow)}

        {siblingBenInd !== undefined && (
          <Row>
            <Col xs={12} md={6} xl={6}>
              <SummaryField dataTest="accounts_siblingBenInd" label={this.getSummaryText('isFamilySiblingLabel')}>
                {familySiblingYesAnswer}
              </SummaryField>
            </Col>
          </Row>
        )}

        <Row>
          <Col xs={12} md={6} xl={6}>
            <SummaryField dataTest="accounts_hasOptions" label={this.getSummaryText('hasOptions')}>
              {hasOption}
            </SummaryField>
          </Col>
          <Col xs={12} md={6} xl={6}>
            <SummaryField dataTest="accounts_promotionCd" label={this.getSummaryText('promotionCd')}>
              {promoCode}
            </SummaryField>
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    const { goToStep, intl, accountsSlice } = this.props;

    return (
      <SummarySection
        intl={intl}
        name="accounts"
        title={this.getSummaryText('pageTitle')}
        stepId={BFF_STEPS_IDS.ACCOUNTSSTEP1}
        goToStep={goToStep}
        renderSectionContent={this.renderContent}
        editable={accountsSlice && accountsSlice.editable}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accountsSlice: getAccountsSlice(state),
  };
};

export default connect<AccountsSectionPropsFromState, {}, AccountsSectionProps>(mapStateToProps)(
  injectIntl(AccountsSection),
);
