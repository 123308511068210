import React, { FunctionComponent } from 'react';
import { InjectedIntl } from 'react-intl';
import { version } from '../../../package.json';
import { getTextFactory } from 'utils/TextUtils';
import AppVersion from 'components/AppVersion';
import './style.scss';

export type FooterProps = {
  intl: InjectedIntl;
};

const Footer: FunctionComponent<FooterProps> = (props: FooterProps) => {
  const { intl } = props;

  const getText = getTextFactory(intl, 'global');

  return (
    <div className="footer">
      <AppVersion version={version} />
      <div className="copyright" data-test="footer-copyright">
        {getText('copyright')}
      </div>
    </div>
  );
};

export default Footer;
