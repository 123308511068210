export enum EVENT_IDS {
  // FS - only for the first step
  FS = 'FS',
  // ST - all the step except the first and last one
  ST = 'ST',
  // FF - last step
  FF = 'FF',
  // RB - business logic error
  RB = 'RB',
  // FE - Form Error
  FE = 'FE',
  // HD - Help Displayed (tooltip, popover, etc...)
  HD = 'HD',
  // TE - Technical Error
  TE = 'TE',
  // LB - Lightbox
  LB = 'LB',
  // CCTA - Click CTA
  CCTA = 'CCTA',
}

export enum EVENT_NAME {
  PAGE_LOAD = 'pageLoad',
  FORM_ERROR = 'formError',
  ROAD_BLOCK = 'roadBlock',
  FIELD_FILLED = 'fieldFilled',
  PAGE_ERROR = 'technicalError',
  HELP_DISPLAYED = 'helpDisplayed',
  LIGHTBOX = 'lightbox',
  CLICK = 'clickCTA',
}

export enum EVENT_TYPE {
  TRACK_PAGE_VIEW = 'trackPageView',
  TRACK_EVENT = 'trackEvent',
}
