import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import { InjectedIntl } from 'react-intl';
import { StockSymbol, StockSymbolOption } from 'types/reducers';
import { FormComponentProps } from '../types';
import { Autocomplete } from '@nbc-design/autocomplete';
import { FormGroup } from '@nbc-design/form-group';
import { Text } from '@nbc-design/text';
import { getErrorMessage, isInvalidOnChange } from '../utils';
import { getTextFactory } from 'utils/TextUtils';
import AnalyticsHelper from 'services/analytics/AnalyticsHelper';

export type ProductFormStockSymbolSelectProps = FormComponentProps & {
  intl: InjectedIntl;
  searchStockSymbol: Function;
  stockSymbolList: StockSymbol[];
  isLoading: boolean;
  tooltip?: React.ReactNode;
};

const ProductFormStockSymbolSelect: FunctionComponent<ProductFormStockSymbolSelectProps> = (
  props: ProductFormStockSymbolSelectProps,
) => {
  const {
    intl,
    input,
    isLoading,
    helpText,
    tooltip,
    placeholder,
    isReadOnly,
    searchStockSymbol,
    stockSymbolList = [],
    className,
  } = props;

  const formatStockSymbol = (symbol: StockSymbol): string | null => {
    if (!symbol || !symbol.marketSymbol) {
      return null;
    }

    return [symbol.marketSymbol.symbolCd, symbol.displayDesc].filter((x) => x).join(' - ');
  };

  const handleInputChange = (event: string): void => {
    const { name } = input;
    handleChange(null);
    searchStockSymbol(event, { name });
  };

  const handleChange = (suggestion: { data?: unknown } | null): void => {
    if (suggestion && suggestion.data) {
      input.onChange && input.onChange(suggestion.data);
    } else {
      input.onChange && input.onChange(null);
    }
  };

  const getSelectOptions = (): StockSymbolOption[] => {
    let stockSymbolOptions: StockSymbol[] = [];

    // Always append the current value so we can render it again when cancelling the dropdown
    if (stockSymbolList && stockSymbolList.length > 0) {
      stockSymbolOptions = [...stockSymbolList, input.value];
    } else if (input.value) {
      stockSymbolOptions = [input.value];
    }

    return (
      (stockSymbolOptions.length > 0 &&
        stockSymbolOptions.map(
          (symbol: StockSymbol, index: number): StockSymbolOption => ({
            label: (symbol && formatStockSymbol(symbol)) || '',
            value: `${index}`,
            data: symbol,
          }),
        )) ||
      []
    );
  };

  const invalid = isInvalidOnChange(props);
  const formattedSymbols = formatStockSymbol(input.value);
  const options = getSelectOptions();
  const selected = formatStockSymbol(input.value) || '';
  const getAccessibilityText = getTextFactory(intl, 'accessibility');
  const onShowTooltip = AnalyticsHelper.handleShowTooltip(input.name);

  return (
    <FormGroup
      className={className}
      tooltip={{ content: tooltip, ariaLabel: getAccessibilityText('information'), onShowTooltip }}
      validate={{ hasError: invalid, errorMsg: getErrorMessage(props) }}
      description={{ text: helpText, id: `help-${input.name}` }}
    >
      {isReadOnly ? (
        <Text type={'span'}>{formattedSymbols}</Text>
      ) : (
        <Autocomplete
          id={`symbol-autocomplete-${input.name}`}
          name={input.name}
          value={selected}
          suggestions={options}
          isLoading={isLoading}
          placeholder={placeholder}
          onSelect={handleChange}
          onChange={handleInputChange}
          className={cx({ error: invalid })}
        />
      )}
    </FormGroup>
  );
};

export default ProductFormStockSymbolSelect;
