import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import { Props as TextProps, Text } from '@nbc-design/text';

export type HelpTextProps = TextProps & {
  className?: string;
  children: React.ReactNode;
};

const HelpText: FunctionComponent<HelpTextProps> = ({ dataTest = 'help_text', className = '', children, ...rest }) => {
  return (
    <Text type={'p'} size={'small'} dataTest={dataTest} className={cx('help-text', className)} {...rest}>
      {children}
    </Text>
  );
};

export default HelpText;
