import BaoServiceKeys from 'services/baoService/actionTypes';
import { BaoReducerState } from 'types/reducers';
import { AnyAction } from 'redux';
import { BaoActionTypes, SetUserCredentialsAction, SetUserTypeAction } from 'types/actions';

export const initialState: BaoReducerState = {
  productId: null,
  packageId: null,
  progress: 0,
  isAuthenticated: false,
  userData: {
    firstName: '',
    lastName: '',
  },
  userCredentials: {
    email: '',
    password: '',
    encryptedPassword: '',
  },
  isSafeIDSuccess: false,
  isMultiHolderMode: false,
};

export default (state: BaoReducerState = initialState, action: BaoActionTypes | AnyAction): BaoReducerState => {
  switch (action.type) {
    case BaoServiceKeys.LOGIN:
      return {
        ...state,
        isAuthenticated: true,
      };
    case BaoServiceKeys.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
      };
    case BaoServiceKeys.SET_USER_DATA:
      return {
        ...state,
        userData: (action as SetUserTypeAction).userData,
      };
    case BaoServiceKeys.SET_USER_CREDENTIALS:
      return {
        ...state,
        userCredentials: (action as SetUserCredentialsAction).userCredentials,
      };
    case BaoServiceKeys.BLOCK_PREVIOUS_STEPS:
      return {
        ...state,
        isSafeIDSuccess: true,
      };
    case BaoServiceKeys.UPDATE_PERSONAL_INFOS_FORM:
      return {
        ...state,
        isMultiHolderMode: true,
      };
    default:
      return state;
  }
};
