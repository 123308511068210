import * as AccountsStep1 from './AccountsStep1';
import * as AccountsStep2 from './AccountsStep2';
import * as AccountsStep3 from './AccountsStep3';
import * as Assisted from './Assisted';
import * as Documents from './Documents';
import * as EmploymentV2 from './EmploymentV2';
import * as EmploymentJointV2 from './EmploymentV2';
import * as Finalisation from './Finalisation';
import * as Finances from './Finances';
import * as FinancesJoint from './Finances';
import * as IdentityVerification from './IdentityVerification';
import * as IdentityVerification2 from './IdentityVerificationStep2';
import * as IdentityVerificationBNC from './IdentityVerificationBNC';
import * as NewAccountRequest from './NewAccountRequest';
import * as PendingRequest from './PendingRequest';
import * as PersonalInformation from './PersonalInformation';
import * as PersonalInformationJoint from './PersonalInformation';
import * as Regulatory from './Regulatory';
import * as RequestAssistant from './RequestAssistant';
import * as RequestId from './RequestId';
import * as SpecificsBankingInfo from './SpecificsBankingInfo';
import * as SpecificsBankingInfoJoint from './SpecificsBankingInfo';
import * as SpecificsOptions from './SpecificsOptions';
import * as Taxes from './Taxes';
import * as TaxesJoint from './Taxes';
import * as Waiting from './Waiting';
import * as ThankYou from './ThankYou';
import * as ThankYouEsign from './ThankYouEsign';
import * as Transfer from './Transfer';
import * as Beneficiary from './Beneficiary';
import * as BeneficiaryInformation from './BeneficiaryInformation';
import * as Welcome from './Welcome';
import * as Summary from './Summary';
import * as SummaryJoint from './Summary';
import * as ESign from './ESign';
import * as ESignFail from './ESignFail';
import * as ClientRedirect from './ClientRedirect';
import * as ClientRedirectNew from './ClientRedirectNew';
import * as IdentityValidation from './IdentityValidation';
import * as CreateUserProfile from './CreateUserProfile';
import * as SpouseInformation from './SpouseInformation';
import * as PaymentInformation from './PaymentInformation';

export default {
  ClientRedirect,
  ClientRedirectNew,
  Welcome,
  NewAccountRequest,
  PendingRequest,
  CreateUserProfile,
  RequestId,
  RequestAssistant,
  AccountsStep1,
  AccountsStep2,
  AccountsStep3,
  Assisted,
  SpecificsOptions,
  PersonalInformation,
  PersonalInformationJoint,
  EmploymentV2,
  EmploymentJointV2,
  Finances,
  FinancesJoint,
  Taxes,
  TaxesJoint,
  SpecificsBankingInfo,
  SpecificsBankingInfoJoint,
  Transfer,
  IdentityVerification,
  IdentityVerificationBNC,
  Beneficiary,
  BeneficiaryInformation,
  Regulatory,
  Summary,
  SummaryJoint,
  Documents,
  ESign,
  Waiting,
  ESignFail,
  Finalisation,
  ThankYou,
  ThankYouEsign,
  IdentityValidation,
  IdentityVerification2,
  SpouseInformation,
  PaymentInformation,
};
