import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import { Check } from '@nbc-design/icons/lib/web/Check';

export type PasswordCriterionProps = {
  id: string;
  label: string;
  isValid: boolean;
};

export type PasswordCriteriaProps = {
  criteria: PasswordCriterionProps[];
  requirementMetLabel: string;
};

const PasswordCriterion: FunctionComponent<PasswordCriterionProps> = (criterion) => (
  <div
    key={criterion.id}
    className={cx('pwd-with-validation__item', {
      'pwd-with-validation__item--valid': criterion.isValid,
    })}
    id={`${criterion.id}-condition`}
  >
    <span className="pwd-with-validation__icon-wrapper-item">
      <span className="bn-icon pwd-with-validation__icon-item">
        <Check title="check" size="small" />
      </span>
    </span>
    <span className="pwd-with-validation__label-item">{criterion.label}</span>
  </div>
);

const PasswordCriteria: FunctionComponent<PasswordCriteriaProps> = (props) => {
  const { criteria, requirementMetLabel } = props;
  return (
    <div className="pwd-with-validation__items">
      {criteria.map(PasswordCriterion)}
      <label id="requirements-met" className="d-none">
        {requirementMetLabel}
      </label>
    </div>
  );
};

export default PasswordCriteria;
