import { createSelector } from 'reselect';
import { Address, MelissaState } from './types';

const melissaSelector = (state) => state.melissaServiceReducer as MelissaState;

export const availableAddressesSelector = createSelector(melissaSelector, (melissaState: MelissaState):
  | Address[]
  | undefined => {
  if (melissaState && (!melissaState.searchedAddresses || !melissaState.searchedAddresses.addresses)) {
    return [];
  }

  return melissaState && melissaState.searchedAddresses && melissaState.searchedAddresses.addresses;
});

export const isMelissaLoadingSelector = createSelector(melissaSelector, (melissaState: MelissaState): boolean => {
  return melissaState && !!melissaState.searchedAddresses && !!melissaState.searchedAddresses.isFetching;
});

export const melissaFetchingErrorsSelector = createSelector(
  melissaSelector,
  (melissaState: MelissaState): object[] | undefined => melissaState && melissaState.fetchingError,
);
