import StockSymbolKeys from 'services/StockSymbolService/actionTypes';
import {
  StockSymbolActionTypes,
  StockSymbolServiceListRequestFailedAction,
  StockSymbolServiceListRequestSucceededAction,
} from 'types/actions';
import { StockSymbolServiceReducerState } from 'types/reducers';
import { AnyAction } from 'redux';

export const initialState: StockSymbolServiceReducerState = {
  stockSymbolList: null,
  isFetching: false,
  fetchingError: null,
  failure: false,
} as StockSymbolServiceReducerState;

export default (state: StockSymbolServiceReducerState = initialState, action: StockSymbolActionTypes | AnyAction) => {
  switch (action.type) {
    case StockSymbolKeys.STOCK_SYMBOL_SERVICE_LIST_REQUESTED: {
      const { name } = action.options;
      if (name) {
        return {
          ...state,
          [name]: {
            ...state[name],
            isFetching: true,
            fetchingError: null,
            failure: false,
          },
        };
      }

      return {
        ...state,
        isFetching: true,
        fetchingError: null,
        failure: false,
      };
    }
    case StockSymbolKeys.STOCK_SYMBOL_LIST_REQUEST_SUCCEEDED: {
      const { name } = action.options;
      if (name) {
        return {
          ...state,
          [name]: {
            ...state[name],
            stockSymbolList: (action as StockSymbolServiceListRequestSucceededAction).stockSymbolList,
            isFetching: false,
            fetchingError: null,
            failure: false,
          },
        };
      }

      return {
        ...state,
        stockSymbolList: (action as StockSymbolServiceListRequestSucceededAction).stockSymbolList,
        isFetching: false,
        fetchingError: null,
        failure: false,
      };
    }
    case StockSymbolKeys.STOCK_SYMBOL_SERVICE_LIST_REQUEST_FAILED: {
      const { name } = action.options;
      if (name) {
        return {
          ...state,
          [name]: {
            ...state[name],
            isFetching: false,
            fetchingError: null,
            failure: false,
          },
        };
      }
      return {
        ...state,
        isFetching: false,
        fetchingError: (action as StockSymbolServiceListRequestFailedAction).err,
        failure: true,
      };
    }
    case StockSymbolKeys.STOCK_SYMBOL_SERVICE_LIST_RESET: {
      const { name } = action.options;
      if (name) {
        return {
          ...state,
          [name]: initialState,
        };
      }
      return initialState;
    }

    default:
      return state;
  }
};
