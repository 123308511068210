import React, { FC } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { SuccessFilledColor } from '@nbc-design/icons/lib/web/SuccessFilledColor';
import { Link } from '@nbc-design/link';

import { BFF_STEPS_IDS } from 'utils/stepsConstants';
import { ONLINE, CLRDCHECK } from '../constants';
import IdentityWrapper from '../../IdentityValidation/components/IdentityWrapper';

type ValidationCompletedProps = {
  getText: (key: string) => string;
  getIdentityValidationText: (key: string) => string;
  indvIdentPrefMethod: string;
  indvIdentMethod?: string;
  goToStep: (step: string) => void;
};

const ValidationCompleted: FC<ValidationCompletedProps> = (props: ValidationCompletedProps) => {
  const { getText, goToStep, indvIdentPrefMethod, indvIdentMethod, getIdentityValidationText } = props;

  const extraHeaderElements = (
    <div className="status-wrapper">
      <section className="status">
        <SuccessFilledColor size="medium" title={getText('completedStatus')} className="check-icon" />
        <span className="status-description">{getText('completedStatus')}</span>
      </section>
      {indvIdentPrefMethod !== ONLINE && (
        <section className="link-modify-wrapper">
          <Link
            href="#"
            underlined={true}
            className="link-modify"
            onClick={() => goToStep(BFF_STEPS_IDS.IDENTITYVALIDATION)}
          >
            {getText('modifyLink')}
          </Link>
        </section>
      )}
    </div>
  );

  const getValidateKey = () => {
    if (indvIdentPrefMethod === ONLINE) {
      return 'validationOnline';
    }
    return indvIdentMethod === CLRDCHECK ? 'validationCheck' : 'validationBankingInfo';
  };

  return (
    <Row>
      <Col xs={12} md={12} lg={12}>
        <IdentityWrapper
          title={getIdentityValidationText('validateTitle')}
          subTitle={getIdentityValidationText(getValidateKey())}
          iconName="selfie"
          hasButton={false}
          extraHeaderElements={extraHeaderElements}
          className="validation-completed"
        ></IdentityWrapper>
      </Col>
    </Row>
  );
};

export default ValidationCompleted;
