import React from 'react';
import { useContainerCtx } from '../../../providers/containerCtx/ContainerCtx';
import { BeneficiaryDataType, BeneficiaryFormValues } from '../types';
import { Text } from '@nbc-design/text';
import { IconButton } from '@nbc-design/button';
import { Edit } from '@nbc-design/icons/lib/web/Edit';
import { Delete } from '@nbc-design/icons/lib/web/Delete';
import BeneficiaryStatus from './BeneficiaryStatus';
import { useBeneficiaryHelpers } from '../hooks/useBeneficiaryHelpers';
import { useBeneficiaryInformationCtx } from '../providers/BeneficiaryInformationCtx';
import { getBeneficiariesNotDeleted, isBeneficiaryProfileComplete } from '../utils';

type BeneficiaryListingItemProps = {
  beneficiary: BeneficiaryDataType;
};

const BeneficiaryListingItem: React.FC<BeneficiaryListingItemProps> = ({ beneficiary }) => {
  const { getText, getAccessibilityText, formValues } = useContainerCtx<BeneficiaryFormValues>();
  const { beneficiaries, account } = formValues;
  const { setBeneficiaryToDelete, setBeneficiaryToEdit } = useBeneficiaryInformationCtx();

  const { isFamilyRespPlanType, areAllContributionFieldsEmpty } = useBeneficiaryHelpers();

  return (
    <div
      key={beneficiary.bncId}
      data-test={`beneficiary-listing-item-${beneficiary.bncId}`}
      className={'mc-bg-white mc-p-normal mc-mb-small mc-d-flex mc-justify-content-between mc-br-medium'}
    >
      <div className={'mc-d-flex'}>
        <div className={'mc-align-items-center mc-d-flex mc-mr-normal'}>
          <BeneficiaryStatus
            isCompleted={isBeneficiaryProfileComplete(
              beneficiary,
              getBeneficiariesNotDeleted(beneficiaries),
              account?.respPlanTypeCd,
            )}
          />
        </div>

        <div className={'mc-d-flex mc-flex-column mc-justify-content-center'}>
          <Text dataTest={'banaficiary-name'}>
            {beneficiary.firstName} {beneficiary.lastName}
          </Text>

          {isFamilyRespPlanType && !areAllContributionFieldsEmpty && (
            <Text>
              {getText('contribution')}
              <strong data-test={'contribution-percent'}>
                {!beneficiary.ownerPercentage && beneficiary.ownerPercentage !== 0
                  ? getText('contribution.missingInfo')
                  : beneficiary.ownerPercentage + '%'}
              </strong>
            </Text>
          )}
        </div>
      </div>

      <div className={'mc-d-flex mc-align-items-center'}>
        {!beneficiary.isCurrentUser && (
          <IconButton
            data-test={'btn-edit-beneficiary'}
            aria-label={getAccessibilityText('button.beneficiary.edit')}
            appearance={'primary'}
            icon={<Edit title={getAccessibilityText('edit')} />}
            onClick={() => setBeneficiaryToEdit(beneficiary)}
            size={'small'}
          />
        )}

        <IconButton
          data-test={'btn-delete-beneficiary'}
          className={'mc-ml-small'}
          appearance={'primary'}
          aria-label={getAccessibilityText('button.beneficiary.delete')}
          icon={<Delete title={getAccessibilityText('delete')} />}
          onClick={() => setBeneficiaryToDelete(beneficiary)}
          size={'small'}
        />
      </div>
    </div>
  );
};

export default BeneficiaryListingItem;
