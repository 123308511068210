import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { updateIntl } from 'react-intl-redux';
import TopNav from './TopNav';

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeLocale: updateIntl }, dispatch);
};

export default compose<any>(injectIntl, connect(null, mapDispatchToProps))(TopNav);
