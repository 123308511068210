import React from 'react';

export default ({ className }) => (
  <svg viewBox="0 0 16 16" className={className}>
    <path
      id="products_idee-a"
      d="M8.602 14.274a0.188 0.188 0 0 1 0.096 0.362l-1.781 0.472a0.188 0.188 0 1 1 -0.096 -0.362l1.781 -0.472zm-1.042 -12.857a0.188 0.188 0 1 1 0.375 0v1.101a0.188 0.188 0 1 1 -0.375 0V1.417zm5.597 5.861a0.188 0.188 0 0 1 0 -0.375h1.141a0.188 0.188 0 0 1 0 0.375h-1.141zM1.25 7.277a0.188 0.188 0 0 1 0 -0.375h1.141a0.188 0.188 0 1 1 0 0.375H1.25zm10.407 -3.146a0.188 0.188 0 1 1 -0.26 -0.27l0.807 -0.779a0.188 0.188 0 0 1 0.26 0.27l-0.807 0.779zm-8.644 -0.795a0.188 0.188 0 1 1 0.261 -0.27l0.807 0.778a0.188 0.188 0 1 1 -0.26 0.27l-0.807 -0.779zM9.777 11.246c-0.216 0.149 -0.432 0.499 -0.493 0.86 -0.043 0.181 -0.203 0.369 -0.393 0.416l-2.187 0.58a0.188 0.188 0 0 1 -0.096 -0.362l2.19 -0.581c0.044 -0.011 0.108 -0.086 0.118 -0.128 0.075 -0.446 0.346 -0.886 0.647 -1.094 1.191 -0.819 1.942 -1.861 1.966 -3.134l0.006 -0.044c0.003 -2.022 -1.692 -3.658 -3.778 -3.658 -2.086 0 -3.78 1.635 -3.78 3.641 0 1.298 0.755 2.361 1.966 3.195 0.301 0.207 0.573 0.647 0.647 1.095a0.188 0.188 0 1 1 -0.37 0.062c-0.059 -0.349 -0.274 -0.699 -0.49 -0.848 -1.303 -0.896 -2.129 -2.059 -2.129 -3.503 0 -2.216 1.865 -4.016 4.155 -4.016 2.29 0 4.155 1.8 4.155 4.016 0 0.03 -0.003 0.059 -0.009 0.088 -0.034 1.405 -0.854 2.539 -2.128 3.415zM8.75 13.21a0.188 0.188 0 1 1 0.096 0.362l-2.076 0.55a0.188 0.188 0 1 1 -0.096 -0.362L8.75 13.21z"
    />
  </svg>
);
