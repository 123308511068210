import React, { FunctionComponent, Fragment, useState } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { ProductFormAmountInput } from 'components/productFormFields';
import Heading from 'components/Heading';
import { FinancesComponentsProps } from '../types';
import { SimplifiedCalculator } from '@nbc-design/icons/lib/web/SimplifiedCalculator';
import CalculatorModal from './CalculatorModal';
import { CALCULATOR_MODE_ASSETS, CALCULATOR_MODE_LIABILITIES } from '../constants';
import {
  addCalculatorRollbackState,
  rollbackCalculatorState,
  sumAssetsTotal,
  sumLiabilitiesTotal,
  updateAssets,
  updateLiabilities,
  updateNetWorth,
} from '../utils';

const FinancialAssets: FunctionComponent<FinancesComponentsProps> = (props: FinancesComponentsProps) => {
  const { formatErrorMessage, getText, change, form, liabilitiesTotal, assetsTotal } = props;

  const initialModalState = {
    isCalculatorModalVisible: false,
    calculatorMode: '',
  };

  addCalculatorRollbackState(initialModalState, props);

  const [state, updateState] = useState(initialModalState);

  const { isCalculatorModalVisible, calculatorMode } = state;

  const closeModal = (validated: boolean = false): void => {
    if (!validated) {
      rollbackCalculatorState(state, change);
    } else {
      addCalculatorRollbackState(state, props);
    }

    updateState({
      ...state,
      isCalculatorModalVisible: false,
      calculatorMode: '',
    });

    setTimeout(() => {
      if (validated) {
        if (calculatorMode === CALCULATOR_MODE_ASSETS) {
          updateAssets(change, props);
          updateAssetsTotal();
        } else {
          updateLiabilities(change, props);
          updateLiabilitiesTotal();
        }
      }
    }, 0);
  };

  const updateAssetsTotal = (): void => {
    const sum = sumAssetsTotal(form);
    change('assetsTotal', sum);
    typeof liabilitiesTotal === 'number' && updateNetWorth(change, sum, liabilitiesTotal);
  };

  const updateLiabilitiesTotal = (): void => {
    const sum = sumLiabilitiesTotal(form);
    change('liabilitiesTotal', sum);
    typeof assetsTotal === 'number' && updateNetWorth(change, assetsTotal, sum);
  };

  const handleModalOpening = (calculatorType: string) => {
    updateState({ ...state, isCalculatorModalVisible: true, calculatorMode: calculatorType });
  };

  return (
    <Fragment>
      <Heading type={'h2'} size={4} dataTest="label_yourFinancialAsset">
        {getText('yourFinancialAsset')}
      </Heading>

      <Row className="spacer">
        <Col xs={12} md={4} lg={4} data-test="field_assetsTotal">
          <ProductFormAmountInput
            name="assetsTotal"
            label={getText('assetsTotal')}
            placeholder={getText('assetsTotalDesc')}
            formatErrorMessage={formatErrorMessage}
            className="finances__assets-total"
            iconButton={{
              icon: <SimplifiedCalculator size="medium" title={getText('assetsCalculator')} />,
            }}
            disabled={true}
            clickInputAction={() => handleModalOpening(CALCULATOR_MODE_ASSETS)}
          />
        </Col>

        <Col xs={12} md={4} lg={4} data-test="field_liabilitiesTotal">
          <ProductFormAmountInput
            name="liabilitiesTotal"
            label={getText('liabilitiesTotal')}
            placeholder={getText('liabilitiesTotalDesc')}
            formatErrorMessage={formatErrorMessage}
            className="finances__liabilities-total"
            iconButton={{
              icon: <SimplifiedCalculator size="medium" title={getText('liabilitiesCalculator')} />,
            }}
            disabled={true}
            clickInputAction={() => handleModalOpening(CALCULATOR_MODE_LIABILITIES)}
          />
        </Col>

        <Col xs={12} md={4} lg={4} data-test="field_netWorth">
          <ProductFormAmountInput
            name="netWorth"
            label={getText('netWorth')}
            isReadOnly
            className="finances__net-worth"
          />
        </Col>
      </Row>

      {isCalculatorModalVisible && (
        <CalculatorModal calculatorMode={calculatorMode} closeModal={closeModal} {...props} />
      )}
    </Fragment>
  );
};

export default FinancialAssets;
