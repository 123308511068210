import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { ProductFormAmountInput } from 'components/productFormFields';
import { getTextFactoryReturnType } from 'utils/TextUtils';
import { ErrorMessage, ProductFormFieldChangeHandler } from 'types/interfaces';
import { Label } from '@nbc-design/label';
import { sumLiabilitiesTotal } from '../utils';
import { CALCULATOR_LIABILITIES_FIELDS } from '../constants';
import { CURRENCIES } from 'utils/constants';

export type LiabilitiesCalculatorProps = {
  formatErrorMessage: (error: ErrorMessage) => string;
  getText: getTextFactoryReturnType;
  change: ProductFormFieldChangeHandler;
  className?: string;
  form: string;
};

const LiabilitiesCalculator: FunctionComponent<LiabilitiesCalculatorProps> = (props: LiabilitiesCalculatorProps) => {
  const { formatErrorMessage, getText, change, className, form } = props;

  change('liabilitiesTotalPopup', sumLiabilitiesTotal(form));

  return (
    <div className={`liabilities-calculator ${className}`}>
      <Row>
        <Col xs={6} sm={6} md={8} lg={8} data-test="field_liabilitiesPersonalLoan_text">
          <div className="label-text">
            <Label htmlFor="AmountInput-liabilitiesPersonalLoan">{getText('liabilitiesPersonalLoan')}</Label>
          </div>
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} data-test="field_liabilitiesPersonalLoan">
          <ProductFormAmountInput
            name={CALCULATOR_LIABILITIES_FIELDS.PERSONAL_LOAN}
            formatErrorMessage={formatErrorMessage}
            suffix={CURRENCIES.CAD}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={6} md={8} lg={8} data-test="field_liabilitiesCreditMargins_text">
          <div className="label-text">
            <Label htmlFor="AmountInput-liabilitiesCreditMargins">{getText('liabilitiesCreditMargins')}</Label>
          </div>
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} data-test="field_liabilitiesCreditMargins">
          <ProductFormAmountInput
            name={CALCULATOR_LIABILITIES_FIELDS.CREDIT_MARGINS}
            formatErrorMessage={formatErrorMessage}
            suffix={CURRENCIES.CAD}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={6} md={8} lg={8} data-test="field_liabilitiesMortgage_text">
          <div className="label-text">
            <Label htmlFor="AmountInput-liabilitiesMortgage">{getText('liabilitiesMortgage')}</Label>
          </div>
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} data-test="field_liabilitiesMortgage">
          <ProductFormAmountInput
            name={CALCULATOR_LIABILITIES_FIELDS.MORTGAGE}
            formatErrorMessage={formatErrorMessage}
            suffix={CURRENCIES.CAD}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={6} md={8} lg={8} data-test="field_liabilitiesOther_text">
          <div className="label-text">
            <Label htmlFor="AmountInput-liabilitiesOther">{getText('liabilitiesOther')}</Label>
          </div>
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} data-test="field_liabilitiesOther">
          <ProductFormAmountInput
            name={CALCULATOR_LIABILITIES_FIELDS.OTHER}
            formatErrorMessage={formatErrorMessage}
            suffix={CURRENCIES.CAD}
          />
        </Col>
      </Row>
      <Row className="total-assets">
        <Col xs={6} sm={6} md={8} lg={8} data-test="field_liabilitiesTotal_text">
          <div className="label-text">
            <Label htmlFor="AmountInput-liabilitiesTotalPopup">{getText('liabilitiesTotalPopup')}</Label>
          </div>
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} data-test="field_liabilitiesTotal">
          <ProductFormAmountInput
            name="liabilitiesTotalPopup"
            isReadOnly
            placeholder={getText('liabilityPlaceholder')}
          />
        </Col>
      </Row>
    </div>
  );
};

export default LiabilitiesCalculator;
