import { put, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import StockSymbolKeys from 'services/StockSymbolService/actionTypes';
import { StockSymbolConfig } from 'types/interfaces';
import { AnyAction } from 'redux';

import * as actions from './actions';
import { getStockSymbol } from './api';

export default function createStockSymbolService(config: StockSymbolConfig) {
  const { endpoint, id, method, headers, callEffect, onError, debounce = 300 } = config;

  if (!endpoint) {
    throw new Error('Stock symbol API endpoint is required.');
  }

  if (!id) {
    throw new Error('Stock symbol API ID is required.');
  }

  if (typeof callEffect !== 'function') {
    throw new Error('API call helper function is required. This is usually provided by utils/requestManager.');
  }

  if (typeof onError !== 'function') {
    throw new Error(
      'Error propagator function is required. This is usually provided by utils/propagate-global-errors.',
    );
  }

  function* getStockSymbolSaga(action: AnyAction) {
    if (debounce > 0) {
      // debounce requests
      yield delay(debounce);
    }

    const { search, options } = action;

    try {
      const stockSymbolList = yield callEffect &&
        callEffect(
          getStockSymbol,
          {
            endpoint,
            id,
            method,
            headers,
            ...options,
          },
          search,
        );
      yield put(actions.stockSymbolServiceListRequestSucceeded(stockSymbolList, options));
    } catch (err) {
      onError && onError(err);
      yield put(actions.stockSymbolServiceListRequestFailed(err, options));
    }
  }

  return function* rootSaga() {
    yield takeLatest(StockSymbolKeys.STOCK_SYMBOL_SERVICE_LIST_REQUESTED, getStockSymbolSaga);
  };
}
