import React, { FunctionComponent } from 'react';
import { InjectedIntl } from 'react-intl';
import Title from 'components/Title';
import { Row, Col } from 'react-flexbox-grid';
import { createErrorFormatter, getLabel, getRefData } from 'utils/productFormUtils';
import { getTextFactory } from 'utils/TextUtils';

import './styles.scss';

import { AccountStep2Data, CurrencyData } from './types';
import { GenericProps } from 'types/interfaces';
import { ProductFormMultiCheckbox } from 'components/productFormFields';

type AccountsStep2Props = GenericProps & {
  intl: InjectedIntl;
  accounts?: AccountStep2Data[];
};

const AccountsStep2: FunctionComponent<AccountsStep2Props> = (props: AccountsStep2Props) => {
  const { intl, accounts = [], change }: AccountsStep2Props = props;

  const accountsRefData = getRefData(props, 'accounts');
  const currenciesRefData = getRefData(props, 'currencies');
  const getText = getTextFactory(intl, 'yourAccounts');
  const formatErrorMessage = (prefix?: string) => createErrorFormatter(intl, prefix);

  return (
    <div className="accountsStep2__form">
      <Title label={getText('pageTitle')} />

      {accounts.map(({ accountType, currency, values }: AccountStep2Data, i: number) => {
        if (!accountType || !values) return null;

        const label: string = getLabel(accountsRefData, intl.locale, accountType);
        const isSelected = !!values.filter(({ value }: CurrencyData): boolean => value).length;

        return (
          <div key={i} className={`accountCard ${isSelected ? 'selected' : ''}`}>
            <Row>
              <Col xs={12} md={4} lg={6} className="label">
                <span className="account" data-test={`label_${accountType}`}>
                  {label}
                </span>
                <span data-test={`currency_${accountType}`}>{getLabel(currenciesRefData, intl.locale, currency)}</span>
              </Col>
              <Col xs={12} md={8} lg={6}>
                <div data-test={`fields_${accountType}`}>
                  <ProductFormMultiCheckbox
                    name={`accounts[${i}].values`}
                    inline
                    className="inline"
                    formatErrorMessage={formatErrorMessage()}
                    required={true}
                    change={change}
                  />
                </div>
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};

export default AccountsStep2;
