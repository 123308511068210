// TODO : this cause tests  to be extremely slow,
//  since a change to any container (or it's dependency)
//  causes all test to be rerun, since this is imported by App.tsx
import Containers from '../containers';
import { AppStep, Container } from '../types/interfaces';

enum STEPS_JOINT {
  PERSONAL_INFOS = 'PersonalInformationJoint',
  EMPLOYMENT = 'EmploymentJoint',
  EMPLOYMENT_V2 = 'EmploymentJointV2',
  FINANCE = 'FinancesJoint',
  TAXES = 'TaxesJoint',
  SPECIFIC_BANKING_INFO = 'SpecificsBankingInfoJoint',
  SUMMARY_STEP = 'SummaryJoint',
}

const STEPS_JOINT_LIST: STEPS_JOINT[] = [
  STEPS_JOINT.PERSONAL_INFOS,
  STEPS_JOINT.EMPLOYMENT,
  STEPS_JOINT.EMPLOYMENT_V2,
  STEPS_JOINT.FINANCE,
  STEPS_JOINT.TAXES,
  STEPS_JOINT.SPECIFIC_BANKING_INFO,
  STEPS_JOINT.SUMMARY_STEP,
];

export const isJointStep = (containerKey: STEPS_JOINT): boolean => STEPS_JOINT_LIST.includes(containerKey);

export const ALL_STEPS: AppStep[] = Object.keys(Containers).map(
  (key: string): AppStep => {
    const step: Container = Containers[key];
    const stepData = !isJointStep(key as STEPS_JOINT)
      ? step.CONFIG
      : {
          ...step.CONFIG,
          ID: step.CONFIG.STEP_JOINT_ID,
          SUB_STEP_IDS: step.CONFIG.SUB_STEP_JOINT_IDS,
        };

    return {
      name: key.toUpperCase(),
      ...stepData,
      validation: step.VALIDATION,
      i18n: step.I18N,
      container: step.default,
    };
  },
);

export const BFF_STEPS: AppStep[] = ALL_STEPS.filter((x: AppStep): boolean => !x.PATH);

export const BFF_STEPS_IDS: { [key: string]: string } = BFF_STEPS.reduce(
  (allSteps: { [key: string]: string }, step: AppStep): {} => ({ ...allSteps, [step.name]: step.ID }),
  {},
);

export const STEPS_WITH_PATH: AppStep[] = ALL_STEPS.filter((step: AppStep): boolean => step.PATH === '' || !!step.PATH);

export const SIDEBAR_MENU_STEPS: AppStep[] = ALL_STEPS.reduce((appSteps: AppStep[], step: AppStep): AppStep[] => {
  if (!step.SIDEBAR_MENU) {
    return appSteps;
  }

  const isSubstep: AppStep | undefined = appSteps.find(
    (appStep: AppStep) =>
      appStep.subSteps && appStep.subSteps.find((subStep: AppStep): boolean => subStep.ID === step.ID),
  );

  if (isSubstep) {
    return appSteps;
  }

  if (!step.SUB_STEP_IDS) {
    return [...appSteps, step];
  }

  const subSteps: AppStep[] = step.SUB_STEP_IDS.map(
    (subStepId: string): AppStep => {
      const concernedStep: AppStep = ALL_STEPS.find((x: AppStep): boolean => x.ID === subStepId) || ({} as AppStep);

      return {
        ...concernedStep,
      };
    },
  );

  return [
    ...appSteps,
    {
      ...step,
      subSteps,
    },
  ];
}, []);

export const JOINT_STEPS: AppStep[] = SIDEBAR_MENU_STEPS.filter(
  (step: AppStep): boolean => !!step.STEP_JOINT_ID && step.STEP_JOINT_ID !== 'SUMMARY_STEP_JOINT',
);

export const APPLICANT_STEPS: AppStep[] = JOINT_STEPS.filter(
  (step: AppStep): boolean => step.ID !== step.STEP_JOINT_ID,
);

export enum APPLICANT_STEP_ID {
  PERSONAL_INFOS = 'PERSONAL_INFOS_STEP',
  EMPLOYMENT = 'EMPLOYMENT_STEP',
  EMPLOYMENT_V2 = 'EMPLOYMENT_V2_STEP',
  FINANCE = 'FINANCE_STEP',
  TAXES = 'TAX_STEP',
  SPECIFIC_BANKING_INFO = 'BANKING_INFOS_STEP',
}

export const APPLICANT_STEPS_IDS_LIST: APPLICANT_STEP_ID[] = [
  APPLICANT_STEP_ID.PERSONAL_INFOS,
  APPLICANT_STEP_ID.EMPLOYMENT,
  APPLICANT_STEP_ID.EMPLOYMENT_V2,
  APPLICANT_STEP_ID.FINANCE,
  APPLICANT_STEP_ID.TAXES,
  APPLICANT_STEP_ID.SPECIFIC_BANKING_INFO,
];

export const SPOUSE_STEPS: AppStep[] = JOINT_STEPS.filter((step: AppStep): boolean => step.ID === step.STEP_JOINT_ID);

export enum SPOUSE_STEP_ID {
  PERSONAL_INFOS = 'PERSONAL_INFOS_STEP_JOINT',
  EMPLOYMENT = 'EMPLOYMENT_STEP_JOINT',
  EMPLOYMENT_V2 = 'EMPLOYMENT_V2_STEP_JOINT',
  FINANCE = 'FINANCE_STEP_JOINT',
  TAXES = 'TAX_STEP_JOINT',
  SPECIFIC_BANKING_INFO = 'BANKING_INFOS_STEP_JOINT',
}

export const SPOUSE_STEPS_IDS_LIST: SPOUSE_STEP_ID[] = [
  SPOUSE_STEP_ID.PERSONAL_INFOS,
  SPOUSE_STEP_ID.EMPLOYMENT,
  SPOUSE_STEP_ID.EMPLOYMENT_V2,
  SPOUSE_STEP_ID.FINANCE,
  SPOUSE_STEP_ID.TAXES,
  SPOUSE_STEP_ID.SPECIFIC_BANKING_INFO,
];

export const PROGRESS_BAR_STEPS: AppStep[] = ALL_STEPS.filter((step: AppStep): boolean => !!step.PROGRESS_BAR_COUNT);
