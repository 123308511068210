import React, { PureComponent } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { getBeneficiarySlice } from '../selectors';
import { BeneficiarySlice } from '../types';
import { getTextFactory } from 'utils/TextUtils';
import { injectIntl, InjectedIntl } from 'react-intl';
import SummarySection from './SummarySection';
import { BFF_STEPS_IDS } from 'utils/stepsConstants';
import SummaryField from './SummaryField';

export type BeneficiarySectionPropsFromState = {
  beneficiarySlice: BeneficiarySlice;
};

export type BeneficiarySectionProps = {
  goToStep: (stepId: string) => string;
  intl: InjectedIntl;
};

export class BeneficiarySection extends PureComponent<BeneficiarySectionProps & BeneficiarySectionPropsFromState> {
  getBeneficiaryText = getTextFactory(this.props.intl, 'beneficiary.summary');
  getSummaryText = getTextFactory(this.props.intl, 'summary.beneficiary');

  renderContent = () => {
    const { beneficiarySlice } = this.props;

    if (!beneficiarySlice) {
      return null;
    }

    const { beneficiary, successor } = beneficiarySlice;

    const beneficiaryAnswer = this.getBeneficiaryText(beneficiary ? 'answerYes' : 'answerNo');
    const hasSuccessorQuestion = successor !== undefined && successor !== null;
    const successorAnswer = this.getBeneficiaryText(successor ? 'answerYes' : 'answerNo');

    return (
      <div className="beneficiary-recap-content">
        <Row>
          <Col xs={12} md={12} xl={12}>
            <SummaryField
              dataTest="beneficiary_wantBeneficiaryQuestion"
              label={this.getBeneficiaryText('wantBeneficiaryQuestion')}
            >
              {beneficiaryAnswer}
            </SummaryField>
          </Col>
        </Row>
        {hasSuccessorQuestion && (
          <Row>
            <Col xs={12} md={12} xl={12}>
              <SummaryField
                dataTest="beneficiary_wantSuccessorQuestion"
                label={this.getBeneficiaryText('wantSuccessorQuestion')}
              >
                {successorAnswer}
              </SummaryField>
            </Col>
          </Row>
        )}
      </div>
    );
  };

  render() {
    const { goToStep, intl, beneficiarySlice } = this.props;

    return (
      <SummarySection
        intl={intl}
        name="beneficiary"
        title={this.getSummaryText('pageTitle')}
        stepId={BFF_STEPS_IDS.BENEFICIARY}
        goToStep={goToStep}
        renderSectionContent={this.renderContent}
        editable={beneficiarySlice && beneficiarySlice.editable}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    beneficiarySlice: getBeneficiarySlice(state),
  };
};

export default connect<BeneficiarySectionPropsFromState, {}, BeneficiarySection>(mapStateToProps)(
  injectIntl(BeneficiarySection),
);
