import React, { FunctionComponent } from 'react';
import { IconButton } from '@nbc-design/button';
import { ArrowLeft } from '@nbc-design/icons/lib/web/ArrowLeft';
import { ArrowRight } from '@nbc-design/icons/lib/web/ArrowRight';

import './styles.scss';

export type PdfPaginationProps = {
  page: number;
  pages: number;
  setPage: (page: number) => void;
};

const PdfPagination: FunctionComponent<PdfPaginationProps> = ({ page, pages, setPage }) => {
  if (!pages) {
    return null;
  }

  const isFirstPage = page === 1;
  const isLastPage = page === pages;

  const previousButton = (
    <IconButton
      icon={<ArrowLeft title="arrow-left" />}
      size="small"
      appearance="primary"
      onClick={() => setPage(page - 1)}
      disabled={isFirstPage}
    />
  );
  const nextButton = (
    <IconButton
      icon={<ArrowRight title="arrow-right" />}
      size="small"
      appearance="primary"
      onClick={() => setPage(page + 1)}
      disabled={isLastPage}
    />
  );
  return (
    <div className="pdf-pagination">
      {previousButton}
      {nextButton}
    </div>
  );
};

export default PdfPagination;
