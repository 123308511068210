import React, { Fragment, FunctionComponent } from 'react';
import { InjectedIntl } from 'react-intl';
import Title from 'components/Title';
import { Col, Row } from 'react-flexbox-grid';
import { createErrorFormatter } from 'utils/productFormUtils';
import { IS_ACCOUNT_TFSA } from './constants';
import { getTextFactory } from 'utils/TextUtils';
import { Check } from '@nbc-design/icons/lib/web/Check';

import { BeneficiaryAccountDataType } from './types';

import { ProductFormRadioBoolean } from 'components/productFormFields';
import { URLS } from 'utils/constants';
import { Link } from '@nbc-design/link';
import { Text } from '@nbc-design/text';
import { ErrorFieldFilledColor } from '@nbc-design/icons/lib/web/ErrorFieldFilledColor';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import './styles.scss';

export type BeneficiaryProps = {
  intl: InjectedIntl;
  beneficiary: boolean;
  accounts?: BeneficiaryAccountDataType[];
};

const Beneficiary: FunctionComponent<BeneficiaryProps> = (props: BeneficiaryProps) => {
  const { intl, beneficiary, accounts = [] } = props;

  const formatErrorMessage = (prefix?: string, fieldName?: string) => createErrorFormatter(intl, prefix, fieldName);
  const getText = getTextFactory(intl, 'beneficiary');
  const getTextGlobal = getTextFactory(intl, 'global');

  const isCodeTfsa = !!accounts.find((account) => {
    return account && account.accountType && account.accountType.code === IS_ACCOUNT_TFSA;
  });

  return (
    <div id="beneficiary_form">
      <Title label={getText('pageTitle')} dataTest="label_page_title" subTitle={getText('subTitle')} />

      <Row className="fields_accounts">
        <Col xs={12} md={12} lg={12} data-test="fields_accounts">
          {accounts.map(
            (
              { accountType, currency, registeredAgmtFlg, provinceLabel }: BeneficiaryAccountDataType,
              index: number,
            ) => {
              const displayLabel = getTextGlobal(registeredAgmtFlg);

              return (
                <div key={index} className="beneficiary_account_label">
                  <Row>
                    <Col xs={12} md={12} lg={12}>
                      <Check title="check" size="xsmall" />
                      <span className="beneficiary_accounts" data-test={`field_accountType_${accountType}_${index}`}>
                        {accountType[intl.locale]}
                      </span>
                      {!isNil(provinceLabel) && !isEmpty(provinceLabel) && (
                        <span data-test={`beneficiary_province`}>{provinceLabel[intl.locale]}</span>
                      )}
                      <span data-test={`field_currency_${currency[intl.locale]}_${index}`}>
                        {currency[intl.locale]}
                      </span>
                      <span data-test={`field_displayLabel_${displayLabel}_${index}`}>{displayLabel}</span>
                    </Col>
                  </Row>
                </div>
              );
            },
          )}
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={12} lg={12} data-test="field_beneficiary" className="field_beneficiary">
          <ProductFormRadioBoolean
            name="beneficiary"
            yesLabel={getTextGlobal('button.yes')}
            noLabel={getTextGlobal('button.no')}
            inline
            label={getText('beneficiaryName')}
            formatErrorMessage={formatErrorMessage()}
          />
        </Col>
      </Row>

      {beneficiary && (
        <Fragment>
          {isCodeTfsa && (
            <Row>
              <Col xs={12} md={12} lg={12} data-test="field_successor">
                <ProductFormRadioBoolean
                  name="successor"
                  yesLabel={getTextGlobal('button.yes')}
                  noLabel={getTextGlobal('button.no')}
                  inline
                  label={getText('beneficiarySpouse')}
                  formatErrorMessage={formatErrorMessage()}
                />
              </Col>
            </Row>
          )}

          <Row>
            <Col xs={12} md={12} lg={12} data-test="text_legal">
              <div className="beneficiary-text-legal">
                <ErrorFieldFilledColor size="xsmall" title="error-field-filled-color" />
                <Text data-test="fields_successor_Information" className="product-form-input__help legal">
                  {getText('legalStartText')}
                  <Link
                    href={URLS.FORMS[intl.locale.toUpperCase()]}
                    target="_blank"
                    rel="noopener noreferrer"
                    underlined
                    data-test="link_forms"
                    className="forms-link"
                  >
                    {getText('legalFormsLink')}
                  </Link>
                  {getText('legalEndText')}
                </Text>
              </div>
            </Col>
          </Row>
        </Fragment>
      )}
    </div>
  );
};

export default Beneficiary;
