import { applyMiddleware, createStore, compose, GenericStoreEnhancer, Store } from 'redux';
import rootReducer from './rootReducer';
import { SagaMiddlewareComponent, createMiddlewares } from './middlewares';
import sagas from './sagas';
import { getLocale } from 'utils';
import { getAllMessagesByLocale } from 'messages/messagesUtils';
import { RootState } from 'types/reducers';
import { setStore } from './store';

const currentLocale = getLocale();

const initialState: Partial<RootState> = {
  intl: {
    locale: currentLocale,
    messages: getAllMessagesByLocale(currentLocale),
  },
};

const configureStore = (): Store<RootState> => {
  const composeEnhancers: Function =
    typeof window === 'object' && window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']
      ? window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']({})
      : compose;

  const enhancer: GenericStoreEnhancer = composeEnhancers(applyMiddleware(...createMiddlewares()));

  const store = createStore<RootState>(rootReducer(), initialState as RootState, enhancer);

  SagaMiddlewareComponent.getInstance().run(sagas);

  setStore(store);

  return store;
};

export default configureStore;
