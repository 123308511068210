import React, { ChangeEvent, FunctionComponent, ReactNode } from 'react';
import { Fieldset } from '@nbc-design/fieldset';
import { Checkbox } from '@nbc-design/checkbox';
import { InjectedIntl } from 'react-intl';
import { FormComponentProps, InputOptionType, OptionsType } from '../types';
import { getErrorMessage, getSchemaOptions } from '../utils';

import './style.scss';
import { ProductFormFieldChangeHandler } from 'types/interfaces';
import { getTextFactory } from 'utils/TextUtils';
import AnalyticsHelper from 'services/analytics/AnalyticsHelper';

export type ProductFormMultiCheckboxProps = FormComponentProps & {
  intl: InjectedIntl;
  change: ProductFormFieldChangeHandler;
  required?: boolean;
  disabled?: number[];
  handleOnChange: (event: ChangeEvent<HTMLInputElement>) => void;
  inline?: boolean;
  tooltip?: React.ReactNode;
};

const ProductFormMultiCheckbox: FunctionComponent<ProductFormMultiCheckboxProps> = (
  props: ProductFormMultiCheckboxProps,
) => {
  const {
    className,
    change,
    input,
    intl,
    meta: { submitFailed },
    required,
    disabled,
    handleOnChange,
    tooltip,
    helpText,
    inline,
  } = props;

  const data: InputOptionType[] = input.value || [];
  const options: OptionsType[] = getSchemaOptions(props);
  const getOptionLabel = (code: string): string => {
    const option: OptionsType | undefined = options.find(({ value }: OptionsType): boolean => value === code);
    return (option && option.label) || '';
  };
  const invalid = required && submitFailed && !data.filter(({ value }) => value).length;
  const getAccessibilityText = getTextFactory(intl, 'accessibility');
  const onShowTooltip = AnalyticsHelper.handleShowTooltip(input.name);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;

    handleOnChange && handleOnChange(event);
    change(name, checked);
  };

  const checkboxes = data.map(
    ({ code, value }: InputOptionType, index: number): ReactNode => {
      return (
        <Checkbox
          key={index}
          id={`checkbox-${input.name}[${index}].value`}
          className={className}
          name={`${input.name}[${index}].value`}
          label={
            options && options.length
              ? getOptionLabel(code)
              : (intl && intl.formatMessage({ id: `global.${code}` })) || ''
          }
          checked={value}
          disabled={disabled && disabled.includes(index)}
          onChange={handleChange}
        />
      );
    },
  );

  return (
    <Fieldset
      tooltip={{ content: tooltip, ariaLabel: getAccessibilityText('information'), onShowTooltip }}
      validate={{ hasError: !!invalid, errorMsg: getErrorMessage(props) || '' }}
      description={{ text: helpText || '', id: 'dscFieldset__description' }}
    >
      {inline ? <div className="multicheckbox-inline">{checkboxes}</div> : checkboxes}
    </Fieldset>
  );
};

export default ProductFormMultiCheckbox;
