import React from 'react';
import { InjectedIntl } from 'react-intl';

import Form from '@nbc-design/form';
import { SkipLink } from '@nbc-design/skip-link';

import { AppStep, ConfigData, GenericProps } from 'types/interfaces';
import { getProgressPercentage } from 'utils/progressBar';
import { ALL_STEPS } from 'utils/stepsConstants';
import { getTextFactory } from 'utils/TextUtils';
import { scrollIntoView } from 'utils/domUtils';
import asideImage from 'assets/images/img-bao-brokerage-account.jpg';
import ProductFormContent from './productFormContent';
import DevNav from '../DevNav';
import Footer from '../Footer';
import Sidebar from '../Sidebar';
import TopNav from '../TopNav';
import { isJointMenu } from 'utils/productFormUtils';

import './styles.scss';

export type LayoutProps = {
  isReady?: boolean;
  isLoading?: boolean;
  Page: React.ReactNode;
  currentStep?: string;
  goToStep?: ((id: string) => void) | null;
  changeLocale?: ((data: InjectedIntl) => void) | null;
  setUserData: Function;
} & GenericProps;

class Layout extends React.PureComponent<LayoutProps> {
  firstName: string;
  lastName: string;
  currentStep: string;

  constructor(props) {
    super(props);
    this.firstName = '';
    this.lastName = '';
    this.currentStep = '';
  }

  componentDidUpdate() {
    const { meta, setUserData } = this.props;

    if (
      meta &&
      meta.firstName &&
      meta.lastName &&
      (meta.firstName !== this.firstName || meta.lastName !== this.lastName)
    ) {
      setUserData({
        firstName: meta.firstName,
        lastName: meta.lastName,
      });

      this.firstName = meta.firstName;
      this.lastName = meta.lastName;
    }
  }

  render() {
    const {
      intl,
      isReady = false,
      isLoading,
      currentStep = null,
      goToStep = null,
      change,
      meta,
      changeLocale = null,
    } = this.props;

    const currentStepInfo: AppStep | undefined = ALL_STEPS.find((step: AppStep): boolean => step.ID === currentStep);
    const requestCode = (meta && meta.requestCode) || 0;

    const defaultLayoutConfig: ConfigData = {
      SIDEBAR_IMAGE: true,
      PROGRESS_BAR: false,
      BG_WHITE: true,
    };
    const layoutConfig = currentStepInfo || defaultLayoutConfig;
    const aside = (
      <Sidebar
        {...this.props}
        config={layoutConfig}
        goToStep={goToStep}
        requestCode={requestCode}
        isJointMenu={isJointMenu(meta)}
      />
    );
    const progress = layoutConfig.PROGRESS_BAR && !!currentStep ? getProgressPercentage(currentStep) : null;
    const theme = layoutConfig.BG_WHITE ? null : 'blue';
    const main = <ProductFormContent config={layoutConfig} {...this.props} stepId={currentStep} />;
    const getAccessibilityText = getTextFactory(intl, 'accessibility');

    if (currentStep && currentStep !== this.currentStep) {
      scrollIntoView('.main-layout');
      this.currentStep = currentStep;
    }

    return (
      <div className="main-layout">
        <SkipLink href="#dsl-main">{getAccessibilityText('skipLink')}</SkipLink>

        <DevNav config={layoutConfig} goToStep={goToStep} />

        <Form
          header={<TopNav config={layoutConfig} change={change} changeLocale={changeLocale} />}
          main={main}
          {...(layoutConfig.SIDEBAR_IMAGE ? { asideImage } : {})}
          {...(layoutConfig.SIDEBAR_MENU ? { aside } : {})}
          {...(progress !== null ? { progress } : {})}
          isFetching={!isReady || isLoading}
          theme={theme}
          footer={<Footer />}
        />
      </div>
    );
  }
}

export default Layout;
