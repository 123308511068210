import React, { ReactNode } from 'react';
import { InjectedIntl } from 'react-intl';
import get from 'lodash/get';
import { ProductFormField } from 'bnc-react-forms';

import { getTextFactory } from 'utils/TextUtils';
import { RefData, RefDataContent } from '../../types/interfaces';
import { FormComponentProps, FormFieldProps, OptionsType, DateFieldsValue } from './types';
import { Props as DateFieldsProps } from '@nbc-design/date-fields';

export const getProductFormField = <TProps extends object>(component: ReactNode) => (props: FormFieldProps<TProps>) => (
  <ProductFormField {...props} component={component} />
);

export const getLabelText = (props: Pick<FormComponentProps, 'label' | 'schema'>): React.ReactNode =>
  props.label || get(props.schema, 'title', '');

export const getIntroductionText = (props: Pick<FormComponentProps, 'introduction'>): React.ReactNode =>
  props.introduction;

export type StringOrLocalizedString = string | { [key: string]: string };
export type SingleInputValue = StringOrLocalizedString | boolean | number;
export type getDisplayValueInputValue = (SingleInputValue | SingleInputValue[]) | null | undefined;

export const getDisplayValue = (value: getDisplayValueInputValue, intl: InjectedIntl): string | string[] => {
  const getGlobalText = getTextFactory(intl, 'global');

  if (typeof value === 'undefined' || value === null) {
    return '';
  }

  if (Array.isArray(value)) {
    return value.map((val): string => getDisplayValue(val, intl) as string);
  }

  if (typeof value === 'object') {
    return value[intl.locale];
  }

  if (typeof value === 'boolean') {
    return getGlobalText(String(value)) || String(value);
  }

  return String(value);
};

export const getErrorMessage = (props: FormComponentProps): string => {
  const { input, meta, formatErrorMessage } = props;
  const { error } = meta;

  // if (meta.valid) {
  //   return '';
  // }

  if (error) {
    // Treat `minLength` and `type` errors on empty fields as required errors
    if ((error.type === 'minLength' || error.type === 'type') && !input.value) {
      error.type = 'required';
    }

    // Make sure we show Required field when a field must respect a pattern
    // but do not have value yet
    if (error.type === 'pattern' && !input.value) {
      error.type = 'required';
    }

    if (formatErrorMessage) {
      const errorMessage = formatErrorMessage(error, props);
      if (errorMessage) {
        return errorMessage;
      }
    }
  }

  return (error && error.message) || '';
};

export const isInvalidOnChange = (
  props: Pick<FormComponentProps, 'schema' | 'customSchema' | 'input' | 'isReadOnly' | 'meta'>,
) => {
  const { schema, customSchema, input } = props;
  const pattern = (schema && schema.pattern) || (customSchema && customSchema.pattern);
  const schemaPatternInvalid = pattern ? !new RegExp(pattern).test(input.value) : false;
  const dirty = props.meta && (props.meta.dirty || props.meta.submitFailed);

  return Boolean(
    dirty && (schemaPatternInvalid || (!props.isReadOnly && props.meta && props.meta.invalid && props.meta.touched)),
  );
};

export const isPrefilled = (props: FormComponentProps): boolean =>
  !props.isReadOnly && props.input.value && props.meta.pristine;

export const getSchemaOptions = (
  { schema, refData, intl, input }: FormComponentProps,
  sort?: ((a: OptionsType, b: OptionsType) => number) | boolean,
  filter?: Function | null,
): OptionsType[] => {
  const locale = intl && intl.locale;

  const simpleSort = (
    { label: label1, sortIndex: sortIndex1 }: OptionsType,
    { label: label2, sortIndex: sortIndex2 }: OptionsType,
  ): number =>
    sortIndex1 || sortIndex2
      ? (sortIndex1 || 0) - (sortIndex2 || 0)
      : label1.localeCompare(label2, locale, { sensitivity: 'base' });

  const options: OptionsType[] = (refData || (schema && schema.refData) || []).map(
    (value: RefData): OptionsType => {
      const val: RefDataContent = locale && value[locale];
      return {
        selected: input.value === value.valueDomainCode,
        value: value.valueDomainCode,
        label: val && val.label,
        ...(value.readOnly ? { readOnly: value.readOnly } : {}),
        ...(val && val.sortIndex ? { sortIndex: val.sortIndex } : {}),
      };
    },
  );

  const filterOptions: OptionsType[] = filter
    ? options.filter((option: OptionsType): boolean => option.value === input.value || filter(option))
    : options;
  const sortFunction = sort === true ? simpleSort : sort;

  let sortedOptions: OptionsType[] = [];
  if (sortFunction) {
    sortedOptions = [...filterOptions].sort(sortFunction);
  }

  const sortOptions: OptionsType[] = sort && sortedOptions.length !== 0 ? sortedOptions : filterOptions;
  return sortOptions;
};

export const getOptionsReadOnlyLabel = (options: OptionsType[] = [], inputValue?: string) => {
  const option = options.find(({ value }: OptionsType): boolean => value === inputValue);
  return option ? option.label : '';
};

export const parseNumber = (str: string): number | null => {
  const value = str.replace(/\D/g, '');

  return value === '' ? null : Number.parseInt(value, 10);
};

export const validateDateFields = (
  inputDate: Required<DateFieldsValue>,
  validate: boolean,
  dateFieldsOptions?: Pick<DateFieldsProps, 'display'>,
) => {
  const { year, month, day } = inputDate;

  const yy = Number.parseInt(year || '', 10);
  const mm = Number.parseInt(month || '', 10);
  const dd = Number.parseInt(day || '', 10);

  const isBetween = (num: number, min: number = -Infinity, max: number = Infinity): boolean => {
    if (isNaN(num)) return false;
    return num >= min && num <= max;
  };

  const leapYear = (!(yy % 4) && yy % 100) || !(yy % 400);
  const daysPerMonth = [31, leapYear ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const lastDayOfMonth = daysPerMonth[mm - 1] || 31;

  const invalidDateFields = {
    hasErrorYear:
      validate &&
      (!dateFieldsOptions || !dateFieldsOptions.display || dateFieldsOptions.display.year !== false) &&
      isNaN(yy),
    hasErrorMonth:
      validate &&
      (!dateFieldsOptions || !dateFieldsOptions.display || dateFieldsOptions.display.month !== false) &&
      !isBetween(mm, 1, 12),
    hasErrorDay:
      validate &&
      (!dateFieldsOptions || !dateFieldsOptions.display || dateFieldsOptions.display.day !== false) &&
      !isBetween(dd, 1, lastDayOfMonth),
  };
  return invalidDateFields;
};

export const convertDateStringValue = (dateString: string): Required<DateFieldsValue> => {
  if (dateString) {
    const dateParts = dateString.split('-');
    return {
      day: dateParts[2] || '',
      month: dateParts[1] || '',
      year: dateParts[0] || '',
    };
  }
  return {
    day: '',
    month: '',
    year: '',
  };
};
