import { combineReducers, Reducer } from 'redux';
import { routerReducer } from 'react-router-redux';
import { intlReducer } from 'react-intl-redux';
import { productFormReducer, reduxFormReducer } from 'bnc-react-forms';
import melissaServiceReducer from 'bnc-react-services/services/MelissaService/reducer';

import stockSymbolServiceReducer from 'services/StockSymbolService/reducer';
import occupationTitleServiceReducer from 'services/OccupationTitleService/reducer';
import baoReducer from 'services/baoService/reducers';
import { RootState, ProductFormReducerState } from 'types/reducers';

const appReducer = combineReducers<RootState>({
  intl: intlReducer,
  routing: routerReducer,
  baoReducer,
  productFormReducer: productFormReducer as Reducer<ProductFormReducerState>,
  reduxFormReducer,
  melissaServiceReducer,
  stockSymbolServiceReducer,
  occupationTitleServiceReducer,
});

const rootReducer = (): Reducer<RootState> => appReducer;

export default rootReducer;
