import { AddressValidationData } from 'containers/Employment/types';
import { DateTime } from 'luxon';
import { ErrorsDictionary } from 'types/interfaces';
import { ERRORTYPE, addressValidation } from 'utils/validation';
import { EmployerPhoneNumberValidationError, EmploymentV2ErrorsDictionary, EmploymentV2FormData } from './types';
import { extensionNumberRegex, phoneNumberCanadaUSARegex } from 'utils/regex';
import { COUNTRIES } from 'utils/constants';

export const EMPLOYMENT_ERROR_TYPE: EmploymentV2ErrorsDictionary = {
  ...ERRORTYPE,
  START_DATE_MUST_HAVE_VALID_DATA: { type: 'startDateMustBeInThePast' },
};

export const startOccupationDateValidation = (values: EmploymentV2FormData): ErrorsDictionary => {
  const { startOccupationDate } = values;

  if (!startOccupationDate) {
    return { startOccupationDate: EMPLOYMENT_ERROR_TYPE.PATTERN };
  }

  const date = DateTime.fromISO(startOccupationDate);
  const { month, year } = date;

  if (!month || !year) {
    return { startOccupationDate: EMPLOYMENT_ERROR_TYPE.REQUIRED };
  }

  if (!date.isValid) {
    return { startOccupationDate: EMPLOYMENT_ERROR_TYPE.PATTERN };
  }

  if ((date.diffNow('months').toObject().months || 0) >= 0) {
    return { startOccupationDate: EMPLOYMENT_ERROR_TYPE.START_DATE_MUST_HAVE_VALID_DATA };
  }

  return {};
};

export const employmentPhoneValidation = (values: EmploymentV2FormData): ErrorsDictionary => {
  if (values.employerPhoneNumber === undefined || values.employerPhoneNumber === null) {
    return {};
  }

  const { country, number, extensionNumber } = values.employerPhoneNumber;

  const validationRule: EmployerPhoneNumberValidationError = {
    country: undefined,
    number: undefined,
    extensionNumber: undefined,
  };

  if (number && number !== '') {
    if (country === COUNTRIES.CA || country === COUNTRIES.US) {
      if (number.length < 10) {
        validationRule.number = EMPLOYMENT_ERROR_TYPE.PATTERN;
      }

      if (!phoneNumberCanadaUSARegex.test(number)) {
        validationRule.number = EMPLOYMENT_ERROR_TYPE.PATTERN;
      }
    } else {
      if (number.length < 4) {
        validationRule.number = EMPLOYMENT_ERROR_TYPE.PATTERN;
      }
    }

    if (extensionNumber && extensionNumber !== '' && !extensionNumberRegex.test(extensionNumber)) {
      validationRule.extensionNumber = EMPLOYMENT_ERROR_TYPE.PATTERN;
    }
  } else {
    if (extensionNumber && extensionNumber !== '') {
      validationRule.number = EMPLOYMENT_ERROR_TYPE.REQUIRED;
    }
  }

  return validationRule;
};

const validation = (values: EmploymentV2FormData): AddressValidationData | ErrorsDictionary | {} => {
  const { employerAddress, emplUID } = values;
  const selectedOccupationError = emplUID === null;

  return {
    ...(selectedOccupationError
      ? { _error: { type: 'selectMainOccupation.required' } }
      : {
          employerAddress: addressValidation(employerAddress),
          employerPhoneNumber: employmentPhoneValidation(values),
          ...startOccupationDateValidation(values),
        }),
  };
};

export default validation;
