import React, { PureComponent, ReactNode } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import Heading from 'components/Heading';
import EditIcon from 'assets/icons/edit';
import { getTextFactory } from 'utils/TextUtils';
import { InjectedIntl } from 'react-intl';
import { Link } from '@nbc-design/link';

export type SummarySectionProps = {
  intl: InjectedIntl;
  name: string;
  title: string;
  stepId: string;
  goToStep: (stepId: string) => string;
  renderSectionContent: () => ReactNode;
  editable?: boolean;
};

export type SummarySectionState = {
  isContentVisible: boolean;
};

class SummarySection extends PureComponent<SummarySectionProps, SummarySectionState> {
  constructor(props) {
    super(props);

    this.state = {
      isContentVisible: true,
    };
  }

  handleOnTitleClick = () => {
    this.toggleContentVisibility();
  };

  toggleContentVisibility = () => {
    const { isContentVisible } = this.state;
    this.setState({ isContentVisible: !isContentVisible });
  };

  handleOnEditButtonClick = () => {
    const { goToStep, stepId } = this.props;

    goToStep(stepId);
  };

  renderContent() {
    const { isContentVisible } = this.state;

    if (!isContentVisible) {
      return null;
    }

    const { renderSectionContent } = this.props;

    return renderSectionContent();
  }

  render() {
    const { name, title, intl, editable = true } = this.props;
    const getAccessibilityText = getTextFactory(intl, 'accessibility.icon');

    return (
      <div className={`${name}Details summary__section`}>
        <Row className="section__header">
          <Col xs={11} md={11} xl={11} onClick={this.handleOnTitleClick} className="section__title">
            <Heading type="h3" size={5} data-test="label_page_title">
              {title}
            </Heading>
          </Col>
          {editable && (
            <Col
              xs={1}
              md={1}
              xl={1}
              onClick={this.handleOnEditButtonClick}
              className="section__icon"
              data-test="image_edit_icon"
            >
              <Link href="#" underlined={false}>
                <EditIcon className="editIcon" title={getAccessibilityText('edit')} />
              </Link>
            </Col>
          )}
        </Row>
        <Row className={`section__content`}>
          <Col xs={12} md={12} xl={12}>
            {this.renderContent()}
          </Col>
        </Row>
      </div>
    );
  }
}

export default SummarySection;
