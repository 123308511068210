import React from 'react';
import { ACCOUNT_SOURCE } from '../constants';
import { useContainerCtx } from '../../../providers/containerCtx/ContainerCtx';
import { TransferFormData, TransferFormRadioKeys } from '../types';
import TransferAccountsOtherFinItem from './TransferAccountsOtherFinItem';
import AccountSubContentContainer from './helpers/AccountSubContentContainer';
import { ProductFormRadioWithSubContent } from 'components/productFormFields/_layout';

const TransferAccountsOtherFin = () => {
  const { getText, formValues } = useContainerCtx<TransferFormData>();

  const { accounts } = formValues;
  const hasOtherFinAccounts = accounts?.some((account) => account.transferSourceCd === ACCOUNT_SOURCE.OTHFIN);

  if (!hasOtherFinAccounts) return null;
  return (
    <ProductFormRadioWithSubContent<TransferFormRadioKeys>
      label={getText('transferAccountOtherFin')}
      name={'transferAccountOtherFin'}
      hasDivider={false}
    >
      <AccountSubContentContainer
        filteredSource={ACCOUNT_SOURCE.OTHFIN}
        headerText={getText('accountTransfer')}
        errorKey={'transferFinancialError'}
        MapAccountItem={TransferAccountsOtherFinItem}
      />
    </ProductFormRadioWithSubContent>
  );
};

export default TransferAccountsOtherFin;
