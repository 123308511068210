import React, { FC, useState, FocusEvent, ChangeEvent } from 'react';
import { InjectedIntl } from 'react-intl';
import classnames from 'classnames';
import { IconButton } from '@nbc-design/button';
import { Check } from '@nbc-design/icons/lib/web/Check';
import { Close } from '@nbc-design/icons/lib/web/Close';
import { Link } from '@nbc-design/link';
import { Text } from '@nbc-design/text';

import { ProductFormInput, ProductFormPhoneInput } from 'components/productFormFields';
import { phoneNumberFormatter } from 'utils';
import { EventData } from 'types/interfaces';
import { createErrorFormatter } from 'utils/productFormUtils';
import { getTextFactory } from 'utils/TextUtils';

type ConfirmInfoProps = {
  change: Function;
  email: string;
  phoneNumber: string;
  intl: InjectedIntl;
  handleOnChange: (event: ChangeEvent<HTMLInputElement>, isValid?: boolean) => void;
};

const ConfirmInfo: FC<ConfirmInfoProps> = (props: ConfirmInfoProps) => {
  const { change, email, phoneNumber, intl, handleOnChange } = props;

  const getText = getTextFactory(intl, 'esign');
  const getAccessibilityText = getTextFactory(intl, 'accessibility.icon');

  const [infoSatus, setInfoStatus] = useState({
    phoneDisabled: true,
    emailDisabled: true,
  });

  const [infoValue, setInfoValue] = useState({
    emailInfo: { newValue: email, oldValue: email },
    phoneInfo: { newValue: phoneNumber, oldValue: phoneNumber },
  });

  const setFieldEnabled = (field: string, enabled: boolean) => {
    setInfoStatus({ ...infoSatus, [`${field}Disabled`]: !enabled });
  };

  const modifyFields = (fieldToEnable: string, fieldToDisable: string) => {
    return () =>
      setInfoStatus({
        ...infoSatus,
        [`${fieldToEnable}Disabled`]: false,
        [`${fieldToDisable}Disabled`]: true,
      });
  };

  const cancelField = (field: string) => {
    return () => {
      const { oldValue } = infoValue[`${field}Info`];
      updateInfoFor(field, oldValue);
      setFieldEnabled(field, false);
      if (field === 'email') {
        change('esignEmail', oldValue);
      } else {
        change('phoneNumber.phoneNumber', oldValue);
      }
    };
  };

  const saveField = (field: string) => {
    return () => {
      setFieldEnabled(field, false);
      const { newValue } = infoValue[`${field}Info`];
      updateInfoFor(field, newValue, true);
    };
  };

  const handleBlurFor = (field: string) => (event: FocusEvent<HTMLInputElement>) => {
    if (!event.relatedTarget || (event.relatedTarget && (event.relatedTarget as any).id === `cancel-${field}`)) {
      cancelField(field)();
    } else if (event.relatedTarget && (event.relatedTarget as any).id === `save-${field}`) {
      setFieldEnabled(field, false);
      const { newValue } = infoValue[`${field}Info`];
      updateInfoFor(field, newValue, true);
    }
  };

  const updateInfoFor = (field: string, value: string, overrideOldValue?: boolean) => {
    const { oldValue } = infoValue[`${field}Info`];
    setInfoValue({
      ...infoValue,
      [`${field}Info`]: {
        oldValue: overrideOldValue ? value : oldValue,
        newValue: value,
      },
    });
  };

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>, isValid?: boolean) => {
    const { value }: EventData = event.target;
    //See line 63 in components/productFormFields/ProductFormInput/ProductFormInput.tsx
    event.target.name = 'phoneNumber.phoneNumber';
    const newPhoneNumber = phoneNumberFormatter(value);
    updateInfoFor('phone', newPhoneNumber);
    handleOnChange(event, isValid);
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>, isValid?: boolean) => {
    const { value }: EventData = event.target;
    //See line 63 in components/productFormFields/ProductFormInput/ProductFormInput.tsx
    event.target.name = 'esignEmail';
    updateInfoFor('email', value);
    handleOnChange(event, isValid);
  };

  const formatErrorMessage = (prefix?: string) => createErrorFormatter(intl, prefix);

  return (
    <div className="esign-info-container">
      <Text size="paragraph">{getText('confirmInformation')}</Text>
      <div className={classnames('esign-info-email', { disabled: infoSatus.emailDisabled })}>
        <span>{getText('email')}</span>
        <ProductFormInput
          name="esignEmail"
          formatErrorMessage={formatErrorMessage()}
          disabled={infoSatus.emailDisabled}
          handleOnBlur={handleBlurFor('email')}
          handleOnChange={handleEmailChange}
          ariaLabel={getText('email')}
        />
        {infoSatus.emailDisabled ? (
          <div className="action-buttons-container">
            <Link href="#" underlined={true} className="link-modify" onClick={modifyFields('email', 'phone')}>
              {getText('modifyLink')}
            </Link>
          </div>
        ) : (
          <div className="action-buttons-container">
            <IconButton
              icon={<Check title={getAccessibilityText('confirm')} />}
              type="button"
              appearance="secondary"
              className="action-confirm-changes"
              onClick={saveField('email')}
              id="save-email"
            />
            <IconButton
              icon={<Close title={getAccessibilityText('cancel')} />}
              type="button"
              appearance="secondary"
              className="action-cancel-changes"
              onClick={cancelField('email')}
              id="cancel-email"
            />
          </div>
        )}
      </div>

      <div className={classnames('esign-info-phone', { disabled: infoSatus.phoneDisabled })}>
        <span>{getText('phone')}</span>
        <ProductFormPhoneInput
          name="phoneNumber"
          formatErrorMessage={formatErrorMessage()}
          disablePhoneType
          disablePhone={infoSatus.phoneDisabled}
          change={change}
          handleOnBlur={handleBlurFor('phone')}
          handleOnChange={handlePhoneChange}
        />
        {infoSatus.phoneDisabled ? (
          <div className="action-buttons-container">
            <Link href="#" underlined={true} className="link-modify" onClick={modifyFields('phone', 'email')}>
              {getText('modifyLink')}
            </Link>
          </div>
        ) : (
          <div className="action-buttons-container">
            <IconButton
              icon={<Check title={getAccessibilityText('confirm')} />}
              type="button"
              appearance="secondary"
              className="action-confirm-changes"
              onClick={saveField('phone')}
              id="save-phone"
            />
            <IconButton
              icon={<Close title={getAccessibilityText('cancel')} />}
              type="button"
              appearance="secondary"
              className="action-cancel-changes"
              onClick={cancelField('phone')}
              id="cancel-phone"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmInfo;
