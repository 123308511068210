import { ROUTE } from 'utils/constants';

const config = {
  PATH: ROUTE.CREATE_USER_PROFILE,
  ID: 'CREATE_USER_PROFILE',
  SIDEBAR_IMAGE: false,
  BUTTON_HELP: true,
  BUTTON_LANG: true,
  BUTTON_QUIT: true,
  FOOTER: true,
};

export default config;
