export enum OTHER_RESIDENCY_ANSWERS {
  YES = 'Y',
  NO = 'N',
}

export enum NO_REVEAL_TIN_REASON {
  NOT_YET_RECEIVED = '1000001',
  NO_TIN = '1000002',
  NOT_ON_HAND = '1000003',
}

export const USA_REMOVE_REASON = NO_REVEAL_TIN_REASON.NO_TIN;
