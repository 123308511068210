import React from 'react';
import { TransferAccountDataType, TransferFormData } from '../types';
import { ACCOUNT_TYPE } from '../../AccountsStep1/constants';
import { Message } from '@nbc-design/message';
import { ProductFormComboRadio } from 'components/productFormFields';
import { useContainerCtx } from 'providers/containerCtx/ContainerCtx';
import { RefData, Schema } from 'types/interfaces';
import get from 'lodash/get';

type TransferAccountOtherFinItemProps = { accountData: TransferAccountDataType; index: number };

const TransferAccountsOtherFinItem: React.FC<TransferAccountOtherFinItemProps> = ({ accountData, index }) => {
  const { formValues, genericProps, getText, formatErrorMessage } = useContainerCtx<TransferFormData>();

  const { accounts } = formValues;
  const { accountType } = accountData;

  const accountTransferDetailSchema: Schema = get(genericProps.productForm, 'schema.definitions.AccountTransferDetail');
  const transferTypeRefData: RefData[] = get(accountTransferDetailSchema, 'properties.transferType.refData', []);

  const transferTypeInfoMessage: string[] = ['TOTAL_MIX', 'PART_KIND'];

  const isInfoMessageDisplayed = (acc?: TransferAccountDataType): boolean => {
    return !!acc?.transferType && transferTypeInfoMessage.includes(acc?.transferType);
  };

  return (
    <>
      {accountType['code'] === ACCOUNT_TYPE.RESP && (
        <Message className="dsc-message-employer" data-test={`dsc-message-employer`}>
          {getText('transferApplication')}
        </Message>
      )}

      {accountType['code'] !== ACCOUNT_TYPE.RESP && (
        <div>
          <ProductFormComboRadio
            name={`accounts[${index}].transferType`}
            formatErrorMessage={formatErrorMessage('transfer', 'atLeastOneTransferType')}
            refData={transferTypeRefData}
          />

          {isInfoMessageDisplayed(accounts?.[index]) && (
            <Message data-test={`accounts[${index}].transferType.message`}>{getText('transferApplication')}</Message>
          )}
        </div>
      )}
    </>
  );
};

export default TransferAccountsOtherFinItem;
