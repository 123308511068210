export const BNC_RECAPTCHA_ID = 'recap';

export type Token = {
  accessToken: string;
  tokenType: string;
  expiresIn: string;
  scope: string;
  expirationTime: Date;
};

export type CurrentEnv = {
  ENV: string;
};

export const isTokenStillValid = (token: Token): boolean => {
  if (!token || !token.accessToken || !token.tokenType || !token.expiresIn || !token.scope || !token.expirationTime) {
    return false;
  }
  return new Date() <= token.expirationTime;
};
