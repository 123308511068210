import { Idp, RedirectUri, SsoData } from './types';

export type SSOEnv = { name: string; code: string };

export const SSO_ENVS: SSOEnv[] = [
  {
    name: 'LOCAL',
    code: 'local',
  },
  {
    name: 'DEV',
    code: 'dev',
  },
  {
    name: 'TI',
    code: 'ti',
  },
  {
    name: 'TA',
    code: 'test',
  },
  {
    name: 'PP',
    code: 'pp',
  },
  {
    name: 'PROD',
    code: 'prod',
  },
];

// https://wiki.bnc.ca/display/IAMAS/IAMx+Servers+-+Okta+on+API+Connect+and+External+Datapower
export enum SSO_BASE_URL {
  // local and dev env
  LOCAL = 'https://api-ti.bnc.ca/bnc/ti-okta/sso',
  DEV = 'https://api-ti.bnc.ca/bnc/ti-okta/sso',

  // TI, TA, PP goes through their respective non-production DataPower as an Okta Gateway
  TI = 'https://api-ti.bnc.ca/bnc/ti-okta/sso',
  TEST = 'https://api-ta.bnc.ca/bnc/ta-okta/sso',
  PP = 'https://api-pp.bnc.ca/bnc/pp-okta/sso',

  // PROD goes through production DataPower as an Okta Gateway
  PROD = 'https://api.bnc.ca/bnc/prod-okta/sso',
}

export enum SSO_ISSUER_BASE_URL {
  LOCAL = 'https://nbc.oktapreview.com',
  DEV = 'https://nbc.oktapreview.com',
  TI = 'https://nbc.oktapreview.com',
  TEST = 'https://nbc.oktapreview.com',
  PP = 'https://nbc.oktapreview.com',
  PROD = 'https://nbc.okta.com',
}

export const SSO_REDIRECT_URI: RedirectUri = {
  LOCAL: {
    EN: '/bao/welcome',
    FR: '/bao/welcome',
  },
  DEV: {
    EN: '/bao/welcome',
    FR: '/bao/welcome',
  },
  TI: {
    EN: '/bao/welcome',
    FR: '/bao/welcome',
  },
  TEST: {
    EN: '/bao/welcome',
    FR: '/bao/welcome',
  },
  PP: {
    EN: '/bao/welcome',
    FR: '/bao/welcome',
  },
  PROD: {
    EN: '/bao/welcome',
    FR: '/bao/welcome',
  },
};

export const SSO_IDP: Idp = {
  LOCAL: {
    EN: '0oar5oyhl0HhH7UaL0h7',
    FR: '0oara2e9dgUZE5fVy0h7',
  },
  DEV: {
    EN: '0oarfgpq6gawoCoQX0h7',
    FR: '0oarfgogpq4S6MF7q0h7',
  },
  TI: {
    EN: '0oar5ouer4TJdivTd0h7',
    FR: '0oara2heddk2OnWc90h7',
  },
  TEST: {
    EN: '0oar5p0824BqGZ6Yd0h7',
    FR: '0oara2ffq8PSRNSSI0h7',
  },
  PP: {
    EN: '0oar5oufne0LTgL4F0h7',
    FR: '0oara2i0r6IE2rKSq0h7',
  },
  PROD: {
    EN: '0oa70opuxa9ipbfSl2p7',
    FR: '0oa72wacvy4HnXAOb2p7',
  },
};

export enum SSO_CLIENT_ID {
  NO_PROD = '0oadznf2itkJ6YJ7H0h7',
  PROD = '0oa14mlkaoblShmmb2p7',
}

export enum SSO_AUTHN_ID {
  NO_PROD = 'ausaghxwz0lbXmszq0h7',
  PROD = 'ausxvdhuBXg5QRDL82p5',
}

export enum SSO_RESPONSE_TYPE {
  TOKEN = 'token',
  ID_TOKEN = 'id_token',
}

export enum SSO_LIB_URL {
  DEV = 'https://connexion-ti.bnc.ca/sso.lib.js',
  TI = 'https://connexion-ti.bnc.ca/sso.lib.js',
  TEST = 'https://connexion-ta.bnc.ca/sso.lib.js',
  PP = 'https://connexion-pp.bnc.ca/sso.lib.js',
  PROD = 'https://connexion.bnc.ca/sso.lib.js',
}

export const SSO_DATA: SsoData = {
  LOCAL: {
    BASE_URL: SSO_BASE_URL.LOCAL,
    ISSUER_BASE_URL: SSO_ISSUER_BASE_URL.LOCAL,
    REDIRECT_URI: SSO_REDIRECT_URI.LOCAL,
    IDP: SSO_IDP.LOCAL,
    CLIENT_ID: SSO_CLIENT_ID.NO_PROD,
    AUTHN_ID: SSO_AUTHN_ID.NO_PROD,
    RESPONSE_TYPE: SSO_RESPONSE_TYPE.TOKEN,
    LIB_URL: SSO_LIB_URL.DEV,
  },
  DEV: {
    BASE_URL: SSO_BASE_URL.DEV,
    ISSUER_BASE_URL: SSO_ISSUER_BASE_URL.DEV,
    REDIRECT_URI: SSO_REDIRECT_URI.DEV,
    IDP: SSO_IDP.DEV,
    CLIENT_ID: SSO_CLIENT_ID.NO_PROD,
    AUTHN_ID: SSO_AUTHN_ID.NO_PROD,
    RESPONSE_TYPE: SSO_RESPONSE_TYPE.TOKEN,
    LIB_URL: SSO_LIB_URL.DEV,
  },
  TI: {
    BASE_URL: SSO_BASE_URL.TI,
    ISSUER_BASE_URL: SSO_ISSUER_BASE_URL.TI,
    REDIRECT_URI: SSO_REDIRECT_URI.TI,
    IDP: SSO_IDP.TI,
    CLIENT_ID: SSO_CLIENT_ID.NO_PROD,
    AUTHN_ID: SSO_AUTHN_ID.NO_PROD,
    RESPONSE_TYPE: SSO_RESPONSE_TYPE.TOKEN,
    LIB_URL: SSO_LIB_URL.TI,
  },
  TEST: {
    BASE_URL: SSO_BASE_URL.TEST,
    ISSUER_BASE_URL: SSO_ISSUER_BASE_URL.TEST,
    REDIRECT_URI: SSO_REDIRECT_URI.TEST,
    IDP: SSO_IDP.TEST,
    CLIENT_ID: SSO_CLIENT_ID.NO_PROD,
    AUTHN_ID: SSO_AUTHN_ID.NO_PROD,
    RESPONSE_TYPE: SSO_RESPONSE_TYPE.TOKEN,
    LIB_URL: SSO_LIB_URL.TEST,
  },
  PP: {
    BASE_URL: SSO_BASE_URL.PP,
    ISSUER_BASE_URL: SSO_ISSUER_BASE_URL.PP,
    REDIRECT_URI: SSO_REDIRECT_URI.PP,
    IDP: SSO_IDP.PP,
    CLIENT_ID: SSO_CLIENT_ID.NO_PROD,
    AUTHN_ID: SSO_AUTHN_ID.NO_PROD,
    RESPONSE_TYPE: SSO_RESPONSE_TYPE.TOKEN,
    LIB_URL: SSO_LIB_URL.PP,
  },
  PROD: {
    BASE_URL: SSO_BASE_URL.PROD,
    ISSUER_BASE_URL: SSO_ISSUER_BASE_URL.PROD,
    REDIRECT_URI: SSO_REDIRECT_URI.PROD,
    IDP: SSO_IDP.PROD,
    CLIENT_ID: SSO_CLIENT_ID.PROD,
    AUTHN_ID: SSO_AUTHN_ID.PROD,
    RESPONSE_TYPE: SSO_RESPONSE_TYPE.TOKEN,
    LIB_URL: SSO_LIB_URL.PROD,
  },
};
