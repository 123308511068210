import React, { useEffect, ChangeEvent, useState, FC } from 'react';
import trimStart from 'lodash/trimStart';
import { FormGroup } from '@nbc-design/form-group';
import { FormComponentProps, OptionsType } from '../types';
import { getLabelText, getErrorMessage, isInvalidOnChange, getSchemaOptions } from '../utils';
import AnalyticsHelper from 'services/analytics/AnalyticsHelper';

import './style.scss';
import { getTextFactory } from 'utils/TextUtils';
import { InputGroup, InputGroupProps } from '@nbc-design/input-group';
import { Select } from '@nbc-design/select';
import { ProductFormFieldChangeHandler } from 'types/interfaces';
import { ProductFormSelectProps } from '../ProductFormSelect/ProductFormSelect';
import { ProductFormInputProps } from '../ProductFormInput/ProductFormInput';
import { areaCodePhoneNumberFormatter } from 'utils';
import { COUNTRIES } from 'utils/constants';

export type ProductFormPhoneInputGroupProps = {
  trim?: boolean;
  maxLength?: number;
  disabled?: boolean;
  tooltip?: React.ReactNode;
  inputRawValue?: string;
  hasError?: boolean;
  prefixName: string;
  prefixValue: string;
  inputProps?: Partial<InputGroupProps>;
  change: ProductFormFieldChangeHandler;
  options: OptionsType[];
};

type DrilledProductFormInputProps = FormComponentProps &
  ProductFormInputProps &
  ProductFormSelectProps &
  ProductFormPhoneInputGroupProps;

const ProductFormPhoneInputGroup: FC<DrilledProductFormInputProps> = (props) => {
  const {
    intl,
    placeholder,
    optionalText,
    input,
    className,
    change,
    disabled,
    inputRawValue,
    helpText = null,
    tooltip = null,
    trim = false,
    hasError = false,
    inputProps: passedInputProps,
    prefixName = '',
    prefixValue,
    refData,
    sort,
    filter,
  } = props;

  const [areaCode, setAreaCode] = useState<string>(COUNTRIES.CA);
  const [phoneNumber, setPhoneNumber] = useState<string>('+1 ');

  const invalid = isInvalidOnChange(props) || (props.meta.submitFailed && hasError);
  const displayedValue = inputRawValue || input.value;
  const value = trim ? (displayedValue as string).trim() : trimStart(displayedValue);
  const onShowTooltip = AnalyticsHelper.handleShowTooltip(input.name);

  const optionsSelect: OptionsType[] = getSchemaOptions(props, sort, filter);

  useEffect(() => {
    if (value !== undefined) {
      const areaCodeData = refData?.find((data) => data.valueDomainCode === prefixValue);
      if (areaCodeData === undefined) {
        return;
      }

      setAreaCode(prefixValue);
      setPhoneNumber(areaCodePhoneNumberFormatter(`+${areaCodeData.en.shortDescription} ${value}`, prefixValue));
    }
  }, [prefixValue, value]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCountryChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const country = event.target.value;
    const areaCodeData = refData?.find((data) => data.valueDomainCode === country);

    const oldPhoneNumber = phoneNumber.split(' ')[1];

    if (oldPhoneNumber === undefined) {
      change(input.name, '');
      setPhoneNumber(`+${areaCodeData?.en.shortDescription} `);
    } else {
      const phoneNumberFormatter = areaCodePhoneNumberFormatter(
        `+${areaCodeData?.en.shortDescription} ${oldPhoneNumber}`,
        country,
      );

      if (country === COUNTRIES.CA || country === COUNTRIES.US) {
        change(input.name, oldPhoneNumber.replace(/[^\d]/g, '').slice(0, 10));
      } else {
        change(input.name, oldPhoneNumber.replace(/[^\d]/g, ''));
      }

      setPhoneNumber(phoneNumberFormatter);
    }

    if (change) {
      change(prefixName, event.target.value);
    }

    setAreaCode(country);
  };

  const selectProps = {
    id: `select-prefix-group`,
    name: prefixName,
    value: prefixValue,
    defaultValue: COUNTRIES.CA,
    placeholder,
    onChange: onCountryChanged,
    disabled,
    options: optionsSelect,
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const phoneText = event.target.value;
    const phoneValue = phoneText.split(' ');
    if (phoneValue[1]) {
      change(input.name, phoneValue[1].replace(/[^\d]/g, ''));
    } else {
      change(input.name, '');
    }
  };

  const label = getLabelText(props);
  const getAccessibilityText = getTextFactory(intl, 'accessibility');

  const maxLengthPhoneNumber = () => {
    const areaCodeData = refData?.find((data) => data.valueDomainCode === prefixValue);
    const maxNumberLength = 16;

    if (areaCode === COUNTRIES.CA || areaCode === COUNTRIES.US) {
      return 15;
    }

    return areaCodeData?.en.shortDescription?.length + maxNumberLength;
  };

  const inputProps = {
    ...passedInputProps,
    inputId: `inputGroup-${input.name}`,
    inputName: input.name,
    placeholder,
    value: phoneNumber,
    maxLength: maxLengthPhoneNumber(),
    onChange,
    prefix: <Select {...selectProps} />,
  };

  return (
    <FormGroup
      className={className}
      label={{ text: label, htmlFor: `inputGroup-${input.name}-form` }}
      optionalText={optionalText}
      tooltip={{ content: tooltip, ariaLabel: getAccessibilityText('information'), onShowTooltip }}
      validate={{ hasError: invalid, errorMsg: getErrorMessage(props) }}
      description={{ text: helpText, id: `helpInputGroup-${input.name}` }}
    >
      <InputGroup {...inputProps} />
    </FormGroup>
  );
};

export default ProductFormPhoneInputGroup;
