import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { AppStep } from 'types/interfaces';
import Layout from '../Layout';
import { AppProps } from './App';

export type LayoutRouteProps = AppProps &
  RouteComponentProps & {
    step: AppStep;
  };

const LayoutRoute: React.FC<LayoutRouteProps> = ({ step, ...props }) => (
  <Layout {...props} Page={step.container} currentStep={step.ID || ''} />
);

export default LayoutRoute;
