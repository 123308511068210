import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { formValues } from 'bnc-react-forms';

import * as actionCreators from 'services/baoService/actions';
import ESign from './ESign';
import config from './config';

export const CONFIG = config;

const mapDispatchToProps = (dispatch) => {
  return {
    setPreviousStepsBlocked: () => dispatch(actionCreators.blockPreviousSteps()),
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(injectIntl(formValues('displayPaperSign', 'displayIdentitySection', 'docPreference', 'paperSignSelected')(ESign)));
